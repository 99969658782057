
import * as _ from 'lodash';
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { AmmatillisetMaarayksetStore } from '@/stores/AmmatillisetMaarayksetStore';
import { Kielet } from '@shared/stores/kieli';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpLinkki from '@shared/components/EpLinkki/EpLinkki.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpSpinner,
    EpSearch,
    EpLinkki,
    EpBPagination,
    EpMaterialIcon,
  },
})
export default class RouteAmmatillinenMaaraykset extends Vue {
  @Prop({ required: true })
  private maarayksetStore!: AmmatillisetMaarayksetStore;

  private query = '';
  private page = 1;
  private perPage = 20;

  @Watch('query')
  onQueryChanged() {
    this.page = 1;
  }

  get maaraykset() {
    return this.maarayksetStore.maaraykset.value;
  }

  get maarayksetPaged() {
    if (this.maaraykset) {
      return _.chain(this.maaraykset)
        .filter(maarays => Kielet.search(this.query, maarays.nimi))
        .drop(this.perPage * (this.page - 1))
        .take(this.perPage)
        .value();
    }
  }

  get total() {
    return _.size(this.maaraykset);
  }
}
