
import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Kielet } from '../../stores/kieli';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpMaterialIcon,
  },
})
export default class EpSearch extends Vue {
  @Prop({ type: String })
  private value!: string;

  @Prop({ type: String })
  private placeholder!: string;

  @Prop({ type: String })
  private srPlaceholder!: string;

  @Prop({ required: false, default: false })
  private isLoading!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  private maxWidth!: boolean;

  get id() {
    return 'search-' + _.uniqueId();
  }

  get icon() {
    return this.isLoading ? 'spinner' : 'search';
  }

  get placeholderText() {
    return this.placeholder || this.$t('etsi');
  }

  get ariaPlaceholderText() {
    return this.srPlaceholder || this.$t('etsi-tietoja-sivulta-haku');
  }

  public onInput(input: any) {
    this.$emit('input', input);
  }

  get val() {
    if (_.isObject(this.value)) {
      return (this.value as any)[Kielet.getSisaltoKieli.value];
    }
    else {
      return this.value;
    }
  }
}
