import { render, staticRenderFns } from "./EpToteutussuunnitelmaKotoLaajaAlainenOsaaminen.vue?vue&type=template&id=882bb384&scoped=true"
import script from "./EpToteutussuunnitelmaKotoLaajaAlainenOsaaminen.vue?vue&type=script&lang=ts"
export * from "./EpToteutussuunnitelmaKotoLaajaAlainenOsaaminen.vue?vue&type=script&lang=ts"
import style0 from "./EpToteutussuunnitelmaKotoLaajaAlainenOsaaminen.vue?vue&type=style&index=0&id=882bb384&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "882bb384",
  null
  
)

export default component.exports