
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpHeading from '@shared/components/EpHeading/EpHeading.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import { OpetussuunnitelmaDataStore } from '@/stores/OpetussuunnitelmaDataStore';
import { Puu } from '@shared/api/ylops';

@Component({
  components: {
    EpSpinner,
    EpHeading,
    EpContentViewer,
    EpCollapse,
  },
})
export default class RouteOpetussuunnitelmaTekstikappale extends Vue {
  @Prop({ required: true })
  private opetussuunnitelmaDataStore!: OpetussuunnitelmaDataStore;

  get tekstikappaleId() {
    return _.toNumber(this.$route.params.viiteId);
  }

  get tekstiKappaleViite() {
    return this.opetussuunnitelmaDataStore.getJulkaistuSisalto({ id: this.tekstikappaleId });
  }

  get tekstiKappale() {
    return this.tekstiKappaleViite.tekstiKappale;
  }

  get perusteTekstikappaleViite() {
    return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto({ _perusteenOsa: _.toString(this.tekstiKappaleViite.perusteTekstikappaleId) });
  }

  get perusteTekstikappale() {
    if (this.perusteTekstikappaleViite) {
      return this.perusteTekstikappaleViite.perusteenOsa;
    }
  }

  get istekstiKappaleAllLoaded() {
    return !!this.tekstiKappaleViite;
  }

  get tekstiKappaleOriginals() {
    return _.map([
      ...(this.tekstiKappaleViite.original ? [this.tekstiKappaleViite.original] : []),
      ...(this.tekstiKappaleViite.original && this.tekstiKappaleViite.original.original && this.tekstiKappaleViite.original.naytaPohjanTeksti ? [this.tekstiKappaleViite.original.original] : []),
    ], 'tekstiKappale');
  }

  get hasTekstikappaleOriginalsTeksteja() {
    return _.size(_.filter(this.tekstiKappaleOriginals, 'teksti')) > 0;
  }

  get perusteAlikappaleetObj() {
    if (!_.isEmpty(this.perusteTekstikappaleViite)) {
      const viitteet: any[] = [];
      const stack = [this.perusteTekstikappaleViite!];

      while (!_.isEmpty(stack)) {
        const head: any = stack.shift()!;

        if (head.perusteenOsa) {
          viitteet.push(head.perusteenOsa);
        }

        stack.unshift(..._.map(head.lapset, viite => ({
          ...viite,
        })));
      }

      // Poistetaan nykyinen viite alikappaleista
      return _.keyBy(_.slice(viitteet, 1), 'id');
    }
  }

  get alikappaleet() {
    if (!_.isEmpty(this.tekstiKappaleViite)) {
      const viitteet: Puu[] = [];
      const stack: Puu[] = [this.tekstiKappaleViite!];

      while (!_.isEmpty(stack)) {
        const head: any = stack.shift()!;

        if (head.tekstiKappale) {
          viitteet.push(head);
        }

        stack.unshift(..._.map(head.lapset, viite => ({
          ...viite,
          level: (head.level || 0) + 1,
        })));
      }

      // Poistetaan nykyinen viite alikappaleista
      return _.slice(_.reject(viitteet, 'piilotettu'), 1);
    }
    else {
      return [];
    }
  }

  get perusteTermit() {
    return this.opetussuunnitelmaDataStore.perusteTermit;
  }

  get termit() {
    return this.opetussuunnitelmaDataStore.termit;
  }

  get perusteKuvat() {
    return this.opetussuunnitelmaDataStore.perusteKuvat;
  }

  get kuvat() {
    return this.opetussuunnitelmaDataStore.kuvat;
  }

  get current() {
    return this.opetussuunnitelmaDataStore.current || null;
  }

  get pohjaNimi() {
    return this.opetussuunnitelmaDataStore.opetussuunnitelma?.pohja?.nimi;
  }

  get laajaAlaisetOsaamiset() {
    if (this.perusteTekstikappale?.tunniste === 'laajaalainenosaaminen') {
      return this.opetussuunnitelmaDataStore.getJulkaistuPerusteSisalto('aipe.laajaalaisetosaamiset');
    }
  }
}

