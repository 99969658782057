import { render, staticRenderFns } from "./EpSidenavNode.vue?vue&type=template&id=49ada387&scoped=true"
import script from "./EpSidenavNode.vue?vue&type=script&lang=ts"
export * from "./EpSidenavNode.vue?vue&type=script&lang=ts"
import style0 from "./EpSidenavNode.vue?vue&type=style&index=0&id=49ada387&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ada387",
  null
  
)

export default component.exports