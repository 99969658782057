
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSpinnerSlot from '@shared/components/EpSpinner/EpSpinnerSlot.vue';
import Paikalliset from './Paikalliset.vue';
import PerusteTile from './PerusteTile.vue';
import { MurupolkuOsa } from '@/tyypit';
import { Meta } from '@shared/utils/decorators';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';
import _ from 'lodash';
import { RawLocation } from 'vue-router';
import { TiedoteDto } from '@shared/api/eperusteet';
import EpJulkiLista, { JulkiRivi } from '@shared/components/EpJulkiLista/EpJulkiLista.vue';
import { OpasStore } from '@/stores/OpasStore';
import { KoosteTiedotteetStore } from '@/stores/KoosteTiedotteetStore';
import { IPaikallinenStore } from '@/stores/IPaikallinenStore';
import { IPerusteKoosteStore } from '@/stores/IPerusteKoosteStore';

@Component({
  components: {
    EpSpinner,
    EpHeader,
    Paikalliset,
    EpExternalLink,
    PerusteTile,
    EpJulkiLista,
    EpSpinnerSlot,
  },
})
export default class RouteKooste extends Vue {
  @Prop({ required: false })
  private perusteKoosteStore!: IPerusteKoosteStore;

  @Prop({ required: true })
  private paikallinenStore!: IPaikallinenStore;

  @Prop({ required: false })
  private opasStore!: OpasStore;

  @Prop({ required: true })
  private tiedotteetStore!: KoosteTiedotteetStore;

  @Prop({ required: true })
  private paikallinenComponent!: any;

  @Prop({ required: false })
  private kuvaus!: string;

  @Prop({ required: false })
  private subheader!: string;

  @Prop({ required: false })
  private perusteetHeader!: string;

  private showEraantyneet: boolean = false;

  @Watch('koulutustyyppi', { immediate: true })
  async koulutustyyppiChange() {
    if (this.perusteKoosteStore) {
      await this.perusteKoosteStore.fetch();
      await this.tiedotteetStore.fetch(this.perusteKoosteStore?.perusteJulkaisut.value);
    }
    else {
      await this.tiedotteetStore.fetch();
    }
  }

  get koulutustyyppi() {
    return this.perusteKoosteStore?.koulutustyyppi?.value || _.get(this.$route.params, 'koulutustyyppi');
  }

  get tiedotteet() {
    if (this.tiedotteetStore?.tiedotteet.value) {
      return _.chain(this.tiedotteetStore.tiedotteet.value)
        .sortBy('luotu')
        .reverse()
        .value();
    }
  }

  get ohjeet() {
    if (this.opasStore?.oppaat.value) {
      return _.chain(this.opasStore.oppaat.value)
        .map(opas => {
          return {
            ...opas,
            otsikko: opas.nimi,
          } as JulkiRivi;
        })
        .sortBy('muokattu')
        .reverse()
        .value();
    }
  }

  get julkaistutPerusteet() {
    if (!this.perusteKoosteStore) {
      return [];
    }

    if (this.perusteKoosteStore?.perusteJulkaisut.value) {
      return _.chain(this.perusteKoosteStore.perusteJulkaisut.value)
        .map(julkaisu => ({
          ...julkaisu,
          perusteId: _.toString(julkaisu.id),
          kaannettyNimi: this.$kaanna(julkaisu.nimi!),
        }))
        .orderBy(['voimassaoloAlkaa', 'kaannettyNimi'], ['desc', 'asc'])
        .value();
    }
  }

  get visibleJulkaistutPerusteet() {
    if (this.showEraantyneet) {
      return [...this.julkaistutVoimassaolevatPerusteet, ...this.julkaistutEraantyneetPerusteet];
    }
    return this.julkaistutVoimassaolevatPerusteet;
  }

  get julkaistutVoimassaolevatPerusteet() {
    return _.filter(this.julkaistutPerusteet, (peruste) => !peruste.voimassaoloLoppuu || Date.now() < peruste.voimassaoloLoppuu);
  }

  get julkaistutEraantyneetPerusteet() {
    return _.filter(this.julkaistutPerusteet, (peruste) => peruste.voimassaoloLoppuu && Date.now() > peruste.voimassaoloLoppuu);
  }

  get muutTilet() {
    return this.perusteKoosteStore.muutTilet?.value;
  }

  toggleEraantyneet() {
    this.showEraantyneet = !this.showEraantyneet;
  }

  avaaTiedote(tiedote: TiedoteDto) {
    this.$router.push({
      name: 'uutinen',
      params: {
        tiedoteId: '' + tiedote.id,
      },
    });
  }

  avaaOpas(ohje: any) {
    this.$router.push({
      name: 'peruste',
      params: {
        koulutustyyppi: 'opas',
        perusteId: ohje.id,
      },
    });
  }

  @Meta
  getMetaInfo() {
    return {
      title: this.$t(this.koulutustyyppi),
    };
  }

  get murupolku(): Array<MurupolkuOsa> {
    return [{
      label: this.koulutustyyppi,
      location: {
        ...this.$route,
      } as RawLocation,
    }];
  }
}
