
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpJulkiLista from '@shared/components/EpJulkiLista/EpJulkiLista.vue';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import PerusteAmmatillinenHaku from './PerusteAmmatillinenHaku.vue';
import { AmmatillinenPerusteHakuStore } from '@/stores/AmmatillinenPerusteHakuStore';
import { Meta } from '@shared/utils/decorators';
import { AmmatillistenTiedoteStore } from '@/stores/AmmatillistenTiedoteStore';
import { koulutustyyppiRyhmat, KoulutustyyppiRyhma } from '@shared/utils/perusteet';
import * as _ from 'lodash';
import { MaaraysDtoTyyppiEnum, TiedoteDto } from '@shared/api/eperusteet';
import { Kielet } from '@shared/stores/kieli';

interface Ylalinkki {
  route: { name: string, query?: any, };
  text: string;
  icon?: string;
}

@Component({
  components: {
    EpSpinner,
    EpHeader,
    PerusteAmmatillinenHaku,
    EpJulkiLista,
    EpMaterialIcon,
  },
})
export default class RouteAmmatillinenSelaus extends Vue {
  @Prop({ required: true })
  private ammatillistenTiedotteetStore!: AmmatillistenTiedoteStore;

  @Prop({ required: true })
  private ammatillinenPerusteHakuStore!: AmmatillinenPerusteHakuStore;

  async mounted() {
    this.ammatillistenTiedotteetStore.init({ koulutusTyyppi: this.ammatillisetkoulutusryhmat.koulutustyypit });
    this.ammatillistenTiedotteetStore.fetch();
  }

  get tiedotteet() {
    return this.ammatillistenTiedotteetStore.tiedotteet.value;
  }

  get ammatillisetkoulutusryhmat(): KoulutustyyppiRyhma {
    return _.filter(koulutustyyppiRyhmat(), koulutusryhma => koulutusryhma.ryhma === 'ammatillinen')[0];
  }

  get linkit(): Ylalinkki[] {
    return [
      {
        route: { name: 'ammatillinenMaaraykset' },
        text: 'maaraykset',
        icon: 'picture_as_pdf',
      },
      {
        route: { name: 'ammatillinenOhjeet' },
        text: 'ohjeet-ja-materiaalit',
        icon: 'menu_book',
      },
      {
        route: { name: 'ammatillinenKoulutuksenjarjestajat' },
        text: 'koulutuksen-jarjestajat',
        icon: 'location_on',
      },
    ];
  }

  get ylaotsikko() {
    switch (this.$route.name) {
    case 'ammatillinenKoulutuksenjarjestajat': return 'koulutuksen-jarjestajat';
    case 'ammatillinenOhjeet': return 'ohjeet-ja-materiaalit';
    case 'ammatillinenKoulutusviennit': return 'koulutusviennit';
    case 'ammatillinenTyopajat': return 'selaa-tyopajoja';
    case 'ammatillinenValmisteillaOlevat': return 'valmisteilla-olevat-perusteet';
    case 'ammatillinenMaaraykset': return 'maaraykset';
    default: return 'ammatillinen-koulutus';
    }
  }

  get koulutustyyppi() {
    return 'koulutustyyppi_1';
  }

  get murupolku() {
    return [{
      label: 'ammatillinen-koulutus',
      location: {
        name: 'ammatillinenSelaus',
      },
    },
    ...this.alamurupolku,
    ];
  }

  get alamurupolku() {
    if (this.ylaotsikko !== 'ammatillinen-koulutus') {
      return [{
        label: this.ylaotsikko,
      }];
    }
    return [];
  }

  @Meta
  getMetaInfo() {
    return {
      title: this.$t('ammatillinen-koulutus'),
    };
  }

  avaaTiedote(tiedote: TiedoteDto) {
    this.$router.push({
      name: 'uutinen',
      params: {
        tiedoteId: '' + tiedote.id,
      },
    });
  }

  get sisaltokieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get furtherFeedbackUrl() {
    return `https://www.oph.fi/${this.sisaltokieli}/koulutus-ja-tutkinnot/tutkintorakenne/lomake`;
  }
}
