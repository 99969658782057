
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { PerusteDataStore } from '@/stores/PerusteDataStore';
import { PerusteenOsaStore } from '@/stores/PerusteenOsaStore';

import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpOpasKiinnitysLinkki from '@shared/components/EpOpasKiinnitysLinkki/EpOpasKiinnitysLinkki.vue';
import _ from 'lodash';

@Component({
  components: {
    EpContentViewer,
    EpFormContent,
    EpSpinner,
    EpOpasKiinnitysLinkki,
  },
})
export default class RouteKoulutuksenOsa extends Vue {
  @Prop({ required: true })
  private perusteDataStore!: PerusteDataStore;

  @Prop({ required: true })
  private perusteenOsaStore!: PerusteenOsaStore;

  get perusteenOsa() {
    return this.perusteenOsaStore.perusteenOsa;
  }

  get current() {
    return this.perusteDataStore.current || null;
  }

  get kuvat() {
    return this.perusteDataStore.kuvat;
  }

  get koulutuksenosaKoodiUri() {
    return (this.perusteenOsa as any)?.nimiKoodi?.uri;
  }

  get nimi() {
    return _.get(this.perusteenOsa, 'nimiKoodi') ? _.get(this.perusteenOsa, 'nimiKoodi.nimi') : _.get(this.perusteenOsa, 'nimi');
  }
}
