
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { koulutustyyppiStateName, koulutustyyppiTheme, yleissivistavatKoulutustyypit } from '@shared/utils/perusteet';
import { Kielet } from '@shared/stores/kieli';
import { PerusteStore } from '@/stores/PerusteStore';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpSpinnerSlot from '@shared/components/EpSpinner/EpSpinnerSlot.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import { JulkiEtusivuDtoEtusivuTyyppiEnum } from '@shared/api/eperusteet';

@Component({
  components: {
    EpSpinner,
    EpSpinnerSlot,
    EpSearch,
  },
})
export default class EpEtusivuHaku extends Vue {
  @Prop({ required: true })
  private perusteStore!: PerusteStore;

  private queryNimi = '';
  private sivu = 1;
  private sivukoko = 10;
  private isLoading: boolean = false;
  OpasType = JulkiEtusivuDtoEtusivuTyyppiEnum.OPAS;

  mounted() {
    this.clear();
  }

  @Watch('queryNimi')
  private async queryChange() {
    if (_.size(this.queryNimi) > 2) {
      this.page = 1;
      await this.fetchOpsitJaPerusteet();
    }
    else {
      this.perusteStore.opsitJaPerusteet = null;
    }
  }

  get kieli() {
    return Kielet.getUiKieli.value;
  }

  @Watch('kieli')
  private async kieliChange() {
    this.clear();
  }

  private clear() {
    this.queryNimi = '';
    this.perusteStore.opsitJaPerusteet = null;
  }

  async updatePage(value) {
    this.page = value;
    await this.fetchOpsitJaPerusteet();
  }

  async fetchOpsitJaPerusteet() {
    this.isLoading = true;
    try {
      await this.perusteStore.getOpsitJaPerusteet({
        nimi: this.queryNimi,
        kieli: this.sisaltoKieli,
        sivu: this.sivu - 1,
        sivukoko: this.sivukoko,
      });
    }
    catch (e) {
      console.error(e);
    }
    this.isLoading = false;
  }

  get opsitJaPerusteet() {
    return _.chain(this.perusteStore.opsitJaPerusteet?.data)
      .map(resultItem => {
        return {
          ...resultItem,
          theme: {
            ['koulutustyyppi-' + koulutustyyppiTheme(resultItem.koulutustyyppi!)]: true,
            'raita': resultItem.etusivuTyyppi === JulkiEtusivuDtoEtusivuTyyppiEnum.PERUSTE,
            'icon ops': resultItem.etusivuTyyppi === JulkiEtusivuDtoEtusivuTyyppiEnum.OPETUSSUUNNITELMA,
            'icon totsu': resultItem.etusivuTyyppi === JulkiEtusivuDtoEtusivuTyyppiEnum.TOTEUTUSSUUNNITELMA,
            'icon opas': resultItem.etusivuTyyppi === JulkiEtusivuDtoEtusivuTyyppiEnum.OPAS,
          },
          koulutustyyppi: resultItem.jotpatyyppi === 'MUU' ? 'koulutustyyppi_muu' : resultItem.koulutustyyppi,
        };
      })
      .map(resultItem => {
        return {
          ...resultItem,
          route: this.generateRoute(resultItem),
        };
      })
      .value();
  }

  generateRoute(resultItem) {
    if (resultItem.etusivuTyyppi === JulkiEtusivuDtoEtusivuTyyppiEnum.OPAS) {
      return {
        name: 'peruste',
        params: {
          koulutustyyppi: 'opas',
          perusteId: _.toString(resultItem.id),
        },
      };
    }
    if (resultItem.etusivuTyyppi === JulkiEtusivuDtoEtusivuTyyppiEnum.PERUSTE) {
      return {
        name: 'peruste',
        params: {
          koulutustyyppi: koulutustyyppiStateName(resultItem.koulutustyyppi),
          perusteId: _.toString(resultItem.id),
        },
      };
    }

    if (_.includes(yleissivistavatKoulutustyypit, resultItem.koulutustyyppi)) {
      return {
        name: 'opetussuunnitelma',
        params: {
          koulutustyyppi: koulutustyyppiStateName(resultItem.koulutustyyppi),
          opetussuunnitelmaId: _.toString(resultItem.id),
        },
      };
    }

    if (!_.includes(yleissivistavatKoulutustyypit, resultItem.koulutustyyppi)) {
      return {
        name: 'toteutussuunnitelma',
        params: {
          koulutustyyppi: koulutustyyppiStateName(resultItem.koulutustyyppi),
          toteutussuunnitelmaId: _.toString(resultItem.id),
        },
      };
    }

    return {};
  }

  get kokonaismaara() {
    return this.perusteStore.opsitJaPerusteet?.kokonaismäärä;
  }

  get hasResults() {
    return _.isNumber(this.kokonaismaara);
  }

  get page() {
    return this.sivu;
  }

  set page(value) {
    this.sivu = value;
  }

  get sisaltoKieli() {
    return Kielet.getSisaltoKieli.value;
  }
}
