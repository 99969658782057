
import { Prop, Component, Vue } from 'vue-property-decorator';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

@Component({
  components: {
    EpMaterialIcon,
  },
})
export default class EpLinkki extends Vue {
  @Prop({ required: true, type: String })
  private url!: string;

  @Prop({
    required: false,
    type: String,
  })
  private label!: string;

  @Prop({ default: '', type: String })
  private icon!: string;

  @Prop({
    default: true,
    type: Boolean,
  })
  private onlyTopLevel!: boolean;

  hasSlot() {
    return !!this.$slots.default;
  }

  get cleanUrl() {
    let result = this.url
      ? (this.url.replace(/^https?:\/\//, ''))
      : '';

    result = result.replace(/^mailto?:/, '');

    if (this.onlyTopLevel) {
      const idx = result.indexOf('/');
      if (idx > 0) {
        result = result.substr(0, idx);
      }
    }
    return result;
  }
}
