
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';
import EpSpinnerInline from '../EpSpinner/EpSpinnerInline.vue';

@Component({
  components: {
    EpSpinnerInline,
    EpMaterialIcon,
  },
})
export default class EpButton extends Vue {
  @Prop({ default: '' })
  private icon!: string;

  @Prop()
  private buttonClass!: string;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  private showSpinner!: boolean;

  @Prop({ default: 'primary', type: String })
  private variant!: string;

  @Prop({ default: 'md', type: String })
  private size!: string;

  @Prop({ default: '', type: String })
  private help!: string;

  @Prop({ default: true, type: Boolean })
  private paddingx!: boolean;

  get isOutline() {
    return _.startsWith(this.variant, 'outline');
  }

  get variantClass() {
    let result = 'btn-' + this.variant;
    if (this.isOutline) {
      result = 'no-outline ' + result;
    }
    if (this.buttonClass) {
      result = this.buttonClass + ' ' + result;
    }
    return result;
  }

  get inherit() {
    return this.variant === 'link' ? 'inherit' : '';
  }
}
