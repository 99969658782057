
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IPaikallinenStore } from '@/stores/IPaikallinenStore';
import { PerusteKoosteStore } from '@/stores/PerusteKoosteStore';
import { Kielet } from '@shared/stores/kieli';
import EpHeader from '@/components/EpHeader/EpHeader.vue';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpSearch from '@shared/components/forms/EpSearch.vue';
import EpExternalLink from '@shared/components/EpExternalLink/EpExternalLink.vue';
import OpetussuunnitelmaTile from './OpetussuunnitelmaTile.vue';
import EpBPagination from '@shared/components/EpBPagination/EpBPagination.vue';
import EpMultiSelect from '@shared/components/forms/EpMultiSelect.vue';
import { ryhmanKoulutustyypit } from '@shared/utils/perusteet';

@Component({
  components: {
    EpHeader,
    EpSearch,
    EpSpinner,
    EpExternalLink,
    OpetussuunnitelmaTile,
    EpBPagination,
    EpMultiSelect,
  },
})
export default class Paikalliset extends Vue {
  @Prop({ required: true })
  private paikallinenStore!: IPaikallinenStore;

  @Prop({ required: true })
  private perusteKoosteStore!: PerusteKoosteStore;

  @Prop({ required: true })
  private koulutustyyppi!: string;

  private query = '';
  private page = 1;
  private perPage = 10;
  private valittuPeruste = {};

  @Watch('koulutustyyppi')
  onKoulutustyyppiChanged() {
    this.valittuPeruste = {};
  }

  @Watch('julkaistutPerusteet', { immediate: true })
  async perusteetChange() {
    if (_.size(this.perusteKoosteStore.perusteJulkaisut) > 0) {
      if (_.get(this.valittuPeruste, 'id')) {
        const peruste = _.find(this.julkaistutPerusteet, peruste => _.get(peruste, 'id') === _.toNumber(_.get(this.$route.params, 'perusteId'))) || this.julkaistutPerusteet![0];
        await this.setActivePeruste(peruste);
      }
      else {
        await this.setActivePeruste(null);
      }
    }
  }

  @Watch('query')
  onQueryChanged() {
    this.page = 1;
  }

  get total() {
    return _.size(this.opetussuunnitelmatFiltered);
  }

  get activePeruste() {
    return this.paikallinenStore.perusteId?.value;
  }

  async setActivePeruste(perusteJulkaisu) {
    this.query = '';
    if (perusteJulkaisu?.id) {
      await this.paikallinenStore.fetch!(perusteJulkaisu.id, perusteJulkaisu.diaarinumero);
    }
    else {
      await this.paikallinenStore.fetch!(undefined, undefined, ryhmanKoulutustyypit(this.koulutustyyppi));
    }
  }

  get julkaistutPerusteet() {
    if (this.perusteKoosteStore.perusteJulkaisut) {
      return _.chain(this.perusteKoosteStore.perusteJulkaisut.value)
        .map(julkaistuPeruste => ({
          ...julkaistuPeruste,
          kaannettyNimi: this.$kaanna(julkaistuPeruste.nimi!),
        }))
        .orderBy(['voimassaoloAlkaa', 'kaannettyNimi'], ['desc', 'asc'])
        .value();
    }
  }

  get perusteetOptions() {
    if (this.julkaistutPerusteet) {
      return [
        {
          nimi: null,
        },
        ...this.julkaistutPerusteet,
      ];
    }
    return [];
  }

  get isLoading() {
    return !this.paikallinenStore.opetussuunnitelmat.value;
  }

  get opetussuunnitelmat() {
    this.page = 1;
    return _.chain(this.paikallinenStore.opetussuunnitelmat.value)
      .map(ops => ({
        ...ops,
        toimijat: _.filter(ops.organisaatiot, org => _.includes(org.tyypit, 'Koulutustoimija')),
        oppilaitokset: _.filter(ops.organisaatiot, org => _.includes(org.tyypit, 'Oppilaitos')),
        route: {
          name: 'opetussuunnitelma',
          params: {
            opetussuunnitelmaId: _.toString(ops.id),
          },
        },
      }))
      .sortBy(ops => Kielet.sortValue(ops.nimi))
      .value();
  }

  get opetussuunnitelmatFiltered() {
    return _.chain(this.opetussuunnitelmat)
      .filter(ops => this.filterByQuery(ops))
      .value();
  }

  filterByQuery(ops) {
    if (Kielet.search(this.query, ops.nimi)) {
      return true;
    }
    for (const oppilaitos of ops.oppilaitokset || []) {
      if (Kielet.search(this.query, oppilaitos.nimi)) {
        return true;
      }
    }
    for (const organisaatio of ops.organisaatiot || []) {
      if (Kielet.search(this.query, organisaatio.nimi)) {
        return true;
      }
    }
    for (const kunta of ops.kunnat || []) {
      if (Kielet.search(this.query, kunta.nimi)) {
        return true;
      }
    }
    return false;
  }

  get opetussuunnitelmatPaginated() {
    return _.chain(this.opetussuunnitelmatFiltered)
      .drop(this.perPage * (this.page - 1))
      .take(this.perPage)
      .value();
  }

  kaannaPerusteNimi(option) {
    if (option.nimi) {
      return this.$kaanna(option.nimi);
    }
    return this.$t('kaikki');
  }
}
