
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NavigationNodeDto } from '@shared/tyypit';
import { NavigationNode, setPerusteData } from '@shared/utils/NavigationBuilder';

@Component({
  components: {
  },
})
export default class EpRouterLink extends Vue {
  @Prop({ required: true })
  private muokkaustieto!: any;

  get location() {
    let node: NavigationNode = {
      type: this.muokkaustieto.kohde,
      children: [],
      path: [],
      location: undefined,
    };
    let navNode: NavigationNodeDto = {
      id: this.muokkaustieto.kohdeId,
      type: this.muokkaustieto.kohde,
      meta: { oppiaine: this.muokkaustieto.lisatieto },
    };
    setPerusteData(node, navNode);
    return node.location;
  }
}
