import { render, staticRenderFns } from "./EpToteutussuunnitelmaTekstikappale.vue?vue&type=template&id=6b65b040&scoped=true"
import script from "./EpToteutussuunnitelmaTekstikappale.vue?vue&type=script&lang=ts"
export * from "./EpToteutussuunnitelmaTekstikappale.vue?vue&type=script&lang=ts"
import style0 from "./EpToteutussuunnitelmaTekstikappale.vue?vue&type=style&index=0&id=6b65b040&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b65b040",
  null
  
)

export default component.exports