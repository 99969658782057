
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpFormContent from '@shared/components/forms/EpFormContent.vue';
import { MuokkaustietoStore } from '@shared/stores/MuokkaustietoStore';
import EpSpinner from '@shared/components/EpSpinner/EpSpinner.vue';
import EpRouterLink from '@shared/components/EpJulkaisuHistoriaJulkinen/EpRouterLink.vue';
import _ from 'lodash';

@Component({
  components: {
    EpFormContent,
    EpSpinner,
    EpRouterLink,
  },
})
export default class EpMuutosvertailu extends Vue {
  @Prop({ required: true })
  private julkaisu!: any;

  private muokkaustietoStore = new MuokkaustietoStore();

  async mounted() {
    await this.muokkaustietoStore.getVersionMuutokset(this.julkaisuData.peruste.id, this.julkaisuData.revision);
  }

  get julkaisuData() {
    return this.julkaisu;
  }

  get muutostiedot() {
    if (this.muokkaustietoStore.muutostiedot.value) {
      return _.map(this.muokkaustietoStore.muutostiedot.value, tieto => {
        return {
          ...tieto,
          tapahtumat: _.map(tieto.tapahtumat, tapahtuma => {
            return {
              ...tapahtuma,
              muokkaustiedot: _.map(tapahtuma.muokkaustiedot, muokkaustieto => {
                return {
                  ...muokkaustieto,
                  kohde: this.solveTapahtuma(muokkaustieto),
                };
              }),
            };
          }),
        };
      });
    }
  }

  solveTapahtuma(muokkaustieto) {
    console.log(muokkaustieto);
    if (this.julkaisu?.peruste?.toteutus === 'perusopetus') {
      if (muokkaustieto.kohde === 'oppiaine') {
        return 'perusopetusoppiaine';
      }
    }

    return muokkaustieto.kohde;
  }
}
