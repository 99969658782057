// tslint:disable
/**
 * ePerusteet api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AIPEKurssiBaseDto
 */
export interface AIPEKurssiBaseDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEKurssiBaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEKurssiBaseDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEKurssiBaseDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEKurssiBaseDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AIPEKurssiBaseDto
     */
    koodi?: KoodiDto;
}
/**
 * 
 * @export
 * @interface AIPEKurssiDto
 */
export interface AIPEKurssiDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEKurssiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEKurssiDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEKurssiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEKurssiDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AIPEKurssiDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEKurssiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<object>}
     * @memberof AIPEKurssiDto
     */
    tavoitteet?: Array<object>;
}
/**
 * 
 * @export
 * @interface AIPEKurssiSuppeaDto
 */
export interface AIPEKurssiSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEKurssiSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEKurssiSuppeaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEKurssiSuppeaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEKurssiSuppeaDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AIPEKurssiSuppeaDto
     */
    koodi?: KoodiDto;
}
/**
 * 
 * @export
 * @interface AIPEOpetuksenSisaltoDto
 */
export interface AIPEOpetuksenSisaltoDto {
    /**
     * 
     * @type {Laaja}
     * @memberof AIPEOpetuksenSisaltoDto
     */
    sisalto?: Laaja;
    /**
     * 
     * @type {Array<LaajaalainenOsaaminenDto>}
     * @memberof AIPEOpetuksenSisaltoDto
     */
    laajaalaisetosaamiset?: Array<LaajaalainenOsaaminenDto>;
    /**
     * 
     * @type {Array<AIPEVaiheDto>}
     * @memberof AIPEOpetuksenSisaltoDto
     */
    vaiheet?: Array<AIPEVaiheDto>;
}
/**
 * 
 * @export
 * @interface AIPEOppiaineBaseDto
 */
export interface AIPEOppiaineBaseDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEOppiaineBaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEOppiaineBaseDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineBaseDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEOppiaineBaseDto
     */
    oppiaine?: object;
}
/**
 * 
 * @export
 * @interface AIPEOppiaineDto
 */
export interface AIPEOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEOppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEOppiaineDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof AIPEOppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AIPEOppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AIPEOppiaineDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AIPEOppiaineDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineDto
     */
    arviointi?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineDto
     */
    tyotavat?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineDto
     */
    ohjaus?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineDto
     */
    sisaltoalueinfo?: TekstiOsaDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineDto
     */
    pakollinenKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineDto
     */
    syventavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineDto
     */
    soveltavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineDto
     */
    kielikasvatus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineDto
     */
    vapaaTeksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof AIPEOppiaineDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {Array<OpetuksenTavoiteDto>}
     * @memberof AIPEOppiaineDto
     */
    tavoitteet?: Array<OpetuksenTavoiteDto>;
    /**
     * 
     * @type {Array<KeskeinenSisaltoalueDto>}
     * @memberof AIPEOppiaineDto
     */
    sisaltoalueet?: Array<KeskeinenSisaltoalueDto>;
    /**
     * 
     * @type {Array<AIPEKurssiDto>}
     * @memberof AIPEOppiaineDto
     */
    kurssit?: Array<AIPEKurssiDto>;
    /**
     * 
     * @type {Array<AIPEOppiaineSuppeaDto>}
     * @memberof AIPEOppiaineDto
     */
    oppimaarat?: Array<AIPEOppiaineSuppeaDto>;
}
/**
 * 
 * @export
 * @interface AIPEOppiaineLaajaDto
 */
export interface AIPEOppiaineLaajaDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEOppiaineLaajaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEOppiaineLaajaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEOppiaineLaajaDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof AIPEOppiaineLaajaDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AIPEOppiaineLaajaDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AIPEOppiaineLaajaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    arviointi?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    tyotavat?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    ohjaus?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    sisaltoalueinfo?: TekstiOsaDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    pakollinenKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    syventavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    soveltavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineLaajaDto
     */
    kielikasvatus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OpetuksenTavoiteDto>}
     * @memberof AIPEOppiaineLaajaDto
     */
    tavoitteet?: Array<OpetuksenTavoiteDto>;
    /**
     * 
     * @type {Array<KeskeinenSisaltoalueDto>}
     * @memberof AIPEOppiaineLaajaDto
     */
    sisaltoalueet?: Array<KeskeinenSisaltoalueDto>;
    /**
     * 
     * @type {Array<AIPEKurssiDto>}
     * @memberof AIPEOppiaineLaajaDto
     */
    kurssit?: Array<AIPEKurssiDto>;
    /**
     * 
     * @type {Array<AIPEOppiaineLaajaDto>}
     * @memberof AIPEOppiaineLaajaDto
     */
    oppimaarat?: Array<AIPEOppiaineLaajaDto>;
}
/**
 * 
 * @export
 * @interface AIPEOppiaineSuppeaDto
 */
export interface AIPEOppiaineSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEOppiaineSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEOppiaineSuppeaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEOppiaineSuppeaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof AIPEOppiaineSuppeaDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof AIPEOppiaineSuppeaDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AIPEOppiaineSuppeaDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AIPEOppiaineSuppeaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AIPEOppiaineSuppeaDto
     */
    koodi?: KoodiDto;
}
/**
 * 
 * @export
 * @interface AIPEVaiheBaseDto
 */
export interface AIPEVaiheBaseDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEVaiheBaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEVaiheBaseDto
     */
    tunniste?: string;
    /**
     * 
     * @type {Date}
     * @memberof AIPEVaiheBaseDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AIPEVaiheBaseDto
     */
    muokattu?: Date;
}
/**
 * 
 * @export
 * @interface AIPEVaiheDto
 */
export interface AIPEVaiheDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEVaiheDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEVaiheDto
     */
    tunniste?: string;
    /**
     * 
     * @type {Date}
     * @memberof AIPEVaiheDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AIPEVaiheDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEVaiheDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEVaiheDto
     */
    siirtymaEdellisesta?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEVaiheDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEVaiheDto
     */
    siirtymaSeuraavaan?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEVaiheDto
     */
    laajaalainenOsaaminen?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof AIPEVaiheDto
     */
    paikallisestiPaatettavatAsiat?: TekstiOsaDto;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof AIPEVaiheDto
     */
    opetuksenKohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<AIPEOppiaineLaajaDto>}
     * @memberof AIPEVaiheDto
     */
    oppiaineet?: Array<AIPEOppiaineLaajaDto>;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof AIPEVaiheDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
}
/**
 * 
 * @export
 * @interface AIPEVaiheSuppeaDto
 */
export interface AIPEVaiheSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof AIPEVaiheSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AIPEVaiheSuppeaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {Date}
     * @memberof AIPEVaiheSuppeaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AIPEVaiheSuppeaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AIPEVaiheSuppeaDto
     */
    nimi?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface AbstractRakenneOsaDto
 */
export interface AbstractRakenneOsaDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AbstractRakenneOsaDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof AbstractRakenneOsaDto
     */
    vieras?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof AbstractRakenneOsaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractRakenneOsaDto
     */
    pakollinen?: boolean;
}
/**
 * 
 * @export
 * @interface AihekokonaisuudetLaajaDto
 */
export interface AihekokonaisuudetLaajaDto extends Laaja {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetLaajaDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AihekokonaisuudetLaajaDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AihekokonaisuudetLaajaDto
     */
    yleiskuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<AihekokonaisuusDto>}
     * @memberof AihekokonaisuudetLaajaDto
     */
    aihekokonaisuudet?: Array<AihekokonaisuusDto>;
}


/**
 * 
 * @export
 * @interface AihekokonaisuudetLaajaDtoAllOf
 */
export interface AihekokonaisuudetLaajaDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuudetLaajaDtoAllOf
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AihekokonaisuudetLaajaDtoAllOf
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AihekokonaisuudetLaajaDtoAllOf
     */
    yleiskuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<AihekokonaisuusDto>}
     * @memberof AihekokonaisuudetLaajaDtoAllOf
     */
    aihekokonaisuudet?: Array<AihekokonaisuusDto>;
}
/**
 * 
 * @export
 * @interface AihekokonaisuusDto
 */
export interface AihekokonaisuusDto {
    /**
     * 
     * @type {string}
     * @memberof AihekokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AihekokonaisuusDto
     */
    jnro?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AihekokonaisuusDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AihekokonaisuusDto
     */
    yleiskuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface Ammattitaitovaatimukset2019Dto
 */
export interface Ammattitaitovaatimukset2019Dto {
    /**
     * 
     * @type {number}
     * @memberof Ammattitaitovaatimukset2019Dto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Ammattitaitovaatimukset2019Dto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<Ammattitaitovaatimus2019Dto>}
     * @memberof Ammattitaitovaatimukset2019Dto
     */
    vaatimukset?: Array<Ammattitaitovaatimus2019Dto>;
    /**
     * 
     * @type {Array<AmmattitaitovaatimustenKohdealue2019Dto>}
     * @memberof Ammattitaitovaatimukset2019Dto
     */
    kohdealueet?: Array<AmmattitaitovaatimustenKohdealue2019Dto>;
}
/**
 * 
 * @export
 * @interface Ammattitaitovaatimus2019Dto
 */
export interface Ammattitaitovaatimus2019Dto {
    /**
     * 
     * @type {KoodiDto}
     * @memberof Ammattitaitovaatimus2019Dto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Ammattitaitovaatimus2019Dto
     */
    vaatimus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimusDto
 */
export interface AmmattitaitovaatimusDto {
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimusDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AmmattitaitovaatimusDto
     */
    selite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof AmmattitaitovaatimusDto
     */
    ammattitaitovaatimusKoodi?: string;
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimusDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimusKohdeDto
 */
export interface AmmattitaitovaatimusKohdeDto {
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimusKohdeDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AmmattitaitovaatimusKohdeDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AmmattitaitovaatimusKohdeDto
     */
    selite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<AmmattitaitovaatimusDto>}
     * @memberof AmmattitaitovaatimusKohdeDto
     */
    vaatimukset?: Array<AmmattitaitovaatimusDto>;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimusKohdealueetDto
 */
export interface AmmattitaitovaatimusKohdealueetDto {
    /**
     * 
     * @type {number}
     * @memberof AmmattitaitovaatimusKohdealueetDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AmmattitaitovaatimusKohdealueetDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<AmmattitaitovaatimusKohdeDto>}
     * @memberof AmmattitaitovaatimusKohdealueetDto
     */
    vaatimuksenKohteet?: Array<AmmattitaitovaatimusKohdeDto>;
}
/**
 * 
 * @export
 * @interface AmmattitaitovaatimustenKohdealue2019Dto
 */
export interface AmmattitaitovaatimustenKohdealue2019Dto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof AmmattitaitovaatimustenKohdealue2019Dto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<Ammattitaitovaatimus2019Dto>}
     * @memberof AmmattitaitovaatimustenKohdealue2019Dto
     */
    vaatimukset?: Array<Ammattitaitovaatimus2019Dto>;
}
/**
 * 
 * @export
 * @interface AmosaaKoulutustoimijaDto
 */
export interface AmosaaKoulutustoimijaDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AmosaaKoulutustoimijaDto
     */
    nimi?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ArvioinninKohdeDto
 */
export interface ArvioinninKohdeDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof ArvioinninKohdeDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof ArvioinninKohdeDto
     */
    selite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamistasonKriteeriDto>}
     * @memberof ArvioinninKohdeDto
     */
    osaamistasonKriteerit?: Array<OsaamistasonKriteeriDto>;
    /**
     * 
     * @type {object}
     * @memberof ArvioinninKohdeDto
     */
    _arviointiAsteikko?: object;
    /**
     * 
     * @type {ArviointiAsteikkoDto}
     * @memberof ArvioinninKohdeDto
     */
    arviointiAsteikko?: ArviointiAsteikkoDto;
}
/**
 * 
 * @export
 * @interface ArvioinninKohdealueDto
 */
export interface ArvioinninKohdealueDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof ArvioinninKohdealueDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<ArvioinninKohdeDto>}
     * @memberof ArvioinninKohdealueDto
     */
    arvioinninKohteet?: Array<ArvioinninKohdeDto>;
    /**
     * 
     * @type {KoodiDto}
     * @memberof ArvioinninKohdealueDto
     */
    koodi?: KoodiDto;
}
/**
 * 
 * @export
 * @interface Arviointi2020Dto
 */
export interface Arviointi2020Dto {
    /**
     * 
     * @type {number}
     * @memberof Arviointi2020Dto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Arviointi2020Dto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof Arviointi2020Dto
     */
    arviointiAsteikko?: object;
    /**
     * 
     * @type {Array<OsaamistasonKriteerit2020Dto>}
     * @memberof Arviointi2020Dto
     */
    osaamistasonKriteerit?: Array<OsaamistasonKriteerit2020Dto>;
}
/**
 * 
 * @export
 * @interface ArviointiAsteikkoDto
 */
export interface ArviointiAsteikkoDto {
    /**
     * 
     * @type {number}
     * @memberof ArviointiAsteikkoDto
     */
    id?: number;
    /**
     * 
     * @type {Array<OsaamistasoDto>}
     * @memberof ArviointiAsteikkoDto
     */
    osaamistasot?: Array<OsaamistasoDto>;
}
/**
 * 
 * @export
 * @interface ArviointiDto
 */
export interface ArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof ArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof ArviointiDto
     */
    lisatiedot?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<ArvioinninKohdealueDto>}
     * @memberof ArviointiDto
     */
    arvioinninKohdealueet?: Array<ArvioinninKohdealueDto>;
}
/**
 * 
 * @export
 * @interface BooleanDto
 */
export interface BooleanDto {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanDto
     */
    vastaus?: boolean;
}
/**
 * 
 * @export
 * @interface CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
 */
export interface CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto {
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    etunimet?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    sukunimi?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    oidHenkilo?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    kieliKoodi?: string;
    /**
     * 
     * @type {object}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    yhteystiedot?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    oikeudet?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    perusteprojekti?: number;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    organisaatioOid?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    tehtavanimike?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto
     */
    passivoitu?: boolean;
}
/**
 * 
 * @export
 * @interface CombinedDtoRevisionHenkiloTietoDto
 */
export interface CombinedDtoRevisionHenkiloTietoDto {
    /**
     * 
     * @type {number}
     * @memberof CombinedDtoRevisionHenkiloTietoDto
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof CombinedDtoRevisionHenkiloTietoDto
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoRevisionHenkiloTietoDto
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoRevisionHenkiloTietoDto
     */
    kommentti?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoRevisionHenkiloTietoDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoRevisionHenkiloTietoDto
     */
    sukunimi?: string;
}
/**
 * 
 * @export
 * @interface CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
 */
export interface CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto {
    /**
     * 
     * @type {number}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    peruste?: object;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    tutkinnonOsaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    tutkinnonOsaArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    osaamisalaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    osaamisalaArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    tutkintonimikeUri?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    tutkintonimikeArvo?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto
     */
    nimi?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface DiaarinumeroHakuDto
 */
export interface DiaarinumeroHakuDto {
    /**
     * 
     * @type {boolean}
     * @memberof DiaarinumeroHakuDto
     */
    loytyi?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiaarinumeroHakuDto
     */
    tila?: DiaarinumeroHakuDtoTilaEnum;
    /**
     * 
     * @type {number}
     * @memberof DiaarinumeroHakuDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DiaarinumeroHakuDto
     */
    diaarinumero?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DiaarinumeroHakuDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface DigitaalisenOsaamisenSisaltoDto
 */
export interface DigitaalisenOsaamisenSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof DigitaalisenOsaamisenSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {Laaja}
     * @memberof DigitaalisenOsaamisenSisaltoDto
     */
    sisalto?: Laaja;
}
/**
 * 
 * @export
 * @interface DokumenttiDto
 */
export interface DokumenttiDto {
    /**
     * 
     * @type {number}
     * @memberof DokumenttiDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DokumenttiDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    luoja?: string;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    kieli?: DokumenttiDtoKieliEnum;
    /**
     * 
     * @type {Date}
     * @memberof DokumenttiDto
     */
    aloitusaika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DokumenttiDto
     */
    valmistumisaika?: Date;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    tila?: DokumenttiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    virhekoodi?: DokumenttiDtoVirhekoodiEnum;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    suoritustapakoodi?: DokumenttiDtoSuoritustapakoodiEnum;
    /**
     * 
     * @type {string}
     * @memberof DokumenttiDto
     */
    generatorVersion?: DokumenttiDtoGeneratorVersionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DokumenttiDto
     */
    julkaisuDokumentti?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoTilaEnum {
    EIOLE = 'EI_OLE',
    JONOSSA = 'JONOSSA',
    LUODAAN = 'LUODAAN',
    EPAONNISTUI = 'EPAONNISTUI',
    VALMIS = 'VALMIS'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoVirhekoodiEnum {
    EIVIRHETTA = 'EI_VIRHETTA',
    PERUSTETTAEILOYTYNYT = 'PERUSTETTA_EI_LOYTYNYT',
    TUNTEMATON = 'TUNTEMATON',
    TUNTEMATONLOKALISOINTI = 'TUNTEMATON_LOKALISOINTI'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoSuoritustapakoodiEnum {
    OPS = 'OPS',
    NAYTTO = 'NAYTTO',
    REFORMI = 'REFORMI',
    PERUSOPETUS = 'PERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    OPAS = 'OPAS',
    ESIOPETUS = 'ESIOPETUS',
    AIPE = 'AIPE',
    TPO = 'TPO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOKOULUTUS2019 = 'LUKIOKOULUTUS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO'
}
/**
    * @export
    * @enum {string}
    */
export enum DokumenttiDtoGeneratorVersionEnum {
    VANHA = 'VANHA',
    UUSI = 'UUSI',
    KVLIITE = 'KVLIITE'
}

/**
 * 
 * @export
 * @interface EsiopetuksenPerusteenSisaltoDto
 */
export interface EsiopetuksenPerusteenSisaltoDto {
    /**
     * 
     * @type {Laaja}
     * @memberof EsiopetuksenPerusteenSisaltoDto
     */
    sisalto?: Laaja;
}
/**
 * 
 * @export
 * @interface GeneerinenArviointiasteikkoDto
 */
export interface GeneerinenArviointiasteikkoDto {
    /**
     * 
     * @type {number}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    arviointiAsteikko?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    julkaistu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    valittavissa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    oletusvalinta?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    koulutustyypit?: Array<GeneerinenArviointiasteikkoDtoKoulutustyypitEnum>;
    /**
     * 
     * @type {Array<GeneerisenArvioinninOsaamistasonKriteeriDto>}
     * @memberof GeneerinenArviointiasteikkoDto
     */
    osaamistasonKriteerit?: Array<GeneerisenArvioinninOsaamistasonKriteeriDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum GeneerinenArviointiasteikkoDtoKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}

/**
 * 
 * @export
 * @interface GeneerinenArviointiasteikkoKaikkiDto
 */
export interface GeneerinenArviointiasteikkoKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {ArviointiAsteikkoDto}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    arviointiAsteikko?: ArviointiAsteikkoDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    julkaistu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    valittavissa?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    koulutustyypit?: Array<GeneerinenArviointiasteikkoKaikkiDtoKoulutustyypitEnum>;
    /**
     * 
     * @type {Array<GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto>}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    osaamistasonKriteerit?: Array<GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto>;
    /**
     * 
     * @type {object}
     * @memberof GeneerinenArviointiasteikkoKaikkiDto
     */
    _arviointiAsteikko?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum GeneerinenArviointiasteikkoKaikkiDtoKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}

/**
 * 
 * @export
 * @interface GeneerisenArvioinninOsaamistasonKriteeriDto
 */
export interface GeneerisenArvioinninOsaamistasonKriteeriDto {
    /**
     * 
     * @type {object}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriDto
     */
    osaamistaso?: object;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriDto
     */
    kriteerit?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
 */
export interface GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto {
    /**
     * 
     * @type {OsaamistasoDto}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
     */
    osaamistaso?: OsaamistasoDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
     */
    kriteerit?: Array<LokalisoituTekstiDto>;
    /**
     * 
     * @type {object}
     * @memberof GeneerisenArvioinninOsaamistasonKriteeriKaikkiDto
     */
    _osaamistaso?: object;
}
/**
 * 
 * @export
 * @interface HenkiloTietoDto
 */
export interface HenkiloTietoDto {
    /**
     * 
     * @type {string}
     * @memberof HenkiloTietoDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof HenkiloTietoDto
     */
    sukunimi?: string;
}
/**
 * 
 * @export
 * @interface JulkaisuBaseDto
 */
export interface JulkaisuBaseDto {
    /**
     * 
     * @type {number}
     * @memberof JulkaisuBaseDto
     */
    revision?: number;
    /**
     * 
     * @type {PerusteBaseDto}
     * @memberof JulkaisuBaseDto
     */
    peruste?: PerusteBaseDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof JulkaisuBaseDto
     */
    tiedote?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof JulkaisuBaseDto
     */
    julkinenTiedote?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Date}
     * @memberof JulkaisuBaseDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuBaseDto
     */
    luoja?: string;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof JulkaisuBaseDto
     */
    kayttajanTieto?: KayttajanTietoDto;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuBaseDto
     */
    tila?: JulkaisuBaseDtoTilaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JulkaisuBaseDto
     */
    julkinen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof JulkaisuBaseDto
     */
    muutosmaaraysVoimaan?: Date;
    /**
     * 
     * @type {Array<JulkaisuLiiteDto>}
     * @memberof JulkaisuBaseDto
     */
    liitteet?: Array<JulkaisuLiiteDto>;
    /**
     * 
     * @type {MaaraysDto}
     * @memberof JulkaisuBaseDto
     */
    muutosmaarays?: MaaraysDto;
}

/**
    * @export
    * @enum {string}
    */
export enum JulkaisuBaseDtoTilaEnum {
    JULKAISEMATON = 'JULKAISEMATON',
    KESKEN = 'KESKEN',
    JULKAISTU = 'JULKAISTU',
    VIRHE = 'VIRHE'
}

/**
 * 
 * @export
 * @interface JulkaisuLiiteDto
 */
export interface JulkaisuLiiteDto {
    /**
     * 
     * @type {number}
     * @memberof JulkaisuLiiteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuLiiteDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuLiiteDto
     */
    kieli?: string;
    /**
     * 
     * @type {string}
     * @memberof JulkaisuLiiteDto
     */
    data?: string;
    /**
     * 
     * @type {LiiteDto}
     * @memberof JulkaisuLiiteDto
     */
    liite?: LiiteDto;
}
/**
 * 
 * @export
 * @interface JulkiEtusivuDto
 */
export interface JulkiEtusivuDto {
    /**
     * 
     * @type {number}
     * @memberof JulkiEtusivuDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof JulkiEtusivuDto
     */
    etusivuTyyppi?: JulkiEtusivuDtoEtusivuTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof JulkiEtusivuDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof JulkiEtusivuDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof JulkiEtusivuDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JulkiEtusivuDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {AmosaaKoulutustoimijaDto}
     * @memberof JulkiEtusivuDto
     */
    koulutustoimija?: AmosaaKoulutustoimijaDto;
    /**
     * 
     * @type {Array<YlopsOrganisaatioDto>}
     * @memberof JulkiEtusivuDto
     */
    organisaatiot?: Array<YlopsOrganisaatioDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JulkiEtusivuDto
     */
    kielet?: Array<JulkiEtusivuDtoKieletEnum>;
    /**
     * 
     * @type {string}
     * @memberof JulkiEtusivuDto
     */
    jotpatyyppi?: JulkiEtusivuDtoJotpatyyppiEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum JulkiEtusivuDtoEtusivuTyyppiEnum {
    PERUSTE = 'PERUSTE',
    OPETUSSUUNNITELMA = 'OPETUSSUUNNITELMA',
    TOTEUTUSSUUNNITELMA = 'TOTEUTUSSUUNNITELMA',
    OPAS = 'OPAS'
}
/**
    * @export
    * @enum {string}
    */
export enum JulkiEtusivuDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum JulkiEtusivuDtoJotpatyyppiEnum {
    VST = 'VST',
    MUU = 'MUU'
}

/**
 * 
 * @export
 * @interface KVLiiteDto
 */
export interface KVLiiteDto {
    /**
     * 
     * @type {number}
     * @memberof KVLiiteDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    tutkintotodistuksenAntaja?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof KVLiiteDto
     */
    arvosanaAsteikko?: object;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    jatkoopintoKelpoisuus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    kansainvalisetSopimukset?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    saadosPerusta?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    pohjakoulutusvaatimukset?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    lisatietoja?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    tutkintotodistuksenSaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteDto
     */
    tutkinnostaPaattavaViranomainen?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface KVLiiteJulkinenDto
 */
export interface KVLiiteJulkinenDto {
    /**
     * 
     * @type {number}
     * @memberof KVLiiteJulkinenDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    tutkintotodistuksenAntaja?: LokalisoituTekstiDto;
    /**
     * 
     * @type {object}
     * @memberof KVLiiteJulkinenDto
     */
    arvosanaAsteikko?: object;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    jatkoopintoKelpoisuus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    kansainvalisetSopimukset?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    saadosPerusta?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    pohjakoulutusvaatimukset?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    lisatietoja?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    tutkintotodistuksenSaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    tutkinnostaPaattavaViranomainen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof KVLiiteJulkinenDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteJulkinenDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {MaarayskirjeDto}
     * @memberof KVLiiteJulkinenDto
     */
    maarayskirje?: MaarayskirjeDto;
    /**
     * 
     * @type {string}
     * @memberof KVLiiteJulkinenDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof KVLiiteJulkinenDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Array<KVLiiteTasoDto>}
     * @memberof KVLiiteJulkinenDto
     */
    tasot?: Array<KVLiiteTasoDto>;
    /**
     * 
     * @type {{ [key: string]: LokalisoituTekstiDto; }}
     * @memberof KVLiiteJulkinenDto
     */
    muodostumisenKuvaus?: { [key: string]: LokalisoituTekstiDto; };
    /**
     * 
     * @type {boolean}
     * @memberof KVLiiteJulkinenDto
     */
    periytynyt?: boolean;
    /**
     * 
     * @type {PerusteprojektiInfoDto}
     * @memberof KVLiiteJulkinenDto
     */
    pohjaProjekti?: PerusteprojektiInfoDto;
}
/**
 * 
 * @export
 * @interface KVLiiteTasoDto
 */
export interface KVLiiteTasoDto {
    /**
     * 
     * @type {string}
     * @memberof KVLiiteTasoDto
     */
    codeUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KVLiiteTasoDto
     */
    codeValue?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KVLiiteTasoDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof KVLiiteTasoDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface KayttajanProjektitiedotDto
 */
export interface KayttajanProjektitiedotDto {
    /**
     * 
     * @type {number}
     * @memberof KayttajanProjektitiedotDto
     */
    perusteprojekti?: number;
    /**
     * 
     * @type {string}
     * @memberof KayttajanProjektitiedotDto
     */
    organisaatioOid?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanProjektitiedotDto
     */
    tehtavanimike?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KayttajanProjektitiedotDto
     */
    passivoitu?: boolean;
}
/**
 * 
 * @export
 * @interface KayttajanTietoDto
 */
export interface KayttajanTietoDto {
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kutsumanimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    etunimet?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    sukunimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    oidHenkilo?: string;
    /**
     * 
     * @type {string}
     * @memberof KayttajanTietoDto
     */
    kieliKoodi?: string;
    /**
     * 
     * @type {object}
     * @memberof KayttajanTietoDto
     */
    yhteystiedot?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof KayttajanTietoDto
     */
    oikeudet?: Array<string>;
}
/**
 * 
 * @export
 * @interface KeskeinenSisaltoalueDto
 */
export interface KeskeinenSisaltoalueDto {
    /**
     * 
     * @type {number}
     * @memberof KeskeinenSisaltoalueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KeskeinenSisaltoalueDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KeskeinenSisaltoalueDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KeskeinenSisaltoalueDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface KevytTekstiKappaleDto
 */
export interface KevytTekstiKappaleDto {
    /**
     * 
     * @type {number}
     * @memberof KevytTekstiKappaleDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KevytTekstiKappaleDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KevytTekstiKappaleDto
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof KevytTekstiKappaleDto
     */
    jnro?: number;
}
/**
 * 
 * @export
 * @interface Koko
 */
export interface Koko {
    /**
     * 
     * @type {number}
     * @memberof Koko
     */
    minimi?: number;
    /**
     * 
     * @type {number}
     * @memberof Koko
     */
    maksimi?: number;
}
/**
 * 
 * @export
 * @interface KoodiDto
 */
export interface KoodiDto {
    /**
     * 
     * @type {number}
     * @memberof KoodiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoodiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    arvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodiDto
     */
    koodisto?: string;
    /**
     * 
     * @type {number}
     * @memberof KoodiDto
     */
    versio?: number;
}
/**
 * 
 * @export
 * @interface KoodistoDto
 */
export interface KoodistoDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoDto
     */
    koodistoUri?: string;
    /**
     * 
     * @type {KoodistoVersioDto}
     * @memberof KoodistoDto
     */
    latestKoodistoVersio?: KoodistoVersioDto;
}
/**
 * 
 * @export
 * @interface KoodistoKoodiDto
 */
export interface KoodistoKoodiDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    versio?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoKoodiDto
     */
    version?: string;
    /**
     * 
     * @type {Array<KoodistoMetadataDto>}
     * @memberof KoodistoKoodiDto
     */
    metadata?: Array<KoodistoMetadataDto>;
    /**
     * 
     * @type {KoodistoDto}
     * @memberof KoodistoKoodiDto
     */
    koodisto?: KoodistoDto;
    /**
     * 
     * @type {Date}
     * @memberof KoodistoKoodiDto
     */
    voimassaAlkuPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof KoodistoKoodiDto
     */
    voimassaLoppuPvm?: Date;
}
/**
 * 
 * @export
 * @interface KoodistoMetadataDto
 */
export interface KoodistoMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    kieli?: string;
    /**
     * 
     * @type {string}
     * @memberof KoodistoMetadataDto
     */
    kuvaus?: string;
}
/**
 * 
 * @export
 * @interface KoodistoVersioDto
 */
export interface KoodistoVersioDto {
    /**
     * 
     * @type {string}
     * @memberof KoodistoVersioDto
     */
    versio?: string;
}
/**
 * 
 * @export
 * @interface KoosteenOsaamisalaDto
 */
export interface KoosteenOsaamisalaDto {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KoosteenOsaamisalaDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof KoosteenOsaamisalaDto
     */
    tutkinnonOsat?: Array<KoodiDto>;
}
/**
 * 
 * @export
 * @interface KotoKielitaitotasoDto
 */
export interface KotoKielitaitotasoDto extends Laaja {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KotoKielitaitotasoDto
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoKielitaitotasoDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoKielitaitotasoDto
     */
    taitotasot?: Array<KotoTaitotasoDto>;
}


/**
 * 
 * @export
 * @interface KotoKielitaitotasoDtoAllOf
 */
export interface KotoKielitaitotasoDtoAllOf {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KotoKielitaitotasoDtoAllOf
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoKielitaitotasoDtoAllOf
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoKielitaitotasoDtoAllOf
     */
    taitotasot?: Array<KotoTaitotasoDto>;
}
/**
 * 
 * @export
 * @interface KotoLaajaAlainenOsaaminenDto
 */
export interface KotoLaajaAlainenOsaaminenDto extends Laaja {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoLaajaAlainenOsaaminenDto
     */
    yleiskuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KotoLaajaAlaisenOsaamisenAlueDto>}
     * @memberof KotoLaajaAlainenOsaaminenDto
     */
    osaamisAlueet?: Array<KotoLaajaAlaisenOsaamisenAlueDto>;
}


/**
 * 
 * @export
 * @interface KotoLaajaAlainenOsaaminenDtoAllOf
 */
export interface KotoLaajaAlainenOsaaminenDtoAllOf {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoLaajaAlainenOsaaminenDtoAllOf
     */
    yleiskuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KotoLaajaAlaisenOsaamisenAlueDto>}
     * @memberof KotoLaajaAlainenOsaaminenDtoAllOf
     */
    osaamisAlueet?: Array<KotoLaajaAlaisenOsaamisenAlueDto>;
}
/**
 * 
 * @export
 * @interface KotoLaajaAlaisenOsaamisenAlueDto
 */
export interface KotoLaajaAlaisenOsaamisenAlueDto {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KotoLaajaAlaisenOsaamisenAlueDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoLaajaAlaisenOsaamisenAlueDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface KotoOpintoDto
 */
export interface KotoOpintoDto extends Laaja {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KotoOpintoDto
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoOpintoDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KotoTaitotasoDto>}
     * @memberof KotoOpintoDto
     */
    taitotasot?: Array<KotoTaitotasoDto>;
}


/**
 * 
 * @export
 * @interface KotoTaitotasoDto
 */
export interface KotoTaitotasoDto {
    /**
     * 
     * @type {number}
     * @memberof KotoTaitotasoDto
     */
    id?: number;
    /**
     * 
     * @type {KoodiDto}
     * @memberof KotoTaitotasoDto
     */
    nimi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    tavoitteet?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    kielenkayttotarkoitus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    aihealueet?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    viestintataidot?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    opiskelijantaidot?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    opiskelijanTyoelamataidot?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof KotoTaitotasoDto
     */
    tyoelamaOpintoMinimiLaajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof KotoTaitotasoDto
     */
    tyoelamaOpintoMaksimiLaajuus?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    suullinenVastaanottaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    suullinenTuottaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KotoTaitotasoDto
     */
    vuorovaikutusJaMediaatio?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface KoulutuksenOsaDto
 */
export interface KoulutuksenOsaDto extends Laaja {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KoulutuksenOsaDto
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDto
     */
    laajuusMinimi?: number;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDto
     */
    koulutusOsanKoulutustyyppi?: KoulutuksenOsaDtoKoulutusOsanKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDto
     */
    koulutusOsanTyyppi?: KoulutuksenOsaDtoKoulutusOsanTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDto
     */
    tavoitteenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof KoulutuksenOsaDto
     */
    tavoitteet?: Array<LokalisoituTekstiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDto
     */
    keskeinenSisalto?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDto
     */
    laajaAlaisenOsaamisenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDto
     */
    arvioinninKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDto
     */
    viiteId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum KoulutuksenOsaDtoKoulutusOsanKoulutustyyppiEnum {
    TUTKINTOKOULUTUKSEENVALMENTAVA = 'TUTKINTOKOULUTUKSEENVALMENTAVA',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    AMMATILLINENKOULUTUS = 'AMMATILLINENKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum KoulutuksenOsaDtoKoulutusOsanTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    VALINNAINEN = 'VALINNAINEN'
}

/**
 * 
 * @export
 * @interface KoulutuksenOsaDtoAllOf
 */
export interface KoulutuksenOsaDtoAllOf {
    /**
     * 
     * @type {KoodiDto}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    laajuusMinimi?: number;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    koulutusOsanKoulutustyyppi?: KoulutuksenOsaDtoAllOfKoulutusOsanKoulutustyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    koulutusOsanTyyppi?: KoulutuksenOsaDtoAllOfKoulutusOsanTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    tavoitteenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    tavoitteet?: Array<LokalisoituTekstiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    keskeinenSisalto?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    laajaAlaisenOsaamisenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    arvioinninKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof KoulutuksenOsaDtoAllOf
     */
    viiteId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum KoulutuksenOsaDtoAllOfKoulutusOsanKoulutustyyppiEnum {
    TUTKINTOKOULUTUKSEENVALMENTAVA = 'TUTKINTOKOULUTUKSEENVALMENTAVA',
    PERUSOPETUS = 'PERUSOPETUS',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    AMMATILLINENKOULUTUS = 'AMMATILLINENKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum KoulutuksenOsaDtoAllOfKoulutusOsanTyyppiEnum {
    YHTEINEN = 'YHTEINEN',
    VALINNAINEN = 'VALINNAINEN'
}

/**
 * 
 * @export
 * @interface KoulutusDto
 */
export interface KoulutusDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutusDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    koulutuskoodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    koulutuskoodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    koulutusalakoodi?: string;
    /**
     * 
     * @type {string}
     * @memberof KoulutusDto
     */
    opintoalakoodi?: string;
}
/**
 * 
 * @export
 * @interface KoulutusalaDto
 */
export interface KoulutusalaDto {
    /**
     * 
     * @type {string}
     * @memberof KoulutusalaDto
     */
    koodi?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof KoulutusalaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OpintoalaDto>}
     * @memberof KoulutusalaDto
     */
    opintoalat?: Array<OpintoalaDto>;
}
/**
 * 
 * @export
 * @interface KoulutustyyppiLukumaara
 */
export interface KoulutustyyppiLukumaara {
    /**
     * 
     * @type {string}
     * @memberof KoulutustyyppiLukumaara
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {number}
     * @memberof KoulutustyyppiLukumaara
     */
    lukumaara?: number;
}
/**
 * 
 * @export
 * @interface Laaja
 */
export interface Laaja {
    /**
     * 
     * @type {number}
     * @memberof Laaja
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Laaja
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Laaja
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Laaja
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Laaja
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof Laaja
     */
    tila?: LaajaTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof Laaja
     */
    tunniste?: LaajaTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Laaja
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Laaja
     */
    kaannettava?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Laaja
     */
    osanTyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LaajaTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum LaajaTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface LaajaalainenOsaaminenDto
 */
export interface LaajaalainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof LaajaalainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LaajaalainenOsaaminenDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LaajaalainenOsaaminenDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LaajaalainenOsaaminenDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Date}
     * @memberof LaajaalainenOsaaminenDto
     */
    muokattu?: Date;
}
/**
 * 
 * @export
 * @interface Laajuus
 */
export interface Laajuus {
    /**
     * 
     * @type {number}
     * @memberof Laajuus
     */
    minimi?: number;
    /**
     * 
     * @type {number}
     * @memberof Laajuus
     */
    maksimi?: number;
    /**
     * 
     * @type {string}
     * @memberof Laajuus
     */
    yksikko?: LaajuusYksikkoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    OPINTOPISTE = 'OPINTOPISTE'
}

/**
 * 
 * @export
 * @interface LiiteBaseDto
 */
export interface LiiteBaseDto {
    /**
     * 
     * @type {string}
     * @memberof LiiteBaseDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteBaseDto
     */
    nimi?: string;
}
/**
 * 
 * @export
 * @interface LiiteDto
 */
export interface LiiteDto {
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    tyyppi?: LiiteDtoTyyppiEnum;
    /**
     * 
     * @type {Date}
     * @memberof LiiteDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    mime?: string;
    /**
     * 
     * @type {string}
     * @memberof LiiteDto
     */
    lisatieto?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LiiteDtoTyyppiEnum {
    TUNTEMATON = 'TUNTEMATON',
    KUVA = 'KUVA',
    OSAAMISMERKKIKUVA = 'OSAAMISMERKKIKUVA',
    DOKUMENTTI = 'DOKUMENTTI',
    MAARAYSKIRJE = 'MAARAYSKIRJE',
    MUUTOSMAARAYS = 'MUUTOSMAARAYS',
    JULKAISUMUUTOSMAARAYS = 'JULKAISUMUUTOSMAARAYS',
    KOULUTUSVIENNINOHJE = 'KOULUTUSVIENNINOHJE',
    KAANNOS = 'KAANNOS'
}

/**
 * 
 * @export
 * @interface LokalisointiDto
 */
export interface LokalisointiDto {
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    key?: string;
    /**
     * 
     * @type {number}
     * @memberof LokalisointiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LokalisointiDto
     */
    category?: string;
}
/**
 * 
 * @export
 * @interface LokalisoitavaOsaDto
 */
export interface LokalisoitavaOsaDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LokalisoitavaOsaDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LokalisoitavaOsaDto
     */
    teksti?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface LokalisoituTekstiDto
 */
export interface LokalisoituTekstiDto {
    /**
     * 
     * @type {number}
     * @memberof LokalisoituTekstiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LokalisoituTekstiDto
     */
    tunniste?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LokalisoituTekstiDto
     */
    tekstit?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Lops2019ArviointiDto
 */
export interface Lops2019ArviointiDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019ArviointiDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface Lops2019LaajaAlainenOsaaminenDto
 */
export interface Lops2019LaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019LaajaAlainenOsaaminenDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface Lops2019LaajaAlainenOsaaminenKokonaisuusDto
 */
export interface Lops2019LaajaAlainenOsaaminenKokonaisuusDto {
    /**
     * 
     * @type {Array<Lops2019LaajaAlainenOsaaminenDto>}
     * @memberof Lops2019LaajaAlainenOsaaminenKokonaisuusDto
     */
    laajaAlaisetOsaamiset?: Array<Lops2019LaajaAlainenOsaaminenDto>;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliBaseDto
 */
export interface Lops2019ModuuliBaseDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019ModuuliBaseDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019ModuuliBaseDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof Lops2019ModuuliBaseDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019ModuuliBaseDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {number}
     * @memberof Lops2019ModuuliBaseDto
     */
    laajuus?: number;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliDto
 */
export interface Lops2019ModuuliDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019ModuuliDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019ModuuliDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof Lops2019ModuuliDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019ModuuliDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {number}
     * @memberof Lops2019ModuuliDto
     */
    laajuus?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019ModuuliDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Lops2019ModuuliTavoiteDto}
     * @memberof Lops2019ModuuliDto
     */
    tavoitteet?: Lops2019ModuuliTavoiteDto;
    /**
     * 
     * @type {Array<Lops2019ModuuliSisaltoDto>}
     * @memberof Lops2019ModuuliDto
     */
    sisallot?: Array<Lops2019ModuuliSisaltoDto>;
    /**
     * 
     * @type {object}
     * @memberof Lops2019ModuuliDto
     */
    oppiaine?: object;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliSisaltoDto
 */
export interface Lops2019ModuuliSisaltoDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019ModuuliSisaltoDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof Lops2019ModuuliSisaltoDto
     */
    sisallot?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface Lops2019ModuuliTavoiteDto
 */
export interface Lops2019ModuuliTavoiteDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019ModuuliTavoiteDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof Lops2019ModuuliTavoiteDto
     */
    tavoitteet?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OpiskeluymparistoTyotavatDto
 */
export interface Lops2019OpiskeluymparistoTyotavatDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OpiskeluymparistoTyotavatDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineDto
 */
export interface Lops2019OppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019OppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019OppiaineDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {object}
     * @memberof Lops2019OppiaineDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {Lops2019ArviointiDto}
     * @memberof Lops2019OppiaineDto
     */
    arviointi?: Lops2019ArviointiDto;
    /**
     * 
     * @type {Lops2019TehtavaDto}
     * @memberof Lops2019OppiaineDto
     */
    tehtava?: Lops2019TehtavaDto;
    /**
     * 
     * @type {Lops2019OpiskeluymparistoTyotavatDto}
     * @memberof Lops2019OppiaineDto
     */
    opiskeluymparistoTyotavat?: Lops2019OpiskeluymparistoTyotavatDto;
    /**
     * 
     * @type {Lops2019OppiaineLaajaAlainenOsaaminenDto}
     * @memberof Lops2019OppiaineDto
     */
    laajaAlaisetOsaamiset?: Lops2019OppiaineLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {Lops2019OppiaineTavoitteetDto}
     * @memberof Lops2019OppiaineDto
     */
    tavoitteet?: Lops2019OppiaineTavoitteetDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineDto
     */
    pakollisetModuulitKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineDto
     */
    valinnaisetModuulitKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<Lops2019ModuuliBaseDto>}
     * @memberof Lops2019OppiaineDto
     */
    moduulit?: Array<Lops2019ModuuliBaseDto>;
    /**
     * 
     * @type {Array<Lops2019OppiaineDto>}
     * @memberof Lops2019OppiaineDto
     */
    oppimaarat?: Array<Lops2019OppiaineDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineKaikkiDto
 */
export interface Lops2019OppiaineKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {object}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {Lops2019ArviointiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    arviointi?: Lops2019ArviointiDto;
    /**
     * 
     * @type {Lops2019TehtavaDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    tehtava?: Lops2019TehtavaDto;
    /**
     * 
     * @type {Lops2019OpiskeluymparistoTyotavatDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    opiskeluymparistoTyotavat?: Lops2019OpiskeluymparistoTyotavatDto;
    /**
     * 
     * @type {Lops2019OppiaineLaajaAlainenOsaaminenDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    laajaAlaisetOsaamiset?: Lops2019OppiaineLaajaAlainenOsaaminenDto;
    /**
     * 
     * @type {Lops2019OppiaineTavoitteetDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    tavoitteet?: Lops2019OppiaineTavoitteetDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    pakollisetModuulitKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    valinnaisetModuulitKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<Lops2019ModuuliDto>}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    moduulit?: Array<Lops2019ModuuliDto>;
    /**
     * 
     * @type {Array<Lops2019OppiaineKaikkiDto>}
     * @memberof Lops2019OppiaineKaikkiDto
     */
    oppimaarat?: Array<Lops2019OppiaineKaikkiDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineLaajaAlainenOsaaminenDto
 */
export interface Lops2019OppiaineLaajaAlainenOsaaminenDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineLaajaAlainenOsaaminenDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineTavoitealueDto
 */
export interface Lops2019OppiaineTavoitealueDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineTavoitealueDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineTavoitealueDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof Lops2019OppiaineTavoitealueDto
     */
    tavoitteet?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface Lops2019OppiaineTavoitteetDto
 */
export interface Lops2019OppiaineTavoitteetDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019OppiaineTavoitteetDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<Lops2019OppiaineTavoitealueDto>}
     * @memberof Lops2019OppiaineTavoitteetDto
     */
    tavoitealueet?: Array<Lops2019OppiaineTavoitealueDto>;
}
/**
 * 
 * @export
 * @interface Lops2019SisaltoDto
 */
export interface Lops2019SisaltoDto {
    /**
     * 
     * @type {Lops2019LaajaAlainenOsaaminenKokonaisuusDto}
     * @memberof Lops2019SisaltoDto
     */
    laajaAlainenOsaaminen?: Lops2019LaajaAlainenOsaaminenKokonaisuusDto;
    /**
     * 
     * @type {Array<Lops2019OppiaineKaikkiDto>}
     * @memberof Lops2019SisaltoDto
     */
    oppiaineet?: Array<Lops2019OppiaineKaikkiDto>;
    /**
     * 
     * @type {Laaja}
     * @memberof Lops2019SisaltoDto
     */
    sisalto?: Laaja;
}
/**
 * 
 * @export
 * @interface Lops2019TehtavaDto
 */
export interface Lops2019TehtavaDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Lops2019TehtavaDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface LukioOpetussuunnitelmaRakenneLaajaDto
 */
export interface LukioOpetussuunnitelmaRakenneLaajaDto extends Laaja {
}


/**
 * 
 * @export
 * @interface LukioOppiaineOppimaaraNodeDto
 */
export interface LukioOppiaineOppimaaraNodeDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    tunniste?: string;
    /**
     * 
     * @type {number}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    pakollinenKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    syventavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    soveltavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoitavaOsaDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    tavoitteet?: LokalisoitavaOsaDto;
    /**
     * 
     * @type {LokalisoitavaOsaDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    arviointi?: LokalisoitavaOsaDto;
    /**
     * 
     * @type {LokalisoitavaOsaDto}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    tehtava?: LokalisoitavaOsaDto;
    /**
     * 
     * @type {Array<LukioOppiaineOppimaaraNodeDto>}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    oppimaarat?: Array<LukioOppiaineOppimaaraNodeDto>;
    /**
     * 
     * @type {Array<LukiokurssiJulkisetTiedotDto>}
     * @memberof LukioOppiaineOppimaaraNodeDto
     */
    kurssit?: Array<LukiokurssiJulkisetTiedotDto>;
}
/**
 * 
 * @export
 * @interface LukioOppiainePuuDto
 */
export interface LukioOppiainePuuDto {
    /**
     * 
     * @type {number}
     * @memberof LukioOppiainePuuDto
     */
    perusteId?: number;
    /**
     * 
     * @type {Array<LukioOppiaineOppimaaraNodeDto>}
     * @memberof LukioOppiainePuuDto
     */
    oppiaineet?: Array<LukioOppiaineOppimaaraNodeDto>;
}
/**
 * 
 * @export
 * @interface LukiokoulutuksenPerusteenSisaltoDto
 */
export interface LukiokoulutuksenPerusteenSisaltoDto {
    /**
     * 
     * @type {Laaja}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    sisalto?: Laaja;
    /**
     * 
     * @type {AihekokonaisuudetLaajaDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    aihekokonaisuudet?: AihekokonaisuudetLaajaDto;
    /**
     * 
     * @type {OpetuksenYleisetTavoitteetLaajaDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    opetuksenYleisetTavoitteet?: OpetuksenYleisetTavoitteetLaajaDto;
    /**
     * 
     * @type {LukioOppiainePuuDto}
     * @memberof LukiokoulutuksenPerusteenSisaltoDto
     */
    rakenne?: LukioOppiainePuuDto;
}
/**
 * 
 * @export
 * @interface LukiokurssiJulkisetTiedotDto
 */
export interface LukiokurssiJulkisetTiedotDto {
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    oppiaineId?: number;
    /**
     * 
     * @type {number}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    tunniste?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    lokalisoituKoodi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    tyyppi?: LukiokurssiJulkisetTiedotDtoTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoitavaOsaDto}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    tavoitteet?: LokalisoitavaOsaDto;
    /**
     * 
     * @type {LokalisoitavaOsaDto}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    keskeisetSisallot?: LokalisoitavaOsaDto;
    /**
     * 
     * @type {LokalisoitavaOsaDto}
     * @memberof LukiokurssiJulkisetTiedotDto
     */
    tavoitteetJaKeskeisetSisallot?: LokalisoitavaOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LukiokurssiJulkisetTiedotDtoTyyppiEnum {
    PAKOLLINEN = 'PAKOLLINEN',
    VALTAKUNNALLINENSYVENTAVA = 'VALTAKUNNALLINEN_SYVENTAVA',
    VALTAKUNNALLINENSOVELTAVA = 'VALTAKUNNALLINEN_SOVELTAVA'
}

/**
 * 
 * @export
 * @interface LukkoDto
 */
export interface LukkoDto {
    /**
     * 
     * @type {string}
     * @memberof LukkoDto
     */
    haltijaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof LukkoDto
     */
    haltijaNimi?: string;
    /**
     * 
     * @type {Date}
     * @memberof LukkoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LukkoDto
     */
    vanhentuu?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LukkoDto
     */
    oma?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LukkoDto
     */
    revisio?: number;
}
/**
 * 
 * @export
 * @interface MaaraysAsiasanaDto
 */
export interface MaaraysAsiasanaDto {
    /**
     * 
     * @type {number}
     * @memberof MaaraysAsiasanaDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaaraysAsiasanaDto
     */
    asiasana?: Array<string>;
}
/**
 * 
 * @export
 * @interface MaaraysDto
 */
export interface MaaraysDto {
    /**
     * 
     * @type {number}
     * @memberof MaaraysDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MaaraysDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MaaraysDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof MaaraysDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof MaaraysDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaaraysDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaaraysDto
     */
    maarayspvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MaaraysDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof MaaraysDto
     */
    tyyppi?: MaaraysDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof MaaraysDto
     */
    liittyyTyyppi?: MaaraysDtoLiittyyTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof MaaraysDto
     */
    tila?: MaaraysDtoTilaEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaaraysDto
     */
    koulutustyypit?: Array<string>;
    /**
     * 
     * @type {PerusteKevytDto}
     * @memberof MaaraysDto
     */
    peruste?: PerusteKevytDto;
    /**
     * 
     * @type {Array<MaaraysKevytDto>}
     * @memberof MaaraysDto
     */
    korvattavatMaaraykset?: Array<MaaraysKevytDto>;
    /**
     * 
     * @type {Array<MaaraysKevytDto>}
     * @memberof MaaraysDto
     */
    korvaavatMaaraykset?: Array<MaaraysKevytDto>;
    /**
     * 
     * @type {Array<MaaraysKevytDto>}
     * @memberof MaaraysDto
     */
    muutettavatMaaraykset?: Array<MaaraysKevytDto>;
    /**
     * 
     * @type {Array<MaaraysKevytDto>}
     * @memberof MaaraysDto
     */
    muuttavatMaaraykset?: Array<MaaraysKevytDto>;
    /**
     * 
     * @type {{ [key: string]: MaaraysKieliLiitteetDto; }}
     * @memberof MaaraysDto
     */
    liitteet?: { [key: string]: MaaraysKieliLiitteetDto; };
    /**
     * 
     * @type {{ [key: string]: MaaraysAsiasanaDto; }}
     * @memberof MaaraysDto
     */
    asiasanat?: { [key: string]: MaaraysAsiasanaDto; };
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof MaaraysDto
     */
    muokkaajaKayttaja?: KayttajanTietoDto;
    /**
     * 
     * @type {string}
     * @memberof MaaraysDto
     */
    muokkaaja?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MaaraysDtoTyyppiEnum {
    OPETUSHALLITUKSENMUU = 'OPETUSHALLITUKSEN_MUU',
    AMMATILLINENMUU = 'AMMATILLINEN_MUU',
    PERUSTE = 'PERUSTE'
}
/**
    * @export
    * @enum {string}
    */
export enum MaaraysDtoLiittyyTyyppiEnum {
    EILIITY = 'EI_LIITY',
    MUUTTAA = 'MUUTTAA',
    KORVAA = 'KORVAA'
}
/**
    * @export
    * @enum {string}
    */
export enum MaaraysDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface MaaraysKevytDto
 */
export interface MaaraysKevytDto {
    /**
     * 
     * @type {number}
     * @memberof MaaraysKevytDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MaaraysKevytDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof MaaraysKevytDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof MaaraysKevytDto
     */
    tila?: MaaraysKevytDtoTilaEnum;
    /**
     * 
     * @type {Date}
     * @memberof MaaraysKevytDto
     */
    voimassaoloAlkaa?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum MaaraysKevytDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface MaaraysKieliLiitteetDto
 */
export interface MaaraysKieliLiitteetDto {
    /**
     * 
     * @type {number}
     * @memberof MaaraysKieliLiitteetDto
     */
    id?: number;
    /**
     * 
     * @type {Array<MaaraysLiiteDto>}
     * @memberof MaaraysKieliLiitteetDto
     */
    liitteet?: Array<MaaraysLiiteDto>;
}
/**
 * 
 * @export
 * @interface MaaraysLiiteDto
 */
export interface MaaraysLiiteDto {
    /**
     * 
     * @type {string}
     * @memberof MaaraysLiiteDto
     */
    id?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MaaraysLiiteDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof MaaraysLiiteDto
     */
    tiedostonimi?: string;
    /**
     * 
     * @type {string}
     * @memberof MaaraysLiiteDto
     */
    tyyppi?: MaaraysLiiteDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof MaaraysLiiteDto
     */
    fileB64?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MaaraysLiiteDtoTyyppiEnum {
    MAARAYSDOKUMENTTI = 'MAARAYSDOKUMENTTI',
    LIITE = 'LIITE'
}

/**
 * 
 * @export
 * @interface MaarayskirjeDto
 */
export interface MaarayskirjeDto {
    /**
     * 
     * @type {number}
     * @memberof MaarayskirjeDto
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MaarayskirjeDto
     */
    url?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: LiiteBaseDto; }}
     * @memberof MaarayskirjeDto
     */
    liitteet?: { [key: string]: LiiteBaseDto; };
}
/**
 * 
 * @export
 * @interface Matala
 */
export interface Matala {
    /**
     * 
     * @type {number}
     * @memberof Matala
     */
    id?: number;
    /**
     * 
     * @type {Laaja}
     * @memberof Matala
     */
    perusteenOsa?: Laaja;
    /**
     * 
     * @type {Array<object>}
     * @memberof Matala
     */
    lapset?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof Matala
     */
    _perusteenOsa?: object;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    kommentti?: string;
}
/**
 * 
 * @export
 * @interface MuodostumisSaantoDto
 */
export interface MuodostumisSaantoDto {
    /**
     * 
     * @type {Laajuus}
     * @memberof MuodostumisSaantoDto
     */
    laajuus?: Laajuus;
    /**
     * 
     * @type {Koko}
     * @memberof MuodostumisSaantoDto
     */
    koko?: Koko;
}
/**
 * 
 * @export
 * @interface MuokkaustietoKayttajallaDto
 */
export interface MuokkaustietoKayttajallaDto {
    /**
     * 
     * @type {number}
     * @memberof MuokkaustietoKayttajallaDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MuokkaustietoKayttajallaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    tapahtuma?: MuokkaustietoKayttajallaDtoTapahtumaEnum;
    /**
     * 
     * @type {number}
     * @memberof MuokkaustietoKayttajallaDto
     */
    perusteId?: number;
    /**
     * 
     * @type {number}
     * @memberof MuokkaustietoKayttajallaDto
     */
    kohdeId?: number;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    kohde?: MuokkaustietoKayttajallaDtoKohdeEnum;
    /**
     * 
     * @type {Date}
     * @memberof MuokkaustietoKayttajallaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof MuokkaustietoKayttajallaDto
     */
    lisatieto?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MuokkaustietoKayttajallaDto
     */
    poistettu?: boolean;
    /**
     * 
     * @type {Array<PerusteenMuokkaustietoLisaparametritDto>}
     * @memberof MuokkaustietoKayttajallaDto
     */
    lisaparametrit?: Array<PerusteenMuokkaustietoLisaparametritDto>;
    /**
     * 
     * @type {KayttajanTietoDto}
     * @memberof MuokkaustietoKayttajallaDto
     */
    kayttajanTieto?: KayttajanTietoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum MuokkaustietoKayttajallaDtoTapahtumaEnum {
    LUONTI = 'LUONTI',
    PAIVITYS = 'PAIVITYS',
    PALAUTUS = 'PALAUTUS',
    JARJESTETTY = 'JARJESTETTY',
    KOPIOINTI = 'KOPIOINTI',
    JULKAISU = 'JULKAISU',
    POISTO = 'POISTO'
}
/**
    * @export
    * @enum {string}
    */
export enum MuokkaustietoKayttajallaDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Kvliite = 'kvliite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Oppimaara = 'oppimaara',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Muodostuminen = 'muodostuminen',
    Tutkinnonosat = 'tutkinnonosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tutkinnonosaviite = 'tutkinnonosaviite',
    Osaalue = 'osaalue',
    Osaalueet = 'osaalueet',
    TutkinnonMuodostuminen = 'tutkinnon_muodostuminen',
    Peruste = 'peruste',
    Lukiorakenne = 'lukiorakenne',
    Aihekokonaisuudet = 'aihekokonaisuudet',
    Opetuksenyleisettavoitteet = 'opetuksenyleisettavoitteet',
    Tekstikappale = 'tekstikappale',
    Taiteenala = 'taiteenala',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Vuosiluokkakokonaisuudet = 'vuosiluokkakokonaisuudet',
    Perusopetuslaajaalaisetosaamiset = 'perusopetuslaajaalaisetosaamiset',
    Perusopetuslaajaalainenosaaminen = 'perusopetuslaajaalainenosaaminen',
    Aipevaihe = 'aipevaihe',
    Aipekurssi = 'aipekurssi',
    Aipeoppiaine = 'aipeoppiaine',
    Kurssit = 'kurssit',
    Taiteenosa = 'taiteenosa',
    Termi = 'termi',
    Opintokokonaisuus = 'opintokokonaisuus',
    Tavoitesisaltoalue = 'tavoitesisaltoalue',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    Linkkisivu = 'linkkisivu',
    Koulutuksenosat = 'koulutuksenosat',
    Koulutuksenosa = 'koulutuksenosa',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    Osaamiskokonaisuus = 'osaamiskokonaisuus',
    OsaamiskokonaisuusPaaAlue = 'osaamiskokonaisuus_paa_alue',
    AipeLaajaalaisetosaamiset = 'aipe_laajaalaisetosaamiset',
    AipeLaajaalainenosaaminen = 'aipe_laajaalainenosaaminen'
}

/**
 * 
 * @export
 * @interface MuuMaaraysDto
 */
export interface MuuMaaraysDto {
    /**
     * 
     * @type {number}
     * @memberof MuuMaaraysDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MuuMaaraysDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MuuMaaraysDto
     */
    url?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof MuuMaaraysDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof MuuMaaraysDto
     */
    muokkaaja?: string;
}
/**
 * 
 * @export
 * @interface MuutosmaaraysDto
 */
export interface MuutosmaaraysDto {
    /**
     * 
     * @type {number}
     * @memberof MuutosmaaraysDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MuutosmaaraysDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof MuutosmaaraysDto
     */
    url?: LokalisoituTekstiDto;
    /**
     * 
     * @type {{ [key: string]: LiiteBaseDto; }}
     * @memberof MuutosmaaraysDto
     */
    liitteet?: { [key: string]: LiiteBaseDto; };
}
/**
 * 
 * @export
 * @interface MuutostapahtumaDto
 */
export interface MuutostapahtumaDto {
    /**
     * 
     * @type {string}
     * @memberof MuutostapahtumaDto
     */
    tapahtuma?: MuutostapahtumaDtoTapahtumaEnum;
    /**
     * 
     * @type {Array<PerusteenMuokkaustietoDto>}
     * @memberof MuutostapahtumaDto
     */
    muokkaustiedot?: Array<PerusteenMuokkaustietoDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum MuutostapahtumaDtoTapahtumaEnum {
    LUONTI = 'LUONTI',
    PAIVITYS = 'PAIVITYS',
    PALAUTUS = 'PALAUTUS',
    JARJESTETTY = 'JARJESTETTY',
    KOPIOINTI = 'KOPIOINTI',
    JULKAISU = 'JULKAISU',
    POISTO = 'POISTO'
}

/**
 * 
 * @export
 * @interface NavigationNodeDto
 */
export interface NavigationNodeDto {
    /**
     * 
     * @type {number}
     * @memberof NavigationNodeDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof NavigationNodeDto
     */
    label?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof NavigationNodeDto
     */
    type?: NavigationNodeDtoTypeEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof NavigationNodeDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof NavigationNodeDto
     */
    meta?: { [key: string]: object; };
    /**
     * 
     * @type {Array<NavigationNodeDto>}
     * @memberof NavigationNodeDto
     */
    children?: Array<NavigationNodeDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum NavigationNodeDtoTypeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Kvliite = 'kvliite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Oppimaara = 'oppimaara',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Muodostuminen = 'muodostuminen',
    Tutkinnonosat = 'tutkinnonosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tutkinnonosaviite = 'tutkinnonosaviite',
    Osaalue = 'osaalue',
    Osaalueet = 'osaalueet',
    TutkinnonMuodostuminen = 'tutkinnon_muodostuminen',
    Peruste = 'peruste',
    Lukiorakenne = 'lukiorakenne',
    Aihekokonaisuudet = 'aihekokonaisuudet',
    Opetuksenyleisettavoitteet = 'opetuksenyleisettavoitteet',
    Tekstikappale = 'tekstikappale',
    Taiteenala = 'taiteenala',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Vuosiluokkakokonaisuudet = 'vuosiluokkakokonaisuudet',
    Perusopetuslaajaalaisetosaamiset = 'perusopetuslaajaalaisetosaamiset',
    Perusopetuslaajaalainenosaaminen = 'perusopetuslaajaalainenosaaminen',
    Aipevaihe = 'aipevaihe',
    Aipekurssi = 'aipekurssi',
    Aipeoppiaine = 'aipeoppiaine',
    Kurssit = 'kurssit',
    Taiteenosa = 'taiteenosa',
    Termi = 'termi',
    Opintokokonaisuus = 'opintokokonaisuus',
    Tavoitesisaltoalue = 'tavoitesisaltoalue',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    Linkkisivu = 'linkkisivu',
    Koulutuksenosat = 'koulutuksenosat',
    Koulutuksenosa = 'koulutuksenosa',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    Osaamiskokonaisuus = 'osaamiskokonaisuus',
    OsaamiskokonaisuusPaaAlue = 'osaamiskokonaisuus_paa_alue',
    AipeLaajaalaisetosaamiset = 'aipe_laajaalaisetosaamiset',
    AipeLaajaalainenosaaminen = 'aipe_laajaalainenosaaminen'
}

/**
 * 
 * @export
 * @interface OmistajaDto
 */
export interface OmistajaDto {
    /**
     * 
     * @type {number}
     * @memberof OmistajaDto
     */
    ownerPerusteProjektiId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OmistajaDto
     */
    owner?: boolean;
}
/**
 * 
 * @export
 * @interface OpasDto
 */
export interface OpasDto {
    /**
     * 
     * @type {number}
     * @memberof OpasDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpasDto
     */
    nimi?: string;
    /**
     * 
     * @type {object}
     * @memberof OpasDto
     */
    peruste?: object;
    /**
     * 
     * @type {string}
     * @memberof OpasDto
     */
    tila?: OpasDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpasDto
     */
    ryhmaOid?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OpasDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface OpasLuontiDto
 */
export interface OpasLuontiDto {
    /**
     * 
     * @type {number}
     * @memberof OpasLuontiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpasLuontiDto
     */
    nimi?: string;
    /**
     * 
     * @type {object}
     * @memberof OpasLuontiDto
     */
    peruste?: object;
    /**
     * 
     * @type {string}
     * @memberof OpasLuontiDto
     */
    tila?: OpasLuontiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof OpasLuontiDto
     */
    ryhmaOid?: string;
    /**
     * 
     * @type {number}
     * @memberof OpasLuontiDto
     */
    pohjaId?: number;
    /**
     * 
     * @type {Array<PerusteKevytDto>}
     * @memberof OpasLuontiDto
     */
    oppaanPerusteet?: Array<PerusteKevytDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpasLuontiDto
     */
    oppaanKoulutustyypit?: Array<OpasLuontiDtoOppaanKoulutustyypitEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpasLuontiDto
     */
    lokalisoituNimi?: LokalisoituTekstiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OpasLuontiDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum OpasLuontiDtoOppaanKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}

/**
 * 
 * @export
 * @interface OpasSisaltoDto
 */
export interface OpasSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof OpasSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {Laaja}
     * @memberof OpasSisaltoDto
     */
    sisalto?: Laaja;
    /**
     * 
     * @type {Array<OppaanKiinnitettyKoodiDto>}
     * @memberof OpasSisaltoDto
     */
    oppaanKiinnitetytKoodit?: Array<OppaanKiinnitettyKoodiDto>;
}
/**
 * 
 * @export
 * @interface OpasSisaltoKevytDto
 */
export interface OpasSisaltoKevytDto {
    /**
     * 
     * @type {number}
     * @memberof OpasSisaltoKevytDto
     */
    id?: number;
    /**
     * 
     * @type {Array<OppaanKiinnitettyKoodiDto>}
     * @memberof OpasSisaltoKevytDto
     */
    oppaanKiinnitetytKoodit?: Array<OppaanKiinnitettyKoodiDto>;
}
/**
 * 
 * @export
 * @interface OpetuksenKohdealueDto
 */
export interface OpetuksenKohdealueDto {
    /**
     * 
     * @type {number}
     * @memberof OpetuksenKohdealueDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenKohdealueDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenKohdealueDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OpetuksenTavoiteDto
 */
export interface OpetuksenTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OpetuksenTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OpetuksenTavoiteDto
     */
    tunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenTavoiteDto
     */
    tavoite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<object>}
     * @memberof OpetuksenTavoiteDto
     */
    sisaltoalueet?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof OpetuksenTavoiteDto
     */
    laajattavoitteet?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof OpetuksenTavoiteDto
     */
    kohdealueet?: Array<object>;
    /**
     * 
     * @type {Array<TavoitteenArviointiDto>}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninkohteet?: Array<TavoitteenArviointiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninOtsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenTavoiteDto
     */
    arvioinninOsaamisenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenTavoiteDto
     */
    tavoitteistaJohdetutOppimisenTavoitteet?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenTavoiteDto
     */
    vapaaTeksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OppiaineenTavoitteenOpetuksenTavoiteDto>}
     * @memberof OpetuksenTavoiteDto
     */
    oppiaineenTavoitteenOpetuksenTavoitteet?: Array<OppiaineenTavoitteenOpetuksenTavoiteDto>;
}
/**
 * 
 * @export
 * @interface OpetuksenYleisetTavoitteetLaajaDto
 */
export interface OpetuksenYleisetTavoitteetLaajaDto extends Laaja {
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetLaajaDto
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenYleisetTavoitteetLaajaDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenYleisetTavoitteetLaajaDto
     */
    kuvaus?: LokalisoituTekstiDto;
}


/**
 * 
 * @export
 * @interface OpetuksenYleisetTavoitteetLaajaDtoAllOf
 */
export interface OpetuksenYleisetTavoitteetLaajaDtoAllOf {
    /**
     * 
     * @type {string}
     * @memberof OpetuksenYleisetTavoitteetLaajaDtoAllOf
     */
    uuidTunniste?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenYleisetTavoitteetLaajaDtoAllOf
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpetuksenYleisetTavoitteetLaajaDtoAllOf
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OpintoalaDto
 */
export interface OpintoalaDto {
    /**
     * 
     * @type {string}
     * @memberof OpintoalaDto
     */
    koodi?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpintoalaDto
     */
    nimi?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OpintokokonaisuusDto
 */
export interface OpintokokonaisuusDto extends Laaja {
    /**
     * 
     * @type {KoodiDto}
     * @memberof OpintokokonaisuusDto
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusDto
     */
    minimilaajuus?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpintokokonaisuusDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpintokokonaisuusDto
     */
    opetuksenTavoiteOtsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof OpintokokonaisuusDto
     */
    opetuksenTavoitteet?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OpintokokonaisuusDto
     */
    arvioinnit?: Array<LokalisoituTekstiDto>;
}


/**
 * 
 * @export
 * @interface OpintokokonaisuusDtoAllOf
 */
export interface OpintokokonaisuusDtoAllOf {
    /**
     * 
     * @type {KoodiDto}
     * @memberof OpintokokonaisuusDtoAllOf
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {number}
     * @memberof OpintokokonaisuusDtoAllOf
     */
    minimilaajuus?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpintokokonaisuusDtoAllOf
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OpintokokonaisuusDtoAllOf
     */
    opetuksenTavoiteOtsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof OpintokokonaisuusDtoAllOf
     */
    opetuksenTavoitteet?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OpintokokonaisuusDtoAllOf
     */
    arvioinnit?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface OppaanKiinnitettyKoodiDto
 */
export interface OppaanKiinnitettyKoodiDto {
    /**
     * 
     * @type {number}
     * @memberof OppaanKiinnitettyKoodiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppaanKiinnitettyKoodiDto
     */
    kiinnitettyKoodiTyyppi?: OppaanKiinnitettyKoodiDtoKiinnitettyKoodiTyyppiEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OppaanKiinnitettyKoodiDto
     */
    koodi?: KoodiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OppaanKiinnitettyKoodiDtoKiinnitettyKoodiTyyppiEnum {
    TUTKINNONOSA = 'TUTKINNONOSA',
    OSAAMISALA = 'OSAAMISALA',
    OPPIAINE = 'OPPIAINE',
    OPINTOKOKONAISUUS = 'OPINTOKOKONAISUUS',
    KOULUTUKSENOSA = 'KOULUTUKSENOSA'
}

/**
 * 
 * @export
 * @interface OppiaineDto
 */
export interface OppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {object}
     * @memberof OppiaineDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof OppiaineDto
     */
    jnro?: number;
    /**
     * 
     * @type {Date}
     * @memberof OppiaineDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineDto
     */
    tavoitteet?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineDto
     */
    arviointi?: TekstiOsaDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineDto
     */
    pakollinenKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineDto
     */
    syventavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineDto
     */
    soveltavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineDto
     */
    partial?: boolean;
    /**
     * 
     * @type {Array<OppiaineSuppeaDto>}
     * @memberof OppiaineDto
     */
    oppimaarat?: Array<OppiaineSuppeaDto>;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof OppiaineDto
     */
    kohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkaKokonaisuusDto>}
     * @memberof OppiaineDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkaKokonaisuusDto>;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OppiaineDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof OppiaineDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
}
/**
 * 
 * @export
 * @interface OppiaineLaajaDto
 */
export interface OppiaineLaajaDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineLaajaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineLaajaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {object}
     * @memberof OppiaineLaajaDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineLaajaDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineLaajaDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineLaajaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof OppiaineLaajaDto
     */
    jnro?: number;
    /**
     * 
     * @type {Date}
     * @memberof OppiaineLaajaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineLaajaDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof OppiaineLaajaDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {Array<OppiaineDto>}
     * @memberof OppiaineLaajaDto
     */
    oppimaarat?: Array<OppiaineDto>;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof OppiaineLaajaDto
     */
    kohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkaKokonaisuusDto>}
     * @memberof OppiaineLaajaDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkaKokonaisuusDto>;
    /**
     * 
     * @type {string}
     * @memberof OppiaineLaajaDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OppiaineLaajaDto
     */
    koodiArvo?: string;
}
/**
 * 
 * @export
 * @interface OppiaineSuppeaDto
 */
export interface OppiaineSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    tunniste?: string;
    /**
     * 
     * @type {object}
     * @memberof OppiaineSuppeaDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineSuppeaDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OppiaineSuppeaDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineSuppeaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof OppiaineSuppeaDto
     */
    jnro?: number;
    /**
     * 
     * @type {Date}
     * @memberof OppiaineSuppeaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {Array<OppiaineSuppeaDto>}
     * @memberof OppiaineSuppeaDto
     */
    oppimaarat?: Array<OppiaineSuppeaDto>;
    /**
     * 
     * @type {string}
     * @memberof OppiaineSuppeaDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkaKokonaisuusSuppeaDto>}
     * @memberof OppiaineSuppeaDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkaKokonaisuusSuppeaDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineSuppeaDto
     */
    pakollinenKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineSuppeaDto
     */
    syventavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineSuppeaDto
     */
    soveltavaKurssiKuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OppiaineenTavoitteenOpetuksenTavoiteDto
 */
export interface OppiaineenTavoitteenOpetuksenTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenTavoitteenOpetuksenTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineenTavoitteenOpetuksenTavoiteDto
     */
    tavoite?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OppiaineenVuosiluokkaKokonaisuusDto
 */
export interface OppiaineenVuosiluokkaKokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    vuosiluokkaKokonaisuus?: object;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    tyotavat?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    ohjaus?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    arviointi?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    sisaltoalueinfo?: TekstiOsaDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    opetuksenTavoitteetOtsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    vapaaTeksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OpetuksenTavoiteDto>}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    tavoitteet?: Array<OpetuksenTavoiteDto>;
    /**
     * 
     * @type {Array<KeskeinenSisaltoalueDto>}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    sisaltoalueet?: Array<KeskeinenSisaltoalueDto>;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof OppiaineenVuosiluokkaKokonaisuusDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
}
/**
 * 
 * @export
 * @interface OppiaineenVuosiluokkaKokonaisuusSuppeaDto
 */
export interface OppiaineenVuosiluokkaKokonaisuusSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof OppiaineenVuosiluokkaKokonaisuusSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof OppiaineenVuosiluokkaKokonaisuusSuppeaDto
     */
    vuosiluokkaKokonaisuus?: object;
}
/**
 * 
 * @export
 * @interface OsaAlueDto
 */
export interface OsaAlueDto {
    /**
     * 
     * @type {number}
     * @memberof OsaAlueDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * Määrittää osaamisalueiden tyypit (vanha vai uusi)
     * @type {string}
     * @memberof OsaAlueDto
     */
    tyyppi?: OsaAlueDtoTyyppiEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueDto
     */
    kielikoodi?: KoodiDto;
    /**
     * 
     * @type {ValmaTelmaSisaltoDto}
     * @memberof OsaAlueDto
     */
    valmaTelmaSisalto?: ValmaTelmaSisaltoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaAlueDtoTyyppiEnum {
    OSAALUE2014 = 'OSAALUE2014',
    OSAALUE2020 = 'OSAALUE2020'
}

/**
 * 
 * @export
 * @interface OsaAlueKaikkiDto
 */
export interface OsaAlueKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof OsaAlueKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueKaikkiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueKaikkiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * Määrittää osaamisalueiden tyypit (vanha vai uusi)
     * @type {string}
     * @memberof OsaAlueKaikkiDto
     */
    tyyppi?: OsaAlueKaikkiDtoTyyppiEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueKaikkiDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueKaikkiDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueKaikkiDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueKaikkiDto
     */
    kielikoodi?: KoodiDto;
    /**
     * 
     * @type {ValmaTelmaSisaltoDto}
     * @memberof OsaAlueKaikkiDto
     */
    valmaTelmaSisalto?: ValmaTelmaSisaltoDto;
    /**
     * 
     * @type {GeneerinenArviointiasteikkoKaikkiDto}
     * @memberof OsaAlueKaikkiDto
     */
    arviointi?: GeneerinenArviointiasteikkoKaikkiDto;
    /**
     * 
     * @type {Osaamistavoite2020Dto}
     * @memberof OsaAlueKaikkiDto
     */
    pakollisetOsaamistavoitteet?: Osaamistavoite2020Dto;
    /**
     * 
     * @type {Osaamistavoite2020Dto}
     * @memberof OsaAlueKaikkiDto
     */
    valinnaisetOsaamistavoitteet?: Osaamistavoite2020Dto;
    /**
     * Vanhan malliset osaamistavoitteet (OSAALUE2014)
     * @type {Array<OsaamistavoiteLaajaDto>}
     * @memberof OsaAlueKaikkiDto
     */
    osaamistavoitteet?: Array<OsaamistavoiteLaajaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaAlueKaikkiDtoTyyppiEnum {
    OSAALUE2014 = 'OSAALUE2014',
    OSAALUE2020 = 'OSAALUE2020'
}

/**
 * 
 * @export
 * @interface OsaAlueKokonaanDto
 */
export interface OsaAlueKokonaanDto {
    /**
     * 
     * @type {number}
     * @memberof OsaAlueKokonaanDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueKokonaanDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueKokonaanDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * Määrittää osaamisalueiden tyypit (vanha vai uusi)
     * @type {string}
     * @memberof OsaAlueKokonaanDto
     */
    tyyppi?: OsaAlueKokonaanDtoTyyppiEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueKokonaanDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueKokonaanDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueKokonaanDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueKokonaanDto
     */
    kielikoodi?: KoodiDto;
    /**
     * 
     * @type {ValmaTelmaSisaltoDto}
     * @memberof OsaAlueKokonaanDto
     */
    valmaTelmaSisalto?: ValmaTelmaSisaltoDto;
    /**
     * 
     * @type {Arviointi2020Dto}
     * @memberof OsaAlueKokonaanDto
     */
    arviointi?: Arviointi2020Dto;
    /**
     * 
     * @type {Osaamistavoite2020Dto}
     * @memberof OsaAlueKokonaanDto
     */
    pakollisetOsaamistavoitteet?: Osaamistavoite2020Dto;
    /**
     * 
     * @type {Osaamistavoite2020Dto}
     * @memberof OsaAlueKokonaanDto
     */
    valinnaisetOsaamistavoitteet?: Osaamistavoite2020Dto;
    /**
     * 
     * @type {Array<OsaamistavoiteLaajaDto>}
     * @memberof OsaAlueKokonaanDto
     */
    osaamistavoitteet?: Array<OsaamistavoiteLaajaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaAlueKokonaanDtoTyyppiEnum {
    OSAALUE2014 = 'OSAALUE2014',
    OSAALUE2020 = 'OSAALUE2020'
}

/**
 * 
 * @export
 * @interface OsaAlueLaajaDto
 */
export interface OsaAlueLaajaDto {
    /**
     * 
     * @type {number}
     * @memberof OsaAlueLaajaDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueLaajaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaAlueLaajaDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * Määrittää osaamisalueiden tyypit (vanha vai uusi)
     * @type {string}
     * @memberof OsaAlueLaajaDto
     */
    tyyppi?: OsaAlueLaajaDtoTyyppiEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueLaajaDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueLaajaDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaAlueLaajaDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaAlueLaajaDto
     */
    kielikoodi?: KoodiDto;
    /**
     * 
     * @type {ValmaTelmaSisaltoDto}
     * @memberof OsaAlueLaajaDto
     */
    valmaTelmaSisalto?: ValmaTelmaSisaltoDto;
    /**
     * 
     * @type {Arviointi2020Dto}
     * @memberof OsaAlueLaajaDto
     */
    arviointi?: Arviointi2020Dto;
    /**
     * 
     * @type {Osaamistavoite2020Dto}
     * @memberof OsaAlueLaajaDto
     */
    pakollisetOsaamistavoitteet?: Osaamistavoite2020Dto;
    /**
     * 
     * @type {Osaamistavoite2020Dto}
     * @memberof OsaAlueLaajaDto
     */
    valinnaisetOsaamistavoitteet?: Osaamistavoite2020Dto;
    /**
     * 
     * @type {Array<OsaamistavoiteDto>}
     * @memberof OsaAlueLaajaDto
     */
    osaamistavoitteet?: Array<OsaamistavoiteDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaAlueLaajaDtoTyyppiEnum {
    OSAALUE2014 = 'OSAALUE2014',
    OSAALUE2020 = 'OSAALUE2020'
}

/**
 * 
 * @export
 * @interface OsaamisalaDto
 */
export interface OsaamisalaDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamisalaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamisalaDto
     */
    osaamisalakoodiArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamisalaDto
     */
    osaamisalakoodiUri?: string;
}
/**
 * 
 * @export
 * @interface OsaamisenArviointiDto
 */
export interface OsaamisenArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamisenArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamisenArviointiDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamisenArviointiDto
     */
    selite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamisenArviointiDto
     */
    tavoitteet?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface OsaamisenTavoiteDto
 */
export interface OsaamisenTavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamisenTavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamisenTavoiteDto
     */
    kohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamisenTavoiteDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamisenTavoiteDto
     */
    selite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamisenTavoiteDto
     */
    tavoitteet?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface OsaamiskokonaisuusDto
 */
export interface OsaamiskokonaisuusDto extends Laaja {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusDto
     */
    keskeinenKasitteisto?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamiskokonaisuusKasitteistoDto>}
     * @memberof OsaamiskokonaisuusDto
     */
    kasitteistot?: Array<OsaamiskokonaisuusKasitteistoDto>;
}


/**
 * 
 * @export
 * @interface OsaamiskokonaisuusDtoAllOf
 */
export interface OsaamiskokonaisuusDtoAllOf {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusDtoAllOf
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusDtoAllOf
     */
    keskeinenKasitteisto?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamiskokonaisuusKasitteistoDto>}
     * @memberof OsaamiskokonaisuusDtoAllOf
     */
    kasitteistot?: Array<OsaamiskokonaisuusKasitteistoDto>;
}
/**
 * 
 * @export
 * @interface OsaamiskokonaisuusKasitteistoDto
 */
export interface OsaamiskokonaisuusKasitteistoDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamiskokonaisuusKasitteistoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OsaamiskokonaisuusKasitteistoDto
     */
    taso?: OsaamiskokonaisuusKasitteistoDtoTasoEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusKasitteistoDto
     */
    kuvaus?: LokalisoituTekstiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamiskokonaisuusKasitteistoDtoTasoEnum {
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    ESIOPETUS = 'ESIOPETUS',
    VUOSILUOKKA12 = 'VUOSILUOKKA_12',
    VUOSILUOKKA3456 = 'VUOSILUOKKA_3456',
    VUOSILUOKKA789 = 'VUOSILUOKKA_789'
}

/**
 * 
 * @export
 * @interface OsaamiskokonaisuusOsaAlueDto
 */
export interface OsaamiskokonaisuusOsaAlueDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamiskokonaisuusOsaAlueDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusOsaAlueDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamiskokonaisuusOsaAlueTasoKuvausDto>}
     * @memberof OsaamiskokonaisuusOsaAlueDto
     */
    tasokuvaukset?: Array<OsaamiskokonaisuusOsaAlueTasoKuvausDto>;
}
/**
 * 
 * @export
 * @interface OsaamiskokonaisuusOsaAlueTasoKuvausDto
 */
export interface OsaamiskokonaisuusOsaAlueTasoKuvausDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamiskokonaisuusOsaAlueTasoKuvausDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OsaamiskokonaisuusOsaAlueTasoKuvausDto
     */
    taso?: OsaamiskokonaisuusOsaAlueTasoKuvausDtoTasoEnum;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamiskokonaisuusOsaAlueTasoKuvausDto
     */
    edelleenKehittyvatOsaamiset?: Array<LokalisoituTekstiDto>;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamiskokonaisuusOsaAlueTasoKuvausDto
     */
    osaamiset?: Array<LokalisoituTekstiDto>;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamiskokonaisuusOsaAlueTasoKuvausDto
     */
    edistynytOsaaminenKuvaukset?: Array<LokalisoituTekstiDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamiskokonaisuusOsaAlueTasoKuvausDtoTasoEnum {
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    ESIOPETUS = 'ESIOPETUS',
    VUOSILUOKKA12 = 'VUOSILUOKKA_12',
    VUOSILUOKKA3456 = 'VUOSILUOKKA_3456',
    VUOSILUOKKA789 = 'VUOSILUOKKA_789'
}

/**
 * 
 * @export
 * @interface OsaamiskokonaisuusPaaAlueDto
 */
export interface OsaamiskokonaisuusPaaAlueDto extends Laaja {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusPaaAlueDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamiskokonaisuusOsaAlueDto>}
     * @memberof OsaamiskokonaisuusPaaAlueDto
     */
    osaAlueet?: Array<OsaamiskokonaisuusOsaAlueDto>;
}


/**
 * 
 * @export
 * @interface OsaamiskokonaisuusPaaAlueDtoAllOf
 */
export interface OsaamiskokonaisuusPaaAlueDtoAllOf {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamiskokonaisuusPaaAlueDtoAllOf
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamiskokonaisuusOsaAlueDto>}
     * @memberof OsaamiskokonaisuusPaaAlueDtoAllOf
     */
    osaAlueet?: Array<OsaamiskokonaisuusOsaAlueDto>;
}
/**
 * 
 * @export
 * @interface OsaamismerkkiArviointikriteeriDto
 */
export interface OsaamismerkkiArviointikriteeriDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiArviointikriteeriDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiArviointikriteeriDto
     */
    arviointikriteeri?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OsaamismerkkiBaseDto
 */
export interface OsaamismerkkiBaseDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiBaseDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiBaseDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiBaseDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiBaseDto
     */
    tila?: OsaamismerkkiBaseDtoTilaEnum;
    /**
     * 
     * @type {OsaamismerkkiKategoriaDto}
     * @memberof OsaamismerkkiBaseDto
     */
    kategoria?: OsaamismerkkiKategoriaDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiBaseDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {Array<OsaamismerkkiOsaamistavoiteDto>}
     * @memberof OsaamismerkkiBaseDto
     */
    osaamistavoitteet?: Array<OsaamismerkkiOsaamistavoiteDto>;
    /**
     * 
     * @type {Array<OsaamismerkkiArviointikriteeriDto>}
     * @memberof OsaamismerkkiBaseDto
     */
    arviointikriteerit?: Array<OsaamismerkkiArviointikriteeriDto>;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiBaseDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiBaseDto
     */
    voimassaoloLoppuu?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamismerkkiBaseDtoTilaEnum {
    LAADINTA = 'LAADINTA',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface OsaamismerkkiDto
 */
export interface OsaamismerkkiDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiDto
     */
    tila?: OsaamismerkkiDtoTilaEnum;
    /**
     * 
     * @type {OsaamismerkkiKategoriaDto}
     * @memberof OsaamismerkkiDto
     */
    kategoria?: OsaamismerkkiKategoriaDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {Array<OsaamismerkkiOsaamistavoiteDto>}
     * @memberof OsaamismerkkiDto
     */
    osaamistavoitteet?: Array<OsaamismerkkiOsaamistavoiteDto>;
    /**
     * 
     * @type {Array<OsaamismerkkiArviointikriteeriDto>}
     * @memberof OsaamismerkkiDto
     */
    arviointikriteerit?: Array<OsaamismerkkiArviointikriteeriDto>;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiDto
     */
    muokkaaja?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamismerkkiDtoTilaEnum {
    LAADINTA = 'LAADINTA',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface OsaamismerkkiExternalDto
 */
export interface OsaamismerkkiExternalDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiExternalDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiExternalDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiExternalDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiExternalDto
     */
    tila?: OsaamismerkkiExternalDtoTilaEnum;
    /**
     * 
     * @type {OsaamismerkkiKategoriaDto}
     * @memberof OsaamismerkkiExternalDto
     */
    kategoria?: OsaamismerkkiKategoriaDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiExternalDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {Array<OsaamismerkkiOsaamistavoiteDto>}
     * @memberof OsaamismerkkiExternalDto
     */
    osaamistavoitteet?: Array<OsaamismerkkiOsaamistavoiteDto>;
    /**
     * 
     * @type {Array<OsaamismerkkiArviointikriteeriDto>}
     * @memberof OsaamismerkkiExternalDto
     */
    arviointikriteerit?: Array<OsaamismerkkiArviointikriteeriDto>;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiExternalDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiExternalDto
     */
    voimassaoloLoppuu?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamismerkkiExternalDtoTilaEnum {
    LAADINTA = 'LAADINTA',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface OsaamismerkkiKategoriaDto
 */
export interface OsaamismerkkiKategoriaDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiKategoriaDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiKategoriaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiKategoriaDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {OsaamismerkkiKategoriaLiiteDto}
     * @memberof OsaamismerkkiKategoriaDto
     */
    liite?: OsaamismerkkiKategoriaLiiteDto;
    /**
     * 
     * @type {Date}
     * @memberof OsaamismerkkiKategoriaDto
     */
    muokattu?: Date;
}
/**
 * 
 * @export
 * @interface OsaamismerkkiKategoriaLiiteDto
 */
export interface OsaamismerkkiKategoriaLiiteDto {
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiKategoriaLiiteDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiKategoriaLiiteDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiKategoriaLiiteDto
     */
    mime?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamismerkkiKategoriaLiiteDto
     */
    binarydata?: string;
}
/**
 * 
 * @export
 * @interface OsaamismerkkiOsaamistavoiteDto
 */
export interface OsaamismerkkiOsaamistavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamismerkkiOsaamistavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamismerkkiOsaamistavoiteDto
     */
    osaamistavoite?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface OsaamistasoDto
 */
export interface OsaamistasoDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamistasoDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamistasoDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaamistasoDto
     */
    koodi?: KoodiDto;
}
/**
 * 
 * @export
 * @interface OsaamistasonKriteeriDto
 */
export interface OsaamistasonKriteeriDto {
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamistasonKriteeriDto
     */
    kriteerit?: Array<LokalisoituTekstiDto>;
    /**
     * 
     * @type {object}
     * @memberof OsaamistasonKriteeriDto
     */
    _osaamistaso?: object;
    /**
     * 
     * @type {OsaamistasoDto}
     * @memberof OsaamistasonKriteeriDto
     */
    osaamistaso?: OsaamistasoDto;
}
/**
 * 
 * @export
 * @interface OsaamistasonKriteerit2020Dto
 */
export interface OsaamistasonKriteerit2020Dto {
    /**
     * 
     * @type {OsaamistasoDto}
     * @memberof OsaamistasonKriteerit2020Dto
     */
    osaamistaso?: OsaamistasoDto;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof OsaamistasonKriteerit2020Dto
     */
    kriteerit?: Array<LokalisoituTekstiDto>;
}
/**
 * 
 * @export
 * @interface Osaamistavoite2020Dto
 */
export interface Osaamistavoite2020Dto {
    /**
     * 
     * @type {number}
     * @memberof Osaamistavoite2020Dto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Osaamistavoite2020Dto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof Osaamistavoite2020Dto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Osaamistavoite2020Dto
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof Osaamistavoite2020Dto
     */
    kieli?: Osaamistavoite2020DtoKieliEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof Osaamistavoite2020Dto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof Osaamistavoite2020Dto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof Osaamistavoite2020Dto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {Ammattitaitovaatimukset2019Dto}
     * @memberof Osaamistavoite2020Dto
     */
    tavoitteet?: Ammattitaitovaatimukset2019Dto;
}

/**
    * @export
    * @enum {string}
    */
export enum Osaamistavoite2020DtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface OsaamistavoiteDto
 */
export interface OsaamistavoiteDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamistavoiteDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamistavoiteDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof OsaamistavoiteDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OsaamistavoiteDto
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OsaamistavoiteDto
     */
    kieli?: OsaamistavoiteDtoKieliEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaamistavoiteDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamistavoiteDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamistavoiteDto
     */
    koodiArvo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamistavoiteDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface OsaamistavoiteLaajaDto
 */
export interface OsaamistavoiteLaajaDto {
    /**
     * 
     * @type {number}
     * @memberof OsaamistavoiteLaajaDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamistavoiteLaajaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof OsaamistavoiteLaajaDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OsaamistavoiteLaajaDto
     */
    laajuus?: number;
    /**
     * 
     * @type {string}
     * @memberof OsaamistavoiteLaajaDto
     */
    kieli?: OsaamistavoiteLaajaDtoKieliEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof OsaamistavoiteLaajaDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof OsaamistavoiteLaajaDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof OsaamistavoiteLaajaDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamistavoiteLaajaDto
     */
    tavoitteet?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof OsaamistavoiteLaajaDto
     */
    tunnustaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {ArviointiDto}
     * @memberof OsaamistavoiteLaajaDto
     */
    arviointi?: ArviointiDto;
    /**
     * 
     * @type {Array<AmmattitaitovaatimusKohdealueetDto>}
     * @memberof OsaamistavoiteLaajaDto
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimusKohdealueetDto>;
    /**
     * 
     * @type {object}
     * @memberof OsaamistavoiteLaajaDto
     */
    esitieto?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum OsaamistavoiteLaajaDtoKieliEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    size?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Page
     */
    content?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Page
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof Page
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageJulkiEtusivuDto
 */
export interface PageJulkiEtusivuDto {
    /**
     * 
     * @type {number}
     * @memberof PageJulkiEtusivuDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageJulkiEtusivuDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageJulkiEtusivuDto
     */
    size?: number;
    /**
     * 
     * @type {Array<JulkiEtusivuDto>}
     * @memberof PageJulkiEtusivuDto
     */
    content?: Array<JulkiEtusivuDto>;
    /**
     * 
     * @type {number}
     * @memberof PageJulkiEtusivuDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageJulkiEtusivuDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageJulkiEtusivuDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageJulkiEtusivuDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageJulkiEtusivuDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageJulkiEtusivuDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageJulkiEtusivuDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageKoodistoKoodiDto
 */
export interface PageKoodistoKoodiDto {
    /**
     * 
     * @type {number}
     * @memberof PageKoodistoKoodiDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageKoodistoKoodiDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageKoodistoKoodiDto
     */
    size?: number;
    /**
     * 
     * @type {Array<KoodistoKoodiDto>}
     * @memberof PageKoodistoKoodiDto
     */
    content?: Array<KoodistoKoodiDto>;
    /**
     * 
     * @type {number}
     * @memberof PageKoodistoKoodiDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageKoodistoKoodiDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageKoodistoKoodiDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageKoodistoKoodiDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageKoodistoKoodiDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageKoodistoKoodiDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageKoodistoKoodiDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageMaaraysDto
 */
export interface PageMaaraysDto {
    /**
     * 
     * @type {number}
     * @memberof PageMaaraysDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMaaraysDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMaaraysDto
     */
    size?: number;
    /**
     * 
     * @type {Array<MaaraysDto>}
     * @memberof PageMaaraysDto
     */
    content?: Array<MaaraysDto>;
    /**
     * 
     * @type {number}
     * @memberof PageMaaraysDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageMaaraysDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageMaaraysDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageMaaraysDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageMaaraysDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageMaaraysDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageMaaraysDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOsaamismerkkiDto
 */
export interface PageOsaamismerkkiDto {
    /**
     * 
     * @type {number}
     * @memberof PageOsaamismerkkiDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOsaamismerkkiDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOsaamismerkkiDto
     */
    size?: number;
    /**
     * 
     * @type {Array<OsaamismerkkiDto>}
     * @memberof PageOsaamismerkkiDto
     */
    content?: Array<OsaamismerkkiDto>;
    /**
     * 
     * @type {number}
     * @memberof PageOsaamismerkkiDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOsaamismerkkiDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageOsaamismerkkiDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOsaamismerkkiDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOsaamismerkkiDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOsaamismerkkiDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageOsaamismerkkiDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePerusteBaseDto
 */
export interface PagePerusteBaseDto {
    /**
     * 
     * @type {number}
     * @memberof PagePerusteBaseDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteBaseDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteBaseDto
     */
    size?: number;
    /**
     * 
     * @type {Array<PerusteBaseDto>}
     * @memberof PagePerusteBaseDto
     */
    content?: Array<PerusteBaseDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteBaseDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePerusteBaseDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteBaseDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteBaseDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteBaseDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePerusteBaseDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteBaseDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePerusteHakuDto
 */
export interface PagePerusteHakuDto {
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuDto
     */
    size?: number;
    /**
     * 
     * @type {Array<PerusteHakuDto>}
     * @memberof PagePerusteHakuDto
     */
    content?: Array<PerusteHakuDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePerusteHakuDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteHakuDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteHakuDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePerusteHakuDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteHakuDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePerusteHakuInternalDto
 */
export interface PagePerusteHakuInternalDto {
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuInternalDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuInternalDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuInternalDto
     */
    size?: number;
    /**
     * 
     * @type {Array<PerusteHakuInternalDto>}
     * @memberof PagePerusteHakuInternalDto
     */
    content?: Array<PerusteHakuInternalDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuInternalDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePerusteHakuInternalDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteHakuInternalDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteHakuInternalDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteHakuInternalDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePerusteHakuInternalDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteHakuInternalDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePerusteInfoDto
 */
export interface PagePerusteInfoDto {
    /**
     * 
     * @type {number}
     * @memberof PagePerusteInfoDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteInfoDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteInfoDto
     */
    size?: number;
    /**
     * 
     * @type {Array<PerusteInfoDto>}
     * @memberof PagePerusteInfoDto
     */
    content?: Array<PerusteInfoDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteInfoDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePerusteInfoDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteInfoDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteInfoDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteInfoDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePerusteInfoDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteInfoDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePerusteenJulkaisuData
 */
export interface PagePerusteenJulkaisuData {
    /**
     * 
     * @type {number}
     * @memberof PagePerusteenJulkaisuData
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteenJulkaisuData
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteenJulkaisuData
     */
    size?: number;
    /**
     * 
     * @type {Array<PerusteenJulkaisuData>}
     * @memberof PagePerusteenJulkaisuData
     */
    content?: Array<PerusteenJulkaisuData>;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteenJulkaisuData
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePerusteenJulkaisuData
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteenJulkaisuData
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteenJulkaisuData
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteenJulkaisuData
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePerusteenJulkaisuData
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteenJulkaisuData
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePerusteprojektiKevytDto
 */
export interface PagePerusteprojektiKevytDto {
    /**
     * 
     * @type {number}
     * @memberof PagePerusteprojektiKevytDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteprojektiKevytDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteprojektiKevytDto
     */
    size?: number;
    /**
     * 
     * @type {Array<PerusteprojektiKevytDto>}
     * @memberof PagePerusteprojektiKevytDto
     */
    content?: Array<PerusteprojektiKevytDto>;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteprojektiKevytDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePerusteprojektiKevytDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteprojektiKevytDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePerusteprojektiKevytDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteprojektiKevytDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePerusteprojektiKevytDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePerusteprojektiKevytDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTiedoteDto
 */
export interface PageTiedoteDto {
    /**
     * 
     * @type {number}
     * @memberof PageTiedoteDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTiedoteDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTiedoteDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TiedoteDto>}
     * @memberof PageTiedoteDto
     */
    content?: Array<TiedoteDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTiedoteDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageTiedoteDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageTiedoteDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTiedoteDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTiedoteDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageTiedoteDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageTiedoteDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTutkinnonOsaDto
 */
export interface PageTutkinnonOsaDto {
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TutkinnonOsaDto>}
     * @memberof PageTutkinnonOsaDto
     */
    content?: Array<TutkinnonOsaDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageTutkinnonOsaDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageTutkinnonOsaDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTutkinnonOsaDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageTutkinnonOsaDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageTutkinnonOsaDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageTutkinnonOsaViiteKontekstiDto
 */
export interface PageTutkinnonOsaViiteKontekstiDto {
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    size?: number;
    /**
     * 
     * @type {Array<TutkinnonOsaViiteKontekstiDto>}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    content?: Array<TutkinnonOsaViiteKontekstiDto>;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    first?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    last?: boolean;
    /**
     * 
     * @type {Pageable}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageTutkinnonOsaViiteKontekstiDto
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
}
/**
 * 
 * @export
 * @interface PalauteDto
 */
export interface PalauteDto {
    /**
     * 
     * @type {number}
     * @memberof PalauteDto
     */
    stars?: number;
    /**
     * 
     * @type {string}
     * @memberof PalauteDto
     */
    feedback?: string;
    /**
     * 
     * @type {string}
     * @memberof PalauteDto
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof PalauteDto
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof PalauteDto
     */
    status?: PalauteDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PalauteDto
     */
    user_agent?: string;
    /**
     * 
     * @type {Date}
     * @memberof PalauteDto
     */
    created_at?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum PalauteDtoStatusEnum {
    SAAPUNUT = 'SAAPUNUT',
    JATKOKEHITYS = 'JATKOKEHITYS',
    HYLATTY = 'HYLATTY',
    POISTETTU = 'POISTETTU'
}

/**
 * 
 * @export
 * @interface ParsitutAmmattitaitovaatimukset
 */
export interface ParsitutAmmattitaitovaatimukset {
    /**
     * 
     * @type {number}
     * @memberof ParsitutAmmattitaitovaatimukset
     */
    projektiId?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsitutAmmattitaitovaatimukset
     */
    perusteId?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsitutAmmattitaitovaatimukset
     */
    tutkinnonOsa?: number;
    /**
     * 
     * @type {number}
     * @memberof ParsitutAmmattitaitovaatimukset
     */
    tutkinnonOsaViite?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ParsitutAmmattitaitovaatimukset
     */
    kohde?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ParsitutAmmattitaitovaatimukset
     */
    vaatimukset?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface PdfData
 */
export interface PdfData {
    /**
     * 
     * @type {string}
     * @memberof PdfData
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfData
     */
    tila?: string;
}
/**
 * 
 * @export
 * @interface PerusopetuksenPerusteenSisaltoDto
 */
export interface PerusopetuksenPerusteenSisaltoDto {
    /**
     * 
     * @type {Laaja}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    sisalto?: Laaja;
    /**
     * 
     * @type {Array<LaajaalainenOsaaminenDto>}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    laajaalaisetosaamiset?: Array<LaajaalainenOsaaminenDto>;
    /**
     * 
     * @type {Array<OppiaineLaajaDto>}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    oppiaineet?: Array<OppiaineLaajaDto>;
    /**
     * 
     * @type {Array<VuosiluokkaKokonaisuusDto>}
     * @memberof PerusopetuksenPerusteenSisaltoDto
     */
    vuosiluokkakokonaisuudet?: Array<VuosiluokkaKokonaisuusDto>;
}
/**
 * 
 * @export
 * @interface PerusteAikatauluDto
 */
export interface PerusteAikatauluDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteAikatauluDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteKevytDto}
     * @memberof PerusteAikatauluDto
     */
    peruste?: PerusteKevytDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteAikatauluDto
     */
    tavoite?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteAikatauluDto
     */
    tapahtuma?: PerusteAikatauluDtoTapahtumaEnum;
    /**
     * 
     * @type {Date}
     * @memberof PerusteAikatauluDto
     */
    tapahtumapaiva?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteAikatauluDto
     */
    julkinen?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteAikatauluDtoTapahtumaEnum {
    LUOMINEN = 'LUOMINEN',
    LAUSUNTOKIERROS = 'LAUSUNTOKIERROS',
    JOHTOKUNNANKASITTELY = 'JOHTOKUNNANKASITTELY',
    ARVIOITUJULKAISUPAIVA = 'ARVIOITUJULKAISUPAIVA',
    TAVOITE = 'TAVOITE',
    JULKAISU = 'JULKAISU'
}

/**
 * 
 * @export
 * @interface PerusteBaseDto
 */
export interface PerusteBaseDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteBaseDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteBaseDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteBaseDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    koulutustyyppi?: string;
    /**
     * Perusteen sisäisen rakenteen toteutuksen ilmaiseva sisältö. Esimerkiksi vanhan ja uuden lukion toteutus (LOPS vs LOPS2019)
     * @type {string}
     * @memberof PerusteBaseDto
     */
    toteutus?: PerusteBaseDtoToteutusEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteBaseDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteBaseDto
     */
    kielet?: Array<PerusteBaseDtoKieletEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteBaseDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {MaarayskirjeDto}
     * @memberof PerusteBaseDto
     */
    maarayskirje?: MaarayskirjeDto;
    /**
     * 
     * @type {Array<MuutosmaaraysDto>}
     * @memberof PerusteBaseDto
     */
    muutosmaaraykset?: Array<MuutosmaaraysDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * Voimassaolon loppumisen jälkeinen perusteen päättymispäivämäärä.
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * Perusteen määräyksen päätöspäivämäärä
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    paatospvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteBaseDto
     */
    luotu?: Date;
    /**
     * Perusteen sisäinen tila. Ei enää merkityksellinen julkaisujen käytönoton jälkeen
     * @type {string}
     * @memberof PerusteBaseDto
     */
    tila?: PerusteBaseDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    tyyppi?: PerusteBaseDtoTyyppiEnum;
    /**
     * EU- ja ETA-maiden koulutusvientikokeiluun tarkoitettu peruste
     * @type {boolean}
     * @memberof PerusteBaseDto
     */
    koulutusvienti?: boolean;
    /**
     * Perusteen vanhemmat määräykset. Eivät välttämättä ole toteutettu ePerusteisiin
     * @type {Array<string>}
     * @memberof PerusteBaseDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * Perusteeseen liittyvät osaamisalakoodit
     * @type {Array<KoodiDto>}
     * @memberof PerusteBaseDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteBaseDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteBaseDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteBaseDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * Perusteet joihin opas liittyy
     * @type {Array<PerusteKevytDto>}
     * @memberof PerusteBaseDto
     */
    oppaanPerusteet?: Array<PerusteKevytDto>;
    /**
     * Koulutustyypit joihin opas liittyy
     * @type {Array<string>}
     * @memberof PerusteBaseDto
     */
    oppaanKoulutustyypit?: Array<PerusteBaseDtoOppaanKoulutustyypitEnum>;
    /**
     * Perusteprosessin päivämäärät
     * @type {Array<PerusteAikatauluDto>}
     * @memberof PerusteBaseDto
     */
    perusteenAikataulut?: Array<PerusteAikatauluDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteBaseDto
     */
    poikkeamismaaraysTarkennus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    poikkeamismaaraysTyyppi?: PerusteBaseDtoPoikkeamismaaraysTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteBaseDto
     */
    opasTyyppi?: PerusteBaseDtoOpasTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteBaseDto
     */
    tietoapalvelustaKuvaus?: LokalisoituTekstiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoOppaanKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoPoikkeamismaaraysTyyppiEnum {
    EITARVITAOHJETTA = 'EI_TARVITA_OHJETTA',
    EIVOIPOIKETA = 'EI_VOI_POIKETA',
    KOULUTUSVIENTILIITE = 'KOULUTUSVIENTILIITE'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteBaseDtoOpasTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TIETOAPALVELUSTA = 'TIETOAPALVELUSTA'
}

/**
 * 
 * @export
 * @interface PerusteDto
 */
export interface PerusteDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    koulutustyyppi?: string;
    /**
     * Perusteen sisäisen rakenteen toteutuksen ilmaiseva sisältö. Esimerkiksi vanhan ja uuden lukion toteutus (LOPS vs LOPS2019)
     * @type {string}
     * @memberof PerusteDto
     */
    toteutus?: PerusteDtoToteutusEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    kielet?: Array<PerusteDtoKieletEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {MaarayskirjeDto}
     * @memberof PerusteDto
     */
    maarayskirje?: MaarayskirjeDto;
    /**
     * 
     * @type {Array<MuutosmaaraysDto>}
     * @memberof PerusteDto
     */
    muutosmaaraykset?: Array<MuutosmaaraysDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * Voimassaolon loppumisen jälkeinen perusteen päättymispäivämäärä.
     * @type {Date}
     * @memberof PerusteDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * Perusteen määräyksen päätöspäivämäärä
     * @type {Date}
     * @memberof PerusteDto
     */
    paatospvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteDto
     */
    luotu?: Date;
    /**
     * Perusteen sisäinen tila. Ei enää merkityksellinen julkaisujen käytönoton jälkeen
     * @type {string}
     * @memberof PerusteDto
     */
    tila?: PerusteDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    tyyppi?: PerusteDtoTyyppiEnum;
    /**
     * EU- ja ETA-maiden koulutusvientikokeiluun tarkoitettu peruste
     * @type {boolean}
     * @memberof PerusteDto
     */
    koulutusvienti?: boolean;
    /**
     * Perusteen vanhemmat määräykset. Eivät välttämättä ole toteutettu ePerusteisiin
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * Perusteeseen liittyvät osaamisalakoodit
     * @type {Array<KoodiDto>}
     * @memberof PerusteDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * Perusteet joihin opas liittyy
     * @type {Array<PerusteKevytDto>}
     * @memberof PerusteDto
     */
    oppaanPerusteet?: Array<PerusteKevytDto>;
    /**
     * Koulutustyypit joihin opas liittyy
     * @type {Array<string>}
     * @memberof PerusteDto
     */
    oppaanKoulutustyypit?: Array<PerusteDtoOppaanKoulutustyypitEnum>;
    /**
     * Perusteprosessin päivämäärät
     * @type {Array<PerusteAikatauluDto>}
     * @memberof PerusteDto
     */
    perusteenAikataulut?: Array<PerusteAikatauluDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteDto
     */
    poikkeamismaaraysTarkennus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    poikkeamismaaraysTyyppi?: PerusteDtoPoikkeamismaaraysTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteDto
     */
    opasTyyppi?: PerusteDtoOpasTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteDto
     */
    tietoapalvelustaKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteDto
     */
    suoritustavat?: Array<SuoritustapaDto>;
    /**
     * 
     * @type {KVLiiteDto}
     * @memberof PerusteDto
     */
    kvliite?: KVLiiteDto;
    /**
     * 
     * @type {VapaasivistystyoSisaltoKevytDto}
     * @memberof PerusteDto
     */
    vstSisalto?: VapaasivistystyoSisaltoKevytDto;
    /**
     * 
     * @type {OpasSisaltoKevytDto}
     * @memberof PerusteDto
     */
    oppaanSisalto?: OpasSisaltoKevytDto;
    /**
     * 
     * @type {number}
     * @memberof PerusteDto
     */
    laajuus?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoOppaanKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoPoikkeamismaaraysTyyppiEnum {
    EITARVITAOHJETTA = 'EI_TARVITA_OHJETTA',
    EIVOIPOIKETA = 'EI_VOI_POIKETA',
    KOULUTUSVIENTILIITE = 'KOULUTUSVIENTILIITE'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteDtoOpasTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TIETOAPALVELUSTA = 'TIETOAPALVELUSTA'
}

/**
 * 
 * @export
 * @interface PerusteHakuDto
 */
export interface PerusteHakuDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteHakuDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteHakuDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuDto
     */
    koulutustyyppi?: string;
    /**
     * Perusteen sisäisen rakenteen toteutuksen ilmaiseva sisältö. Esimerkiksi vanhan ja uuden lukion toteutus (LOPS vs LOPS2019)
     * @type {string}
     * @memberof PerusteHakuDto
     */
    toteutus?: PerusteHakuDtoToteutusEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteHakuDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteHakuDto
     */
    kielet?: Array<PerusteHakuDtoKieletEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {MaarayskirjeDto}
     * @memberof PerusteHakuDto
     */
    maarayskirje?: MaarayskirjeDto;
    /**
     * 
     * @type {Array<MuutosmaaraysDto>}
     * @memberof PerusteHakuDto
     */
    muutosmaaraykset?: Array<MuutosmaaraysDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * Voimassaolon loppumisen jälkeinen perusteen päättymispäivämäärä.
     * @type {Date}
     * @memberof PerusteHakuDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * Perusteen määräyksen päätöspäivämäärä
     * @type {Date}
     * @memberof PerusteHakuDto
     */
    paatospvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuDto
     */
    luotu?: Date;
    /**
     * Perusteen sisäinen tila. Ei enää merkityksellinen julkaisujen käytönoton jälkeen
     * @type {string}
     * @memberof PerusteHakuDto
     */
    tila?: PerusteHakuDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuDto
     */
    tyyppi?: PerusteHakuDtoTyyppiEnum;
    /**
     * EU- ja ETA-maiden koulutusvientikokeiluun tarkoitettu peruste
     * @type {boolean}
     * @memberof PerusteHakuDto
     */
    koulutusvienti?: boolean;
    /**
     * Perusteen vanhemmat määräykset. Eivät välttämättä ole toteutettu ePerusteisiin
     * @type {Array<string>}
     * @memberof PerusteHakuDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * Perusteeseen liittyvät osaamisalakoodit
     * @type {Array<KoodiDto>}
     * @memberof PerusteHakuDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteHakuDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * Perusteet joihin opas liittyy
     * @type {Array<PerusteKevytDto>}
     * @memberof PerusteHakuDto
     */
    oppaanPerusteet?: Array<PerusteKevytDto>;
    /**
     * Koulutustyypit joihin opas liittyy
     * @type {Array<string>}
     * @memberof PerusteHakuDto
     */
    oppaanKoulutustyypit?: Array<PerusteHakuDtoOppaanKoulutustyypitEnum>;
    /**
     * Perusteprosessin päivämäärät
     * @type {Array<PerusteAikatauluDto>}
     * @memberof PerusteHakuDto
     */
    perusteenAikataulut?: Array<PerusteAikatauluDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuDto
     */
    poikkeamismaaraysTarkennus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuDto
     */
    poikkeamismaaraysTyyppi?: PerusteHakuDtoPoikkeamismaaraysTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuDto
     */
    opasTyyppi?: PerusteHakuDtoOpasTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuDto
     */
    tietoapalvelustaKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteHakuDto
     */
    suoritustavat?: Array<SuoritustapaDto>;
    /**
     * 
     * @type {KVLiiteDto}
     * @memberof PerusteHakuDto
     */
    kvliite?: KVLiiteDto;
    /**
     * 
     * @type {VapaasivistystyoSisaltoKevytDto}
     * @memberof PerusteHakuDto
     */
    vstSisalto?: VapaasivistystyoSisaltoKevytDto;
    /**
     * 
     * @type {OpasSisaltoKevytDto}
     * @memberof PerusteHakuDto
     */
    oppaanSisalto?: OpasSisaltoKevytDto;
    /**
     * 
     * @type {number}
     * @memberof PerusteHakuDto
     */
    laajuus?: number;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof PerusteHakuDto
     */
    tutkintonimikeKoodit?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto>}
     * @memberof PerusteHakuDto
     */
    tutkintonimikkeetKoodisto?: Array<CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto>;
    /**
     * 
     * @type {Array<PerusteInfoDto>}
     * @memberof PerusteHakuDto
     */
    korvaavatPerusteet?: Array<PerusteInfoDto>;
    /**
     * 
     * @type {Array<PerusteInfoDto>}
     * @memberof PerusteHakuDto
     */
    korvattavatPerusteet?: Array<PerusteInfoDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoOppaanKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoPoikkeamismaaraysTyyppiEnum {
    EITARVITAOHJETTA = 'EI_TARVITA_OHJETTA',
    EIVOIPOIKETA = 'EI_VOI_POIKETA',
    KOULUTUSVIENTILIITE = 'KOULUTUSVIENTILIITE'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuDtoOpasTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TIETOAPALVELUSTA = 'TIETOAPALVELUSTA'
}

/**
 * 
 * @export
 * @interface PerusteHakuInternalDto
 */
export interface PerusteHakuInternalDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteHakuInternalDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteHakuInternalDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuInternalDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    koulutustyyppi?: string;
    /**
     * Perusteen sisäisen rakenteen toteutuksen ilmaiseva sisältö. Esimerkiksi vanhan ja uuden lukion toteutus (LOPS vs LOPS2019)
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    toteutus?: PerusteHakuInternalDtoToteutusEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteHakuInternalDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteHakuInternalDto
     */
    kielet?: Array<PerusteHakuInternalDtoKieletEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuInternalDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {MaarayskirjeDto}
     * @memberof PerusteHakuInternalDto
     */
    maarayskirje?: MaarayskirjeDto;
    /**
     * 
     * @type {Array<MuutosmaaraysDto>}
     * @memberof PerusteHakuInternalDto
     */
    muutosmaaraykset?: Array<MuutosmaaraysDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuInternalDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * Voimassaolon loppumisen jälkeinen perusteen päättymispäivämäärä.
     * @type {Date}
     * @memberof PerusteHakuInternalDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuInternalDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * Perusteen määräyksen päätöspäivämäärä
     * @type {Date}
     * @memberof PerusteHakuInternalDto
     */
    paatospvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuInternalDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteHakuInternalDto
     */
    luotu?: Date;
    /**
     * Perusteen sisäinen tila. Ei enää merkityksellinen julkaisujen käytönoton jälkeen
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    tila?: PerusteHakuInternalDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    tyyppi?: PerusteHakuInternalDtoTyyppiEnum;
    /**
     * EU- ja ETA-maiden koulutusvientikokeiluun tarkoitettu peruste
     * @type {boolean}
     * @memberof PerusteHakuInternalDto
     */
    koulutusvienti?: boolean;
    /**
     * Perusteen vanhemmat määräykset. Eivät välttämättä ole toteutettu ePerusteisiin
     * @type {Array<string>}
     * @memberof PerusteHakuInternalDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * Perusteeseen liittyvät osaamisalakoodit
     * @type {Array<KoodiDto>}
     * @memberof PerusteHakuInternalDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuInternalDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuInternalDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteHakuInternalDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * Perusteet joihin opas liittyy
     * @type {Array<PerusteKevytDto>}
     * @memberof PerusteHakuInternalDto
     */
    oppaanPerusteet?: Array<PerusteKevytDto>;
    /**
     * Koulutustyypit joihin opas liittyy
     * @type {Array<string>}
     * @memberof PerusteHakuInternalDto
     */
    oppaanKoulutustyypit?: Array<PerusteHakuInternalDtoOppaanKoulutustyypitEnum>;
    /**
     * Perusteprosessin päivämäärät
     * @type {Array<PerusteAikatauluDto>}
     * @memberof PerusteHakuInternalDto
     */
    perusteenAikataulut?: Array<PerusteAikatauluDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuInternalDto
     */
    poikkeamismaaraysTarkennus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    poikkeamismaaraysTyyppi?: PerusteHakuInternalDtoPoikkeamismaaraysTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteHakuInternalDto
     */
    opasTyyppi?: PerusteHakuInternalDtoOpasTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteHakuInternalDto
     */
    tietoapalvelustaKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteHakuInternalDto
     */
    suoritustavat?: Array<SuoritustapaDto>;
    /**
     * 
     * @type {KVLiiteDto}
     * @memberof PerusteHakuInternalDto
     */
    kvliite?: KVLiiteDto;
    /**
     * 
     * @type {VapaasivistystyoSisaltoKevytDto}
     * @memberof PerusteHakuInternalDto
     */
    vstSisalto?: VapaasivistystyoSisaltoKevytDto;
    /**
     * 
     * @type {OpasSisaltoKevytDto}
     * @memberof PerusteHakuInternalDto
     */
    oppaanSisalto?: OpasSisaltoKevytDto;
    /**
     * 
     * @type {number}
     * @memberof PerusteHakuInternalDto
     */
    laajuus?: number;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof PerusteHakuInternalDto
     */
    tutkintonimikeKoodit?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto>}
     * @memberof PerusteHakuInternalDto
     */
    tutkintonimikkeetKoodisto?: Array<CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto>;
    /**
     * 
     * @type {Array<PerusteInfoDto>}
     * @memberof PerusteHakuInternalDto
     */
    korvaavatPerusteet?: Array<PerusteInfoDto>;
    /**
     * 
     * @type {Array<PerusteInfoDto>}
     * @memberof PerusteHakuInternalDto
     */
    korvattavatPerusteet?: Array<PerusteInfoDto>;
    /**
     * 
     * @type {PerusteprojektiDto}
     * @memberof PerusteHakuInternalDto
     */
    perusteprojekti?: PerusteprojektiDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoOppaanKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoPoikkeamismaaraysTyyppiEnum {
    EITARVITAOHJETTA = 'EI_TARVITA_OHJETTA',
    EIVOIPOIKETA = 'EI_VOI_POIKETA',
    KOULUTUSVIENTILIITE = 'KOULUTUSVIENTILIITE'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteHakuInternalDtoOpasTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TIETOAPALVELUSTA = 'TIETOAPALVELUSTA'
}

/**
 * 
 * @export
 * @interface PerusteInfoDto
 */
export interface PerusteInfoDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteInfoDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteInfoDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteInfoDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteInfoDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteInfoDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteInfoDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteInfoDto
     */
    tila?: PerusteInfoDtoTilaEnum;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteInfoDto
     */
    suoritustavat?: Array<SuoritustapaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteInfoDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}

/**
 * 
 * @export
 * @interface PerusteKaikkiDto
 */
export interface PerusteKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteKaikkiDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKaikkiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    koulutustyyppi?: string;
    /**
     * Perusteen sisäisen rakenteen toteutuksen ilmaiseva sisältö. Esimerkiksi vanhan ja uuden lukion toteutus (LOPS vs LOPS2019)
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    toteutus?: PerusteKaikkiDtoToteutusEnum;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteKaikkiDto
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteKaikkiDto
     */
    kielet?: Array<PerusteKaikkiDtoKieletEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKaikkiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {MaarayskirjeDto}
     * @memberof PerusteKaikkiDto
     */
    maarayskirje?: MaarayskirjeDto;
    /**
     * 
     * @type {Array<MuutosmaaraysDto>}
     * @memberof PerusteKaikkiDto
     */
    muutosmaaraykset?: Array<MuutosmaaraysDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKaikkiDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * Voimassaolon loppumisen jälkeinen perusteen päättymispäivämäärä.
     * @type {Date}
     * @memberof PerusteKaikkiDto
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKaikkiDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * Perusteen määräyksen päätöspäivämäärä
     * @type {Date}
     * @memberof PerusteKaikkiDto
     */
    paatospvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKaikkiDto
     */
    viimeisinJulkaisuAika?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKaikkiDto
     */
    luotu?: Date;
    /**
     * Perusteen sisäinen tila. Ei enää merkityksellinen julkaisujen käytönoton jälkeen
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    tila?: PerusteKaikkiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    tyyppi?: PerusteKaikkiDtoTyyppiEnum;
    /**
     * EU- ja ETA-maiden koulutusvientikokeiluun tarkoitettu peruste
     * @type {boolean}
     * @memberof PerusteKaikkiDto
     */
    koulutusvienti?: boolean;
    /**
     * Perusteen vanhemmat määräykset. Eivät välttämättä ole toteutettu ePerusteisiin
     * @type {Array<string>}
     * @memberof PerusteKaikkiDto
     */
    korvattavatDiaarinumerot?: Array<string>;
    /**
     * Perusteeseen liittyvät osaamisalakoodit
     * @type {Array<KoodiDto>}
     * @memberof PerusteKaikkiDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKaikkiDto
     */
    tyotehtavatJoissaVoiToimia?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKaikkiDto
     */
    suorittaneenOsaaminen?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteKaikkiDto
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * Perusteet joihin opas liittyy
     * @type {Array<PerusteKevytDto>}
     * @memberof PerusteKaikkiDto
     */
    oppaanPerusteet?: Array<PerusteKevytDto>;
    /**
     * Koulutustyypit joihin opas liittyy
     * @type {Array<string>}
     * @memberof PerusteKaikkiDto
     */
    oppaanKoulutustyypit?: Array<PerusteKaikkiDtoOppaanKoulutustyypitEnum>;
    /**
     * Perusteprosessin päivämäärät
     * @type {Array<PerusteAikatauluDto>}
     * @memberof PerusteKaikkiDto
     */
    perusteenAikataulut?: Array<PerusteAikatauluDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKaikkiDto
     */
    poikkeamismaaraysTarkennus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    poikkeamismaaraysTyyppi?: PerusteKaikkiDtoPoikkeamismaaraysTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteKaikkiDto
     */
    opasTyyppi?: PerusteKaikkiDtoOpasTyyppiEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKaikkiDto
     */
    tietoapalvelustaKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<SuoritustapaLaajaDto>}
     * @memberof PerusteKaikkiDto
     */
    suoritustavat?: Array<SuoritustapaLaajaDto>;
    /**
     * 
     * @type {Array<TutkinnonOsaKaikkiDto>}
     * @memberof PerusteKaikkiDto
     */
    tutkinnonOsat?: Array<TutkinnonOsaKaikkiDto>;
    /**
     * 
     * @type {Array<KoulutuksenOsaDto>}
     * @memberof PerusteKaikkiDto
     */
    koulutuksenOsat?: Array<KoulutuksenOsaDto>;
    /**
     * 
     * @type {PerusopetuksenPerusteenSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    perusopetus?: PerusopetuksenPerusteenSisaltoDto;
    /**
     * 
     * @type {LukiokoulutuksenPerusteenSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    lukiokoulutus?: LukiokoulutuksenPerusteenSisaltoDto;
    /**
     * 
     * @type {Lops2019SisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    lops2019?: Lops2019SisaltoDto;
    /**
     * 
     * @type {EsiopetuksenPerusteenSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    esiopetus?: EsiopetuksenPerusteenSisaltoDto;
    /**
     * 
     * @type {AIPEOpetuksenSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    aipe?: AIPEOpetuksenSisaltoDto;
    /**
     * 
     * @type {TPOOpetuksenSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    tpo?: TPOOpetuksenSisaltoDto;
    /**
     * 
     * @type {VapaasivistystyoSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    vapaasivistystyo?: VapaasivistystyoSisaltoDto;
    /**
     * 
     * @type {TutkintoonvalmentavaSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    tutkintoonvalmentava?: TutkintoonvalmentavaSisaltoDto;
    /**
     * 
     * @type {OpasSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    opas?: OpasSisaltoDto;
    /**
     * 
     * @type {DigitaalisenOsaamisenSisaltoDto}
     * @memberof PerusteKaikkiDto
     */
    digitaalinenosaaminen?: DigitaalisenOsaamisenSisaltoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoOppaanKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoPoikkeamismaaraysTyyppiEnum {
    EITARVITAOHJETTA = 'EI_TARVITA_OHJETTA',
    EIVOIPOIKETA = 'EI_VOI_POIKETA',
    KOULUTUSVIENTILIITE = 'KOULUTUSVIENTILIITE'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKaikkiDtoOpasTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TIETOAPALVELUSTA = 'TIETOAPALVELUSTA'
}

/**
 * 
 * @export
 * @interface PerusteKevytDto
 */
export interface PerusteKevytDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteKevytDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKevytDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteKevytDto
     */
    tila?: PerusteKevytDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteKevytDto
     */
    tyyppi?: PerusteKevytDtoTyyppiEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteKevytDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteKevytDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKevytDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteKevytDto
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {object}
     * @memberof PerusteKevytDto
     */
    perusteprojekti?: object;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteKevytDto
     */
    suoritustavat?: Array<SuoritustapaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteKevytDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteKevytDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}

/**
 * 
 * @export
 * @interface PerusteKoosteDto
 */
export interface PerusteKoosteDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteKoosteDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteKoosteDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteKoosteDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteKoosteDto
     */
    kielet?: Array<PerusteKoosteDtoKieletEnum>;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof PerusteKoosteDto
     */
    tutkinnonOsat?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<KoosteenOsaamisalaDto>}
     * @memberof PerusteKoosteDto
     */
    osaamisalat?: Array<KoosteenOsaamisalaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteKoosteDtoKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface PerusteTekstikappaleillaDto
 */
export interface PerusteTekstikappaleillaDto {
    /**
     * 
     * @type {PerusteDto}
     * @memberof PerusteTekstikappaleillaDto
     */
    peruste?: PerusteDto;
    /**
     * 
     * @type {Array<TekstiKappaleDto>}
     * @memberof PerusteTekstikappaleillaDto
     */
    tekstikappeet?: Array<TekstiKappaleDto>;
}
/**
 * 
 * @export
 * @interface PerusteVersionDto
 */
export interface PerusteVersionDto {
    /**
     * 
     * @type {Date}
     * @memberof PerusteVersionDto
     */
    aikaleima?: Date;
}
/**
 * 
 * @export
 * @interface PerusteenJulkaisuData
 */
export interface PerusteenJulkaisuData {
    /**
     * 
     * @type {number}
     * @memberof PerusteenJulkaisuData
     */
    id?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PerusteenJulkaisuData
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {Date}
     * @memberof PerusteenJulkaisuData
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenJulkaisuData
     */
    voimassaoloLoppuu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenJulkaisuData
     */
    siirtymaPaattyy?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenJulkaisuData
     */
    paatospvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteenJulkaisuData
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof PerusteenJulkaisuData
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<TutkintonimikeKoodiDto>}
     * @memberof PerusteenJulkaisuData
     */
    tutkintonimikkeet?: Array<TutkintonimikeKoodiDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteenJulkaisuData
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {number}
     * @memberof PerusteenJulkaisuData
     */
    laajuus?: number;
    /**
     * 
     * @type {Array<KoulutusDto>}
     * @memberof PerusteenJulkaisuData
     */
    koulutukset?: Array<KoulutusDto>;
    /**
     * 
     * @type {Array<SuoritustapaDto>}
     * @memberof PerusteenJulkaisuData
     */
    suoritustavat?: Array<SuoritustapaDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteenJulkaisuData
     */
    koodit?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenJulkaisuData
     */
    julkaistu?: Date;
    /**
     * 
     * @type {number}
     * @memberof PerusteenJulkaisuData
     */
    luotu?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteenJulkaisuData
     */
    tyyppi?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteenJulkaisuData
     */
    kielet?: Array<PerusteenJulkaisuDataKieletEnum>;
    /**
     * 
     * @type {TutkinnonOsaKaikkiDto}
     * @memberof PerusteenJulkaisuData
     */
    tutkinnonosa?: TutkinnonOsaKaikkiDto;
    /**
     * 
     * @type {Array<PerusteenJulkaisuData>}
     * @memberof PerusteenJulkaisuData
     */
    perusteet?: Array<PerusteenJulkaisuData>;
    /**
     * 
     * @type {string}
     * @memberof PerusteenJulkaisuData
     */
    sisaltotyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenJulkaisuDataKieletEnum {
    FI = 'FI',
    SV = 'SV',
    SE = 'SE',
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface PerusteenMuokkaustietoDto
 */
export interface PerusteenMuokkaustietoDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteenMuokkaustietoDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteenMuokkaustietoDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteenMuokkaustietoDto
     */
    tapahtuma?: PerusteenMuokkaustietoDtoTapahtumaEnum;
    /**
     * 
     * @type {number}
     * @memberof PerusteenMuokkaustietoDto
     */
    perusteId?: number;
    /**
     * 
     * @type {number}
     * @memberof PerusteenMuokkaustietoDto
     */
    kohdeId?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteenMuokkaustietoDto
     */
    kohde?: PerusteenMuokkaustietoDtoKohdeEnum;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenMuokkaustietoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteenMuokkaustietoDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteenMuokkaustietoDto
     */
    lisatieto?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteenMuokkaustietoDto
     */
    poistettu?: boolean;
    /**
     * 
     * @type {Array<PerusteenMuokkaustietoLisaparametritDto>}
     * @memberof PerusteenMuokkaustietoDto
     */
    lisaparametrit?: Array<PerusteenMuokkaustietoLisaparametritDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenMuokkaustietoDtoTapahtumaEnum {
    LUONTI = 'LUONTI',
    PAIVITYS = 'PAIVITYS',
    PALAUTUS = 'PALAUTUS',
    JARJESTETTY = 'JARJESTETTY',
    KOPIOINTI = 'KOPIOINTI',
    JULKAISU = 'JULKAISU',
    POISTO = 'POISTO'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteenMuokkaustietoDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Kvliite = 'kvliite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Oppimaara = 'oppimaara',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Muodostuminen = 'muodostuminen',
    Tutkinnonosat = 'tutkinnonosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tutkinnonosaviite = 'tutkinnonosaviite',
    Osaalue = 'osaalue',
    Osaalueet = 'osaalueet',
    TutkinnonMuodostuminen = 'tutkinnon_muodostuminen',
    Peruste = 'peruste',
    Lukiorakenne = 'lukiorakenne',
    Aihekokonaisuudet = 'aihekokonaisuudet',
    Opetuksenyleisettavoitteet = 'opetuksenyleisettavoitteet',
    Tekstikappale = 'tekstikappale',
    Taiteenala = 'taiteenala',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Vuosiluokkakokonaisuudet = 'vuosiluokkakokonaisuudet',
    Perusopetuslaajaalaisetosaamiset = 'perusopetuslaajaalaisetosaamiset',
    Perusopetuslaajaalainenosaaminen = 'perusopetuslaajaalainenosaaminen',
    Aipevaihe = 'aipevaihe',
    Aipekurssi = 'aipekurssi',
    Aipeoppiaine = 'aipeoppiaine',
    Kurssit = 'kurssit',
    Taiteenosa = 'taiteenosa',
    Termi = 'termi',
    Opintokokonaisuus = 'opintokokonaisuus',
    Tavoitesisaltoalue = 'tavoitesisaltoalue',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    Linkkisivu = 'linkkisivu',
    Koulutuksenosat = 'koulutuksenosat',
    Koulutuksenosa = 'koulutuksenosa',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    Osaamiskokonaisuus = 'osaamiskokonaisuus',
    OsaamiskokonaisuusPaaAlue = 'osaamiskokonaisuus_paa_alue',
    AipeLaajaalaisetosaamiset = 'aipe_laajaalaisetosaamiset',
    AipeLaajaalainenosaaminen = 'aipe_laajaalainenosaaminen'
}

/**
 * 
 * @export
 * @interface PerusteenMuokkaustietoLisaparametritDto
 */
export interface PerusteenMuokkaustietoLisaparametritDto {
    /**
     * 
     * @type {string}
     * @memberof PerusteenMuokkaustietoLisaparametritDto
     */
    kohde?: PerusteenMuokkaustietoLisaparametritDtoKohdeEnum;
    /**
     * 
     * @type {number}
     * @memberof PerusteenMuokkaustietoLisaparametritDto
     */
    kohdeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenMuokkaustietoLisaparametritDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Kvliite = 'kvliite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Oppimaara = 'oppimaara',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Muodostuminen = 'muodostuminen',
    Tutkinnonosat = 'tutkinnonosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tutkinnonosaviite = 'tutkinnonosaviite',
    Osaalue = 'osaalue',
    Osaalueet = 'osaalueet',
    TutkinnonMuodostuminen = 'tutkinnon_muodostuminen',
    Peruste = 'peruste',
    Lukiorakenne = 'lukiorakenne',
    Aihekokonaisuudet = 'aihekokonaisuudet',
    Opetuksenyleisettavoitteet = 'opetuksenyleisettavoitteet',
    Tekstikappale = 'tekstikappale',
    Taiteenala = 'taiteenala',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Vuosiluokkakokonaisuudet = 'vuosiluokkakokonaisuudet',
    Perusopetuslaajaalaisetosaamiset = 'perusopetuslaajaalaisetosaamiset',
    Perusopetuslaajaalainenosaaminen = 'perusopetuslaajaalainenosaaminen',
    Aipevaihe = 'aipevaihe',
    Aipekurssi = 'aipekurssi',
    Aipeoppiaine = 'aipeoppiaine',
    Kurssit = 'kurssit',
    Taiteenosa = 'taiteenosa',
    Termi = 'termi',
    Opintokokonaisuus = 'opintokokonaisuus',
    Tavoitesisaltoalue = 'tavoitesisaltoalue',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    Linkkisivu = 'linkkisivu',
    Koulutuksenosat = 'koulutuksenosat',
    Koulutuksenosa = 'koulutuksenosa',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    Osaamiskokonaisuus = 'osaamiskokonaisuus',
    OsaamiskokonaisuusPaaAlue = 'osaamiskokonaisuus_paa_alue',
    AipeLaajaalaisetosaamiset = 'aipe_laajaalaisetosaamiset',
    AipeLaajaalainenosaaminen = 'aipe_laajaalainenosaaminen'
}

/**
 * 
 * @export
 * @interface PerusteenMuutostietoDto
 */
export interface PerusteenMuutostietoDto {
    /**
     * 
     * @type {string}
     * @memberof PerusteenMuutostietoDto
     */
    kohde?: PerusteenMuutostietoDtoKohdeEnum;
    /**
     * 
     * @type {Array<MuutostapahtumaDto>}
     * @memberof PerusteenMuutostietoDto
     */
    tapahtumat?: Array<MuutostapahtumaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenMuutostietoDtoKohdeEnum {
    Root = 'root',
    Tiedot = 'tiedot',
    Viite = 'viite',
    Liitteet = 'liitteet',
    Liite = 'liite',
    Kvliite = 'kvliite',
    Laajaalaiset = 'laajaalaiset',
    Laajaalainen = 'laajaalainen',
    Oppiaineet = 'oppiaineet',
    Oppimaarat = 'oppimaarat',
    Oppiaine = 'oppiaine',
    Oppimaara = 'oppimaara',
    Lukiooppiaineet2015 = 'lukiooppiaineet_2015',
    Lukiooppimaarat2015 = 'lukiooppimaarat_2015',
    Lukiooppiaine2015 = 'lukiooppiaine_2015',
    Lukiokurssit = 'lukiokurssit',
    Lukiokurssi = 'lukiokurssi',
    Moduulit = 'moduulit',
    Moduuli = 'moduuli',
    Muodostuminen = 'muodostuminen',
    Tutkinnonosat = 'tutkinnonosat',
    Tutkinnonosa = 'tutkinnonosa',
    Tutkinnonosaviite = 'tutkinnonosaviite',
    Osaalue = 'osaalue',
    Osaalueet = 'osaalueet',
    TutkinnonMuodostuminen = 'tutkinnon_muodostuminen',
    Peruste = 'peruste',
    Lukiorakenne = 'lukiorakenne',
    Aihekokonaisuudet = 'aihekokonaisuudet',
    Opetuksenyleisettavoitteet = 'opetuksenyleisettavoitteet',
    Tekstikappale = 'tekstikappale',
    Taiteenala = 'taiteenala',
    Perusopetusoppiaineet = 'perusopetusoppiaineet',
    Perusopetusoppiaine = 'perusopetusoppiaine',
    Vuosiluokkakokonaisuus = 'vuosiluokkakokonaisuus',
    Vuosiluokkakokonaisuudet = 'vuosiluokkakokonaisuudet',
    Perusopetuslaajaalaisetosaamiset = 'perusopetuslaajaalaisetosaamiset',
    Perusopetuslaajaalainenosaaminen = 'perusopetuslaajaalainenosaaminen',
    Aipevaihe = 'aipevaihe',
    Aipekurssi = 'aipekurssi',
    Aipeoppiaine = 'aipeoppiaine',
    Kurssit = 'kurssit',
    Taiteenosa = 'taiteenosa',
    Termi = 'termi',
    Opintokokonaisuus = 'opintokokonaisuus',
    Tavoitesisaltoalue = 'tavoitesisaltoalue',
    KotoKielitaitotaso = 'koto_kielitaitotaso',
    KotoOpinto = 'koto_opinto',
    KotoLaajaalainenosaaminen = 'koto_laajaalainenosaaminen',
    Linkkisivu = 'linkkisivu',
    Koulutuksenosat = 'koulutuksenosat',
    Koulutuksenosa = 'koulutuksenosa',
    Laajaalainenosaaminen = 'laajaalainenosaaminen',
    Osaamiskokonaisuus = 'osaamiskokonaisuus',
    OsaamiskokonaisuusPaaAlue = 'osaamiskokonaisuus_paa_alue',
    AipeLaajaalaisetosaamiset = 'aipe_laajaalaisetosaamiset',
    AipeLaajaalainenosaaminen = 'aipe_laajaalainenosaaminen'
}

/**
 * 
 * @export
 * @interface PerusteenOsaDto
 */
export interface PerusteenOsaDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteenOsaDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenOsaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenOsaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteenOsaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaDto
     */
    tila?: PerusteenOsaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaDto
     */
    tunniste?: PerusteenOsaDtoTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteenOsaDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteenOsaDto
     */
    kaannettava?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenOsaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteenOsaDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface PerusteenOsaTyoryhmaDto
 */
export interface PerusteenOsaTyoryhmaDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteenOsaTyoryhmaDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof PerusteenOsaTyoryhmaDto
     */
    perusteprojekti?: object;
    /**
     * 
     * @type {object}
     * @memberof PerusteenOsaTyoryhmaDto
     */
    perusteenosa?: object;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaTyoryhmaDto
     */
    nimi?: string;
}
/**
 * 
 * @export
 * @interface PerusteenOsaUpdateDto
 */
export interface PerusteenOsaUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteenOsaUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenOsaUpdateDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteenOsaUpdateDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaUpdateDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteenOsaUpdateDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaUpdateDto
     */
    tila?: PerusteenOsaUpdateDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaUpdateDto
     */
    tunniste?: PerusteenOsaUpdateDtoTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteenOsaUpdateDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteenOsaUpdateDto
     */
    kaannettava?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PerusteenOsaUpdateDto
     */
    osanTyyppi?: string;
    /**
     * 
     * @type {MetaData}
     * @memberof PerusteenOsaUpdateDto
     */
    metadata?: MetaData;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteenOsaUpdateDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteenOsaUpdateDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface PerusteenOsaViiteDto
 */
export interface PerusteenOsaViiteDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteenOsaViiteDto
     */
    id?: number;
    /**
     * 
     * @type {PerusteenOsaDto}
     * @memberof PerusteenOsaViiteDto
     */
    perusteenOsa?: PerusteenOsaDto;
    /**
     * 
     * @type {object}
     * @memberof PerusteenOsaViiteDto
     */
    _perusteenOsa?: object;
}
/**
 * 
 * @export
 * @interface PerusteenOsaViiteDtoObject
 */
export interface PerusteenOsaViiteDtoObject {
    /**
     * 
     * @type {number}
     * @memberof PerusteenOsaViiteDtoObject
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof PerusteenOsaViiteDtoObject
     */
    perusteenOsa?: object;
    /**
     * 
     * @type {object}
     * @memberof PerusteenOsaViiteDtoObject
     */
    _perusteenOsa?: object;
}
/**
 * 
 * @export
 * @interface PerusteprojektiDto
 */
export interface PerusteprojektiDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    nimi?: string;
    /**
     * 
     * @type {object}
     * @memberof PerusteprojektiDto
     */
    peruste?: object;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiDto
     */
    paatosPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiDto
     */
    toimikausiAlku?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiDto
     */
    toimikausiLoppu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    tehtavaluokka?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    tehtava?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    yhteistyotaho?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    projektiKuvaus?: PerusteprojektiDtoProjektiKuvausEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteprojektiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    tila?: PerusteprojektiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiDto
     */
    ryhmaOid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteprojektiDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {Array<TavoitepaivamaaraDto>}
     * @memberof PerusteprojektiDto
     */
    tavoitepaivamaarat?: Array<TavoitepaivamaaraDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiDtoProjektiKuvausEnum {
    UUDISTUS = 'PERUSTEEN_UUDISTUS',
    KORJAUS = 'PERUSTEEN_KORJAUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface PerusteprojektiInfoDto
 */
export interface PerusteprojektiInfoDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektiInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiInfoDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiInfoDto
     */
    tila?: PerusteprojektiInfoDtoTilaEnum;
    /**
     * 
     * @type {PerusteDto}
     * @memberof PerusteprojektiInfoDto
     */
    peruste?: PerusteDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiInfoDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiInfoDto
     */
    ryhmaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiInfoDto
     */
    koulutustyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiInfoDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface PerusteprojektiKevytDto
 */
export interface PerusteprojektiKevytDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektiKevytDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    tila?: PerusteprojektiKevytDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    perusteendiaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    toteutus?: PerusteprojektiKevytDtoToteutusEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiKevytDto
     */
    tyyppi?: PerusteprojektiKevytDtoTyyppiEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PerusteprojektiKevytDto
     */
    suoritustavat?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiKevytDto
     */
    luotu?: Date;
    /**
     * 
     * @type {PerusteVersionDto}
     * @memberof PerusteprojektiKevytDto
     */
    globalVersion?: PerusteVersionDto;
    /**
     * 
     * @type {PerusteBaseDto}
     * @memberof PerusteprojektiKevytDto
     */
    peruste?: PerusteBaseDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiKevytDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiKevytDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiKevytDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}

/**
 * 
 * @export
 * @interface PerusteprojektiListausDto
 */
export interface PerusteprojektiListausDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektiListausDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiListausDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiListausDto
     */
    tila?: PerusteprojektiListausDtoTilaEnum;
    /**
     * 
     * @type {PerusteKevytDto}
     * @memberof PerusteprojektiListausDto
     */
    peruste?: PerusteKevytDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiListausDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiListausDto
     */
    ryhmaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiListausDto
     */
    koulutustyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiListausDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}

/**
 * 
 * @export
 * @interface PerusteprojektiLuontiDto
 */
export interface PerusteprojektiLuontiDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektiLuontiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    nimi?: string;
    /**
     * 
     * @type {object}
     * @memberof PerusteprojektiLuontiDto
     */
    peruste?: object;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiLuontiDto
     */
    paatosPvm?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiLuontiDto
     */
    toimikausiAlku?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiLuontiDto
     */
    toimikausiLoppu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    tehtavaluokka?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    tehtava?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    yhteistyotaho?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    projektiKuvaus?: PerusteprojektiLuontiDtoProjektiKuvausEnum;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PerusteprojektiLuontiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    tila?: PerusteprojektiLuontiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    ryhmaOid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteprojektiLuontiDto
     */
    esikatseltavissa?: boolean;
    /**
     * 
     * @type {Array<TavoitepaivamaaraDto>}
     * @memberof PerusteprojektiLuontiDto
     */
    tavoitepaivamaarat?: Array<TavoitepaivamaaraDto>;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    laajuusYksikko?: PerusteprojektiLuontiDtoLaajuusYksikkoEnum;
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektiLuontiDto
     */
    perusteId?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    toteutus?: PerusteprojektiLuontiDtoToteutusEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektiLuontiDto
     */
    tyyppi?: PerusteprojektiLuontiDtoTyyppiEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PerusteprojektiLuontiDto
     */
    reforminMukainen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiLuontiDto
     */
    voimassaoloAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiLuontiDto
     */
    lausuntakierrosAlkaa?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektiLuontiDto
     */
    johtokunnanKasittely?: Date;
    /**
     * 
     * @type {Array<PerusteAikatauluDto>}
     * @memberof PerusteprojektiLuontiDto
     */
    perusteenAikataulut?: Array<PerusteAikatauluDto>;
    /**
     * 
     * @type {MaaraysDto}
     * @memberof PerusteprojektiLuontiDto
     */
    maarays?: MaaraysDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiLuontiDtoProjektiKuvausEnum {
    UUDISTUS = 'PERUSTEEN_UUDISTUS',
    KORJAUS = 'PERUSTEEN_KORJAUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiLuontiDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiLuontiDtoLaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    OPINTOPISTE = 'OPINTOPISTE'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiLuontiDtoToteutusEnum {
    YKSINKERTAINEN = 'YKSINKERTAINEN',
    PERUSOPETUS = 'PERUSOPETUS',
    LOPS = 'LOPS',
    AMMATILLINEN = 'AMMATILLINEN',
    TPO = 'TPO',
    LOPS2019 = 'LOPS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    KOTOUTUMISKOULUTUS = 'KOTOUTUMISKOULUTUS'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektiLuontiDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}

/**
 * 
 * @export
 * @interface PerusteprojektinPerusteenosaDto
 */
export interface PerusteprojektinPerusteenosaDto {
    /**
     * 
     * @type {number}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    nimi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    tila?: PerusteprojektinPerusteenosaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    perusteendiaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    diaarinumero?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    koulutustyyppi?: string;
    /**
     * 
     * @type {string}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    tyyppi?: PerusteprojektinPerusteenosaDtoTyyppiEnum;
    /**
     * 
     * @type {Date}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {PerusteInfoDto}
     * @memberof PerusteprojektinPerusteenosaDto
     */
    peruste?: PerusteInfoDto;
}

/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektinPerusteenosaDtoTilaEnum {
    POISTETTU = 'POISTETTU',
    LAADINTA = 'LAADINTA',
    KOMMENTOINTI = 'KOMMENTOINTI',
    VIIMEISTELY = 'VIIMEISTELY',
    VALMIS = 'VALMIS',
    JULKAISTU = 'JULKAISTU'
}
/**
    * @export
    * @enum {string}
    */
export enum PerusteprojektinPerusteenosaDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    OPAS = 'OPAS',
    AMOSAAYHTEINEN = 'AMOSAA_YHTEINEN',
    POHJA = 'POHJA',
    DIGITAALINENOSAAMINEN = 'DIGITAALINEN_OSAAMINEN'
}

/**
 * 
 * @export
 * @interface PoistettuSisaltoDto
 */
export interface PoistettuSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof PoistettuSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof PoistettuSisaltoDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof PoistettuSisaltoDto
     */
    luoja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuSisaltoDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PoistettuSisaltoDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {Date}
     * @memberof PoistettuSisaltoDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof PoistettuSisaltoDto
     */
    tyyppi?: PoistettuSisaltoDtoTyyppiEnum;
    /**
     * 
     * @type {number}
     * @memberof PoistettuSisaltoDto
     */
    poistettuId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PoistettuSisaltoDtoTyyppiEnum {
    TEKSTIKAPPALE = 'TEKSTIKAPPALE'
}

/**
 * 
 * @export
 * @interface Puu
 */
export interface Puu {
    /**
     * 
     * @type {number}
     * @memberof Puu
     */
    id?: number;
    /**
     * 
     * @type {PerusteenOsaDto}
     * @memberof Puu
     */
    perusteenOsa?: PerusteenOsaDto;
    /**
     * 
     * @type {Array<PuuPerusteenOsaDtoObject>}
     * @memberof Puu
     */
    lapset?: Array<PuuPerusteenOsaDtoObject>;
    /**
     * 
     * @type {object}
     * @memberof Puu
     */
    _perusteenOsa?: object;
}
/**
 * 
 * @export
 * @interface PuuPerusteenOsaDtoObject
 */
export interface PuuPerusteenOsaDtoObject {
    /**
     * 
     * @type {number}
     * @memberof PuuPerusteenOsaDtoObject
     */
    id?: number;
    /**
     * 
     * @type {PerusteenOsaDto}
     * @memberof PuuPerusteenOsaDtoObject
     */
    perusteenOsa?: PerusteenOsaDto;
    /**
     * 
     * @type {Array<object>}
     * @memberof PuuPerusteenOsaDtoObject
     */
    lapset?: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof PuuPerusteenOsaDtoObject
     */
    _perusteenOsa?: object;
}
/**
 * 
 * @export
 * @interface RakenneModuuliDto
 */
export interface RakenneModuuliDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof RakenneModuuliDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof RakenneModuuliDto
     */
    vieras?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof RakenneModuuliDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RakenneModuuliDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof RakenneModuuliDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof RakenneModuuliDto
     */
    rooli?: RakenneModuuliDtoRooliEnum;
    /**
     * 
     * @type {MuodostumisSaantoDto}
     * @memberof RakenneModuuliDto
     */
    muodostumisSaanto?: MuodostumisSaantoDto;
    /**
     * 
     * @type {OsaamisalaDto}
     * @memberof RakenneModuuliDto
     */
    osaamisala?: OsaamisalaDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof RakenneModuuliDto
     */
    tutkintonimike?: KoodiDto;
    /**
     * 
     * @type {Array<AbstractRakenneOsaDto>}
     * @memberof RakenneModuuliDto
     */
    osat?: Array<AbstractRakenneOsaDto>;
    /**
     * 
     * @type {number}
     * @memberof RakenneModuuliDto
     */
    versioId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RakenneModuuliDtoRooliEnum {
    NORMAALI = 'NORMAALI',
    VIRTUAALINEN = 'VIRTUAALINEN',
    OSAAMISALA = 'OSAAMISALA',
    TUTKINTONIMIKE = 'TUTKINTONIMIKE',
    VIERAS = 'VIERAS'
}

/**
 * 
 * @export
 * @interface Revision
 */
export interface Revision {
    /**
     * 
     * @type {number}
     * @memberof Revision
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof Revision
     */
    pvm?: Date;
    /**
     * 
     * @type {string}
     * @memberof Revision
     */
    muokkaajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof Revision
     */
    kommentti?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
}
/**
 * 
 * @export
 * @interface SortableDto
 */
export interface SortableDto {
    /**
     * 
     * @type {number}
     * @memberof SortableDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SortableDto
     */
    jarjestys?: number;
}
/**
 * 
 * @export
 * @interface SuoritustapaDto
 */
export interface SuoritustapaDto {
    /**
     * 
     * @type {string}
     * @memberof SuoritustapaDto
     */
    suoritustapakoodi?: SuoritustapaDtoSuoritustapakoodiEnum;
    /**
     * 
     * @type {string}
     * @memberof SuoritustapaDto
     */
    laajuusYksikko?: SuoritustapaDtoLaajuusYksikkoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SuoritustapaDtoSuoritustapakoodiEnum {
    OPS = 'OPS',
    NAYTTO = 'NAYTTO',
    REFORMI = 'REFORMI',
    PERUSOPETUS = 'PERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    OPAS = 'OPAS',
    ESIOPETUS = 'ESIOPETUS',
    AIPE = 'AIPE',
    TPO = 'TPO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOKOULUTUS2019 = 'LUKIOKOULUTUS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO'
}
/**
    * @export
    * @enum {string}
    */
export enum SuoritustapaDtoLaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    OPINTOPISTE = 'OPINTOPISTE'
}

/**
 * 
 * @export
 * @interface SuoritustapaLaajaDto
 */
export interface SuoritustapaLaajaDto {
    /**
     * 
     * @type {string}
     * @memberof SuoritustapaLaajaDto
     */
    suoritustapakoodi?: SuoritustapaLaajaDtoSuoritustapakoodiEnum;
    /**
     * 
     * @type {string}
     * @memberof SuoritustapaLaajaDto
     */
    laajuusYksikko?: SuoritustapaLaajaDtoLaajuusYksikkoEnum;
    /**
     * 
     * @type {RakenneModuuliDto}
     * @memberof SuoritustapaLaajaDto
     */
    rakenne?: RakenneModuuliDto;
    /**
     * 
     * @type {Laaja}
     * @memberof SuoritustapaLaajaDto
     */
    sisalto?: Laaja;
    /**
     * 
     * @type {Array<TutkinnonOsaViiteSuppeaDto>}
     * @memberof SuoritustapaLaajaDto
     */
    tutkinnonOsaViitteet?: Array<TutkinnonOsaViiteSuppeaDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum SuoritustapaLaajaDtoSuoritustapakoodiEnum {
    OPS = 'OPS',
    NAYTTO = 'NAYTTO',
    REFORMI = 'REFORMI',
    PERUSOPETUS = 'PERUSOPETUS',
    LISAOPETUS = 'LISAOPETUS',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    OPAS = 'OPAS',
    ESIOPETUS = 'ESIOPETUS',
    AIPE = 'AIPE',
    TPO = 'TPO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    LUKIOKOULUTUS2019 = 'LUKIOKOULUTUS2019',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO'
}
/**
    * @export
    * @enum {string}
    */
export enum SuoritustapaLaajaDtoLaajuusYksikkoEnum {
    OPINTOVIIKKO = 'OPINTOVIIKKO',
    OSAAMISPISTE = 'OSAAMISPISTE',
    KURSSI = 'KURSSI',
    OPINTOPISTE = 'OPINTOPISTE'
}

/**
 * 
 * @export
 * @interface Suppea
 */
export interface Suppea {
    /**
     * 
     * @type {number}
     * @memberof Suppea
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Suppea
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Suppea
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof Suppea
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof Suppea
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof Suppea
     */
    tila?: SuppeaTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof Suppea
     */
    tunniste?: SuppeaTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Suppea
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Suppea
     */
    kaannettava?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Suppea
     */
    osanTyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SuppeaTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum SuppeaTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface TPOOpetuksenSisaltoDto
 */
export interface TPOOpetuksenSisaltoDto {
    /**
     * 
     * @type {Laaja}
     * @memberof TPOOpetuksenSisaltoDto
     */
    sisalto?: Laaja;
}
/**
 * 
 * @export
 * @interface TaiteenalaDto
 */
export interface TaiteenalaDto extends Laaja {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TaiteenalaDto
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TaiteenalaDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDto
     */
    kasvatus?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDto
     */
    yhteisetOpinnot?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDto
     */
    teemaopinnot?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDto
     */
    aikuistenOpetus?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDto
     */
    tyotavatOpetuksessa?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDto
     */
    oppimisenArviointiOpetuksessa?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof TaiteenalaDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {{ [key: string]: KevytTekstiKappaleDto; }}
     * @memberof TaiteenalaDto
     */
    osaavainMap?: { [key: string]: KevytTekstiKappaleDto; };
}


/**
 * 
 * @export
 * @interface TaiteenalaDtoAllOf
 */
export interface TaiteenalaDtoAllOf {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TaiteenalaDtoAllOf
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TaiteenalaDtoAllOf
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDtoAllOf
     */
    kasvatus?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDtoAllOf
     */
    yhteisetOpinnot?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDtoAllOf
     */
    teemaopinnot?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDtoAllOf
     */
    aikuistenOpetus?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDtoAllOf
     */
    tyotavatOpetuksessa?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {KevytTekstiKappaleDto}
     * @memberof TaiteenalaDtoAllOf
     */
    oppimisenArviointiOpetuksessa?: KevytTekstiKappaleDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof TaiteenalaDtoAllOf
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {{ [key: string]: KevytTekstiKappaleDto; }}
     * @memberof TaiteenalaDtoAllOf
     */
    osaavainMap?: { [key: string]: KevytTekstiKappaleDto; };
}
/**
 * 
 * @export
 * @interface TavoiteAlueDto
 */
export interface TavoiteAlueDto {
    /**
     * 
     * @type {number}
     * @memberof TavoiteAlueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TavoiteAlueDto
     */
    tavoiteAlueTyyppi?: TavoiteAlueDtoTavoiteAlueTyyppiEnum;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TavoiteAlueDto
     */
    otsikko?: KoodiDto;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof TavoiteAlueDto
     */
    tavoitteet?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<LokalisoituTekstiDto>}
     * @memberof TavoiteAlueDto
     */
    keskeisetSisaltoalueet?: Array<LokalisoituTekstiDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum TavoiteAlueDtoTavoiteAlueTyyppiEnum {
    OTSIKKO = 'OTSIKKO',
    TAVOITESISALTOALUE = 'TAVOITESISALTOALUE'
}

/**
 * 
 * @export
 * @interface TavoitepaivamaaraDto
 */
export interface TavoitepaivamaaraDto {
    /**
     * 
     * @type {string}
     * @memberof TavoitepaivamaaraDto
     */
    tavoite?: string;
    /**
     * 
     * @type {Date}
     * @memberof TavoitepaivamaaraDto
     */
    tapahtumapaivamaara?: Date;
}
/**
 * 
 * @export
 * @interface TavoitesisaltoalueDto
 */
export interface TavoitesisaltoalueDto extends Laaja {
    /**
     * 
     * @type {KoodiDto}
     * @memberof TavoitesisaltoalueDto
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TavoitesisaltoalueDto
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TavoiteAlueDto>}
     * @memberof TavoitesisaltoalueDto
     */
    tavoitealueet?: Array<TavoiteAlueDto>;
}


/**
 * 
 * @export
 * @interface TavoitesisaltoalueDtoAllOf
 */
export interface TavoitesisaltoalueDtoAllOf {
    /**
     * 
     * @type {KoodiDto}
     * @memberof TavoitesisaltoalueDtoAllOf
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TavoitesisaltoalueDtoAllOf
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<TavoiteAlueDto>}
     * @memberof TavoitesisaltoalueDtoAllOf
     */
    tavoitealueet?: Array<TavoiteAlueDto>;
}
/**
 * 
 * @export
 * @interface TavoitteenArviointiDto
 */
export interface TavoitteenArviointiDto {
    /**
     * 
     * @type {number}
     * @memberof TavoitteenArviointiDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TavoitteenArviointiDto
     */
    arvioinninKohde?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TavoitteenArviointiDto
     */
    hyvanOsaamisenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TavoitteenArviointiDto
     */
    osaamisenKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof TavoitteenArviointiDto
     */
    arvosana?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof TavoitteenArviointiDto
     */
    opetuksenTavoitteet?: Array<object>;
}
/**
 * 
 * @export
 * @interface TekstiKappaleDto
 */
export interface TekstiKappaleDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiKappaleDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TekstiKappaleDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TekstiKappaleDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    tila?: TekstiKappaleDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    tunniste?: TekstiKappaleDtoTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    kaannettava?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TekstiKappaleDto
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TekstiKappaleDto
     */
    osaamisala?: KoodiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TekstiKappaleDto
     */
    tutkintonimike?: KoodiDto;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof TekstiKappaleDto
     */
    koodit?: Array<KoodiDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TekstiKappaleDto
     */
    liite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TekstiKappaleDto
     */
    osanTyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TekstiKappaleDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum TekstiKappaleDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface TekstiOsaDto
 */
export interface TekstiOsaDto {
    /**
     * 
     * @type {number}
     * @memberof TekstiOsaDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TekstiOsaDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TekstiOsaDto
     */
    teksti?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface TermiDto
 */
export interface TermiDto {
    /**
     * 
     * @type {number}
     * @memberof TermiDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TermiDto
     */
    avain?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TermiDto
     */
    termi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TermiDto
     */
    selitys?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof TermiDto
     */
    alaviite?: boolean;
}
/**
 * 
 * @export
 * @interface TiedoteDto
 */
export interface TiedoteDto {
    /**
     * 
     * @type {number}
     * @memberof TiedoteDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof TiedoteDto
     */
    perusteprojekti?: object;
    /**
     * 
     * @type {PerusteKevytDto}
     * @memberof TiedoteDto
     */
    peruste?: PerusteKevytDto;
    /**
     * 
     * @type {boolean}
     * @memberof TiedoteDto
     */
    julkinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TiedoteDto
     */
    yleinen?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TiedoteDto
     */
    otsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TiedoteDto
     */
    sisalto?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiedoteDto
     */
    julkaisupaikat?: Array<TiedoteDtoJulkaisupaikatEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TiedoteDto
     */
    koulutustyypit?: Array<TiedoteDtoKoulutustyypitEnum>;
    /**
     * 
     * @type {Array<PerusteKevytDto>}
     * @memberof TiedoteDto
     */
    perusteet?: Array<PerusteKevytDto>;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof TiedoteDto
     */
    tutkinnonosat?: Array<KoodiDto>;
    /**
     * 
     * @type {Array<KoodiDto>}
     * @memberof TiedoteDto
     */
    osaamisalat?: Array<KoodiDto>;
    /**
     * 
     * @type {Date}
     * @memberof TiedoteDto
     */
    luotu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    luoja?: string;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    nimi?: string;
    /**
     * 
     * @type {Date}
     * @memberof TiedoteDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TiedoteDto
     */
    muokkaaja?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TiedoteDtoJulkaisupaikatEnum {
    OPINTOPOLKU = 'OPINTOPOLKU',
    OPINTOPOLKUETUSIVU = 'OPINTOPOLKU_ETUSIVU',
    OPS = 'OPS',
    LOPS = 'LOPS',
    AMOSAA = 'AMOSAA',
    VST = 'VST',
    TUVA = 'TUVA',
    KOTO = 'KOTO'
}
/**
    * @export
    * @enum {string}
    */
export enum TiedoteDtoKoulutustyypitEnum {
    PERUSTUTKINTO = 'PERUSTUTKINTO',
    LUKIOKOULUTUS = 'LUKIOKOULUTUS',
    TELMA = 'TELMA',
    LISAOPETUS = 'LISAOPETUS',
    AMMATTITUTKINTO = 'AMMATTITUTKINTO',
    ERIKOISAMMATTITUTKINTO = 'ERIKOISAMMATTITUTKINTO',
    AIKUISTENLUKIOKOULUTUS = 'AIKUISTENLUKIOKOULUTUS',
    ESIOPETUS = 'ESIOPETUS',
    PERUSOPETUS = 'PERUSOPETUS',
    AIKUISTENPERUSOPETUS = 'AIKUISTENPERUSOPETUS',
    VALMA = 'VALMA',
    VARHAISKASVATUS = 'VARHAISKASVATUS',
    PERUSOPETUSVALMISTAVA = 'PERUSOPETUSVALMISTAVA',
    LUKIOVALMISTAVAKOULUTUS = 'LUKIOVALMISTAVAKOULUTUS',
    TPO = 'TPO',
    VAPAASIVISTYSTYO = 'VAPAASIVISTYSTYO',
    MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS = 'MAAHANMUUTTAJIENKOTOUTUMISKOULUTUS',
    VAPAASIVISTYSTYOLUKUTAITO = 'VAPAASIVISTYSTYOLUKUTAITO',
    TUTKINTOONVALMENTAVA = 'TUTKINTOONVALMENTAVA',
    MUUKOULUTUS = 'MUU_KOULUTUS'
}

/**
 * 
 * @export
 * @interface TietoaPalvelustaDto
 */
export interface TietoaPalvelustaDto {
    /**
     * 
     * @type {number}
     * @memberof TietoaPalvelustaDto
     */
    id?: number;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TietoaPalvelustaDto
     */
    tietoapalvelustaKuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface TilaUpdateStatus
 */
export interface TilaUpdateStatus {
    /**
     * 
     * @type {Array<Validointi>}
     * @memberof TilaUpdateStatus
     */
    validoinnit?: Array<Validointi>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof TilaUpdateStatus
     */
    huomautukset?: Array<Virhe>;
    /**
     * 
     * @type {boolean}
     * @memberof TilaUpdateStatus
     */
    vaihtoOk?: boolean;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof TilaUpdateStatus
     */
    virheet?: Array<Virhe>;
}
/**
 * 
 * @export
 * @interface TutkinnonOsaDto
 */
export interface TutkinnonOsaDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    tila?: TutkinnonOsaDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    tunniste?: TutkinnonOsaDtoTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaDto
     */
    kaannettava?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaDto
     */
    tavoitteet?: LokalisoituTekstiDto;
    /**
     * 
     * @type {ArviointiDto}
     * @memberof TutkinnonOsaDto
     */
    arviointi?: ArviointiDto;
    /**
     * 
     * @type {Array<AmmattitaitovaatimusKohdealueetDto>}
     * @memberof TutkinnonOsaDto
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimusKohdealueetDto>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaDto
     */
    ammattitaitovaatimukset?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaDto
     */
    ammattitaidonOsoittamistavat?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TutkinnonOsaDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {Array<OsaAlueDto>}
     * @memberof TutkinnonOsaDto
     */
    osaAlueet?: Array<OsaAlueDto>;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof TutkinnonOsaDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    tyyppi?: TutkinnonOsaDtoTyyppiEnum;
    /**
     * 
     * @type {ValmaTelmaSisaltoDto}
     * @memberof TutkinnonOsaDto
     */
    valmaTelmaSisalto?: ValmaTelmaSisaltoDto;
    /**
     * 
     * @type {Ammattitaitovaatimukset2019Dto}
     * @memberof TutkinnonOsaDto
     */
    ammattitaitovaatimukset2019?: Ammattitaitovaatimukset2019Dto;
    /**
     * 
     * @type {object}
     * @memberof TutkinnonOsaDto
     */
    geneerinenArviointiasteikko?: object;
    /**
     * 
     * @type {PerusteKevytDto}
     * @memberof TutkinnonOsaDto
     */
    alkuperainenPeruste?: PerusteKevytDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaDto
     */
    osanTyyppi?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}
/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TUTKE2 = 'TUTKE2',
    REFORMITUTKE2 = 'REFORMI_TUTKE2'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaKaikkiDto
 */
export interface TutkinnonOsaKaikkiDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaKaikkiDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaKaikkiDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaKaikkiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    muokkaaja?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    tila?: TutkinnonOsaKaikkiDtoTilaEnum;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    tunniste?: TutkinnonOsaKaikkiDtoTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaKaikkiDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaKaikkiDto
     */
    kaannettava?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    osanTyyppi?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaKaikkiDto
     */
    opintoluokitus?: number;
    /**
     * 
     * @type {KoodiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaKaikkiDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaKaikkiDto
     */
    laajuusMaksimi?: number;
    /**
     * Yhteisen tutkinnon osan osa-alueet
     * @type {Array<OsaAlueKaikkiDto>}
     * @memberof TutkinnonOsaKaikkiDto
     */
    osaAlueet?: Array<OsaAlueKaikkiDto>;
    /**
     * Ilmaisee onko kyseessä normaali vai yhteinen osa (uusi tai vanha)
     * @type {string}
     * @memberof TutkinnonOsaKaikkiDto
     */
    tyyppi?: TutkinnonOsaKaikkiDtoTyyppiEnum;
    /**
     * 
     * @type {ValmaTelmaSisaltoDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    valmaTelmaSisalto?: ValmaTelmaSisaltoDto;
    /**
     * 
     * @type {GeneerinenArviointiasteikkoKaikkiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    geneerinenArviointiasteikko?: GeneerinenArviointiasteikkoKaikkiDto;
    /**
     * Tutkinnon osan lisätarkennukset
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof TutkinnonOsaKaikkiDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {Ammattitaitovaatimukset2019Dto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    ammattitaitovaatimukset2019?: Ammattitaitovaatimukset2019Dto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    ammattitaidonOsoittamistavat?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    tavoitteet?: LokalisoituTekstiDto;
    /**
     * 
     * @type {ArviointiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    arviointi?: ArviointiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaKaikkiDto
     */
    ammattitaitovaatimukset?: LokalisoituTekstiDto;
    /**
     * Vanhentunut rakenteeton ammattitaitovaatimukset. Ei käytössä uusissa reformin mukaisissa tutkinnon osissa.
     * @type {Array<AmmattitaitovaatimusKohdealueetDto>}
     * @memberof TutkinnonOsaKaikkiDto
     */
    ammattitaitovaatimuksetLista?: Array<AmmattitaitovaatimusKohdealueetDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaKaikkiDtoTilaEnum {
    LUONNOS = 'LUONNOS',
    VALMIS = 'VALMIS',
    POISTETTU = 'POISTETTU'
}
/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaKaikkiDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}
/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaKaikkiDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TUTKE2 = 'TUTKE2',
    REFORMITUTKE2 = 'REFORMI_TUTKE2'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaTilaDto
 */
export interface TutkinnonOsaTilaDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaTilaDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaTilaDto
     */
    luotu?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaTilaDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaTilaDto
     */
    tunniste?: TutkinnonOsaTilaDtoTunnisteEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaTilaDto
     */
    valmis?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaTilaDto
     */
    kaannettava?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaTilaDtoTunnisteEnum {
    NORMAALI = 'NORMAALI',
    LAAJAALAINENOSAAMINEN = 'LAAJAALAINENOSAAMINEN',
    RAKENNE = 'RAKENNE'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaViiteDto
 */
export interface TutkinnonOsaViiteDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaViiteDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaViiteDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaViiteDto
     */
    tyyppi?: TutkinnonOsaViiteDtoTyyppiEnum;
    /**
     * 
     * @type {object}
     * @memberof TutkinnonOsaViiteDto
     */
    _tutkinnonOsa?: object;
    /**
     * 
     * @type {TutkinnonOsaDto}
     * @memberof TutkinnonOsaViiteDto
     */
    tutkinnonOsa?: TutkinnonOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaViiteDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TUTKE2 = 'TUTKE2',
    REFORMITUTKE2 = 'REFORMI_TUTKE2'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaViiteKontekstiDto
 */
export interface TutkinnonOsaViiteKontekstiDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    tyyppi?: TutkinnonOsaViiteKontekstiDtoTyyppiEnum;
    /**
     * 
     * @type {PerusteInfoDto}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    peruste?: PerusteInfoDto;
    /**
     * 
     * @type {SuoritustapaDto}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    suoritustapa?: SuoritustapaDto;
    /**
     * 
     * @type {PerusteprojektiKevytDto}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    perusteProjekti?: PerusteprojektiKevytDto;
    /**
     * 
     * @type {object}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    _tutkinnonOsa?: object;
    /**
     * 
     * @type {TutkinnonOsaDto}
     * @memberof TutkinnonOsaViiteKontekstiDto
     */
    tutkinnonOsa?: TutkinnonOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaViiteKontekstiDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TUTKE2 = 'TUTKE2',
    REFORMITUTKE2 = 'REFORMI_TUTKE2'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaViiteLuontiDto
 */
export interface TutkinnonOsaViiteLuontiDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    tyyppi?: TutkinnonOsaViiteLuontiDtoTyyppiEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    kopioiMuokattavaksi?: boolean;
    /**
     * 
     * @type {PerusteKevytDto}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    alkuperainenPeruste?: PerusteKevytDto;
    /**
     * 
     * @type {object}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    _tutkinnonOsa?: object;
    /**
     * 
     * @type {TutkinnonOsaDto}
     * @memberof TutkinnonOsaViiteLuontiDto
     */
    tutkinnonOsa?: TutkinnonOsaDto;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaViiteLuontiDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TUTKE2 = 'TUTKE2',
    REFORMITUTKE2 = 'REFORMI_TUTKE2'
}

/**
 * 
 * @export
 * @interface TutkinnonOsaViiteSuppeaDto
 */
export interface TutkinnonOsaViiteSuppeaDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteSuppeaDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteSuppeaDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteSuppeaDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteSuppeaDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {object}
     * @memberof TutkinnonOsaViiteSuppeaDto
     */
    tutkinnonOsa?: object;
}
/**
 * 
 * @export
 * @interface TutkinnonOsaViiteUpdateDto
 */
export interface TutkinnonOsaViiteUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    laajuus?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    laajuusMaksimi?: number;
    /**
     * 
     * @type {number}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    jarjestys?: number;
    /**
     * 
     * @type {Date}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    tyyppi?: TutkinnonOsaViiteUpdateDtoTyyppiEnum;
    /**
     * 
     * @type {object}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    _tutkinnonOsa?: object;
    /**
     * 
     * @type {TutkinnonOsaDto}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    tutkinnonOsa?: TutkinnonOsaDto;
    /**
     * 
     * @type {MetaData}
     * @memberof TutkinnonOsaViiteUpdateDto
     */
    metadata?: MetaData;
}

/**
    * @export
    * @enum {string}
    */
export enum TutkinnonOsaViiteUpdateDtoTyyppiEnum {
    NORMAALI = 'NORMAALI',
    TUTKE2 = 'TUTKE2',
    REFORMITUTKE2 = 'REFORMI_TUTKE2'
}

/**
 * 
 * @export
 * @interface TutkintonimikeKoodiDto
 */
export interface TutkintonimikeKoodiDto {
    /**
     * 
     * @type {number}
     * @memberof TutkintonimikeKoodiDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof TutkintonimikeKoodiDto
     */
    peruste?: object;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkinnonOsaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkinnonOsaArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    osaamisalaUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    osaamisalaArvo?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkintonimikeUri?: string;
    /**
     * 
     * @type {string}
     * @memberof TutkintonimikeKoodiDto
     */
    tutkintonimikeArvo?: string;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TutkintonimikeKoodiDto
     */
    nimi?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface TutkintoonvalmentavaSisaltoDto
 */
export interface TutkintoonvalmentavaSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof TutkintoonvalmentavaSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {Laaja}
     * @memberof TutkintoonvalmentavaSisaltoDto
     */
    sisalto?: Laaja;
}
/**
 * 
 * @export
 * @interface TuvaLaajaAlainenOsaaminenDto
 */
export interface TuvaLaajaAlainenOsaaminenDto extends Laaja {
    /**
     * 
     * @type {KoodiDto}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof TuvaLaajaAlainenOsaaminenDto
     */
    liite?: boolean;
}


/**
 * 
 * @export
 * @interface TuvaLaajaAlainenOsaaminenDtoAllOf
 */
export interface TuvaLaajaAlainenOsaaminenDtoAllOf {
    /**
     * 
     * @type {KoodiDto}
     * @memberof TuvaLaajaAlainenOsaaminenDtoAllOf
     */
    nimiKoodi?: KoodiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof TuvaLaajaAlainenOsaaminenDtoAllOf
     */
    teksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof TuvaLaajaAlainenOsaaminenDtoAllOf
     */
    liite?: boolean;
}
/**
 * 
 * @export
 * @interface TyoryhmaHenkiloDto
 */
export interface TyoryhmaHenkiloDto {
    /**
     * 
     * @type {number}
     * @memberof TyoryhmaHenkiloDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TyoryhmaHenkiloDto
     */
    kayttajaOid?: string;
    /**
     * 
     * @type {string}
     * @memberof TyoryhmaHenkiloDto
     */
    nimi?: string;
}
/**
 * 
 * @export
 * @interface UpdateDto
 */
export interface UpdateDto {
    /**
     * 
     * @type {MetaData}
     * @memberof UpdateDto
     */
    metadata?: MetaData;
}
/**
 * 
 * @export
 * @interface UpdateDtoOppiaineDto
 */
export interface UpdateDtoOppiaineDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDtoOppiaineDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDtoOppiaineDto
     */
    tunniste?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateDtoOppiaineDto
     */
    oppiaine?: object;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDtoOppiaineDto
     */
    koosteinen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDtoOppiaineDto
     */
    abstrakti?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoOppiaineDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateDtoOppiaineDto
     */
    jnro?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateDtoOppiaineDto
     */
    muokattu?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateDtoOppiaineDto
     */
    koodiUri?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDtoOppiaineDto
     */
    koodiArvo?: string;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineDto
     */
    tavoitteet?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineDto
     */
    arviointi?: TekstiOsaDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoOppiaineDto
     */
    pakollinenKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoOppiaineDto
     */
    syventavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoOppiaineDto
     */
    soveltavaKurssiKuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDtoOppiaineDto
     */
    partial?: boolean;
    /**
     * 
     * @type {Array<OppiaineSuppeaDto>}
     * @memberof UpdateDtoOppiaineDto
     */
    oppimaarat?: Array<OppiaineSuppeaDto>;
    /**
     * 
     * @type {Array<OpetuksenKohdealueDto>}
     * @memberof UpdateDtoOppiaineDto
     */
    kohdealueet?: Array<OpetuksenKohdealueDto>;
    /**
     * 
     * @type {Array<OppiaineenVuosiluokkaKokonaisuusDto>}
     * @memberof UpdateDtoOppiaineDto
     */
    vuosiluokkakokonaisuudet?: Array<OppiaineenVuosiluokkaKokonaisuusDto>;
    /**
     * 
     * @type {KoodiDto}
     * @memberof UpdateDtoOppiaineDto
     */
    koodi?: KoodiDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof UpdateDtoOppiaineDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {MetaData}
     * @memberof UpdateDtoOppiaineDto
     */
    metadata?: MetaData;
}
/**
 * 
 * @export
 * @interface UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
 */
export interface UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    vuosiluokkaKokonaisuus?: object;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    tyotavat?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    ohjaus?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    arviointi?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    sisaltoalueinfo?: TekstiOsaDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    opetuksenTavoitteetOtsikko?: LokalisoituTekstiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    vapaaTeksti?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OpetuksenTavoiteDto>}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    tavoitteet?: Array<OpetuksenTavoiteDto>;
    /**
     * 
     * @type {Array<KeskeinenSisaltoalueDto>}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    sisaltoalueet?: Array<KeskeinenSisaltoalueDto>;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {MetaData}
     * @memberof UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto
     */
    metadata?: MetaData;
}
/**
 * 
 * @export
 * @interface UpdateDtoRakenneModuuliDto
 */
export interface UpdateDtoRakenneModuuliDto {
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    kuvaus?: LokalisoituTekstiDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    vieras?: KoodiDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    tunniste?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    pakollinen?: boolean;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    rooli?: UpdateDtoRakenneModuuliDtoRooliEnum;
    /**
     * 
     * @type {MuodostumisSaantoDto}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    muodostumisSaanto?: MuodostumisSaantoDto;
    /**
     * 
     * @type {OsaamisalaDto}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    osaamisala?: OsaamisalaDto;
    /**
     * 
     * @type {KoodiDto}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    tutkintonimike?: KoodiDto;
    /**
     * 
     * @type {Array<AbstractRakenneOsaDto>}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    osat?: Array<AbstractRakenneOsaDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    versioId?: number;
    /**
     * 
     * @type {MetaData}
     * @memberof UpdateDtoRakenneModuuliDto
     */
    metadata?: MetaData;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateDtoRakenneModuuliDtoRooliEnum {
    NORMAALI = 'NORMAALI',
    VIRTUAALINEN = 'VIRTUAALINEN',
    OSAAMISALA = 'OSAAMISALA',
    TUTKINTONIMIKE = 'TUTKINTONIMIKE',
    VIERAS = 'VIERAS'
}

/**
 * 
 * @export
 * @interface UpdateDtoVuosiluokkaKokonaisuusDto
 */
export interface UpdateDtoVuosiluokkaKokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    vuosiluokat?: Array<UpdateDtoVuosiluokkaKokonaisuusDtoVuosiluokatEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    siirtymaEdellisesta?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    siirtymaSeuraavaan?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    laajaalainenOsaaminen?: TekstiOsaDto;
    /**
     * 
     * @type {Array<VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto>}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    laajaalaisetOsaamiset?: Array<VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto>;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    paikallisestiPaatettavatAsiat?: TekstiOsaDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
    /**
     * 
     * @type {MetaData}
     * @memberof UpdateDtoVuosiluokkaKokonaisuusDto
     */
    metadata?: MetaData;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateDtoVuosiluokkaKokonaisuusDtoVuosiluokatEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface Validointi
 */
export interface Validointi {
    /**
     * 
     * @type {string}
     * @memberof Validointi
     */
    kategoria?: ValidointiKategoriaEnum;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    virheet?: Array<Virhe>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    huomautukset?: Array<Virhe>;
    /**
     * 
     * @type {Array<Virhe>}
     * @memberof Validointi
     */
    huomiot?: Array<Virhe>;
}

/**
    * @export
    * @enum {string}
    */
export enum ValidointiKategoriaEnum {
    MAARITTELEMATON = 'MAARITTELEMATON',
    TEKSTI = 'TEKSTI',
    KIELISISALTO = 'KIELISISALTO',
    PERUSTE = 'PERUSTE',
    RAKENNE = 'RAKENNE',
    KOODISTO = 'KOODISTO'
}

/**
 * 
 * @export
 * @interface ValmaTelmaSisaltoDto
 */
export interface ValmaTelmaSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof ValmaTelmaSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {OsaamisenArviointiDto}
     * @memberof ValmaTelmaSisaltoDto
     */
    osaamisenarviointi?: OsaamisenArviointiDto;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof ValmaTelmaSisaltoDto
     */
    osaamisenarviointiTekstina?: LokalisoituTekstiDto;
    /**
     * 
     * @type {Array<OsaamisenTavoiteDto>}
     * @memberof ValmaTelmaSisaltoDto
     */
    osaamistavoite?: Array<OsaamisenTavoiteDto>;
}
/**
 * 
 * @export
 * @interface VapaasivistystyoSisaltoDto
 */
export interface VapaasivistystyoSisaltoDto {
    /**
     * 
     * @type {number}
     * @memberof VapaasivistystyoSisaltoDto
     */
    id?: number;
    /**
     * 
     * @type {Laaja}
     * @memberof VapaasivistystyoSisaltoDto
     */
    sisalto?: Laaja;
    /**
     * 
     * @type {number}
     * @memberof VapaasivistystyoSisaltoDto
     */
    laajuus?: number;
}
/**
 * 
 * @export
 * @interface VapaasivistystyoSisaltoKevytDto
 */
export interface VapaasivistystyoSisaltoKevytDto {
    /**
     * 
     * @type {number}
     * @memberof VapaasivistystyoSisaltoKevytDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof VapaasivistystyoSisaltoKevytDto
     */
    laajuus?: number;
}
/**
 * 
 * @export
 * @interface Virhe
 */
export interface Virhe {
    /**
     * 
     * @type {string}
     * @memberof Virhe
     */
    kuvaus?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Virhe
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {NavigationNodeDto}
     * @memberof Virhe
     */
    navigationNode?: NavigationNodeDto;
}
/**
 * 
 * @export
 * @interface VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto
 */
export interface VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto {
    /**
     * 
     * @type {number}
     * @memberof VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto
     */
    id?: number;
    /**
     * 
     * @type {object}
     * @memberof VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto
     */
    laajaalainenOsaaminen?: object;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto
     */
    kuvaus?: LokalisoituTekstiDto;
}
/**
 * 
 * @export
 * @interface VuosiluokkaKokonaisuusDto
 */
export interface VuosiluokkaKokonaisuusDto {
    /**
     * 
     * @type {number}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    tunniste?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    vuosiluokat?: Array<VuosiluokkaKokonaisuusDtoVuosiluokatEnum>;
    /**
     * 
     * @type {LokalisoituTekstiDto}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    nimi?: LokalisoituTekstiDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    siirtymaEdellisesta?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    tehtava?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    siirtymaSeuraavaan?: TekstiOsaDto;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    laajaalainenOsaaminen?: TekstiOsaDto;
    /**
     * 
     * @type {Array<VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto>}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    laajaalaisetOsaamiset?: Array<VuosiluokkaKokonaisuudenLaajaalainenOsaaminenDto>;
    /**
     * 
     * @type {TekstiOsaDto}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    paikallisestiPaatettavatAsiat?: TekstiOsaDto;
    /**
     * 
     * @type {Array<KevytTekstiKappaleDto>}
     * @memberof VuosiluokkaKokonaisuusDto
     */
    vapaatTekstit?: Array<KevytTekstiKappaleDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum VuosiluokkaKokonaisuusDtoVuosiluokatEnum {
    _1 = 'VUOSILUOKKA_1',
    _2 = 'VUOSILUOKKA_2',
    _3 = 'VUOSILUOKKA_3',
    _4 = 'VUOSILUOKKA_4',
    _5 = 'VUOSILUOKKA_5',
    _6 = 'VUOSILUOKKA_6',
    _7 = 'VUOSILUOKKA_7',
    _8 = 'VUOSILUOKKA_8',
    _9 = 'VUOSILUOKKA_9'
}

/**
 * 
 * @export
 * @interface YllapitoDto
 */
export interface YllapitoDto {
    /**
     * 
     * @type {number}
     * @memberof YllapitoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof YllapitoDto
     */
    kuvaus?: string;
    /**
     * 
     * @type {string}
     * @memberof YllapitoDto
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof YllapitoDto
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface YlopsOrganisaatioDto
 */
export interface YlopsOrganisaatioDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof YlopsOrganisaatioDto
     */
    nimi?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof YlopsOrganisaatioDto
     */
    tyypit?: Array<string>;
}

/**
 * AikataulutApi - axios parameter creator
 * @export
 */
export const AikataulutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<PerusteAikatauluDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenAikataulut(perusteId: number, body?: Array<PerusteAikatauluDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updatePerusteenAikataulut.');
            }
            const localVarPath = `/api/aikataulu/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AikataulutApi - functional programming interface
 * @export
 */
export const AikataulutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<PerusteAikatauluDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenAikataulut(perusteId: number, body?: Array<PerusteAikatauluDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AikataulutApiAxiosParamCreator(configuration).updatePerusteenAikataulut(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AikataulutApi - factory interface
 * @export
 */
export const AikataulutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<PerusteAikatauluDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenAikataulut(perusteId: number, body?: Array<PerusteAikatauluDto>, options?: any): AxiosPromise<void> {
            return AikataulutApiFp(configuration).updatePerusteenAikataulut(perusteId, body, options)(axios, basePath);
        },
    };
};

/**
 * AikataulutApi - object-oriented interface
 * @export
 * @class AikataulutApi
 * @extends {BaseAPI}
 */
export class AikataulutApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {Array<PerusteAikatauluDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AikataulutApi
     */
    public updatePerusteenAikataulut(perusteId: number, body?: Array<PerusteAikatauluDto>, options?: any) {
        return AikataulutApiFp(this.configuration).updatePerusteenAikataulut(perusteId, body, options)(this.axios, this.basePath);
    }

}


/**
 * AipeopetuksensisaltoApi - axios parameter creator
 * @export
 */
export const AipeopetuksensisaltoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEKurssiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEKurssiDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addAipeKurssi.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling addAipeKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling addAipeKurssi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOppiaine(perusteId: number, vaiheId: number, body?: AIPEOppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addAipeOppiaine.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling addAipeOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOppimaara(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addAipeOppimaara.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling addAipeOppimaara.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling addAipeOppimaara.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/oppimaarat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addAipeOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/laajaalaiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {AIPEVaiheDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVaihe(perusteId: number, body?: AIPEVaiheDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addVaihe.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajaalainenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAipeOsaaminen(perusteId: number, laajaalainenId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteAipeOsaaminen.');
            }
            // verify required parameter 'laajaalainenId' is not null or undefined
            if (laajaalainenId === null || laajaalainenId === undefined) {
                throw new RequiredError('laajaalainenId','Required parameter laajaalainenId was null or undefined when calling deleteAipeOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/laajaalaiset/{laajaalainenId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"laajaalainenId"}}`, encodeURIComponent(String(laajaalainenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKohdealueet(perusteId: number, vaiheId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeKohdealueet.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeKohdealueet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/kohdealueet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeKurssi.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAipeKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling getAipeKurssi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit/{kurssiId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rev !== undefined) {
                localVarQueryParameter['rev'] = rev;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKurssit(perusteId: number, vaiheId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeKurssit.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeKurssit.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAipeKurssit.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeOppiaine.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAipeOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rev !== undefined) {
                localVarQueryParameter['rev'] = rev;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaineVersiot(perusteId: number, vaiheId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeOppiaineVersiot.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeOppiaineVersiot.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAipeOppiaineVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/versiot`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaineet(perusteId: number, vaiheId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeOppiaineet.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeOppiaineet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppimaarat(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeOppimaarat.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getAipeOppimaarat.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getAipeOppimaarat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/oppimaarat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rev !== undefined) {
                localVarQueryParameter['rev'] = rev;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajalainenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOsaaminen(perusteId: number, laajalainenId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeOsaaminen.');
            }
            // verify required parameter 'laajalainenId' is not null or undefined
            if (laajalainenId === null || laajalainenId === undefined) {
                throw new RequiredError('laajalainenId','Required parameter laajalainenId was null or undefined when calling getAipeOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/laajaalaiset/{laajalainenId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"laajalainenId"}}`, encodeURIComponent(String(laajalainenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOsaamiset(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAipeOsaamiset.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/laajaalaiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKurssiVersiot(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKurssiVersiot.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getKurssiVersiot.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getKurssiVersiot.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling getKurssiVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit/{kurssiId}/versiot`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaihe(perusteId: number, vaiheId: number, rev?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getVaihe.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getVaihe.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rev !== undefined) {
                localVarQueryParameter['rev'] = rev;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaiheVersiot(perusteId: number, vaiheId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getVaiheVersiot.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling getVaiheVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/versiot`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaiheet(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getVaiheet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeAipeKurssi.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling removeAipeKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling removeAipeKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling removeAipeKurssi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit/{kurssiId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeAipeOppiaine.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling removeAipeOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling removeAipeOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeVaihe(perusteId: number, vaiheId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeVaihe.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling removeVaihe.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling revertAipeOppiaine.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling revertAipeOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling revertAipeOppiaine.');
            }
            // verify required parameter 'rev' is not null or undefined
            if (rev === null || rev === undefined) {
                throw new RequiredError('rev','Required parameter rev was null or undefined when calling revertAipeOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/palauta/{rev}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"rev"}}`, encodeURIComponent(String(rev)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling revertKurssi.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling revertKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling revertKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling revertKurssi.');
            }
            // verify required parameter 'rev' is not null or undefined
            if (rev === null || rev === undefined) {
                throw new RequiredError('rev','Required parameter rev was null or undefined when calling revertKurssi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit/{kurssiId}/palauta/{rev}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)))
                .replace(`{${"rev"}}`, encodeURIComponent(String(rev)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertVaihe(perusteId: number, vaiheId: number, rev: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling revertVaihe.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling revertVaihe.');
            }
            // verify required parameter 'rev' is not null or undefined
            if (rev === null || rev === undefined) {
                throw new RequiredError('rev','Required parameter rev was null or undefined when calling revertVaihe.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/palauta/{rev}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"rev"}}`, encodeURIComponent(String(rev)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {AIPEKurssiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, body?: AIPEKurssiDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateAipeKurssi.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling updateAipeKurssi.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateAipeKurssi.');
            }
            // verify required parameter 'kurssiId' is not null or undefined
            if (kurssiId === null || kurssiId === undefined) {
                throw new RequiredError('kurssiId','Required parameter kurssiId was null or undefined when calling updateAipeKurssi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit/{kurssiId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kurssiId"}}`, encodeURIComponent(String(kurssiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateAipeOppiaine.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling updateAipeOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateAipeOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajaalainenId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeOsaaminen(perusteId: number, laajaalainenId: number, body?: LaajaalainenOsaaminenDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateAipeOsaaminen.');
            }
            // verify required parameter 'laajaalainenId' is not null or undefined
            if (laajaalainenId === null || laajaalainenId === undefined) {
                throw new RequiredError('laajaalainenId','Required parameter laajaalainenId was null or undefined when calling updateAipeOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/laajaalaiset/{laajaalainenId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"laajaalainenId"}}`, encodeURIComponent(String(laajaalainenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {Array<AIPEKurssiBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKurssitJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEKurssiBaseDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateKurssitJarjestys.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling updateKurssitJarjestys.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateKurssitJarjestys.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/kurssit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<LaajaalainenOsaaminenDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLaajaalaisetJarjestys(perusteId: number, body?: Array<LaajaalainenOsaaminenDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateLaajaalaisetJarjestys.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/laajaalaiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {Array<AIPEOppiaineBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineetJarjestys(perusteId: number, vaiheId: number, body?: Array<AIPEOppiaineBaseDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOppiaineetJarjestys.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling updateOppiaineetJarjestys.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {Array<AIPEOppiaineBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppimaaratJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEOppiaineBaseDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOppimaaratJarjestys.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling updateOppimaaratJarjestys.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateOppimaaratJarjestys.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}/oppiaineet/{oppiaineId}/oppimaarat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {AIPEVaiheDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVaihe(perusteId: number, vaiheId: number, body?: AIPEVaiheDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateVaihe.');
            }
            // verify required parameter 'vaiheId' is not null or undefined
            if (vaiheId === null || vaiheId === undefined) {
                throw new RequiredError('vaiheId','Required parameter vaiheId was null or undefined when calling updateVaihe.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet/{vaiheId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"vaiheId"}}`, encodeURIComponent(String(vaiheId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<AIPEVaiheBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVaiheetJarjestys(perusteId: number, body?: Array<AIPEVaiheBaseDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateVaiheetJarjestys.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/aipeopetus/vaiheet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AipeopetuksensisaltoApi - functional programming interface
 * @export
 */
export const AipeopetuksensisaltoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEKurssiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEKurssiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEKurssiDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).addAipeKurssi(perusteId, vaiheId, oppiaineId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOppiaine(perusteId: number, vaiheId: number, body?: AIPEOppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEOppiaineSuppeaDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).addAipeOppiaine(perusteId, vaiheId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOppimaara(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEOppiaineDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).addAipeOppimaara(perusteId, vaiheId, oppiaineId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).addAipeOsaaminen(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {AIPEVaiheDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVaihe(perusteId: number, body?: AIPEVaiheDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEVaiheSuppeaDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).addVaihe(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajaalainenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAipeOsaaminen(perusteId: number, laajaalainenId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).deleteAipeOsaaminen(perusteId, laajaalainenId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKohdealueet(perusteId: number, vaiheId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetuksenKohdealueDto>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeKohdealueet(perusteId, vaiheId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEKurssiDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKurssit(perusteId: number, vaiheId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AIPEKurssiSuppeaDto>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeKurssit(perusteId, vaiheId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEOppiaineDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeOppiaine(perusteId, vaiheId, oppiaineId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaineVersiot(perusteId: number, vaiheId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Revision>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeOppiaineVersiot(perusteId, vaiheId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaineet(perusteId: number, vaiheId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AIPEOppiaineSuppeaDto>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeOppiaineet(perusteId, vaiheId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppimaarat(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AIPEOppiaineSuppeaDto>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeOppimaarat(perusteId, vaiheId, oppiaineId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajalainenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOsaaminen(perusteId: number, laajalainenId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeOsaaminen(perusteId, laajalainenId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOsaamiset(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LaajaalainenOsaaminenDto>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getAipeOsaamiset(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKurssiVersiot(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Revision>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getKurssiVersiot(perusteId, vaiheId, oppiaineId, kurssiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaihe(perusteId: number, vaiheId: number, rev?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEVaiheDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getVaihe(perusteId, vaiheId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaiheVersiot(perusteId: number, vaiheId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Revision>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getVaiheVersiot(perusteId, vaiheId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaiheet(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AIPEVaiheSuppeaDto>> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).getVaiheet(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).removeAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).removeAipeOppiaine(perusteId, vaiheId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeVaihe(perusteId: number, vaiheId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).removeVaihe(perusteId, vaiheId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEOppiaineDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).revertAipeOppiaine(perusteId, vaiheId, oppiaineId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEKurssiDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).revertKurssi(perusteId, vaiheId, oppiaineId, kurssiId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertVaihe(perusteId: number, vaiheId: number, rev: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEVaiheDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).revertVaihe(perusteId, vaiheId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {AIPEKurssiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, body?: AIPEKurssiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEKurssiDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEOppiaineSuppeaDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateAipeOppiaine(perusteId, vaiheId, oppiaineId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajaalainenId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeOsaaminen(perusteId: number, laajaalainenId: number, body?: LaajaalainenOsaaminenDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateAipeOsaaminen(perusteId, laajaalainenId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {Array<AIPEKurssiBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKurssitJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEKurssiBaseDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateKurssitJarjestys(perusteId, vaiheId, oppiaineId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<LaajaalainenOsaaminenDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLaajaalaisetJarjestys(perusteId: number, body?: Array<LaajaalainenOsaaminenDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateLaajaalaisetJarjestys(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {Array<AIPEOppiaineBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineetJarjestys(perusteId: number, vaiheId: number, body?: Array<AIPEOppiaineBaseDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateOppiaineetJarjestys(perusteId, vaiheId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {Array<AIPEOppiaineBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppimaaratJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEOppiaineBaseDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateOppimaaratJarjestys(perusteId, vaiheId, oppiaineId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {AIPEVaiheDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVaihe(perusteId: number, vaiheId: number, body?: AIPEVaiheDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIPEVaiheDto> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateVaihe(perusteId, vaiheId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<AIPEVaiheBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVaiheetJarjestys(perusteId: number, body?: Array<AIPEVaiheBaseDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AipeopetuksensisaltoApiAxiosParamCreator(configuration).updateVaiheetJarjestys(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AipeopetuksensisaltoApi - factory interface
 * @export
 */
export const AipeopetuksensisaltoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEKurssiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEKurssiDto, options?: any): AxiosPromise<AIPEKurssiDto> {
            return AipeopetuksensisaltoApiFp(configuration).addAipeKurssi(perusteId, vaiheId, oppiaineId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOppiaine(perusteId: number, vaiheId: number, body?: AIPEOppiaineDto, options?: any): AxiosPromise<AIPEOppiaineSuppeaDto> {
            return AipeopetuksensisaltoApiFp(configuration).addAipeOppiaine(perusteId, vaiheId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOppimaara(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options?: any): AxiosPromise<AIPEOppiaineDto> {
            return AipeopetuksensisaltoApiFp(configuration).addAipeOppimaara(perusteId, vaiheId, oppiaineId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAipeOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return AipeopetuksensisaltoApiFp(configuration).addAipeOsaaminen(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {AIPEVaiheDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVaihe(perusteId: number, body?: AIPEVaiheDto, options?: any): AxiosPromise<AIPEVaiheSuppeaDto> {
            return AipeopetuksensisaltoApiFp(configuration).addVaihe(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajaalainenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAipeOsaaminen(perusteId: number, laajaalainenId: number, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).deleteAipeOsaaminen(perusteId, laajaalainenId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKohdealueet(perusteId: number, vaiheId: number, options?: any): AxiosPromise<Array<OpetuksenKohdealueDto>> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeKohdealueet(perusteId, vaiheId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev?: number, options?: any): AxiosPromise<AIPEKurssiDto> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeKurssit(perusteId: number, vaiheId: number, oppiaineId: number, options?: any): AxiosPromise<Array<AIPEKurssiSuppeaDto>> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeKurssit(perusteId, vaiheId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options?: any): AxiosPromise<AIPEOppiaineDto> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeOppiaine(perusteId, vaiheId, oppiaineId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaineVersiot(perusteId: number, vaiheId: number, oppiaineId: number, options?: any): AxiosPromise<Array<Revision>> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeOppiaineVersiot(perusteId, vaiheId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppiaineet(perusteId: number, vaiheId: number, options?: any): AxiosPromise<Array<AIPEOppiaineSuppeaDto>> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeOppiaineet(perusteId, vaiheId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOppimaarat(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options?: any): AxiosPromise<Array<AIPEOppiaineSuppeaDto>> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeOppimaarat(perusteId, vaiheId, oppiaineId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajalainenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOsaaminen(perusteId: number, laajalainenId: number, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeOsaaminen(perusteId, laajalainenId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAipeOsaamiset(perusteId: number, options?: any): AxiosPromise<Array<LaajaalainenOsaaminenDto>> {
            return AipeopetuksensisaltoApiFp(configuration).getAipeOsaamiset(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKurssiVersiot(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options?: any): AxiosPromise<Array<Revision>> {
            return AipeopetuksensisaltoApiFp(configuration).getKurssiVersiot(perusteId, vaiheId, oppiaineId, kurssiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaihe(perusteId: number, vaiheId: number, rev?: number, options?: any): AxiosPromise<AIPEVaiheDto> {
            return AipeopetuksensisaltoApiFp(configuration).getVaihe(perusteId, vaiheId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaiheVersiot(perusteId: number, vaiheId: number, options?: any): AxiosPromise<Array<Revision>> {
            return AipeopetuksensisaltoApiFp(configuration).getVaiheVersiot(perusteId, vaiheId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaiheet(perusteId: number, options?: any): AxiosPromise<Array<AIPEVaiheSuppeaDto>> {
            return AipeopetuksensisaltoApiFp(configuration).getVaiheet(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).removeAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).removeAipeOppiaine(perusteId, vaiheId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeVaihe(perusteId: number, vaiheId: number, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).removeVaihe(perusteId, vaiheId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev: number, options?: any): AxiosPromise<AIPEOppiaineDto> {
            return AipeopetuksensisaltoApiFp(configuration).revertAipeOppiaine(perusteId, vaiheId, oppiaineId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev: number, options?: any): AxiosPromise<AIPEKurssiDto> {
            return AipeopetuksensisaltoApiFp(configuration).revertKurssi(perusteId, vaiheId, oppiaineId, kurssiId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertVaihe(perusteId: number, vaiheId: number, rev: number, options?: any): AxiosPromise<AIPEVaiheDto> {
            return AipeopetuksensisaltoApiFp(configuration).revertVaihe(perusteId, vaiheId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {number} kurssiId 
         * @param {AIPEKurssiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, body?: AIPEKurssiDto, options?: any): AxiosPromise<AIPEKurssiDto> {
            return AipeopetuksensisaltoApiFp(configuration).updateAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {AIPEOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options?: any): AxiosPromise<AIPEOppiaineSuppeaDto> {
            return AipeopetuksensisaltoApiFp(configuration).updateAipeOppiaine(perusteId, vaiheId, oppiaineId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} laajaalainenId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAipeOsaaminen(perusteId: number, laajaalainenId: number, body?: LaajaalainenOsaaminenDto, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return AipeopetuksensisaltoApiFp(configuration).updateAipeOsaaminen(perusteId, laajaalainenId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {Array<AIPEKurssiBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKurssitJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEKurssiBaseDto>, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).updateKurssitJarjestys(perusteId, vaiheId, oppiaineId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<LaajaalainenOsaaminenDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLaajaalaisetJarjestys(perusteId: number, body?: Array<LaajaalainenOsaaminenDto>, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).updateLaajaalaisetJarjestys(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {Array<AIPEOppiaineBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineetJarjestys(perusteId: number, vaiheId: number, body?: Array<AIPEOppiaineBaseDto>, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).updateOppiaineetJarjestys(perusteId, vaiheId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {number} oppiaineId 
         * @param {Array<AIPEOppiaineBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppimaaratJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEOppiaineBaseDto>, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).updateOppimaaratJarjestys(perusteId, vaiheId, oppiaineId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} vaiheId 
         * @param {AIPEVaiheDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVaihe(perusteId: number, vaiheId: number, body?: AIPEVaiheDto, options?: any): AxiosPromise<AIPEVaiheDto> {
            return AipeopetuksensisaltoApiFp(configuration).updateVaihe(perusteId, vaiheId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<AIPEVaiheBaseDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVaiheetJarjestys(perusteId: number, body?: Array<AIPEVaiheBaseDto>, options?: any): AxiosPromise<void> {
            return AipeopetuksensisaltoApiFp(configuration).updateVaiheetJarjestys(perusteId, body, options)(axios, basePath);
        },
    };
};

/**
 * AipeopetuksensisaltoApi - object-oriented interface
 * @export
 * @class AipeopetuksensisaltoApi
 * @extends {BaseAPI}
 */
export class AipeopetuksensisaltoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {AIPEKurssiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public addAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEKurssiDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).addAipeKurssi(perusteId, vaiheId, oppiaineId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {AIPEOppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public addAipeOppiaine(perusteId: number, vaiheId: number, body?: AIPEOppiaineDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).addAipeOppiaine(perusteId, vaiheId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {AIPEOppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public addAipeOppimaara(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).addAipeOppimaara(perusteId, vaiheId, oppiaineId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {LaajaalainenOsaaminenDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public addAipeOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).addAipeOsaaminen(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {AIPEVaiheDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public addVaihe(perusteId: number, body?: AIPEVaiheDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).addVaihe(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} laajaalainenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public deleteAipeOsaaminen(perusteId: number, laajaalainenId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).deleteAipeOsaaminen(perusteId, laajaalainenId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeKohdealueet(perusteId: number, vaiheId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeKohdealueet(perusteId, vaiheId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {number} [rev] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev?: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeKurssit(perusteId: number, vaiheId: number, oppiaineId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeKurssit(perusteId, vaiheId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} [rev] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeOppiaine(perusteId, vaiheId, oppiaineId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeOppiaineVersiot(perusteId: number, vaiheId: number, oppiaineId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeOppiaineVersiot(perusteId, vaiheId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeOppiaineet(perusteId: number, vaiheId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeOppiaineet(perusteId, vaiheId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} [rev] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeOppimaarat(perusteId: number, vaiheId: number, oppiaineId: number, rev?: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeOppimaarat(perusteId, vaiheId, oppiaineId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} laajalainenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeOsaaminen(perusteId: number, laajalainenId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeOsaaminen(perusteId, laajalainenId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getAipeOsaamiset(perusteId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getAipeOsaamiset(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getKurssiVersiot(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getKurssiVersiot(perusteId, vaiheId, oppiaineId, kurssiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} [rev] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getVaihe(perusteId: number, vaiheId: number, rev?: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getVaihe(perusteId, vaiheId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getVaiheVersiot(perusteId: number, vaiheId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getVaiheVersiot(perusteId, vaiheId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public getVaiheet(perusteId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).getVaiheet(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public removeAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).removeAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public removeAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).removeAipeOppiaine(perusteId, vaiheId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public removeVaihe(perusteId: number, vaiheId: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).removeVaihe(perusteId, vaiheId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} rev 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public revertAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, rev: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).revertAipeOppiaine(perusteId, vaiheId, oppiaineId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {number} rev 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public revertKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, rev: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).revertKurssi(perusteId, vaiheId, oppiaineId, kurssiId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} rev 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public revertVaihe(perusteId: number, vaiheId: number, rev: number, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).revertVaihe(perusteId, vaiheId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {number} kurssiId 
     * @param {AIPEKurssiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateAipeKurssi(perusteId: number, vaiheId: number, oppiaineId: number, kurssiId: number, body?: AIPEKurssiDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateAipeKurssi(perusteId, vaiheId, oppiaineId, kurssiId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {AIPEOppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateAipeOppiaine(perusteId: number, vaiheId: number, oppiaineId: number, body?: AIPEOppiaineDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateAipeOppiaine(perusteId, vaiheId, oppiaineId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} laajaalainenId 
     * @param {LaajaalainenOsaaminenDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateAipeOsaaminen(perusteId: number, laajaalainenId: number, body?: LaajaalainenOsaaminenDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateAipeOsaaminen(perusteId, laajaalainenId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {Array<AIPEKurssiBaseDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateKurssitJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEKurssiBaseDto>, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateKurssitJarjestys(perusteId, vaiheId, oppiaineId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Array<LaajaalainenOsaaminenDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateLaajaalaisetJarjestys(perusteId: number, body?: Array<LaajaalainenOsaaminenDto>, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateLaajaalaisetJarjestys(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {Array<AIPEOppiaineBaseDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateOppiaineetJarjestys(perusteId: number, vaiheId: number, body?: Array<AIPEOppiaineBaseDto>, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateOppiaineetJarjestys(perusteId, vaiheId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {number} oppiaineId 
     * @param {Array<AIPEOppiaineBaseDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateOppimaaratJarjestys(perusteId: number, vaiheId: number, oppiaineId: number, body?: Array<AIPEOppiaineBaseDto>, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateOppimaaratJarjestys(perusteId, vaiheId, oppiaineId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} vaiheId 
     * @param {AIPEVaiheDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateVaihe(perusteId: number, vaiheId: number, body?: AIPEVaiheDto, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateVaihe(perusteId, vaiheId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Array<AIPEVaiheBaseDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AipeopetuksensisaltoApi
     */
    public updateVaiheetJarjestys(perusteId: number, body?: Array<AIPEVaiheBaseDto>, options?: any) {
        return AipeopetuksensisaltoApiFp(this.configuration).updateVaiheetJarjestys(perusteId, body, options)(this.axios, this.basePath);
    }

}


/**
 * AmmattitaitovaatimuksetApi - axios parameter creator
 * @export
 */
export const AmmattitaitovaatimuksetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [uri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteetByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/ammattitaitovaatimukset/perusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (uri !== undefined) {
                localVarQueryParameter['uri'] = uri;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [uri] 
         * @param {boolean} [kaikki] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsatByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, kaikki?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/ammattitaitovaatimukset/tutkinnonosat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (uri !== undefined) {
                localVarQueryParameter['uri'] = uri;
            }

            if (kaikki !== undefined) {
                localVarQueryParameter['kaikki'] = kaikki;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmmattitaitovaatimuksetApi - functional programming interface
 * @export
 */
export const AmmattitaitovaatimuksetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [uri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteetByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteBaseDto> {
            const localVarAxiosArgs = AmmattitaitovaatimuksetApiAxiosParamCreator(configuration).getPerusteetByAmmattitaitovaatimus(sivu, sivukoko, uri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [uri] 
         * @param {boolean} [kaikki] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsatByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, kaikki?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTutkinnonOsaViiteKontekstiDto> {
            const localVarAxiosArgs = AmmattitaitovaatimuksetApiAxiosParamCreator(configuration).getTutkinnonOsatByAmmattitaitovaatimus(sivu, sivukoko, uri, kaikki, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AmmattitaitovaatimuksetApi - factory interface
 * @export
 */
export const AmmattitaitovaatimuksetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [uri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteetByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, options?: any): AxiosPromise<PagePerusteBaseDto> {
            return AmmattitaitovaatimuksetApiFp(configuration).getPerusteetByAmmattitaitovaatimus(sivu, sivukoko, uri, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [uri] 
         * @param {boolean} [kaikki] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsatByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, kaikki?: boolean, options?: any): AxiosPromise<PageTutkinnonOsaViiteKontekstiDto> {
            return AmmattitaitovaatimuksetApiFp(configuration).getTutkinnonOsatByAmmattitaitovaatimus(sivu, sivukoko, uri, kaikki, options)(axios, basePath);
        },
    };
};

/**
 * AmmattitaitovaatimuksetApi - object-oriented interface
 * @export
 * @class AmmattitaitovaatimuksetApi
 * @extends {BaseAPI}
 */
export class AmmattitaitovaatimuksetApi extends BaseAPI {
    /**
     * 
     * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [uri] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmmattitaitovaatimuksetApi
     */
    public getPerusteetByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, options?: any) {
        return AmmattitaitovaatimuksetApiFp(this.configuration).getPerusteetByAmmattitaitovaatimus(sivu, sivukoko, uri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ammattitaitovaatimuksen sisältävien perusteiden haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [uri] 
     * @param {boolean} [kaikki] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmmattitaitovaatimuksetApi
     */
    public getTutkinnonOsatByAmmattitaitovaatimus(sivu?: number, sivukoko?: number, uri?: string, kaikki?: boolean, options?: any) {
        return AmmattitaitovaatimuksetApiFp(this.configuration).getTutkinnonOsatByAmmattitaitovaatimus(sivu, sivukoko, uri, kaikki, options)(this.axios, this.basePath);
    }

}


/**
 * ArviointiasteikotApi - axios parameter creator
 * @export
 */
export const ArviointiasteikotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/api/arviointiasteikot/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options: any = {}): RequestArgs {
            const localVarPath = `/api/arviointiasteikot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ArviointiAsteikkoDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArviointiasteikot(body?: Array<ArviointiAsteikkoDto>, options: any = {}): RequestArgs {
            const localVarPath = `/api/arviointiasteikot`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArviointiasteikotApi - functional programming interface
 * @export
 */
export const ArviointiasteikotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArviointiAsteikkoDto> {
            const localVarAxiosArgs = ArviointiasteikotApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArviointiAsteikkoDto>> {
            const localVarAxiosArgs = ArviointiasteikotApiAxiosParamCreator(configuration).getAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<ArviointiAsteikkoDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArviointiasteikot(body?: Array<ArviointiAsteikkoDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArviointiAsteikkoDto>> {
            const localVarAxiosArgs = ArviointiasteikotApiAxiosParamCreator(configuration).updateArviointiasteikot(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ArviointiasteikotApi - factory interface
 * @export
 */
export const ArviointiasteikotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<ArviointiAsteikkoDto> {
            return ArviointiasteikotApiFp(configuration).get(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<ArviointiAsteikkoDto>> {
            return ArviointiasteikotApiFp(configuration).getAll(options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<ArviointiAsteikkoDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArviointiasteikot(body?: Array<ArviointiAsteikkoDto>, options?: any): AxiosPromise<Array<ArviointiAsteikkoDto>> {
            return ArviointiasteikotApiFp(configuration).updateArviointiasteikot(body, options)(axios, basePath);
        },
    };
};

/**
 * ArviointiasteikotApi - object-oriented interface
 * @export
 * @class ArviointiasteikotApi
 * @extends {BaseAPI}
 */
export class ArviointiasteikotApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArviointiasteikotApi
     */
    public get(id: number, options?: any) {
        return ArviointiasteikotApiFp(this.configuration).get(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArviointiasteikotApi
     */
    public getAll(options?: any) {
        return ArviointiasteikotApiFp(this.configuration).getAll(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<ArviointiAsteikkoDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArviointiasteikotApi
     */
    public updateArviointiasteikot(body?: Array<ArviointiAsteikkoDto>, options?: any) {
        return ArviointiasteikotApiFp(this.configuration).updateArviointiasteikot(body, options)(this.axios, this.basePath);
    }

}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKoulutusalat(options: any = {}): RequestArgs {
            const localVarPath = `/api/koulutusalat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpintoalat(options: any = {}): RequestArgs {
            const localVarPath = `/api/opintoalat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKoulutusalat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutusalaDto>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAllKoulutusalat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpintoalat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpintoalaDto>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAllOpintoalat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKoulutusalat(options?: any): AxiosPromise<Array<KoulutusalaDto>> {
            return DefaultApiFp(configuration).getAllKoulutusalat(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOpintoalat(options?: any): AxiosPromise<Array<OpintoalaDto>> {
            return DefaultApiFp(configuration).getAllOpintoalat(options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllKoulutusalat(options?: any) {
        return DefaultApiFp(this.configuration).getAllKoulutusalat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllOpintoalat(options?: any) {
        return DefaultApiFp(this.configuration).getAllOpintoalat(options)(this.axios, this.basePath);
    }

}


/**
 * DokumentitApi - axios parameter creator
 * @export
 */
export const DokumentitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary luo dokumentti
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapakoodi 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDokumentti(perusteId: number, kieli: string, suoritustapakoodi: string, version: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling createDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling createDokumentti.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling createDokumentti.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling createDokumentti.');
            }
            const localVarPath = `/api/dokumentit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (suoritustapakoodi !== undefined) {
                localVarQueryParameter['suoritustapakoodi'] = suoritustapakoodi;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentti(fileName: string, options: any = {}): RequestArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getDokumentti.');
            }
            const localVarPath = `/api/dokumentit/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getDokumenttiId.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getDokumenttiId.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling getDokumenttiId.');
            }
            const localVarPath = `/api/dokumentit/peruste`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (suoritustapa !== undefined) {
                localVarQueryParameter['suoritustapa'] = suoritustapa;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(perusteId: number, kieli: string, revision?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaistuDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getJulkaistuDokumentti.');
            }
            const localVarPath = `/api/dokumentit/julkaistu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (revision !== undefined) {
                localVarQueryParameter['revision'] = revision;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKVLiiteDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKVLiiteDokumenttiId.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getKVLiiteDokumenttiId.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling getKVLiiteDokumenttiId.');
            }
            const localVarPath = `/api/dokumentit/kvliite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (suoritustapa !== undefined) {
                localVarQueryParameter['suoritustapa'] = suoritustapa;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(perusteId: number, kieli: string, suoritustapa: string, version: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getLatestDokumentti.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getLatestDokumentti.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling getLatestDokumentti.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling getLatestDokumentti.');
            }
            const localVarPath = `/api/dokumentit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (suoritustapa !== undefined) {
                localVarQueryParameter['suoritustapa'] = suoritustapa;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumenttiTila(dokumenttiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling queryDokumenttiTila.');
            }
            const localVarPath = `/api/dokumentit/{dokumenttiId}/tila`
                .replace(`{${"dokumenttiId"}}`, encodeURIComponent(String(dokumenttiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePdfData(dokumenttiId: number, body?: PdfData, options: any = {}): RequestArgs {
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling savePdfData.');
            }
            const localVarPath = `/api/dokumentit/pdf/data/{dokumenttiId}`
                .replace(`{${"dokumenttiId"}}`, encodeURIComponent(String(dokumenttiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumenttiTila(dokumenttiId: number, body?: PdfData, options: any = {}): RequestArgs {
            // verify required parameter 'dokumenttiId' is not null or undefined
            if (dokumenttiId === null || dokumenttiId === undefined) {
                throw new RequiredError('dokumenttiId','Required parameter dokumenttiId was null or undefined when calling updateDokumenttiTila.');
            }
            const localVarPath = `/api/dokumentit/pdf/tila/{dokumenttiId}`
                .replace(`{${"dokumenttiId"}}`, encodeURIComponent(String(dokumenttiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DokumentitApi - functional programming interface
 * @export
 */
export const DokumentitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary luo dokumentti
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapakoodi 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDokumentti(perusteId: number, kieli: string, suoritustapakoodi: string, version: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).createDokumentti(perusteId, kieli, suoritustapakoodi, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentti(fileName: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getDokumentti(fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getDokumenttiId(perusteId, kieli, suoritustapa, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(perusteId: number, kieli: string, revision?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getJulkaistuDokumentti(perusteId, kieli, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKVLiiteDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getKVLiiteDokumenttiId(perusteId, kieli, suoritustapa, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(perusteId: number, kieli: string, suoritustapa: string, version: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).getLatestDokumentti(perusteId, kieli, suoritustapa, version, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumenttiTila(dokumenttiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumenttiDto> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).queryDokumenttiTila(dokumenttiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePdfData(dokumenttiId: number, body?: PdfData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).savePdfData(dokumenttiId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumenttiTila(dokumenttiId: number, body?: PdfData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DokumentitApiAxiosParamCreator(configuration).updateDokumenttiTila(dokumenttiId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DokumentitApi - factory interface
 * @export
 */
export const DokumentitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary luo dokumentti
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapakoodi 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDokumentti(perusteId: number, kieli: string, suoritustapakoodi: string, version: string, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).createDokumentti(perusteId, kieli, suoritustapakoodi, version, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentti(fileName: string, options?: any): AxiosPromise<object> {
            return DokumentitApiFp(configuration).getDokumentti(fileName, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options?: any): AxiosPromise<number> {
            return DokumentitApiFp(configuration).getDokumenttiId(perusteId, kieli, suoritustapa, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {number} [revision] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuDokumentti(perusteId: number, kieli: string, revision?: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).getJulkaistuDokumentti(perusteId, kieli, revision, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKVLiiteDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options?: any): AxiosPromise<number> {
            return DokumentitApiFp(configuration).getKVLiiteDokumenttiId(perusteId, kieli, suoritustapa, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} kieli 
         * @param {string} suoritustapa 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestDokumentti(perusteId: number, kieli: string, suoritustapa: string, version: string, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).getLatestDokumentti(perusteId, kieli, suoritustapa, version, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDokumenttiTila(dokumenttiId: number, options?: any): AxiosPromise<DokumenttiDto> {
            return DokumentitApiFp(configuration).queryDokumenttiTila(dokumenttiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePdfData(dokumenttiId: number, body?: PdfData, options?: any): AxiosPromise<string> {
            return DokumentitApiFp(configuration).savePdfData(dokumenttiId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} dokumenttiId 
         * @param {PdfData} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumenttiTila(dokumenttiId: number, body?: PdfData, options?: any): AxiosPromise<string> {
            return DokumentitApiFp(configuration).updateDokumenttiTila(dokumenttiId, body, options)(axios, basePath);
        },
    };
};

/**
 * DokumentitApi - object-oriented interface
 * @export
 * @class DokumentitApi
 * @extends {BaseAPI}
 */
export class DokumentitApi extends BaseAPI {
    /**
     * 
     * @summary luo dokumentti
     * @param {number} perusteId 
     * @param {string} kieli 
     * @param {string} suoritustapakoodi 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public createDokumentti(perusteId: number, kieli: string, suoritustapakoodi: string, version: string, options?: any) {
        return DokumentitApiFp(this.configuration).createDokumentti(perusteId, kieli, suoritustapakoodi, version, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getDokumentti(fileName: string, options?: any) {
        return DokumentitApiFp(this.configuration).getDokumentti(fileName, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} kieli 
     * @param {string} suoritustapa 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options?: any) {
        return DokumentitApiFp(this.configuration).getDokumenttiId(perusteId, kieli, suoritustapa, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} kieli 
     * @param {number} [revision] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getJulkaistuDokumentti(perusteId: number, kieli: string, revision?: number, options?: any) {
        return DokumentitApiFp(this.configuration).getJulkaistuDokumentti(perusteId, kieli, revision, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} kieli 
     * @param {string} suoritustapa 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getKVLiiteDokumenttiId(perusteId: number, kieli: string, suoritustapa: string, options?: any) {
        return DokumentitApiFp(this.configuration).getKVLiiteDokumenttiId(perusteId, kieli, suoritustapa, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} kieli 
     * @param {string} suoritustapa 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public getLatestDokumentti(perusteId: number, kieli: string, suoritustapa: string, version: string, options?: any) {
        return DokumentitApiFp(this.configuration).getLatestDokumentti(perusteId, kieli, suoritustapa, version, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} dokumenttiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public queryDokumenttiTila(dokumenttiId: number, options?: any) {
        return DokumentitApiFp(this.configuration).queryDokumenttiTila(dokumenttiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} dokumenttiId 
     * @param {PdfData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public savePdfData(dokumenttiId: number, body?: PdfData, options?: any) {
        return DokumentitApiFp(this.configuration).savePdfData(dokumenttiId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} dokumenttiId 
     * @param {PdfData} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentitApi
     */
    public updateDokumenttiTila(dokumenttiId: number, body?: PdfData, options?: any) {
        return DokumentitApiFp(this.configuration).updateDokumenttiTila(dokumenttiId, body, options)(this.axios, this.basePath);
    }

}


/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Perusteen osan haku
         * @param {number} perusteId 
         * @param {number} perusteenOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuPerusteenOsa(perusteId: number, perusteenOsaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaistuPerusteenOsa.');
            }
            // verify required parameter 'perusteenOsaId' is not null or undefined
            if (perusteenOsaId === null || perusteenOsaId === undefined) {
                throw new RequiredError('perusteenOsaId','Required parameter perusteenOsaId was null or undefined when calling getJulkaistuPerusteenOsa.');
            }
            const localVarPath = `/api/external/peruste/{perusteId}/perusteenosa/{perusteenOsaId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"perusteenOsaId"}}`, encodeURIComponent(String(perusteenOsaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perusteen osaamisalakuvauksien haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutOsaamisalaKuvaukset(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaistutOsaamisalaKuvaukset.');
            }
            const localVarPath = `/api/external/peruste/{perusteId}/osaamisalakuvaukset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hae kaikki julkaistut osaamismerkit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamismerkit(options: any = {}): RequestArgs {
            const localVarPath = `/api/external/osaamismerkit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hae julkaistu osaamismerkki koodiurilla
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamismerkkiByUri(uri: string, options: any = {}): RequestArgs {
            // verify required parameter 'uri' is not null or undefined
            if (uri === null || uri === undefined) {
                throw new RequiredError('uri','Required parameter uri was null or undefined when calling getOsaamismerkkiByUri.');
            }
            const localVarPath = `/api/external/osaamismerkki/koodi/{uri}`
                .replace(`{${"uri"}}`, encodeURIComponent(String(uri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruste(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPeruste.');
            }
            const localVarPath = `/api/external/peruste/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Url parametreiksi voi antaa peruste id:n lisäksi erilaisia perusteen rakenteen osia ja id-kenttien arvoja. Esim. /peruste/8505691/tutkinnonOsat/7283253/koodi/nimi/fi antaa perusteen (id: 8505691) tutkinnon osien tietueen (id: 7283253) koodi-tiedon nimen suomenkielisenä.
         * @summary Perusteen tietojen haku tarkalla sisältörakenteella
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteDynamicQuery(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteDynamicQuery.');
            }
            const localVarPath = `/api/external/peruste/{perusteId}/**`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Statuskoodi 409 jos löytyy useampi peruste. Statuskoodi 204, jos haettu rakenne on tyhjä.
         * @summary Perusteen haku koulutuskoodilla ja sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'.
         * @param {number} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteWithKoodi(koodi: number, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getPerusteWithKoodi.');
            }
            const localVarPath = `/api/external/peruste/koulutuskoodi/{koodi}/**`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perusteiden haku
         * @param {Array<string>} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {boolean} [tulevat] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [siirtyma] 
         * @param {boolean} [poistuneet] 
         * @param {boolean} [koulutusvienti] 
         * @param {string} [tyyppi] 
         * @param {string} [diaarinumero] 
         * @param {string} [koodi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(koulutustyyppi?: Array<string>, nimi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, sivu?: number, sivukoko?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/external/perusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (tulevat !== undefined) {
                localVarQueryParameter['tulevat'] = tulevat;
            }

            if (voimassa !== undefined) {
                localVarQueryParameter['voimassa'] = voimassa;
            }

            if (siirtyma !== undefined) {
                localVarQueryParameter['siirtyma'] = siirtyma;
            }

            if (poistuneet !== undefined) {
                localVarQueryParameter['poistuneet'] = poistuneet;
            }

            if (koulutusvienti !== undefined) {
                localVarQueryParameter['koulutusvienti'] = koulutusvienti;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (diaarinumero !== undefined) {
                localVarQueryParameter['diaarinumero'] = diaarinumero;
            }

            if (koodi !== undefined) {
                localVarQueryParameter['koodi'] = koodi;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Yhteisien tutkinnon osien -perusteen(YTO) tietojen haku tarkalla sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYtoPerusteDynamicQuery(options: any = {}): RequestArgs {
            const localVarPath = `/api/external/peruste/yto/**`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Perusteen osan haku
         * @param {number} perusteId 
         * @param {number} perusteenOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuPerusteenOsa(perusteId: number, perusteenOsaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteenOsaDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getJulkaistuPerusteenOsa(perusteId, perusteenOsaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Perusteen osaamisalakuvauksien haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutOsaamisalaKuvaukset(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<TekstiKappaleDto>; }; }> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getJulkaistutOsaamisalaKuvaukset(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Hae kaikki julkaistut osaamismerkit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamismerkit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaamismerkkiExternalDto>> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getOsaamismerkit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Hae julkaistu osaamismerkki koodiurilla
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamismerkkiByUri(uri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamismerkkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getOsaamismerkkiByUri(uri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruste(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPeruste(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Url parametreiksi voi antaa peruste id:n lisäksi erilaisia perusteen rakenteen osia ja id-kenttien arvoja. Esim. /peruste/8505691/tutkinnonOsat/7283253/koodi/nimi/fi antaa perusteen (id: 8505691) tutkinnon osien tietueen (id: 7283253) koodi-tiedon nimen suomenkielisenä.
         * @summary Perusteen tietojen haku tarkalla sisältörakenteella
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteDynamicQuery(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPerusteDynamicQuery(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Statuskoodi 409 jos löytyy useampi peruste. Statuskoodi 204, jos haettu rakenne on tyhjä.
         * @summary Perusteen haku koulutuskoodilla ja sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'.
         * @param {number} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteWithKoodi(koodi: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPerusteWithKoodi(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Perusteiden haku
         * @param {Array<string>} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {boolean} [tulevat] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [siirtyma] 
         * @param {boolean} [poistuneet] 
         * @param {boolean} [koulutusvienti] 
         * @param {string} [tyyppi] 
         * @param {string} [diaarinumero] 
         * @param {string} [koodi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(koulutustyyppi?: Array<string>, nimi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, sivu?: number, sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteenJulkaisuData> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getPerusteet(koulutustyyppi, nimi, kieli, tulevat, voimassa, siirtyma, poistuneet, koulutusvienti, tyyppi, diaarinumero, koodi, sivu, sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Yhteisien tutkinnon osien -perusteen(YTO) tietojen haku tarkalla sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYtoPerusteDynamicQuery(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = ExternalApiAxiosParamCreator(configuration).getYtoPerusteDynamicQuery(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Perusteen osan haku
         * @param {number} perusteId 
         * @param {number} perusteenOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistuPerusteenOsa(perusteId: number, perusteenOsaId: number, options?: any): AxiosPromise<PerusteenOsaDto> {
            return ExternalApiFp(configuration).getJulkaistuPerusteenOsa(perusteId, perusteenOsaId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Perusteen osaamisalakuvauksien haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutOsaamisalaKuvaukset(perusteId: number, options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<TekstiKappaleDto>; }; }> {
            return ExternalApiFp(configuration).getJulkaistutOsaamisalaKuvaukset(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Hae kaikki julkaistut osaamismerkit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamismerkit(options?: any): AxiosPromise<Array<OsaamismerkkiExternalDto>> {
            return ExternalApiFp(configuration).getOsaamismerkit(options)(axios, basePath);
        },
        /**
         * 
         * @summary Hae julkaistu osaamismerkki koodiurilla
         * @param {string} uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamismerkkiByUri(uri: string, options?: any): AxiosPromise<OsaamismerkkiDto> {
            return ExternalApiFp(configuration).getOsaamismerkkiByUri(uri, options)(axios, basePath);
        },
        /**
         * 
         * @summary Perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeruste(perusteId: number, options?: any): AxiosPromise<PerusteKaikkiDto> {
            return ExternalApiFp(configuration).getPeruste(perusteId, options)(axios, basePath);
        },
        /**
         * Url parametreiksi voi antaa peruste id:n lisäksi erilaisia perusteen rakenteen osia ja id-kenttien arvoja. Esim. /peruste/8505691/tutkinnonOsat/7283253/koodi/nimi/fi antaa perusteen (id: 8505691) tutkinnon osien tietueen (id: 7283253) koodi-tiedon nimen suomenkielisenä.
         * @summary Perusteen tietojen haku tarkalla sisältörakenteella
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteDynamicQuery(perusteId: number, options?: any): AxiosPromise<PerusteKaikkiDto> {
            return ExternalApiFp(configuration).getPerusteDynamicQuery(perusteId, options)(axios, basePath);
        },
        /**
         * Statuskoodi 409 jos löytyy useampi peruste. Statuskoodi 204, jos haettu rakenne on tyhjä.
         * @summary Perusteen haku koulutuskoodilla ja sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'.
         * @param {number} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteWithKoodi(koodi: number, options?: any): AxiosPromise<PerusteKaikkiDto> {
            return ExternalApiFp(configuration).getPerusteWithKoodi(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @summary Perusteiden haku
         * @param {Array<string>} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {boolean} [tulevat] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [siirtyma] 
         * @param {boolean} [poistuneet] 
         * @param {boolean} [koulutusvienti] 
         * @param {string} [tyyppi] 
         * @param {string} [diaarinumero] 
         * @param {string} [koodi] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteet(koulutustyyppi?: Array<string>, nimi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, sivu?: number, sivukoko?: number, options?: any): AxiosPromise<PagePerusteenJulkaisuData> {
            return ExternalApiFp(configuration).getPerusteet(koulutustyyppi, nimi, kieli, tulevat, voimassa, siirtyma, poistuneet, koulutusvienti, tyyppi, diaarinumero, koodi, sivu, sivukoko, options)(axios, basePath);
        },
        /**
         * 
         * @summary Yhteisien tutkinnon osien -perusteen(YTO) tietojen haku tarkalla sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYtoPerusteDynamicQuery(options?: any): AxiosPromise<PerusteKaikkiDto> {
            return ExternalApiFp(configuration).getYtoPerusteDynamicQuery(options)(axios, basePath);
        },
    };
};

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
    /**
     * 
     * @summary Perusteen osan haku
     * @param {number} perusteId 
     * @param {number} perusteenOsaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getJulkaistuPerusteenOsa(perusteId: number, perusteenOsaId: number, options?: any) {
        return ExternalApiFp(this.configuration).getJulkaistuPerusteenOsa(perusteId, perusteenOsaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Perusteen osaamisalakuvauksien haku
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getJulkaistutOsaamisalaKuvaukset(perusteId: number, options?: any) {
        return ExternalApiFp(this.configuration).getJulkaistutOsaamisalaKuvaukset(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Hae kaikki julkaistut osaamismerkit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getOsaamismerkit(options?: any) {
        return ExternalApiFp(this.configuration).getOsaamismerkit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Hae julkaistu osaamismerkki koodiurilla
     * @param {string} uri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getOsaamismerkkiByUri(uri: string, options?: any) {
        return ExternalApiFp(this.configuration).getOsaamismerkkiByUri(uri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Perusteen tietojen haku
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPeruste(perusteId: number, options?: any) {
        return ExternalApiFp(this.configuration).getPeruste(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * Url parametreiksi voi antaa peruste id:n lisäksi erilaisia perusteen rakenteen osia ja id-kenttien arvoja. Esim. /peruste/8505691/tutkinnonOsat/7283253/koodi/nimi/fi antaa perusteen (id: 8505691) tutkinnon osien tietueen (id: 7283253) koodi-tiedon nimen suomenkielisenä.
     * @summary Perusteen tietojen haku tarkalla sisältörakenteella
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPerusteDynamicQuery(perusteId: number, options?: any) {
        return ExternalApiFp(this.configuration).getPerusteDynamicQuery(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * Statuskoodi 409 jos löytyy useampi peruste. Statuskoodi 204, jos haettu rakenne on tyhjä.
     * @summary Perusteen haku koulutuskoodilla ja sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'.
     * @param {number} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPerusteWithKoodi(koodi: number, options?: any) {
        return ExternalApiFp(this.configuration).getPerusteWithKoodi(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Perusteiden haku
     * @param {Array<string>} [koulutustyyppi] 
     * @param {string} [nimi] 
     * @param {string} [kieli] 
     * @param {boolean} [tulevat] 
     * @param {boolean} [voimassa] 
     * @param {boolean} [siirtyma] 
     * @param {boolean} [poistuneet] 
     * @param {boolean} [koulutusvienti] 
     * @param {string} [tyyppi] 
     * @param {string} [diaarinumero] 
     * @param {string} [koodi] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getPerusteet(koulutustyyppi?: Array<string>, nimi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, sivu?: number, sivukoko?: number, options?: any) {
        return ExternalApiFp(this.configuration).getPerusteet(koulutustyyppi, nimi, kieli, tulevat, voimassa, siirtyma, poistuneet, koulutusvienti, tyyppi, diaarinumero, koodi, sivu, sivukoko, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Yhteisien tutkinnon osien -perusteen(YTO) tietojen haku tarkalla sisältörakenteella. Kts \'Perusteen tietojen haku tarkalla sisältörakenteella\'
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getYtoPerusteDynamicQuery(options?: any) {
        return ExternalApiFp(this.configuration).getYtoPerusteDynamicQuery(options)(this.axios, this.basePath);
    }

}


/**
 * GeneerinenArviointiasteikkoApi - axios parameter creator
 * @export
 */
export const GeneerinenArviointiasteikkoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GeneerinenArviointiasteikkoDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGeneerinenArviointiasteikko(body?: GeneerinenArviointiasteikkoDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/geneerinenarviointi`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneerisetArviointiasteikot(options: any = {}): RequestArgs {
            const localVarPath = `/api/geneerinenarviointi`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneerisetArviointiasteikotJulkaistu(options: any = {}): RequestArgs {
            const localVarPath = `/api/geneerinenarviointi/julkaistu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneGeneerisetArviointiasteikko(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneGeneerisetArviointiasteikko.');
            }
            const localVarPath = `/api/geneerinenarviointi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneGeneerisetArviointiasteikkoKaikki(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneGeneerisetArviointiasteikkoKaikki.');
            }
            const localVarPath = `/api/geneerinenarviointi/{id}/kaikki`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiGeneerinenArviontiasteikko(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling kopioiGeneerinenArviontiasteikko.');
            }
            const localVarPath = `/api/geneerinenarviointi/{id}/kopioi`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGeneerinenArviontiasteikko(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeGeneerinenArviontiasteikko.');
            }
            const localVarPath = `/api/geneerinenarviointi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GeneerinenArviointiasteikkoDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneerinenArviontiasteikko(id: number, body?: GeneerinenArviointiasteikkoDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateGeneerinenArviontiasteikko.');
            }
            const localVarPath = `/api/geneerinenarviointi/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneerinenArviointiasteikkoApi - functional programming interface
 * @export
 */
export const GeneerinenArviointiasteikkoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GeneerinenArviointiasteikkoDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGeneerinenArviointiasteikko(body?: GeneerinenArviointiasteikkoDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneerinenArviointiasteikkoDto> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).addGeneerinenArviointiasteikko(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneerisetArviointiasteikot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneerinenArviointiasteikkoDto>> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).getAllGeneerisetArviointiasteikot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneerisetArviointiasteikotJulkaistu(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneerinenArviointiasteikkoDto>> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).getAllGeneerisetArviointiasteikotJulkaistu(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneGeneerisetArviointiasteikko(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneerinenArviointiasteikkoDto> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).getOneGeneerisetArviointiasteikko(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneGeneerisetArviointiasteikkoKaikki(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneerinenArviointiasteikkoKaikkiDto> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).getOneGeneerisetArviointiasteikkoKaikki(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiGeneerinenArviontiasteikko(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneerinenArviointiasteikkoDto> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).kopioiGeneerinenArviontiasteikko(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGeneerinenArviontiasteikko(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).removeGeneerinenArviontiasteikko(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GeneerinenArviointiasteikkoDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneerinenArviontiasteikko(id: number, body?: GeneerinenArviointiasteikkoDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneerinenArviointiasteikkoDto> {
            const localVarAxiosArgs = GeneerinenArviointiasteikkoApiAxiosParamCreator(configuration).updateGeneerinenArviontiasteikko(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GeneerinenArviointiasteikkoApi - factory interface
 * @export
 */
export const GeneerinenArviointiasteikkoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {GeneerinenArviointiasteikkoDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGeneerinenArviointiasteikko(body?: GeneerinenArviointiasteikkoDto, options?: any): AxiosPromise<GeneerinenArviointiasteikkoDto> {
            return GeneerinenArviointiasteikkoApiFp(configuration).addGeneerinenArviointiasteikko(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneerisetArviointiasteikot(options?: any): AxiosPromise<Array<GeneerinenArviointiasteikkoDto>> {
            return GeneerinenArviointiasteikkoApiFp(configuration).getAllGeneerisetArviointiasteikot(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneerisetArviointiasteikotJulkaistu(options?: any): AxiosPromise<Array<GeneerinenArviointiasteikkoDto>> {
            return GeneerinenArviointiasteikkoApiFp(configuration).getAllGeneerisetArviointiasteikotJulkaistu(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneGeneerisetArviointiasteikko(id: number, options?: any): AxiosPromise<GeneerinenArviointiasteikkoDto> {
            return GeneerinenArviointiasteikkoApiFp(configuration).getOneGeneerisetArviointiasteikko(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneGeneerisetArviointiasteikkoKaikki(id: number, options?: any): AxiosPromise<GeneerinenArviointiasteikkoKaikkiDto> {
            return GeneerinenArviointiasteikkoApiFp(configuration).getOneGeneerisetArviointiasteikkoKaikki(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopioiGeneerinenArviontiasteikko(id: number, options?: any): AxiosPromise<GeneerinenArviointiasteikkoDto> {
            return GeneerinenArviointiasteikkoApiFp(configuration).kopioiGeneerinenArviontiasteikko(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGeneerinenArviontiasteikko(id: number, options?: any): AxiosPromise<void> {
            return GeneerinenArviointiasteikkoApiFp(configuration).removeGeneerinenArviontiasteikko(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GeneerinenArviointiasteikkoDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneerinenArviontiasteikko(id: number, body?: GeneerinenArviointiasteikkoDto, options?: any): AxiosPromise<GeneerinenArviointiasteikkoDto> {
            return GeneerinenArviointiasteikkoApiFp(configuration).updateGeneerinenArviontiasteikko(id, body, options)(axios, basePath);
        },
    };
};

/**
 * GeneerinenArviointiasteikkoApi - object-oriented interface
 * @export
 * @class GeneerinenArviointiasteikkoApi
 * @extends {BaseAPI}
 */
export class GeneerinenArviointiasteikkoApi extends BaseAPI {
    /**
     * 
     * @param {GeneerinenArviointiasteikkoDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public addGeneerinenArviointiasteikko(body?: GeneerinenArviointiasteikkoDto, options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).addGeneerinenArviointiasteikko(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public getAllGeneerisetArviointiasteikot(options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).getAllGeneerisetArviointiasteikot(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public getAllGeneerisetArviointiasteikotJulkaistu(options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).getAllGeneerisetArviointiasteikotJulkaistu(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public getOneGeneerisetArviointiasteikko(id: number, options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).getOneGeneerisetArviointiasteikko(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public getOneGeneerisetArviointiasteikkoKaikki(id: number, options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).getOneGeneerisetArviointiasteikkoKaikki(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public kopioiGeneerinenArviontiasteikko(id: number, options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).kopioiGeneerinenArviontiasteikko(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public removeGeneerinenArviontiasteikko(id: number, options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).removeGeneerinenArviontiasteikko(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {GeneerinenArviointiasteikkoDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneerinenArviointiasteikkoApi
     */
    public updateGeneerinenArviontiasteikko(id: number, body?: GeneerinenArviointiasteikkoDto, options?: any) {
        return GeneerinenArviointiasteikkoApiFp(this.configuration).updateGeneerinenArviontiasteikko(id, body, options)(this.axios, this.basePath);
    }

}


/**
 * JulkaisutApi - axios parameter creator
 * @export
 */
export const JulkaisutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} projektiId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(projektiId: number, revision: number, options: any = {}): RequestArgs {
            // verify required parameter 'projektiId' is not null or undefined
            if (projektiId === null || projektiId === undefined) {
                throw new RequiredError('projektiId','Required parameter projektiId was null or undefined when calling aktivoiJulkaisu.');
            }
            // verify required parameter 'revision' is not null or undefined
            if (revision === null || revision === undefined) {
                throw new RequiredError('revision','Required parameter revision was null or undefined when calling aktivoiJulkaisu.');
            }
            const localVarPath = `/api/perusteet/{projektiId}/aktivoi/{revision}`
                .replace(`{${"projektiId"}}`, encodeURIComponent(String(projektiId)))
                .replace(`{${"revision"}}`, encodeURIComponent(String(revision)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaisut.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/julkaisut`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetJulkaisut(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkisetJulkaisut.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/julkaisut/julkinen`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaisut(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKaikkiJulkaisut.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/julkaisut/kaikki`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary julkaistujen perusteiden haku
         * @param {Array<string>} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [nimiTaiKoodi] 
         * @param {string} [kieli] 
         * @param {boolean} [tulevat] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [siirtyma] 
         * @param {boolean} [poistuneet] 
         * @param {boolean} [koulutusvienti] 
         * @param {string} [tyyppi] 
         * @param {string} [diaarinumero] 
         * @param {string} [koodi] 
         * @param {boolean} [perusteet] 
         * @param {boolean} [tutkinnonosat] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustyyppienJulkaisut(koulutustyyppi?: Array<string>, nimi?: string, nimiTaiKoodi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, perusteet?: boolean, tutkinnonosat?: boolean, sivu?: number, sivukoko?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (nimiTaiKoodi !== undefined) {
                localVarQueryParameter['nimiTaiKoodi'] = nimiTaiKoodi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (tulevat !== undefined) {
                localVarQueryParameter['tulevat'] = tulevat;
            }

            if (voimassa !== undefined) {
                localVarQueryParameter['voimassa'] = voimassa;
            }

            if (siirtyma !== undefined) {
                localVarQueryParameter['siirtyma'] = siirtyma;
            }

            if (poistuneet !== undefined) {
                localVarQueryParameter['poistuneet'] = poistuneet;
            }

            if (koulutusvienti !== undefined) {
                localVarQueryParameter['koulutusvienti'] = koulutusvienti;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (diaarinumero !== undefined) {
                localVarQueryParameter['diaarinumero'] = diaarinumero;
            }

            if (koodi !== undefined) {
                localVarQueryParameter['koodi'] = koodi;
            }

            if (perusteet !== undefined) {
                localVarQueryParameter['perusteet'] = perusteet;
            }

            if (tutkinnonosat !== undefined) {
                localVarQueryParameter['tutkinnonosat'] = tutkinnonosat;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling julkaisemattomiaMuutoksia.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/julkaisu/muutoksia`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kooditaPeruste(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling kooditaPeruste.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/koodita`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nollaaJulkaisuTila(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling nollaaJulkaisuTila.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/nollaajulkaisutila`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projektiId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisu(projektiId: number, body?: JulkaisuBaseDto, options: any = {}): RequestArgs {
            // verify required parameter 'projektiId' is not null or undefined
            if (projektiId === null || projektiId === undefined) {
                throw new RequiredError('projektiId','Required parameter projektiId was null or undefined when calling teeJulkaisu.');
            }
            const localVarPath = `/api/perusteet/{projektiId}/julkaisu`
                .replace(`{${"projektiId"}}`, encodeURIComponent(String(projektiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJulkaisu(perusteId: number, body?: JulkaisuBaseDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateJulkaisu.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/julkaisu/update`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuAika(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling viimeisinJulkaisuAika.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/viimeisinjulkaisuaika`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling viimeisinJulkaisuTila.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/viimeisinjulkaisutila`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JulkaisutApi - functional programming interface
 * @export
 */
export const JulkaisutApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} projektiId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(projektiId: number, revision: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).aktivoiJulkaisu(projektiId, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JulkaisuBaseDto>> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getJulkaisut(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetJulkaisut(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JulkaisuBaseDto>> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getJulkisetJulkaisut(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaisut(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JulkaisuBaseDto>> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getKaikkiJulkaisut(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary julkaistujen perusteiden haku
         * @param {Array<string>} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [nimiTaiKoodi] 
         * @param {string} [kieli] 
         * @param {boolean} [tulevat] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [siirtyma] 
         * @param {boolean} [poistuneet] 
         * @param {boolean} [koulutusvienti] 
         * @param {string} [tyyppi] 
         * @param {string} [diaarinumero] 
         * @param {string} [koodi] 
         * @param {boolean} [perusteet] 
         * @param {boolean} [tutkinnonosat] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustyyppienJulkaisut(koulutustyyppi?: Array<string>, nimi?: string, nimiTaiKoodi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, perusteet?: boolean, tutkinnonosat?: boolean, sivu?: number, sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteenJulkaisuData> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).getKoulutustyyppienJulkaisut(koulutustyyppi, nimi, nimiTaiKoodi, kieli, tulevat, voimassa, siirtyma, poistuneet, koulutusvienti, tyyppi, diaarinumero, koodi, perusteet, tutkinnonosat, sivu, sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).julkaisemattomiaMuutoksia(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kooditaPeruste(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).kooditaPeruste(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nollaaJulkaisuTila(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).nollaaJulkaisuTila(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} projektiId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisu(projektiId: number, body?: JulkaisuBaseDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).teeJulkaisu(projektiId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJulkaisu(perusteId: number, body?: JulkaisuBaseDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).updateJulkaisu(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuAika(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Date> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).viimeisinJulkaisuAika(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = JulkaisutApiAxiosParamCreator(configuration).viimeisinJulkaisuTila(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JulkaisutApi - factory interface
 * @export
 */
export const JulkaisutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} projektiId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aktivoiJulkaisu(projektiId: number, revision: number, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).aktivoiJulkaisu(projektiId, revision, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisut(perusteId: number, options?: any): AxiosPromise<Array<JulkaisuBaseDto>> {
            return JulkaisutApiFp(configuration).getJulkaisut(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetJulkaisut(perusteId: number, options?: any): AxiosPromise<Array<JulkaisuBaseDto>> {
            return JulkaisutApiFp(configuration).getJulkisetJulkaisut(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKaikkiJulkaisut(perusteId: number, options?: any): AxiosPromise<Array<JulkaisuBaseDto>> {
            return JulkaisutApiFp(configuration).getKaikkiJulkaisut(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @summary julkaistujen perusteiden haku
         * @param {Array<string>} [koulutustyyppi] 
         * @param {string} [nimi] 
         * @param {string} [nimiTaiKoodi] 
         * @param {string} [kieli] 
         * @param {boolean} [tulevat] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [siirtyma] 
         * @param {boolean} [poistuneet] 
         * @param {boolean} [koulutusvienti] 
         * @param {string} [tyyppi] 
         * @param {string} [diaarinumero] 
         * @param {string} [koodi] 
         * @param {boolean} [perusteet] 
         * @param {boolean} [tutkinnonosat] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoulutustyyppienJulkaisut(koulutustyyppi?: Array<string>, nimi?: string, nimiTaiKoodi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, perusteet?: boolean, tutkinnonosat?: boolean, sivu?: number, sivukoko?: number, options?: any): AxiosPromise<PagePerusteenJulkaisuData> {
            return JulkaisutApiFp(configuration).getKoulutustyyppienJulkaisut(koulutustyyppi, nimi, nimiTaiKoodi, kieli, tulevat, voimassa, siirtyma, poistuneet, koulutusvienti, tyyppi, diaarinumero, koodi, perusteet, tutkinnonosat, sivu, sivukoko, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        julkaisemattomiaMuutoksia(perusteId: number, options?: any): AxiosPromise<boolean> {
            return JulkaisutApiFp(configuration).julkaisemattomiaMuutoksia(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kooditaPeruste(perusteId: number, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).kooditaPeruste(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nollaaJulkaisuTila(perusteId: number, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).nollaaJulkaisuTila(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} projektiId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisu(projektiId: number, body?: JulkaisuBaseDto, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).teeJulkaisu(projektiId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {JulkaisuBaseDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJulkaisu(perusteId: number, body?: JulkaisuBaseDto, options?: any): AxiosPromise<void> {
            return JulkaisutApiFp(configuration).updateJulkaisu(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuAika(perusteId: number, options?: any): AxiosPromise<Date> {
            return JulkaisutApiFp(configuration).viimeisinJulkaisuAika(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viimeisinJulkaisuTila(perusteId: number, options?: any): AxiosPromise<string> {
            return JulkaisutApiFp(configuration).viimeisinJulkaisuTila(perusteId, options)(axios, basePath);
        },
    };
};

/**
 * JulkaisutApi - object-oriented interface
 * @export
 * @class JulkaisutApi
 * @extends {BaseAPI}
 */
export class JulkaisutApi extends BaseAPI {
    /**
     * 
     * @param {number} projektiId 
     * @param {number} revision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public aktivoiJulkaisu(projektiId: number, revision: number, options?: any) {
        return JulkaisutApiFp(this.configuration).aktivoiJulkaisu(projektiId, revision, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getJulkaisut(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getJulkaisut(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getJulkisetJulkaisut(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getJulkisetJulkaisut(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getKaikkiJulkaisut(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getKaikkiJulkaisut(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary julkaistujen perusteiden haku
     * @param {Array<string>} [koulutustyyppi] 
     * @param {string} [nimi] 
     * @param {string} [nimiTaiKoodi] 
     * @param {string} [kieli] 
     * @param {boolean} [tulevat] 
     * @param {boolean} [voimassa] 
     * @param {boolean} [siirtyma] 
     * @param {boolean} [poistuneet] 
     * @param {boolean} [koulutusvienti] 
     * @param {string} [tyyppi] 
     * @param {string} [diaarinumero] 
     * @param {string} [koodi] 
     * @param {boolean} [perusteet] 
     * @param {boolean} [tutkinnonosat] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public getKoulutustyyppienJulkaisut(koulutustyyppi?: Array<string>, nimi?: string, nimiTaiKoodi?: string, kieli?: string, tulevat?: boolean, voimassa?: boolean, siirtyma?: boolean, poistuneet?: boolean, koulutusvienti?: boolean, tyyppi?: string, diaarinumero?: string, koodi?: string, perusteet?: boolean, tutkinnonosat?: boolean, sivu?: number, sivukoko?: number, options?: any) {
        return JulkaisutApiFp(this.configuration).getKoulutustyyppienJulkaisut(koulutustyyppi, nimi, nimiTaiKoodi, kieli, tulevat, voimassa, siirtyma, poistuneet, koulutusvienti, tyyppi, diaarinumero, koodi, perusteet, tutkinnonosat, sivu, sivukoko, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public julkaisemattomiaMuutoksia(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).julkaisemattomiaMuutoksia(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public kooditaPeruste(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).kooditaPeruste(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public nollaaJulkaisuTila(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).nollaaJulkaisuTila(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} projektiId 
     * @param {JulkaisuBaseDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public teeJulkaisu(projektiId: number, body?: JulkaisuBaseDto, options?: any) {
        return JulkaisutApiFp(this.configuration).teeJulkaisu(projektiId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {JulkaisuBaseDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public updateJulkaisu(perusteId: number, body?: JulkaisuBaseDto, options?: any) {
        return JulkaisutApiFp(this.configuration).updateJulkaisu(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public viimeisinJulkaisuAika(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).viimeisinJulkaisuAika(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkaisutApi
     */
    public viimeisinJulkaisuTila(perusteId: number, options?: any) {
        return JulkaisutApiFp(this.configuration).viimeisinJulkaisuTila(perusteId, options)(this.axios, this.basePath);
    }

}


/**
 * JulkinenApi - axios parameter creator
 * @export
 */
export const JulkinenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmatJaPerusteet(nimi?: string, kieli?: string, sivu?: number, sivukoko?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/etusivu`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTietoaPalvelusta(options: any = {}): RequestArgs {
            const localVarPath = `/api/julkinen/tietoapalvelusta`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JulkinenApi - functional programming interface
 * @export
 */
export const JulkinenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmatJaPerusteet(nimi?: string, kieli?: string, sivu?: number, sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageJulkiEtusivuDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getOpetussuunnitelmatJaPerusteet(nimi, kieli, sivu, sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTietoaPalvelusta(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TietoaPalvelustaDto> {
            const localVarAxiosArgs = JulkinenApiAxiosParamCreator(configuration).getTietoaPalvelusta(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JulkinenApi - factory interface
 * @export
 */
export const JulkinenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpetussuunnitelmatJaPerusteet(nimi?: string, kieli?: string, sivu?: number, sivukoko?: number, options?: any): AxiosPromise<PageJulkiEtusivuDto> {
            return JulkinenApiFp(configuration).getOpetussuunnitelmatJaPerusteet(nimi, kieli, sivu, sivukoko, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTietoaPalvelusta(options?: any): AxiosPromise<TietoaPalvelustaDto> {
            return JulkinenApiFp(configuration).getTietoaPalvelusta(options)(axios, basePath);
        },
    };
};

/**
 * JulkinenApi - object-oriented interface
 * @export
 * @class JulkinenApi
 * @extends {BaseAPI}
 */
export class JulkinenApi extends BaseAPI {
    /**
     * 
     * @param {string} [nimi] 
     * @param {string} [kieli] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getOpetussuunnitelmatJaPerusteet(nimi?: string, kieli?: string, sivu?: number, sivukoko?: number, options?: any) {
        return JulkinenApiFp(this.configuration).getOpetussuunnitelmatJaPerusteet(nimi, kieli, sivu, sivukoko, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JulkinenApi
     */
    public getTietoaPalvelusta(options?: any) {
        return JulkinenApiFp(this.configuration).getTietoaPalvelusta(options)(this.axios, this.basePath);
    }

}


/**
 * KayttajatApi - axios parameter creator
 * @export
 */
export const KayttajatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getKayttaja.');
            }
            const localVarPath = `/api/kayttajatieto/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {number} projektiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanPerusteprojekti(oid: string, projektiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getKayttajanPerusteprojekti.');
            }
            // verify required parameter 'projektiId' is not null or undefined
            if (projektiId === null || projektiId === undefined) {
                throw new RequiredError('projektiId','Required parameter projektiId was null or undefined when calling getKayttajanPerusteprojekti.');
            }
            const localVarPath = `/api/kayttajatieto/{oid}/perusteprojektit/{projektiId}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)))
                .replace(`{${"projektiId"}}`, encodeURIComponent(String(projektiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanPerusteprojektit(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getKayttajanPerusteprojektit.');
            }
            const localVarPath = `/api/kayttajatieto/{oid}/perusteprojektit`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKirjautunutKayttajat(options: any = {}): RequestArgs {
            const localVarPath = `/api/kayttajatieto`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KayttajatApi - functional programming interface
 * @export
 */
export const KayttajatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getKayttaja(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {number} projektiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanPerusteprojekti(oid: string, projektiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanProjektitiedotDto> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getKayttajanPerusteprojekti(oid, projektiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanPerusteprojektit(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajanProjektitiedotDto>> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getKayttajanPerusteprojektit(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKirjautunutKayttajat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KayttajanTietoDto> {
            const localVarAxiosArgs = KayttajatApiAxiosParamCreator(configuration).getKirjautunutKayttajat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KayttajatApi - factory interface
 * @export
 */
export const KayttajatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttaja(oid: string, options?: any): AxiosPromise<KayttajanTietoDto> {
            return KayttajatApiFp(configuration).getKayttaja(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {number} projektiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanPerusteprojekti(oid: string, projektiId: number, options?: any): AxiosPromise<KayttajanProjektitiedotDto> {
            return KayttajatApiFp(configuration).getKayttajanPerusteprojekti(oid, projektiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKayttajanPerusteprojektit(oid: string, options?: any): AxiosPromise<Array<KayttajanProjektitiedotDto>> {
            return KayttajatApiFp(configuration).getKayttajanPerusteprojektit(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKirjautunutKayttajat(options?: any): AxiosPromise<KayttajanTietoDto> {
            return KayttajatApiFp(configuration).getKirjautunutKayttajat(options)(axios, basePath);
        },
    };
};

/**
 * KayttajatApi - object-oriented interface
 * @export
 * @class KayttajatApi
 * @extends {BaseAPI}
 */
export class KayttajatApi extends BaseAPI {
    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getKayttaja(oid: string, options?: any) {
        return KayttajatApiFp(this.configuration).getKayttaja(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {number} projektiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getKayttajanPerusteprojekti(oid: string, projektiId: number, options?: any) {
        return KayttajatApiFp(this.configuration).getKayttajanPerusteprojekti(oid, projektiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getKayttajanPerusteprojektit(oid: string, options?: any) {
        return KayttajatApiFp(this.configuration).getKayttajanPerusteprojektit(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KayttajatApi
     */
    public getKirjautunutKayttajat(options?: any) {
        return KayttajatApiFp(this.configuration).getKirjautunutKayttajat(options)(this.axios, this.basePath);
    }

}


/**
 * KoodistoApi - axios parameter creator
 * @export
 */
export const KoodistoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alarelaatio(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling alarelaatio.');
            }
            const localVarPath = `/api/koodisto/relaatio/sisaltyy-alakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikki(koodisto: string, haku?: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling kaikki.');
            }
            const localVarPath = `/api/koodisto/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (haku !== undefined) {
                localVarQueryParameter['haku'] = haku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikkiSivutettuna(koodisto: string, haku?: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling kaikkiSivutettuna.');
            }
            const localVarPath = `/api/koodisto/sivutettu/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (haku !== undefined) {
                localVarQueryParameter['haku'] = haku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {LokalisoituTekstiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lisaaUusiKoodi(koodisto: string, body?: LokalisoituTekstiDto, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling lisaaUusiKoodi.');
            }
            const localVarPath = `/api/koodisto/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksi(koodisto: string, koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling yksi.');
            }
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling yksi.');
            }
            const localVarPath = `/api/koodisto/{koodisto}/{koodi}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)))
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ylarelaatio(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling ylarelaatio.');
            }
            const localVarPath = `/api/koodisto/relaatio/sisaltyy-ylakoodit/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KoodistoApi - functional programming interface
 * @export
 */
export const KoodistoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alarelaatio(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistoApiAxiosParamCreator(configuration).alarelaatio(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikki(koodisto: string, haku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistoApiAxiosParamCreator(configuration).kaikki(koodisto, haku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikkiSivutettuna(koodisto: string, haku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageKoodistoKoodiDto> {
            const localVarAxiosArgs = KoodistoApiAxiosParamCreator(configuration).kaikkiSivutettuna(koodisto, haku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {LokalisoituTekstiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lisaaUusiKoodi(koodisto: string, body?: LokalisoituTekstiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoodistoKoodiDto> {
            const localVarAxiosArgs = KoodistoApiAxiosParamCreator(configuration).lisaaUusiKoodi(koodisto, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksi(koodisto: string, koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KoodistoKoodiDto> {
            const localVarAxiosArgs = KoodistoApiAxiosParamCreator(configuration).yksi(koodisto, koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ylarelaatio(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodistoKoodiDto>> {
            const localVarAxiosArgs = KoodistoApiAxiosParamCreator(configuration).ylarelaatio(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KoodistoApi - factory interface
 * @export
 */
export const KoodistoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alarelaatio(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistoApiFp(configuration).alarelaatio(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikki(koodisto: string, haku?: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistoApiFp(configuration).kaikki(koodisto, haku, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kaikkiSivutettuna(koodisto: string, haku?: string, options?: any): AxiosPromise<PageKoodistoKoodiDto> {
            return KoodistoApiFp(configuration).kaikkiSivutettuna(koodisto, haku, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {LokalisoituTekstiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lisaaUusiKoodi(koodisto: string, body?: LokalisoituTekstiDto, options?: any): AxiosPromise<KoodistoKoodiDto> {
            return KoodistoApiFp(configuration).lisaaUusiKoodi(koodisto, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodisto 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yksi(koodisto: string, koodi: string, options?: any): AxiosPromise<KoodistoKoodiDto> {
            return KoodistoApiFp(configuration).yksi(koodisto, koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ylarelaatio(koodi: string, options?: any): AxiosPromise<Array<KoodistoKoodiDto>> {
            return KoodistoApiFp(configuration).ylarelaatio(koodi, options)(axios, basePath);
        },
    };
};

/**
 * KoodistoApi - object-oriented interface
 * @export
 * @class KoodistoApi
 * @extends {BaseAPI}
 */
export class KoodistoApi extends BaseAPI {
    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistoApi
     */
    public alarelaatio(koodi: string, options?: any) {
        return KoodistoApiFp(this.configuration).alarelaatio(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} [haku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistoApi
     */
    public kaikki(koodisto: string, haku?: string, options?: any) {
        return KoodistoApiFp(this.configuration).kaikki(koodisto, haku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} [haku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistoApi
     */
    public kaikkiSivutettuna(koodisto: string, haku?: string, options?: any) {
        return KoodistoApiFp(this.configuration).kaikkiSivutettuna(koodisto, haku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {LokalisoituTekstiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistoApi
     */
    public lisaaUusiKoodi(koodisto: string, body?: LokalisoituTekstiDto, options?: any) {
        return KoodistoApiFp(this.configuration).lisaaUusiKoodi(koodisto, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodisto 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistoApi
     */
    public yksi(koodisto: string, koodi: string, options?: any) {
        return KoodistoApiFp(this.configuration).yksi(koodisto, koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KoodistoApi
     */
    public ylarelaatio(koodi: string, options?: any) {
        return KoodistoApiFp(this.configuration).ylarelaatio(koodi, options)(this.axios, this.basePath);
    }

}


/**
 * LiitetiedostotApi - axios parameter creator
 * @export
 */
export const LiitetiedostotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(perusteId: number, id: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling _delete.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kuvat/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKuvat(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAllKuvat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kuvat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLiitteet(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAllLiitteet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/liitteet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisuLiite(perusteId: number, fileName: string, If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaisuLiite.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getJulkaisuLiite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/julkaisu/liitteet/{fileName}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKuva(perusteId: number, fileName: string, If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKuva.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getKuva.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kuvat/{fileName}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiite(perusteId: number, fileName: string, If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getLiite.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getLiite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/liitteet/{fileName}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} liiteId 
         * @param {string} lisatieto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaLisatieto(perusteId: number, liiteId: string, lisatieto: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling paivitaLisatieto.');
            }
            // verify required parameter 'liiteId' is not null or undefined
            if (liiteId === null || liiteId === undefined) {
                throw new RequiredError('liiteId','Required parameter liiteId was null or undefined when calling paivitaLisatieto.');
            }
            // verify required parameter 'lisatieto' is not null or undefined
            if (lisatieto === null || lisatieto === undefined) {
                throw new RequiredError('lisatieto','Required parameter lisatieto was null or undefined when calling paivitaLisatieto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lisatieto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (liiteId !== undefined) {
                localVarQueryParameter['liiteId'] = liiteId;
            }

            if (lisatieto !== undefined) {
                localVarQueryParameter['lisatieto'] = lisatieto;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadKuva(perusteId: number, nimi: string, file: Ref, width: number, height: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling uploadKuva.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling uploadKuva.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadKuva.');
            }
            // verify required parameter 'width' is not null or undefined
            if (width === null || width === undefined) {
                throw new RequiredError('width','Required parameter width was null or undefined when calling uploadKuva.');
            }
            // verify required parameter 'height' is not null or undefined
            if (height === null || height === undefined) {
                throw new RequiredError('height','Required parameter height was null or undefined when calling uploadKuva.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kuvat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {string} tyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLiite(perusteId: number, nimi: string, file: Ref, tyyppi: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling uploadLiite.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling uploadLiite.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadLiite.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling uploadLiite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/liitteet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {string} file 
         * @param {string} tyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLiiteBase64(perusteId: number, nimi: string, file: string, tyyppi: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling uploadLiiteBase64.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling uploadLiiteBase64.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadLiiteBase64.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling uploadLiiteBase64.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/liitteet/b64`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiitetiedostotApi - functional programming interface
 * @export
 */
export const LiitetiedostotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(perusteId: number, id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration)._delete(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKuvat(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiiteDto>> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getAllKuvat(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLiitteet(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiiteDto>> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getAllLiitteet(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisuLiite(perusteId: number, fileName: string, If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getJulkaisuLiite(perusteId, fileName, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKuva(perusteId: number, fileName: string, If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getKuva(perusteId, fileName, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiite(perusteId: number, fileName: string, If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).getLiite(perusteId, fileName, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} liiteId 
         * @param {string} lisatieto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaLisatieto(perusteId: number, liiteId: string, lisatieto: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).paivitaLisatieto(perusteId, liiteId, lisatieto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadKuva(perusteId: number, nimi: string, file: Ref, width: number, height: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).uploadKuva(perusteId, nimi, file, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {string} tyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLiite(perusteId: number, nimi: string, file: Ref, tyyppi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).uploadLiite(perusteId, nimi, file, tyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {string} file 
         * @param {string} tyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLiiteBase64(perusteId: number, nimi: string, file: string, tyyppi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = LiitetiedostotApiAxiosParamCreator(configuration).uploadLiiteBase64(perusteId, nimi, file, tyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LiitetiedostotApi - factory interface
 * @export
 */
export const LiitetiedostotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(perusteId: number, id: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration)._delete(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKuvat(perusteId: number, options?: any): AxiosPromise<Array<LiiteDto>> {
            return LiitetiedostotApiFp(configuration).getAllKuvat(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLiitteet(perusteId: number, options?: any): AxiosPromise<Array<LiiteDto>> {
            return LiitetiedostotApiFp(configuration).getAllLiitteet(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisuLiite(perusteId: number, fileName: string, If_None_Match?: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).getJulkaisuLiite(perusteId, fileName, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKuva(perusteId: number, fileName: string, If_None_Match?: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).getKuva(perusteId, fileName, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiite(perusteId: number, fileName: string, If_None_Match?: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).getLiite(perusteId, fileName, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} liiteId 
         * @param {string} lisatieto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaLisatieto(perusteId: number, liiteId: string, lisatieto: string, options?: any): AxiosPromise<void> {
            return LiitetiedostotApiFp(configuration).paivitaLisatieto(perusteId, liiteId, lisatieto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadKuva(perusteId: number, nimi: string, file: Ref, width: number, height: number, options?: any): AxiosPromise<string> {
            return LiitetiedostotApiFp(configuration).uploadKuva(perusteId, nimi, file, width, height, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {Ref} file 
         * @param {string} tyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLiite(perusteId: number, nimi: string, file: Ref, tyyppi: string, options?: any): AxiosPromise<string> {
            return LiitetiedostotApiFp(configuration).uploadLiite(perusteId, nimi, file, tyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} nimi 
         * @param {string} file 
         * @param {string} tyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLiiteBase64(perusteId: number, nimi: string, file: string, tyyppi: string, options?: any): AxiosPromise<string> {
            return LiitetiedostotApiFp(configuration).uploadLiiteBase64(perusteId, nimi, file, tyyppi, options)(axios, basePath);
        },
    };
};

/**
 * LiitetiedostotApi - object-oriented interface
 * @export
 * @class LiitetiedostotApi
 * @extends {BaseAPI}
 */
export class LiitetiedostotApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public _delete(perusteId: number, id: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration)._delete(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getAllKuvat(perusteId: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getAllKuvat(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getAllLiitteet(perusteId: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getAllLiitteet(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} fileName 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getJulkaisuLiite(perusteId: number, fileName: string, If_None_Match?: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getJulkaisuLiite(perusteId, fileName, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} fileName 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getKuva(perusteId: number, fileName: string, If_None_Match?: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getKuva(perusteId, fileName, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} fileName 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public getLiite(perusteId: number, fileName: string, If_None_Match?: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).getLiite(perusteId, fileName, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} liiteId 
     * @param {string} lisatieto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public paivitaLisatieto(perusteId: number, liiteId: string, lisatieto: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).paivitaLisatieto(perusteId, liiteId, lisatieto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} nimi 
     * @param {Ref} file 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public uploadKuva(perusteId: number, nimi: string, file: Ref, width: number, height: number, options?: any) {
        return LiitetiedostotApiFp(this.configuration).uploadKuva(perusteId, nimi, file, width, height, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} nimi 
     * @param {Ref} file 
     * @param {string} tyyppi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public uploadLiite(perusteId: number, nimi: string, file: Ref, tyyppi: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).uploadLiite(perusteId, nimi, file, tyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} nimi 
     * @param {string} file 
     * @param {string} tyyppi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiitetiedostotApi
     */
    public uploadLiiteBase64(perusteId: number, nimi: string, file: string, tyyppi: string, options?: any) {
        return LiitetiedostotApiFp(this.configuration).uploadLiiteBase64(perusteId, nimi, file, tyyppi, options)(this.axios, this.basePath);
    }

}


/**
 * LokalisointiApi - axios parameter creator
 * @export
 */
export const LokalisointiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKaannokset(locale: string, options: any = {}): RequestArgs {
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError('locale','Required parameter locale was null or undefined when calling getAllKaannokset.');
            }
            const localVarPath = `/api/lokalisointi/eperusteet-opintopolku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEperusteKaannokset(options: any = {}): RequestArgs {
            const localVarPath = `/api/lokalisointi/kaannokset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} palvelu 
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPalveluKaannokset(palvelu: string, kieli: string, options: any = {}): RequestArgs {
            // verify required parameter 'palvelu' is not null or undefined
            if (palvelu === null || palvelu === undefined) {
                throw new RequiredError('palvelu','Required parameter palvelu was null or undefined when calling getPalveluKaannokset.');
            }
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getPalveluKaannokset.');
            }
            const localVarPath = `/api/lokalisointi/kaannokset/{palvelu}/{kieli}`
                .replace(`{${"palvelu"}}`, encodeURIComponent(String(palvelu)))
                .replace(`{${"kieli"}}`, encodeURIComponent(String(kieli)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LokalisointiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKaannokset(body?: Array<LokalisointiDto>, options: any = {}): RequestArgs {
            const localVarPath = `/api/lokalisointi/kaannokset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LokalisointiApi - functional programming interface
 * @export
 */
export const LokalisointiApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKaannokset(locale: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LokalisointiDto>> {
            const localVarAxiosArgs = LokalisointiApiAxiosParamCreator(configuration).getAllKaannokset(locale, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEperusteKaannokset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LokalisointiDto>> {
            const localVarAxiosArgs = LokalisointiApiAxiosParamCreator(configuration).getEperusteKaannokset(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} palvelu 
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPalveluKaannokset(palvelu: string, kieli: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LokalisointiDto>> {
            const localVarAxiosArgs = LokalisointiApiAxiosParamCreator(configuration).getPalveluKaannokset(palvelu, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<LokalisointiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKaannokset(body?: Array<LokalisointiDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = LokalisointiApiAxiosParamCreator(configuration).updateKaannokset(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LokalisointiApi - factory interface
 * @export
 */
export const LokalisointiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllKaannokset(locale: string, options?: any): AxiosPromise<Array<LokalisointiDto>> {
            return LokalisointiApiFp(configuration).getAllKaannokset(locale, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEperusteKaannokset(options?: any): AxiosPromise<Array<LokalisointiDto>> {
            return LokalisointiApiFp(configuration).getEperusteKaannokset(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} palvelu 
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPalveluKaannokset(palvelu: string, kieli: string, options?: any): AxiosPromise<Array<LokalisointiDto>> {
            return LokalisointiApiFp(configuration).getPalveluKaannokset(palvelu, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<LokalisointiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKaannokset(body?: Array<LokalisointiDto>, options?: any): AxiosPromise<void> {
            return LokalisointiApiFp(configuration).updateKaannokset(body, options)(axios, basePath);
        },
    };
};

/**
 * LokalisointiApi - object-oriented interface
 * @export
 * @class LokalisointiApi
 * @extends {BaseAPI}
 */
export class LokalisointiApi extends BaseAPI {
    /**
     * 
     * @param {string} locale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokalisointiApi
     */
    public getAllKaannokset(locale: string, options?: any) {
        return LokalisointiApiFp(this.configuration).getAllKaannokset(locale, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokalisointiApi
     */
    public getEperusteKaannokset(options?: any) {
        return LokalisointiApiFp(this.configuration).getEperusteKaannokset(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} palvelu 
     * @param {string} kieli 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokalisointiApi
     */
    public getPalveluKaannokset(palvelu: string, kieli: string, options?: any) {
        return LokalisointiApiFp(this.configuration).getPalveluKaannokset(palvelu, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<LokalisointiDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LokalisointiApi
     */
    public updateKaannokset(body?: Array<LokalisointiDto>, options?: any) {
        return LokalisointiApiFp(this.configuration).updateKaannokset(body, options)(this.axios, this.basePath);
    }

}


/**
 * Lops2019Api - axios parameter creator
 * @export
 */
export const Lops2019ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {Lops2019OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaine(perusteId: number, body?: Lops2019OppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/uusi`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisalto(perusteId: number, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addSisalto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/sisalto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteModuuli.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling deleteModuuli.');
            }
            // verify required parameter 'moduuliId' is not null or undefined
            if (moduuliId === null || moduuliId === undefined) {
                throw new RequiredError('moduuliId','Required parameter moduuliId was null or undefined when calling deleteModuuli.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{oppiaineId}/moduulit/{moduuliId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"moduuliId"}}`, encodeURIComponent(String(moduuliId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaine(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSisalto(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSisalto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/sisalto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaajaAlainenOsaaminenKokonaisuus(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getLaajaAlainenOsaaminenKokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/laajaalaiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getModuuli.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getModuuli.');
            }
            // verify required parameter 'moduuliId' is not null or undefined
            if (moduuliId === null || moduuliId === undefined) {
                throw new RequiredError('moduuliId','Required parameter moduuliId was null or undefined when calling getModuuli.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{oppiaineId}/moduulit/{moduuliId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"moduuliId"}}`, encodeURIComponent(String(moduuliId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaine(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVersioData(perusteId: number, oppiaineId: number, rev: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaineenVersioData.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getOppiaineenVersioData.');
            }
            // verify required parameter 'rev' is not null or undefined
            if (rev === null || rev === undefined) {
                throw new RequiredError('rev','Required parameter rev was null or undefined when calling getOppiaineenVersioData.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{oppiaineId}/versiot/{rev}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"rev"}}`, encodeURIComponent(String(rev)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVersiot(perusteId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaineenVersiot.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getOppiaineenVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{oppiaineId}/versiot`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineet(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaineet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaineenModuulit(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling palautaOppiaineenModuulit.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling palautaOppiaineenModuulit.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{id}/palautamoduulit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaineenSisalto(perusteId: number, oppiaineId: number, rev: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling palautaOppiaineenSisalto.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling palautaOppiaineenSisalto.');
            }
            // verify required parameter 'rev' is not null or undefined
            if (rev === null || rev === undefined) {
                throw new RequiredError('rev','Required parameter rev was null or undefined when calling palautaOppiaineenSisalto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{oppiaineId}/versiot/{rev}/palauta`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"rev"}}`, encodeURIComponent(String(rev)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<Lops2019OppiaineDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortOppiaineet(perusteId: number, body?: Array<Lops2019OppiaineDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling sortOppiaineet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Lops2019LaajaAlainenOsaaminenKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLaajaAlainenOsaaminenKokonaisuus(perusteId: number, body?: Lops2019LaajaAlainenOsaaminenKokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateLaajaAlainenOsaaminenKokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/laajaalaiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {Lops2019ModuuliDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuuli(perusteId: number, oppiaineId: number, moduuliId: number, body?: Lops2019ModuuliDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateModuuli.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateModuuli.');
            }
            // verify required parameter 'moduuliId' is not null or undefined
            if (moduuliId === null || moduuliId === undefined) {
                throw new RequiredError('moduuliId','Required parameter moduuliId was null or undefined when calling updateModuuli.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{oppiaineId}/moduulit/{moduuliId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"moduuliId"}}`, encodeURIComponent(String(moduuliId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Lops2019OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaine(perusteId: number, id: number, body?: Lops2019OppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lops2019/oppiaineet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Lops2019Api - functional programming interface
 * @export
 */
export const Lops2019ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {Lops2019OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaine(perusteId: number, body?: Lops2019OppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OppiaineDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).addOppiaine(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisalto(perusteId: number, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).addSisalto(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).deleteModuuli(perusteId, oppiaineId, moduuliId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaine(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).deleteOppiaine(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSisalto(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).deleteSisalto(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaajaAlainenOsaaminenKokonaisuus(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019LaajaAlainenOsaaminenKokonaisuusDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getLaajaAlainenOsaaminenKokonaisuus(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019ModuuliDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getModuuli(perusteId, oppiaineId, moduuliId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaine(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OppiaineDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getOppiaine(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVersioData(perusteId: number, oppiaineId: number, rev: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OppiaineKaikkiDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getOppiaineenVersioData(perusteId, oppiaineId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVersiot(perusteId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Revision>> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getOppiaineenVersiot(perusteId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineet(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019OppiaineDto>> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).getOppiaineet(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaineenModuulit(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).palautaOppiaineenModuulit(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaineenSisalto(perusteId: number, oppiaineId: number, rev: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).palautaOppiaineenSisalto(perusteId, oppiaineId, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<Lops2019OppiaineDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortOppiaineet(perusteId: number, body?: Array<Lops2019OppiaineDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lops2019OppiaineDto>> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).sortOppiaineet(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Lops2019LaajaAlainenOsaaminenKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLaajaAlainenOsaaminenKokonaisuus(perusteId: number, body?: Lops2019LaajaAlainenOsaaminenKokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019LaajaAlainenOsaaminenKokonaisuusDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).updateLaajaAlainenOsaaminenKokonaisuus(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {Lops2019ModuuliDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuuli(perusteId: number, oppiaineId: number, moduuliId: number, body?: Lops2019ModuuliDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019ModuuliDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).updateModuuli(perusteId, oppiaineId, moduuliId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Lops2019OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaine(perusteId: number, id: number, body?: Lops2019OppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lops2019OppiaineDto> {
            const localVarAxiosArgs = Lops2019ApiAxiosParamCreator(configuration).updateOppiaine(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * Lops2019Api - factory interface
 * @export
 */
export const Lops2019ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {Lops2019OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaine(perusteId: number, body?: Lops2019OppiaineDto, options?: any): AxiosPromise<Lops2019OppiaineDto> {
            return Lops2019ApiFp(configuration).addOppiaine(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisalto(perusteId: number, body?: Matala, options?: any): AxiosPromise<Matala> {
            return Lops2019ApiFp(configuration).addSisalto(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options?: any): AxiosPromise<void> {
            return Lops2019ApiFp(configuration).deleteModuuli(perusteId, oppiaineId, moduuliId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaine(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return Lops2019ApiFp(configuration).deleteOppiaine(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSisalto(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return Lops2019ApiFp(configuration).deleteSisalto(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLaajaAlainenOsaaminenKokonaisuus(perusteId: number, options?: any): AxiosPromise<Lops2019LaajaAlainenOsaaminenKokonaisuusDto> {
            return Lops2019ApiFp(configuration).getLaajaAlainenOsaaminenKokonaisuus(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options?: any): AxiosPromise<Lops2019ModuuliDto> {
            return Lops2019ApiFp(configuration).getModuuli(perusteId, oppiaineId, moduuliId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaine(perusteId: number, id: number, options?: any): AxiosPromise<Lops2019OppiaineDto> {
            return Lops2019ApiFp(configuration).getOppiaine(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVersioData(perusteId: number, oppiaineId: number, rev: number, options?: any): AxiosPromise<Lops2019OppiaineKaikkiDto> {
            return Lops2019ApiFp(configuration).getOppiaineenVersioData(perusteId, oppiaineId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVersiot(perusteId: number, oppiaineId: number, options?: any): AxiosPromise<Array<Revision>> {
            return Lops2019ApiFp(configuration).getOppiaineenVersiot(perusteId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineet(perusteId: number, options?: any): AxiosPromise<Array<Lops2019OppiaineDto>> {
            return Lops2019ApiFp(configuration).getOppiaineet(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaineenModuulit(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return Lops2019ApiFp(configuration).palautaOppiaineenModuulit(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} rev 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautaOppiaineenSisalto(perusteId: number, oppiaineId: number, rev: number, options?: any): AxiosPromise<void> {
            return Lops2019ApiFp(configuration).palautaOppiaineenSisalto(perusteId, oppiaineId, rev, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<Lops2019OppiaineDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortOppiaineet(perusteId: number, body?: Array<Lops2019OppiaineDto>, options?: any): AxiosPromise<Array<Lops2019OppiaineDto>> {
            return Lops2019ApiFp(configuration).sortOppiaineet(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Lops2019LaajaAlainenOsaaminenKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLaajaAlainenOsaaminenKokonaisuus(perusteId: number, body?: Lops2019LaajaAlainenOsaaminenKokonaisuusDto, options?: any): AxiosPromise<Lops2019LaajaAlainenOsaaminenKokonaisuusDto> {
            return Lops2019ApiFp(configuration).updateLaajaAlainenOsaaminenKokonaisuus(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} moduuliId 
         * @param {Lops2019ModuuliDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModuuli(perusteId: number, oppiaineId: number, moduuliId: number, body?: Lops2019ModuuliDto, options?: any): AxiosPromise<Lops2019ModuuliDto> {
            return Lops2019ApiFp(configuration).updateModuuli(perusteId, oppiaineId, moduuliId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Lops2019OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaine(perusteId: number, id: number, body?: Lops2019OppiaineDto, options?: any): AxiosPromise<Lops2019OppiaineDto> {
            return Lops2019ApiFp(configuration).updateOppiaine(perusteId, id, body, options)(axios, basePath);
        },
    };
};

/**
 * Lops2019Api - object-oriented interface
 * @export
 * @class Lops2019Api
 * @extends {BaseAPI}
 */
export class Lops2019Api extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {Lops2019OppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public addOppiaine(perusteId: number, body?: Lops2019OppiaineDto, options?: any) {
        return Lops2019ApiFp(this.configuration).addOppiaine(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public addSisalto(perusteId: number, body?: Matala, options?: any) {
        return Lops2019ApiFp(this.configuration).addSisalto(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} moduuliId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public deleteModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).deleteModuuli(perusteId, oppiaineId, moduuliId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public deleteOppiaine(perusteId: number, id: number, options?: any) {
        return Lops2019ApiFp(this.configuration).deleteOppiaine(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public deleteSisalto(perusteId: number, id: number, options?: any) {
        return Lops2019ApiFp(this.configuration).deleteSisalto(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getLaajaAlainenOsaaminenKokonaisuus(perusteId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getLaajaAlainenOsaaminenKokonaisuus(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} moduuliId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getModuuli(perusteId: number, oppiaineId: number, moduuliId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getModuuli(perusteId, oppiaineId, moduuliId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getOppiaine(perusteId: number, id: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getOppiaine(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} rev 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getOppiaineenVersioData(perusteId: number, oppiaineId: number, rev: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getOppiaineenVersioData(perusteId, oppiaineId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getOppiaineenVersiot(perusteId: number, oppiaineId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getOppiaineenVersiot(perusteId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public getOppiaineet(perusteId: number, options?: any) {
        return Lops2019ApiFp(this.configuration).getOppiaineet(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public palautaOppiaineenModuulit(perusteId: number, id: number, options?: any) {
        return Lops2019ApiFp(this.configuration).palautaOppiaineenModuulit(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} rev 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public palautaOppiaineenSisalto(perusteId: number, oppiaineId: number, rev: number, options?: any) {
        return Lops2019ApiFp(this.configuration).palautaOppiaineenSisalto(perusteId, oppiaineId, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Array<Lops2019OppiaineDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public sortOppiaineet(perusteId: number, body?: Array<Lops2019OppiaineDto>, options?: any) {
        return Lops2019ApiFp(this.configuration).sortOppiaineet(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Lops2019LaajaAlainenOsaaminenKokonaisuusDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public updateLaajaAlainenOsaaminenKokonaisuus(perusteId: number, body?: Lops2019LaajaAlainenOsaaminenKokonaisuusDto, options?: any) {
        return Lops2019ApiFp(this.configuration).updateLaajaAlainenOsaaminenKokonaisuus(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} moduuliId 
     * @param {Lops2019ModuuliDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public updateModuuli(perusteId: number, oppiaineId: number, moduuliId: number, body?: Lops2019ModuuliDto, options?: any) {
        return Lops2019ApiFp(this.configuration).updateModuuli(perusteId, oppiaineId, moduuliId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {Lops2019OppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Lops2019Api
     */
    public updateOppiaine(perusteId: number, id: number, body?: Lops2019OppiaineDto, options?: any) {
        return Lops2019ApiFp(this.configuration).updateOppiaine(perusteId, id, body, options)(this.axios, this.basePath);
    }

}


/**
 * LukioperusteenJulkisetTiedotApi - axios parameter creator
 * @export
 */
export const LukioperusteenJulkisetTiedotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiainePuuRakenne(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiainePuuRakenne.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/lukiokoulutus/julkinen/oppiainerakenne`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LukioperusteenJulkisetTiedotApi - functional programming interface
 * @export
 */
export const LukioperusteenJulkisetTiedotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiainePuuRakenne(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukioOppiainePuuDto> {
            const localVarAxiosArgs = LukioperusteenJulkisetTiedotApiAxiosParamCreator(configuration).getOppiainePuuRakenne(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LukioperusteenJulkisetTiedotApi - factory interface
 * @export
 */
export const LukioperusteenJulkisetTiedotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiainePuuRakenne(perusteId: number, options?: any): AxiosPromise<LukioOppiainePuuDto> {
            return LukioperusteenJulkisetTiedotApiFp(configuration).getOppiainePuuRakenne(perusteId, options)(axios, basePath);
        },
    };
};

/**
 * LukioperusteenJulkisetTiedotApi - object-oriented interface
 * @export
 * @class LukioperusteenJulkisetTiedotApi
 * @extends {BaseAPI}
 */
export class LukioperusteenJulkisetTiedotApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LukioperusteenJulkisetTiedotApi
     */
    public getOppiainePuuRakenne(perusteId: number, options?: any) {
        return LukioperusteenJulkisetTiedotApiFp(this.configuration).getOppiainePuuRakenne(perusteId, options)(this.axios, this.basePath);
    }

}


/**
 * MaarayksetApi - axios parameter creator
 * @export
 */
export const MaarayksetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMaarays(body?: MaaraysDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/maaraykset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [perusteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaarays(id: number, perusteId?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMaarays.');
            }
            const localVarPath = `/api/maaraykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsiasanat(options: any = {}): RequestArgs {
            const localVarPath = `/api/maaraykset/asiasanat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE'} [tyyppi] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [paattynyt] 
         * @param {boolean} [luonnos] 
         * @param {boolean} [julkaistu] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [jarjestysTapa] 
         * @param {string} [jarjestys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraykset(nimi?: string, kieli?: string, tyyppi?: 'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE', koulutustyyppi?: Array<string>, tuleva?: boolean, voimassa?: boolean, paattynyt?: boolean, luonnos?: boolean, julkaistu?: boolean, sivu?: number, sivukoko?: number, jarjestysTapa?: string, jarjestys?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/maaraykset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (voimassa !== undefined) {
                localVarQueryParameter['voimassa'] = voimassa;
            }

            if (paattynyt !== undefined) {
                localVarQueryParameter['paattynyt'] = paattynyt;
            }

            if (luonnos !== undefined) {
                localVarQueryParameter['luonnos'] = luonnos;
            }

            if (julkaistu !== undefined) {
                localVarQueryParameter['julkaistu'] = julkaistu;
            }

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (jarjestysTapa !== undefined) {
                localVarQueryParameter['jarjestysTapa'] = jarjestysTapa;
            }

            if (jarjestys !== undefined) {
                localVarQueryParameter['jarjestys'] = jarjestys;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarayksetNimet(options: any = {}): RequestArgs {
            const localVarPath = `/api/maaraykset/nimet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarayksienKoulutustyypit(options: any = {}): RequestArgs {
            const localVarPath = `/api/maaraykset/koulutustyypit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarays(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMaarays.');
            }
            const localVarPath = `/api/maaraykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraysLiite(fileName: string, If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getMaaraysLiite.');
            }
            const localVarPath = `/api/maaraykset/liite/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraysPerusteella(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getMaaraysPerusteella.');
            }
            const localVarPath = `/api/maaraykset/peruste/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenJulkaistutMuutosmaaraykset(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenJulkaistutMuutosmaaraykset.');
            }
            const localVarPath = `/api/maaraykset/peruste/{perusteId}/muutosmaaraykset/julkaistut`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuutosmaaraykset(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenMuutosmaaraykset.');
            }
            const localVarPath = `/api/maaraykset/peruste/{perusteId}/muutosmaaraykset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaarays(id: number, body?: MaaraysDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMaarays.');
            }
            const localVarPath = `/api/maaraykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaaraysLiiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMaaraysLiite(body?: MaaraysLiiteDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/maaraykset/liite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaarayksetApi - functional programming interface
 * @export
 */
export const MaarayksetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMaarays(body?: MaaraysDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaaraysDto> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).addMaarays(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [perusteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaarays(id: number, perusteId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).deleteMaarays(id, perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsiasanat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getAsiasanat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE'} [tyyppi] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [paattynyt] 
         * @param {boolean} [luonnos] 
         * @param {boolean} [julkaistu] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [jarjestysTapa] 
         * @param {string} [jarjestys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraykset(nimi?: string, kieli?: string, tyyppi?: 'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE', koulutustyyppi?: Array<string>, tuleva?: boolean, voimassa?: boolean, paattynyt?: boolean, luonnos?: boolean, julkaistu?: boolean, sivu?: number, sivukoko?: number, jarjestysTapa?: string, jarjestys?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMaaraysDto> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getMaaraykset(nimi, kieli, tyyppi, koulutustyyppi, tuleva, voimassa, paattynyt, luonnos, julkaistu, sivu, sivukoko, jarjestysTapa, jarjestys, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarayksetNimet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaaraysKevytDto>> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getMaarayksetNimet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarayksienKoulutustyypit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getMaarayksienKoulutustyypit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarays(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaaraysDto> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getMaarays(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraysLiite(fileName: string, If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getMaaraysLiite(fileName, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraysPerusteella(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaaraysDto> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getMaaraysPerusteella(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenJulkaistutMuutosmaaraykset(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaaraysDto>> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getPerusteenJulkaistutMuutosmaaraykset(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuutosmaaraykset(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaaraysDto>> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).getPerusteenMuutosmaaraykset(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaarays(id: number, body?: MaaraysDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaaraysDto> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).updateMaarays(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MaaraysLiiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMaaraysLiite(body?: MaaraysLiiteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MaarayksetApiAxiosParamCreator(configuration).uploadMaaraysLiite(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MaarayksetApi - factory interface
 * @export
 */
export const MaarayksetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMaarays(body?: MaaraysDto, options?: any): AxiosPromise<MaaraysDto> {
            return MaarayksetApiFp(configuration).addMaarays(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [perusteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaarays(id: number, perusteId?: number, options?: any): AxiosPromise<void> {
            return MaarayksetApiFp(configuration).deleteMaarays(id, perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsiasanat(options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return MaarayksetApiFp(configuration).getAsiasanat(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [nimi] 
         * @param {string} [kieli] 
         * @param {'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE'} [tyyppi] 
         * @param {Array<string>} [koulutustyyppi] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [paattynyt] 
         * @param {boolean} [luonnos] 
         * @param {boolean} [julkaistu] 
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [jarjestysTapa] 
         * @param {string} [jarjestys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraykset(nimi?: string, kieli?: string, tyyppi?: 'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE', koulutustyyppi?: Array<string>, tuleva?: boolean, voimassa?: boolean, paattynyt?: boolean, luonnos?: boolean, julkaistu?: boolean, sivu?: number, sivukoko?: number, jarjestysTapa?: string, jarjestys?: string, options?: any): AxiosPromise<PageMaaraysDto> {
            return MaarayksetApiFp(configuration).getMaaraykset(nimi, kieli, tyyppi, koulutustyyppi, tuleva, voimassa, paattynyt, luonnos, julkaistu, sivu, sivukoko, jarjestysTapa, jarjestys, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarayksetNimet(options?: any): AxiosPromise<Array<MaaraysKevytDto>> {
            return MaarayksetApiFp(configuration).getMaarayksetNimet(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarayksienKoulutustyypit(options?: any): AxiosPromise<Array<string>> {
            return MaarayksetApiFp(configuration).getMaarayksienKoulutustyypit(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaarays(id: number, options?: any): AxiosPromise<MaaraysDto> {
            return MaarayksetApiFp(configuration).getMaarays(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraysLiite(fileName: string, If_None_Match?: string, options?: any): AxiosPromise<void> {
            return MaarayksetApiFp(configuration).getMaaraysLiite(fileName, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaaraysPerusteella(perusteId: number, options?: any): AxiosPromise<MaaraysDto> {
            return MaarayksetApiFp(configuration).getMaaraysPerusteella(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenJulkaistutMuutosmaaraykset(perusteId: number, options?: any): AxiosPromise<Array<MaaraysDto>> {
            return MaarayksetApiFp(configuration).getPerusteenJulkaistutMuutosmaaraykset(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuutosmaaraykset(perusteId: number, options?: any): AxiosPromise<Array<MaaraysDto>> {
            return MaarayksetApiFp(configuration).getPerusteenMuutosmaaraykset(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {MaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaarays(id: number, body?: MaaraysDto, options?: any): AxiosPromise<MaaraysDto> {
            return MaarayksetApiFp(configuration).updateMaarays(id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {MaaraysLiiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMaaraysLiite(body?: MaaraysLiiteDto, options?: any): AxiosPromise<string> {
            return MaarayksetApiFp(configuration).uploadMaaraysLiite(body, options)(axios, basePath);
        },
    };
};

/**
 * MaarayksetApi - object-oriented interface
 * @export
 * @class MaarayksetApi
 * @extends {BaseAPI}
 */
export class MaarayksetApi extends BaseAPI {
    /**
     * 
     * @param {MaaraysDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public addMaarays(body?: MaaraysDto, options?: any) {
        return MaarayksetApiFp(this.configuration).addMaarays(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [perusteId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public deleteMaarays(id: number, perusteId?: number, options?: any) {
        return MaarayksetApiFp(this.configuration).deleteMaarays(id, perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getAsiasanat(options?: any) {
        return MaarayksetApiFp(this.configuration).getAsiasanat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [nimi] 
     * @param {string} [kieli] 
     * @param {'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE'} [tyyppi] 
     * @param {Array<string>} [koulutustyyppi] 
     * @param {boolean} [tuleva] 
     * @param {boolean} [voimassa] 
     * @param {boolean} [paattynyt] 
     * @param {boolean} [luonnos] 
     * @param {boolean} [julkaistu] 
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [jarjestysTapa] 
     * @param {string} [jarjestys] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getMaaraykset(nimi?: string, kieli?: string, tyyppi?: 'OPETUSHALLITUKSEN_MUU' | 'AMMATILLINEN_MUU' | 'PERUSTE', koulutustyyppi?: Array<string>, tuleva?: boolean, voimassa?: boolean, paattynyt?: boolean, luonnos?: boolean, julkaistu?: boolean, sivu?: number, sivukoko?: number, jarjestysTapa?: string, jarjestys?: string, options?: any) {
        return MaarayksetApiFp(this.configuration).getMaaraykset(nimi, kieli, tyyppi, koulutustyyppi, tuleva, voimassa, paattynyt, luonnos, julkaistu, sivu, sivukoko, jarjestysTapa, jarjestys, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getMaarayksetNimet(options?: any) {
        return MaarayksetApiFp(this.configuration).getMaarayksetNimet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getMaarayksienKoulutustyypit(options?: any) {
        return MaarayksetApiFp(this.configuration).getMaarayksienKoulutustyypit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getMaarays(id: number, options?: any) {
        return MaarayksetApiFp(this.configuration).getMaarays(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} fileName 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getMaaraysLiite(fileName: string, If_None_Match?: string, options?: any) {
        return MaarayksetApiFp(this.configuration).getMaaraysLiite(fileName, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getMaaraysPerusteella(perusteId: number, options?: any) {
        return MaarayksetApiFp(this.configuration).getMaaraysPerusteella(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getPerusteenJulkaistutMuutosmaaraykset(perusteId: number, options?: any) {
        return MaarayksetApiFp(this.configuration).getPerusteenJulkaistutMuutosmaaraykset(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public getPerusteenMuutosmaaraykset(perusteId: number, options?: any) {
        return MaarayksetApiFp(this.configuration).getPerusteenMuutosmaaraykset(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {MaaraysDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public updateMaarays(id: number, body?: MaaraysDto, options?: any) {
        return MaarayksetApiFp(this.configuration).updateMaarays(id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {MaaraysLiiteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaarayksetApi
     */
    public uploadMaaraysLiite(body?: MaaraysLiiteDto, options?: any) {
        return MaarayksetApiFp(this.configuration).uploadMaaraysLiite(body, options)(this.axios, this.basePath);
    }

}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMissingOsaamisalakuvaukset(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/puuttuvatosaamisalakuvaukset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options: any = {}): RequestArgs {
            // verify required parameter 'cache' is not null or undefined
            if (cache === null || cache === undefined) {
                throw new RequiredError('cache','Required parameter cache was null or undefined when calling clearCache.');
            }
            const localVarPath = `/api/maintenance/cacheclear/{cache}`
                .replace(`{${"cache"}}`, encodeURIComponent(String(cache)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArvioinninAmmattitaitovaatimukset(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/arvioinninammattitaitovaatimukset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYllapidot(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/yllapito`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYllapito(key: string, options: any = {}): RequestArgs {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getYllapito.');
            }
            const localVarPath = `/api/maintenance/yllapito/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lisaaAmmattitaitovaatimuksetPerusteelle(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling lisaaAmmattitaitovaatimuksetPerusteelle.');
            }
            const localVarPath = `/api/maintenance/lisaaAmmattitaitovaatimukset2019/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maarayksetperusteille(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/maarayksetperusteille`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaAmosaaKoulutustoimijat(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/amosaa/koulutustoimija/paivitys`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushAllAmmattitaitovaatimuksetToKoodisto(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/ammattitaitovaatimuskoodisto`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} julkaisekaikki 
         * @param {string} tyyppi 
         * @param {string} tiedote 
         * @param {string} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki: boolean, tyyppi: string, tiedote: string, koulutustyyppi?: string, options: any = {}): RequestArgs {
            // verify required parameter 'julkaisekaikki' is not null or undefined
            if (julkaisekaikki === null || julkaisekaikki === undefined) {
                throw new RequiredError('julkaisekaikki','Required parameter julkaisekaikki was null or undefined when calling teeJulkaisut.');
            }
            // verify required parameter 'tyyppi' is not null or undefined
            if (tyyppi === null || tyyppi === undefined) {
                throw new RequiredError('tyyppi','Required parameter tyyppi was null or undefined when calling teeJulkaisut.');
            }
            // verify required parameter 'tiedote' is not null or undefined
            if (tiedote === null || tiedote === undefined) {
                throw new RequiredError('tiedote','Required parameter tiedote was null or undefined when calling teeJulkaisut.');
            }
            const localVarPath = `/api/maintenance/julkaisut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (julkaisekaikki !== undefined) {
                localVarQueryParameter['julkaisekaikki'] = julkaisekaikki;
            }

            if (tyyppi !== undefined) {
                localVarQueryParameter['tyyppi'] = tyyppi;
            }

            if (koulutustyyppi !== undefined) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (tiedote !== undefined) {
                localVarQueryParameter['tiedote'] = tiedote;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} tiedote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeMaintenanceJulkaisu(perusteId: number, tiedote: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling teeMaintenanceJulkaisu.');
            }
            // verify required parameter 'tiedote' is not null or undefined
            if (tiedote === null || tiedote === undefined) {
                throw new RequiredError('tiedote','Required parameter tiedote was null or undefined when calling teeMaintenanceJulkaisu.');
            }
            const localVarPath = `/api/maintenance/julkaise/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tiedote !== undefined) {
                localVarQueryParameter['tiedote'] = tiedote;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tuoPeruste(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/import`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<YllapitoDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYllapito(body?: Array<YllapitoDto>, options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/yllapito/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viePeruste(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling viePeruste.');
            }
            const localVarPath = `/api/maintenance/export/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viePerusteJson(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling viePerusteJson.');
            }
            const localVarPath = `/api/maintenance/export/{perusteId}/json`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virheellisetAmmattitaitovaatimukset(options: any = {}): RequestArgs {
            const localVarPath = `/api/maintenance/virheellisetAmmattitaitovaatimukset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMissingOsaamisalakuvaukset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).addMissingOsaamisalakuvaukset(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).clearCache(cache, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArvioinninAmmattitaitovaatimukset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).createArvioinninAmmattitaitovaatimukset(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYllapidot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YllapitoDto>> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).getYllapidot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYllapito(key: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).getYllapito(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lisaaAmmattitaitovaatimuksetPerusteelle(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).lisaaAmmattitaitovaatimuksetPerusteelle(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maarayksetperusteille(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).maarayksetperusteille(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaAmosaaKoulutustoimijat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).paivitaAmosaaKoulutustoimijat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushAllAmmattitaitovaatimuksetToKoodisto(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).pushAllAmmattitaitovaatimuksetToKoodisto(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} julkaisekaikki 
         * @param {string} tyyppi 
         * @param {string} tiedote 
         * @param {string} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki: boolean, tyyppi: string, tiedote: string, koulutustyyppi?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).teeJulkaisut(julkaisekaikki, tyyppi, tiedote, koulutustyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} tiedote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeMaintenanceJulkaisu(perusteId: number, tiedote: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).teeMaintenanceJulkaisu(perusteId, tiedote, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tuoPeruste(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).tuoPeruste(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<YllapitoDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYllapito(body?: Array<YllapitoDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).updateYllapito(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viePeruste(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).viePeruste(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viePerusteJson(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).viePerusteJson(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virheellisetAmmattitaitovaatimukset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParsitutAmmattitaitovaatimukset>> {
            const localVarAxiosArgs = MaintenanceApiAxiosParamCreator(configuration).virheellisetAmmattitaitovaatimukset(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMissingOsaamisalakuvaukset(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).addMissingOsaamisalakuvaukset(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} cache 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(cache: string, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).clearCache(cache, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArvioinninAmmattitaitovaatimukset(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).createArvioinninAmmattitaitovaatimukset(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYllapidot(options?: any): AxiosPromise<Array<YllapitoDto>> {
            return MaintenanceApiFp(configuration).getYllapidot(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYllapito(key: string, options?: any): AxiosPromise<string> {
            return MaintenanceApiFp(configuration).getYllapito(key, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lisaaAmmattitaitovaatimuksetPerusteelle(perusteId: number, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).lisaaAmmattitaitovaatimuksetPerusteelle(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maarayksetperusteille(options?: any): AxiosPromise<string> {
            return MaintenanceApiFp(configuration).maarayksetperusteille(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paivitaAmosaaKoulutustoimijat(options?: any): AxiosPromise<string> {
            return MaintenanceApiFp(configuration).paivitaAmosaaKoulutustoimijat(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushAllAmmattitaitovaatimuksetToKoodisto(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).pushAllAmmattitaitovaatimuksetToKoodisto(options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} julkaisekaikki 
         * @param {string} tyyppi 
         * @param {string} tiedote 
         * @param {string} [koulutustyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeJulkaisut(julkaisekaikki: boolean, tyyppi: string, tiedote: string, koulutustyyppi?: string, options?: any): AxiosPromise<string> {
            return MaintenanceApiFp(configuration).teeJulkaisut(julkaisekaikki, tyyppi, tiedote, koulutustyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} tiedote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teeMaintenanceJulkaisu(perusteId: number, tiedote: string, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).teeMaintenanceJulkaisu(perusteId, tiedote, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tuoPeruste(options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).tuoPeruste(options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<YllapitoDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateYllapito(body?: Array<YllapitoDto>, options?: any): AxiosPromise<void> {
            return MaintenanceApiFp(configuration).updateYllapito(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viePeruste(perusteId: number, options?: any): AxiosPromise<object> {
            return MaintenanceApiFp(configuration).viePeruste(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viePerusteJson(perusteId: number, options?: any): AxiosPromise<PerusteKaikkiDto> {
            return MaintenanceApiFp(configuration).viePerusteJson(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virheellisetAmmattitaitovaatimukset(options?: any): AxiosPromise<Array<ParsitutAmmattitaitovaatimukset>> {
            return MaintenanceApiFp(configuration).virheellisetAmmattitaitovaatimukset(options)(axios, basePath);
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public addMissingOsaamisalakuvaukset(options?: any) {
        return MaintenanceApiFp(this.configuration).addMissingOsaamisalakuvaukset(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} cache 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public clearCache(cache: string, options?: any) {
        return MaintenanceApiFp(this.configuration).clearCache(cache, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public createArvioinninAmmattitaitovaatimukset(options?: any) {
        return MaintenanceApiFp(this.configuration).createArvioinninAmmattitaitovaatimukset(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public getYllapidot(options?: any) {
        return MaintenanceApiFp(this.configuration).getYllapidot(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public getYllapito(key: string, options?: any) {
        return MaintenanceApiFp(this.configuration).getYllapito(key, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public lisaaAmmattitaitovaatimuksetPerusteelle(perusteId: number, options?: any) {
        return MaintenanceApiFp(this.configuration).lisaaAmmattitaitovaatimuksetPerusteelle(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maarayksetperusteille(options?: any) {
        return MaintenanceApiFp(this.configuration).maarayksetperusteille(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public paivitaAmosaaKoulutustoimijat(options?: any) {
        return MaintenanceApiFp(this.configuration).paivitaAmosaaKoulutustoimijat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public pushAllAmmattitaitovaatimuksetToKoodisto(options?: any) {
        return MaintenanceApiFp(this.configuration).pushAllAmmattitaitovaatimuksetToKoodisto(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} julkaisekaikki 
     * @param {string} tyyppi 
     * @param {string} tiedote 
     * @param {string} [koulutustyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public teeJulkaisut(julkaisekaikki: boolean, tyyppi: string, tiedote: string, koulutustyyppi?: string, options?: any) {
        return MaintenanceApiFp(this.configuration).teeJulkaisut(julkaisekaikki, tyyppi, tiedote, koulutustyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} tiedote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public teeMaintenanceJulkaisu(perusteId: number, tiedote: string, options?: any) {
        return MaintenanceApiFp(this.configuration).teeMaintenanceJulkaisu(perusteId, tiedote, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public tuoPeruste(options?: any) {
        return MaintenanceApiFp(this.configuration).tuoPeruste(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<YllapitoDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public updateYllapito(body?: Array<YllapitoDto>, options?: any) {
        return MaintenanceApiFp(this.configuration).updateYllapito(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public viePeruste(perusteId: number, options?: any) {
        return MaintenanceApiFp(this.configuration).viePeruste(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public viePerusteJson(perusteId: number, options?: any) {
        return MaintenanceApiFp(this.configuration).viePerusteJson(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public virheellisetAmmattitaitovaatimukset(options?: any) {
        return MaintenanceApiFp(this.configuration).virheellisetAmmattitaitovaatimukset(options)(this.axios, this.basePath);
    }

}


/**
 * MuokkaustietoApi - axios parameter creator
 * @export
 */
export const MuokkaustietoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuokkausTiedotWithLuomisaika(perusteId: number, viimeisinLuomisaika?: number, lukumaara?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenMuokkausTiedotWithLuomisaika.');
            }
            const localVarPath = `/api/muokkaustieto/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (viimeisinLuomisaika !== undefined) {
                localVarQueryParameter['viimeisinLuomisaika'] = viimeisinLuomisaika;
            }

            if (lukumaara !== undefined) {
                localVarQueryParameter['lukumaara'] = lukumaara;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenVersionMuokkaustiedot(perusteId: number, revision: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenVersionMuokkaustiedot.');
            }
            // verify required parameter 'revision' is not null or undefined
            if (revision === null || revision === undefined) {
                throw new RequiredError('revision','Required parameter revision was null or undefined when calling getPerusteenVersionMuokkaustiedot.');
            }
            const localVarPath = `/api/muokkaustieto/muutokset/{perusteId}/{revision}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"revision"}}`, encodeURIComponent(String(revision)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MuokkaustietoApi - functional programming interface
 * @export
 */
export const MuokkaustietoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuokkausTiedotWithLuomisaika(perusteId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MuokkaustietoKayttajallaDto>> {
            const localVarAxiosArgs = MuokkaustietoApiAxiosParamCreator(configuration).getPerusteenMuokkausTiedotWithLuomisaika(perusteId, viimeisinLuomisaika, lukumaara, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenVersionMuokkaustiedot(perusteId: number, revision: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteenMuutostietoDto>> {
            const localVarAxiosArgs = MuokkaustietoApiAxiosParamCreator(configuration).getPerusteenVersionMuokkaustiedot(perusteId, revision, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MuokkaustietoApi - factory interface
 * @export
 */
export const MuokkaustietoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} [viimeisinLuomisaika] 
         * @param {number} [lukumaara] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenMuokkausTiedotWithLuomisaika(perusteId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any): AxiosPromise<Array<MuokkaustietoKayttajallaDto>> {
            return MuokkaustietoApiFp(configuration).getPerusteenMuokkausTiedotWithLuomisaika(perusteId, viimeisinLuomisaika, lukumaara, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} revision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenVersionMuokkaustiedot(perusteId: number, revision: number, options?: any): AxiosPromise<Array<PerusteenMuutostietoDto>> {
            return MuokkaustietoApiFp(configuration).getPerusteenVersionMuokkaustiedot(perusteId, revision, options)(axios, basePath);
        },
    };
};

/**
 * MuokkaustietoApi - object-oriented interface
 * @export
 * @class MuokkaustietoApi
 * @extends {BaseAPI}
 */
export class MuokkaustietoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} [viimeisinLuomisaika] 
     * @param {number} [lukumaara] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuokkaustietoApi
     */
    public getPerusteenMuokkausTiedotWithLuomisaika(perusteId: number, viimeisinLuomisaika?: number, lukumaara?: number, options?: any) {
        return MuokkaustietoApiFp(this.configuration).getPerusteenMuokkausTiedotWithLuomisaika(perusteId, viimeisinLuomisaika, lukumaara, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} revision 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuokkaustietoApi
     */
    public getPerusteenVersionMuokkaustiedot(perusteId: number, revision: number, options?: any) {
        return MuokkaustietoApiFp(this.configuration).getPerusteenVersionMuokkaustiedot(perusteId, revision, options)(this.axios, this.basePath);
    }

}


/**
 * MuutMaarayksetApi - axios parameter creator
 * @export
 */
export const MuutMaarayksetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MuuMaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMuuMaarays(body?: MuuMaaraysDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/muutmaaraykset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMuuMaarays(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMuuMaarays.');
            }
            const localVarPath = `/api/muutmaaraykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMuutMaaraykset(options: any = {}): RequestArgs {
            const localVarPath = `/api/muutmaaraykset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MuuMaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMuuMaarays(id: number, body?: MuuMaaraysDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMuuMaarays.');
            }
            const localVarPath = `/api/muutmaaraykset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MuutMaarayksetApi - functional programming interface
 * @export
 */
export const MuutMaarayksetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MuuMaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMuuMaarays(body?: MuuMaaraysDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MuuMaaraysDto> {
            const localVarAxiosArgs = MuutMaarayksetApiAxiosParamCreator(configuration).addMuuMaarays(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMuuMaarays(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MuutMaarayksetApiAxiosParamCreator(configuration).deleteMuuMaarays(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMuutMaaraykset(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MuuMaaraysDto>> {
            const localVarAxiosArgs = MuutMaarayksetApiAxiosParamCreator(configuration).getMuutMaaraykset(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MuuMaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMuuMaarays(id: number, body?: MuuMaaraysDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MuuMaaraysDto> {
            const localVarAxiosArgs = MuutMaarayksetApiAxiosParamCreator(configuration).updateMuuMaarays(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MuutMaarayksetApi - factory interface
 * @export
 */
export const MuutMaarayksetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MuuMaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMuuMaarays(body?: MuuMaaraysDto, options?: any): AxiosPromise<MuuMaaraysDto> {
            return MuutMaarayksetApiFp(configuration).addMuuMaarays(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMuuMaarays(id: number, options?: any): AxiosPromise<void> {
            return MuutMaarayksetApiFp(configuration).deleteMuuMaarays(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMuutMaaraykset(options?: any): AxiosPromise<Array<MuuMaaraysDto>> {
            return MuutMaarayksetApiFp(configuration).getMuutMaaraykset(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {MuuMaaraysDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMuuMaarays(id: number, body?: MuuMaaraysDto, options?: any): AxiosPromise<MuuMaaraysDto> {
            return MuutMaarayksetApiFp(configuration).updateMuuMaarays(id, body, options)(axios, basePath);
        },
    };
};

/**
 * MuutMaarayksetApi - object-oriented interface
 * @export
 * @class MuutMaarayksetApi
 * @extends {BaseAPI}
 */
export class MuutMaarayksetApi extends BaseAPI {
    /**
     * 
     * @param {MuuMaaraysDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuutMaarayksetApi
     */
    public addMuuMaarays(body?: MuuMaaraysDto, options?: any) {
        return MuutMaarayksetApiFp(this.configuration).addMuuMaarays(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuutMaarayksetApi
     */
    public deleteMuuMaarays(id: number, options?: any) {
        return MuutMaarayksetApiFp(this.configuration).deleteMuuMaarays(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuutMaarayksetApi
     */
    public getMuutMaaraykset(options?: any) {
        return MuutMaarayksetApiFp(this.configuration).getMuutMaaraykset(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {MuuMaaraysDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MuutMaarayksetApi
     */
    public updateMuuMaarays(id: number, body?: MuuMaaraysDto, options?: any) {
        return MuutMaarayksetApiFp(this.configuration).updateMuuMaarays(id, body, options)(this.axios, this.basePath);
    }

}


/**
 * OppaatApi - axios parameter creator
 * @export
 */
export const OppaatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OpasLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpas(body?: OpasLuontiDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/oppaat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary oppaiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] oppaan nimen kieli
         * @param {number} [muokattu] muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC)
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {boolean} [tuleva] hae myös tulevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaat(sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, muokattu?: number, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/oppaat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (muokattu !== undefined) {
                localVarQueryParameter['muokattu'] = muokattu;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (voimassaolo !== undefined) {
                localVarQueryParameter['voimassaolo'] = voimassaolo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaatKevyt(sivukoko?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/oppaat/projektit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OppaatApi - functional programming interface
 * @export
 */
export const OppaatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OpasLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpas(body?: OpasLuontiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpasDto> {
            const localVarAxiosArgs = OppaatApiAxiosParamCreator(configuration).addOpas(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary oppaiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] oppaan nimen kieli
         * @param {number} [muokattu] muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC)
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {boolean} [tuleva] hae myös tulevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaat(sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, muokattu?: number, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteHakuDto> {
            const localVarAxiosArgs = OppaatApiAxiosParamCreator(configuration).getAllOppaat(sivu, sivukoko, nimi, kieli, muokattu, koulutustyyppi, tuleva, voimassaolo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaatKevyt(sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteprojektiKevytDto> {
            const localVarAxiosArgs = OppaatApiAxiosParamCreator(configuration).getAllOppaatKevyt(sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OppaatApi - factory interface
 * @export
 */
export const OppaatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {OpasLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOpas(body?: OpasLuontiDto, options?: any): AxiosPromise<OpasDto> {
            return OppaatApiFp(configuration).addOpas(body, options)(axios, basePath);
        },
        /**
         * 
         * @summary oppaiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {string} [kieli] oppaan nimen kieli
         * @param {number} [muokattu] muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC)
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {boolean} [tuleva] hae myös tulevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaat(sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, muokattu?: number, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, options?: any): AxiosPromise<PagePerusteHakuDto> {
            return OppaatApiFp(configuration).getAllOppaat(sivu, sivukoko, nimi, kieli, muokattu, koulutustyyppi, tuleva, voimassaolo, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaatKevyt(sivukoko?: number, options?: any): AxiosPromise<PagePerusteprojektiKevytDto> {
            return OppaatApiFp(configuration).getAllOppaatKevyt(sivukoko, options)(axios, basePath);
        },
    };
};

/**
 * OppaatApi - object-oriented interface
 * @export
 * @class OppaatApi
 * @extends {BaseAPI}
 */
export class OppaatApi extends BaseAPI {
    /**
     * 
     * @param {OpasLuontiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppaatApi
     */
    public addOpas(body?: OpasLuontiDto, options?: any) {
        return OppaatApiFp(this.configuration).addOpas(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary oppaiden haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {string} [kieli] oppaan nimen kieli
     * @param {number} [muokattu] muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC)
     * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
     * @param {boolean} [tuleva] hae myös tulevat perusteet
     * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppaatApi
     */
    public getAllOppaat(sivu?: number, sivukoko?: number, nimi?: string, kieli?: string, muokattu?: number, koulutustyyppi?: Array<string>, tuleva?: boolean, voimassaolo?: boolean, options?: any) {
        return OppaatApiFp(this.configuration).getAllOppaat(sivu, sivukoko, nimi, kieli, muokattu, koulutustyyppi, tuleva, voimassaolo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OppaatApi
     */
    public getAllOppaatKevyt(sivukoko?: number, options?: any) {
        return OppaatApiFp(this.configuration).getAllOppaatKevyt(sivukoko, options)(this.axios, this.basePath);
    }

}


/**
 * OsaAlueetApi - axios parameter creator
 * @export
 */
export const OsaAlueetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaAluePerusteella(perusteId: number, viiteId: number, body?: OsaAlueLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addOsaAluePerusteella.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling addOsaAluePerusteella.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/peruste/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaAlueV2(viiteId: number, body?: OsaAlueLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling addOsaAlueV2.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaAlueLock(viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getOsaAlueLock.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling getOsaAlueLock.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaAlueV2(viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getOsaAlueV2.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling getOsaAlueV2.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOsaAlue(viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling lockOsaAlue.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling lockOsaAlue.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeOsaAluePerusteella.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling removeOsaAluePerusteella.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling removeOsaAluePerusteella.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}/peruste/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAlueV2(viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling removeOsaAlueV2.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling removeOsaAlueV2.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOsaAlue(viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling unlockOsaAlue.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling unlockOsaAlue.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOsaAluePerusteella.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling updateOsaAluePerusteella.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling updateOsaAluePerusteella.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}/peruste/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaAlueV2(viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling updateOsaAlueV2.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling updateOsaAlueV2.');
            }
            const localVarPath = `/api/tutkinnonosat/{viiteId}/osaalueet/{osaAlueId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OsaAlueetApi - functional programming interface
 * @export
 */
export const OsaAlueetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaAluePerusteella(perusteId: number, viiteId: number, body?: OsaAlueLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueLaajaDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).addOsaAluePerusteella(perusteId, viiteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaAlueV2(viiteId: number, body?: OsaAlueLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueLaajaDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).addOsaAlueV2(viiteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaAlueLock(viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).getOsaAlueLock(viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaAlueV2(viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueLaajaDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).getOsaAlueV2(viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOsaAlue(viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).lockOsaAlue(viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).removeOsaAluePerusteella(perusteId, viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAlueV2(viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).removeOsaAlueV2(viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOsaAlue(viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).unlockOsaAlue(viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueLaajaDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).updateOsaAluePerusteella(perusteId, viiteId, osaAlueId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaAlueV2(viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueLaajaDto> {
            const localVarAxiosArgs = OsaAlueetApiAxiosParamCreator(configuration).updateOsaAlueV2(viiteId, osaAlueId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OsaAlueetApi - factory interface
 * @export
 */
export const OsaAlueetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaAluePerusteella(perusteId: number, viiteId: number, body?: OsaAlueLaajaDto, options?: any): AxiosPromise<OsaAlueLaajaDto> {
            return OsaAlueetApiFp(configuration).addOsaAluePerusteella(perusteId, viiteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaAlueV2(viiteId: number, body?: OsaAlueLaajaDto, options?: any): AxiosPromise<OsaAlueLaajaDto> {
            return OsaAlueetApiFp(configuration).addOsaAlueV2(viiteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaAlueLock(viiteId: number, osaAlueId: number, options?: any): AxiosPromise<LukkoDto> {
            return OsaAlueetApiFp(configuration).getOsaAlueLock(viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaAlueV2(viiteId: number, osaAlueId: number, options?: any): AxiosPromise<OsaAlueLaajaDto> {
            return OsaAlueetApiFp(configuration).getOsaAlueV2(viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockOsaAlue(viiteId: number, osaAlueId: number, options?: any): AxiosPromise<LukkoDto> {
            return OsaAlueetApiFp(configuration).lockOsaAlue(viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, options?: any): AxiosPromise<void> {
            return OsaAlueetApiFp(configuration).removeOsaAluePerusteella(perusteId, viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAlueV2(viiteId: number, osaAlueId: number, options?: any): AxiosPromise<void> {
            return OsaAlueetApiFp(configuration).removeOsaAlueV2(viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockOsaAlue(viiteId: number, osaAlueId: number, options?: any): AxiosPromise<void> {
            return OsaAlueetApiFp(configuration).unlockOsaAlue(viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options?: any): AxiosPromise<OsaAlueLaajaDto> {
            return OsaAlueetApiFp(configuration).updateOsaAluePerusteella(perusteId, viiteId, osaAlueId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaAlueV2(viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options?: any): AxiosPromise<OsaAlueLaajaDto> {
            return OsaAlueetApiFp(configuration).updateOsaAlueV2(viiteId, osaAlueId, body, options)(axios, basePath);
        },
    };
};

/**
 * OsaAlueetApi - object-oriented interface
 * @export
 * @class OsaAlueetApi
 * @extends {BaseAPI}
 */
export class OsaAlueetApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} viiteId 
     * @param {OsaAlueLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public addOsaAluePerusteella(perusteId: number, viiteId: number, body?: OsaAlueLaajaDto, options?: any) {
        return OsaAlueetApiFp(this.configuration).addOsaAluePerusteella(perusteId, viiteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {OsaAlueLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public addOsaAlueV2(viiteId: number, body?: OsaAlueLaajaDto, options?: any) {
        return OsaAlueetApiFp(this.configuration).addOsaAlueV2(viiteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public getOsaAlueLock(viiteId: number, osaAlueId: number, options?: any) {
        return OsaAlueetApiFp(this.configuration).getOsaAlueLock(viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public getOsaAlueV2(viiteId: number, osaAlueId: number, options?: any) {
        return OsaAlueetApiFp(this.configuration).getOsaAlueV2(viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public lockOsaAlue(viiteId: number, osaAlueId: number, options?: any) {
        return OsaAlueetApiFp(this.configuration).lockOsaAlue(viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public removeOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, options?: any) {
        return OsaAlueetApiFp(this.configuration).removeOsaAluePerusteella(perusteId, viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public removeOsaAlueV2(viiteId: number, osaAlueId: number, options?: any) {
        return OsaAlueetApiFp(this.configuration).removeOsaAlueV2(viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public unlockOsaAlue(viiteId: number, osaAlueId: number, options?: any) {
        return OsaAlueetApiFp(this.configuration).unlockOsaAlue(viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {OsaAlueLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public updateOsaAluePerusteella(perusteId: number, viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options?: any) {
        return OsaAlueetApiFp(this.configuration).updateOsaAluePerusteella(perusteId, viiteId, osaAlueId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {OsaAlueLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaAlueetApi
     */
    public updateOsaAlueV2(viiteId: number, osaAlueId: number, body?: OsaAlueLaajaDto, options?: any) {
        return OsaAlueetApiFp(this.configuration).updateOsaAlueV2(viiteId, osaAlueId, body, options)(this.axios, this.basePath);
    }

}


/**
 * OsaamismerkitApi - axios parameter creator
 * @export
 */
export const OsaamismerkitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKategoria(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteKategoria.');
            }
            const localVarPath = `/api/osaamismerkit/kategoria/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOsaamismerkki(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOsaamismerkki.');
            }
            const localVarPath = `/api/osaamismerkit/osaamismerkki/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary julkisten osaamismerkkien haku
         * @param {string} [nimi] 
         * @param {number} [kategoria] 
         * @param {Array<number>} [koodit] 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJulkisetOsaamismerkitBy(nimi?: string, kategoria?: number, koodit?: Array<number>, poistunut?: boolean, kieli?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/osaamismerkit/haku/julkiset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (kategoria !== undefined) {
                localVarQueryParameter['kategoria'] = kategoria;
            }

            if (koodit) {
                localVarQueryParameter['koodit'] = koodit;
            }

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary osaamismerkkien haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {Array<string>} [tila] 
         * @param {Array<number>} [koodit] 
         * @param {number} [kategoria] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOsaamismerkitBy(sivu?: number, sivukoko?: number, nimi?: string, tila?: Array<string>, koodit?: Array<number>, kategoria?: number, voimassa?: boolean, tuleva?: boolean, poistunut?: boolean, kieli?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/osaamismerkit/haku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (tila) {
                localVarQueryParameter['tila'] = tila;
            }

            if (koodit) {
                localVarQueryParameter['koodit'] = koodit;
            }

            if (kategoria !== undefined) {
                localVarQueryParameter['kategoria'] = kategoria;
            }

            if (voimassa !== undefined) {
                localVarQueryParameter['voimassa'] = voimassa;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkinenOsaamismerkkiById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getJulkinenOsaamismerkkiById.');
            }
            const localVarPath = `/api/osaamismerkit/osaamismerkki/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkinenOsaamismerkkiByKoodi(koodi: number, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getJulkinenOsaamismerkkiByKoodi.');
            }
            const localVarPath = `/api/osaamismerkit/osaamismerkki/koodi/{koodi}`
                .replace(`{${"koodi"}}`, encodeURIComponent(String(koodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetKategoriat(poistunut?: boolean, kieli?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/osaamismerkit/kategoriat/julkiset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKategoriat(options: any = {}): RequestArgs {
            const localVarPath = `/api/osaamismerkit/kategoriat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OsaamismerkkiKategoriaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKategoria(body?: OsaamismerkkiKategoriaDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/osaamismerkit/kategoria/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OsaamismerkkiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamismerkki(body?: OsaamismerkkiDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/osaamismerkit/osaamismerkki/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OsaamismerkitApi - functional programming interface
 * @export
 */
export const OsaamismerkitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKategoria(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).deleteKategoria(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOsaamismerkki(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).deleteOsaamismerkki(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary julkisten osaamismerkkien haku
         * @param {string} [nimi] 
         * @param {number} [kategoria] 
         * @param {Array<number>} [koodit] 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJulkisetOsaamismerkitBy(nimi?: string, kategoria?: number, koodit?: Array<number>, poistunut?: boolean, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaamismerkkiBaseDto>> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).findJulkisetOsaamismerkitBy(nimi, kategoria, koodit, poistunut, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary osaamismerkkien haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {Array<string>} [tila] 
         * @param {Array<number>} [koodit] 
         * @param {number} [kategoria] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOsaamismerkitBy(sivu?: number, sivukoko?: number, nimi?: string, tila?: Array<string>, koodit?: Array<number>, kategoria?: number, voimassa?: boolean, tuleva?: boolean, poistunut?: boolean, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOsaamismerkkiDto> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).findOsaamismerkitBy(sivu, sivukoko, nimi, tila, koodit, kategoria, voimassa, tuleva, poistunut, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkinenOsaamismerkkiById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamismerkkiBaseDto> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).getJulkinenOsaamismerkkiById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkinenOsaamismerkkiByKoodi(koodi: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamismerkkiBaseDto> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).getJulkinenOsaamismerkkiByKoodi(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetKategoriat(poistunut?: boolean, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaamismerkkiKategoriaDto>> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).getJulkisetKategoriat(poistunut, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKategoriat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaamismerkkiKategoriaDto>> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).getKategoriat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OsaamismerkkiKategoriaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKategoria(body?: OsaamismerkkiKategoriaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamismerkkiKategoriaDto> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).updateKategoria(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {OsaamismerkkiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamismerkki(body?: OsaamismerkkiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamismerkkiDto> {
            const localVarAxiosArgs = OsaamismerkitApiAxiosParamCreator(configuration).updateOsaamismerkki(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OsaamismerkitApi - factory interface
 * @export
 */
export const OsaamismerkitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKategoria(id: number, options?: any): AxiosPromise<void> {
            return OsaamismerkitApiFp(configuration).deleteKategoria(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOsaamismerkki(id: number, options?: any): AxiosPromise<void> {
            return OsaamismerkitApiFp(configuration).deleteOsaamismerkki(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary julkisten osaamismerkkien haku
         * @param {string} [nimi] 
         * @param {number} [kategoria] 
         * @param {Array<number>} [koodit] 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findJulkisetOsaamismerkitBy(nimi?: string, kategoria?: number, koodit?: Array<number>, poistunut?: boolean, kieli?: string, options?: any): AxiosPromise<Array<OsaamismerkkiBaseDto>> {
            return OsaamismerkitApiFp(configuration).findJulkisetOsaamismerkitBy(nimi, kategoria, koodit, poistunut, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @summary osaamismerkkien haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {Array<string>} [tila] 
         * @param {Array<number>} [koodit] 
         * @param {number} [kategoria] 
         * @param {boolean} [voimassa] 
         * @param {boolean} [tuleva] 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOsaamismerkitBy(sivu?: number, sivukoko?: number, nimi?: string, tila?: Array<string>, koodit?: Array<number>, kategoria?: number, voimassa?: boolean, tuleva?: boolean, poistunut?: boolean, kieli?: string, options?: any): AxiosPromise<PageOsaamismerkkiDto> {
            return OsaamismerkitApiFp(configuration).findOsaamismerkitBy(sivu, sivukoko, nimi, tila, koodit, kategoria, voimassa, tuleva, poistunut, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkinenOsaamismerkkiById(id: number, options?: any): AxiosPromise<OsaamismerkkiBaseDto> {
            return OsaamismerkitApiFp(configuration).getJulkinenOsaamismerkkiById(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkinenOsaamismerkkiByKoodi(koodi: number, options?: any): AxiosPromise<OsaamismerkkiBaseDto> {
            return OsaamismerkitApiFp(configuration).getJulkinenOsaamismerkkiByKoodi(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [poistunut] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkisetKategoriat(poistunut?: boolean, kieli?: string, options?: any): AxiosPromise<Array<OsaamismerkkiKategoriaDto>> {
            return OsaamismerkitApiFp(configuration).getJulkisetKategoriat(poistunut, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKategoriat(options?: any): AxiosPromise<Array<OsaamismerkkiKategoriaDto>> {
            return OsaamismerkitApiFp(configuration).getKategoriat(options)(axios, basePath);
        },
        /**
         * 
         * @param {OsaamismerkkiKategoriaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKategoria(body?: OsaamismerkkiKategoriaDto, options?: any): AxiosPromise<OsaamismerkkiKategoriaDto> {
            return OsaamismerkitApiFp(configuration).updateKategoria(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {OsaamismerkkiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamismerkki(body?: OsaamismerkkiDto, options?: any): AxiosPromise<OsaamismerkkiDto> {
            return OsaamismerkitApiFp(configuration).updateOsaamismerkki(body, options)(axios, basePath);
        },
    };
};

/**
 * OsaamismerkitApi - object-oriented interface
 * @export
 * @class OsaamismerkitApi
 * @extends {BaseAPI}
 */
export class OsaamismerkitApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public deleteKategoria(id: number, options?: any) {
        return OsaamismerkitApiFp(this.configuration).deleteKategoria(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public deleteOsaamismerkki(id: number, options?: any) {
        return OsaamismerkitApiFp(this.configuration).deleteOsaamismerkki(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary julkisten osaamismerkkien haku
     * @param {string} [nimi] 
     * @param {number} [kategoria] 
     * @param {Array<number>} [koodit] 
     * @param {boolean} [poistunut] 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public findJulkisetOsaamismerkitBy(nimi?: string, kategoria?: number, koodit?: Array<number>, poistunut?: boolean, kieli?: string, options?: any) {
        return OsaamismerkitApiFp(this.configuration).findJulkisetOsaamismerkitBy(nimi, kategoria, koodit, poistunut, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary osaamismerkkien haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {Array<string>} [tila] 
     * @param {Array<number>} [koodit] 
     * @param {number} [kategoria] 
     * @param {boolean} [voimassa] 
     * @param {boolean} [tuleva] 
     * @param {boolean} [poistunut] 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public findOsaamismerkitBy(sivu?: number, sivukoko?: number, nimi?: string, tila?: Array<string>, koodit?: Array<number>, kategoria?: number, voimassa?: boolean, tuleva?: boolean, poistunut?: boolean, kieli?: string, options?: any) {
        return OsaamismerkitApiFp(this.configuration).findOsaamismerkitBy(sivu, sivukoko, nimi, tila, koodit, kategoria, voimassa, tuleva, poistunut, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public getJulkinenOsaamismerkkiById(id: number, options?: any) {
        return OsaamismerkitApiFp(this.configuration).getJulkinenOsaamismerkkiById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public getJulkinenOsaamismerkkiByKoodi(koodi: number, options?: any) {
        return OsaamismerkitApiFp(this.configuration).getJulkinenOsaamismerkkiByKoodi(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [poistunut] 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public getJulkisetKategoriat(poistunut?: boolean, kieli?: string, options?: any) {
        return OsaamismerkitApiFp(this.configuration).getJulkisetKategoriat(poistunut, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public getKategoriat(options?: any) {
        return OsaamismerkitApiFp(this.configuration).getKategoriat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {OsaamismerkkiKategoriaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public updateKategoria(body?: OsaamismerkkiKategoriaDto, options?: any) {
        return OsaamismerkitApiFp(this.configuration).updateKategoria(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {OsaamismerkkiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OsaamismerkitApi
     */
    public updateOsaamismerkki(body?: OsaamismerkkiDto, options?: any) {
        return OsaamismerkitApiFp(this.configuration).updateOsaamismerkki(body, options)(this.axios, this.basePath);
    }

}


/**
 * PalautteetApi - axios parameter creator
 * @export
 */
export const PalautteetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} palautekanava 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauteStatukset(palautekanava: string, options: any = {}): RequestArgs {
            // verify required parameter 'palautekanava' is not null or undefined
            if (palautekanava === null || palautekanava === undefined) {
                throw new RequiredError('palautekanava','Required parameter palautekanava was null or undefined when calling palauteStatukset.');
            }
            const localVarPath = `/api/palaute/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (palautekanava !== undefined) {
                localVarQueryParameter['palautekanava'] = palautekanava;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} palautekanava 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautteet(palautekanava: string, options: any = {}): RequestArgs {
            // verify required parameter 'palautekanava' is not null or undefined
            if (palautekanava === null || palautekanava === undefined) {
                throw new RequiredError('palautekanava','Required parameter palautekanava was null or undefined when calling palautteet.');
            }
            const localVarPath = `/api/palaute`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (palautekanava !== undefined) {
                localVarQueryParameter['palautekanava'] = palautekanava;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalauteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPalaute(body?: PalauteDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/palaute`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PalauteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePalaute(body?: PalauteDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/palaute/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PalautteetApi - functional programming interface
 * @export
 */
export const PalautteetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} palautekanava 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauteStatukset(palautekanava: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PalauteDto>> {
            const localVarAxiosArgs = PalautteetApiAxiosParamCreator(configuration).palauteStatukset(palautekanava, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} palautekanava 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautteet(palautekanava: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PalautteetApiAxiosParamCreator(configuration).palautteet(palautekanava, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PalauteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPalaute(body?: PalauteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PalauteDto> {
            const localVarAxiosArgs = PalautteetApiAxiosParamCreator(configuration).sendPalaute(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PalauteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePalaute(body?: PalauteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PalauteDto> {
            const localVarAxiosArgs = PalautteetApiAxiosParamCreator(configuration).updatePalaute(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PalautteetApi - factory interface
 * @export
 */
export const PalautteetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} palautekanava 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauteStatukset(palautekanava: string, options?: any): AxiosPromise<Array<PalauteDto>> {
            return PalautteetApiFp(configuration).palauteStatukset(palautekanava, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} palautekanava 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palautteet(palautekanava: string, options?: any): AxiosPromise<void> {
            return PalautteetApiFp(configuration).palautteet(palautekanava, options)(axios, basePath);
        },
        /**
         * 
         * @param {PalauteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPalaute(body?: PalauteDto, options?: any): AxiosPromise<PalauteDto> {
            return PalautteetApiFp(configuration).sendPalaute(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {PalauteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePalaute(body?: PalauteDto, options?: any): AxiosPromise<PalauteDto> {
            return PalautteetApiFp(configuration).updatePalaute(body, options)(axios, basePath);
        },
    };
};

/**
 * PalautteetApi - object-oriented interface
 * @export
 * @class PalautteetApi
 * @extends {BaseAPI}
 */
export class PalautteetApi extends BaseAPI {
    /**
     * 
     * @param {string} palautekanava 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalautteetApi
     */
    public palauteStatukset(palautekanava: string, options?: any) {
        return PalautteetApiFp(this.configuration).palauteStatukset(palautekanava, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} palautekanava 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalautteetApi
     */
    public palautteet(palautekanava: string, options?: any) {
        return PalautteetApiFp(this.configuration).palautteet(palautekanava, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {PalauteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalautteetApi
     */
    public sendPalaute(body?: PalauteDto, options?: any) {
        return PalautteetApiFp(this.configuration).sendPalaute(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {PalauteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PalautteetApi
     */
    public updatePalaute(body?: PalauteDto, options?: any) {
        return PalautteetApiFp(this.configuration).updatePalaute(body, options)(this.axios, this.basePath);
    }

}


/**
 * PerusopetuksenPerusteenSisaltoApi - axios parameter creator
 * @export
 */
export const PerusopetuksenPerusteenSisaltoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {OpetuksenKohdealueDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaineenKohdealue(perusteId: number, id: number, body?: OpetuksenKohdealueDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addOppiaineenKohdealue.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addOppiaineenKohdealue.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/kohdealueet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaineenVuosiluokkakokonaisuus(perusteId: number, id: number, body?: OppiaineenVuosiluokkaKokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addOppiaineenVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/vuosiluokkakokonaisuudet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetuksenSisaltoLapsi(perusteId: number, id: number, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addPerusopetuksenSisaltoLapsi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPerusopetuksenSisaltoLapsi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/sisalto/{id}/lapset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetusOppiaine(perusteId: number, body?: OppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addPerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetusSisalto(perusteId: number, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addPerusopetusSisalto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/sisalto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {VuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVuosiluokkaKokonaisuus(perusteId: number, body?: VuosiluokkaKokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addVuosiluokkaKokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} kohdealueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaineenKohdealue(perusteId: number, id: number, kohdealueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteOppiaineenKohdealue.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOppiaineenKohdealue.');
            }
            // verify required parameter 'kohdealueId' is not null or undefined
            if (kohdealueId === null || kohdealueId === undefined) {
                throw new RequiredError('kohdealueId','Required parameter kohdealueId was null or undefined when calling deleteOppiaineenKohdealue.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/kohdealueet/{kohdealueId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"kohdealueId"}}`, encodeURIComponent(String(kohdealueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling deleteOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOppiaineenVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusOppiaine(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deletePerusopetusOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusOsaaminen(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deletePerusopetusOsaaminen.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePerusopetusOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusSisalto(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deletePerusopetusSisalto.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePerusopetusSisalto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/sisalto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVuosiluokkaKokonaisuus(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteVuosiluokkaKokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVuosiluokkaKokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKohdealueet(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKohdealueet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getKohdealueet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/kohdealueet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} revisio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineRevisiolla(perusteId: number, id: number, revisio: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaineRevisiolla.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineRevisiolla.');
            }
            // verify required parameter 'revisio' is not null or undefined
            if (revisio === null || revisio === undefined) {
                throw new RequiredError('revisio','Required parameter revisio was null or undefined when calling getOppiaineRevisiolla.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/versiot/{revisio}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"revisio"}}`, encodeURIComponent(String(revisio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkaKokonaisuudet(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaineenVuosiluokkaKokonaisuudet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineenVuosiluokkaKokonaisuudet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/vuosiluokkakokonaisuudet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling getOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOppiaineenVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminen(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOsaaminen.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminenVersiolla(perusteId: number, id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOsaaminenVersiolla.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOsaaminenVersiolla.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling getOsaaminenVersiolla.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{id}/versiot/{versioId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminenVersiot(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOsaaminenVersiot.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOsaaminenVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{id}/versiot/`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamiset(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOsaamiset.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppiaine(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusopetusOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppiaineet(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusopetusOppiaineet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppimaarat(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusopetusOppimaarat.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusopetusOppimaarat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/oppimaarat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuudenOppiaineet(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getVuosiluokkaKokonaisuudenOppiaineet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVuosiluokkaKokonaisuudenOppiaineet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{id}/oppiaineet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuudet(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getVuosiluokkaKokonaisuudet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuus(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getVuosiluokkaKokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVuosiluokkaKokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaPerusteenTekstiKappale(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling kloonaaPerusteenTekstiKappale.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling kloonaaPerusteenTekstiKappale.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/sisalto/{id}/muokattavakopio`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Array<OpetuksenKohdealueDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKohdealueet(perusteId: number, id: number, body?: Array<OpetuksenKohdealueDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateKohdealueet.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateKohdealueet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}/kohdealueet/update`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<OppiaineSuppeaDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineJarjestys(perusteId: number, body?: Array<OppiaineSuppeaDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOppiaineJarjestys.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/jarjestys`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, body?: UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling updateOppiaineenVuosiluokkakokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOppiaineenVuosiluokkakokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaaminen(perusteId: number, id: number, body?: LaajaalainenOsaaminenDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOsaaminen.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOsaaminen.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {UpdateDtoOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusopetusOppiaine(perusteId: number, id: number, body?: UpdateDtoOppiaineDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updatePerusopetusOppiaine.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Suppea} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViite(perusteId: number, id: number, body?: Suppea, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateSisaltoViite.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSisaltoViite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/sisalto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {UpdateDtoVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkaKokonaisuus(perusteId: number, id: number, body?: UpdateDtoVuosiluokkaKokonaisuusDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateVuosiluokkaKokonaisuus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateVuosiluokkaKokonaisuus.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusopetuksenPerusteenSisaltoApi - functional programming interface
 * @export
 */
export const PerusopetuksenPerusteenSisaltoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {OpetuksenKohdealueDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaineenKohdealue(perusteId: number, id: number, body?: OpetuksenKohdealueDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpetuksenKohdealueDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addOppiaineenKohdealue(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaineenVuosiluokkakokonaisuus(perusteId: number, id: number, body?: OppiaineenVuosiluokkaKokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkaKokonaisuusDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addOppiaineenVuosiluokkakokonaisuus(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addOsaaminen(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetuksenSisaltoLapsi(perusteId: number, id: number, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addPerusopetuksenSisaltoLapsi(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetusOppiaine(perusteId: number, body?: OppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addPerusopetusOppiaine(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetusSisalto(perusteId: number, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addPerusopetusSisalto(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {VuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVuosiluokkaKokonaisuus(perusteId: number, body?: VuosiluokkaKokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VuosiluokkaKokonaisuusDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).addVuosiluokkaKokonaisuus(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} kohdealueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaineenKohdealue(perusteId: number, id: number, kohdealueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).deleteOppiaineenKohdealue(perusteId, id, kohdealueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).deleteOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusOppiaine(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).deletePerusopetusOppiaine(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusOsaaminen(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).deletePerusopetusOsaaminen(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusSisalto(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).deletePerusopetusSisalto(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVuosiluokkaKokonaisuus(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).deleteVuosiluokkaKokonaisuus(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKohdealueet(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetuksenKohdealueDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getKohdealueet(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} revisio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineRevisiolla(perusteId: number, id: number, revisio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOppiaineRevisiolla(perusteId, id, revisio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkaKokonaisuudet(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineenVuosiluokkaKokonaisuusDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOppiaineenVuosiluokkaKokonaisuudet(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkaKokonaisuusDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminen(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOsaaminen(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminenVersiolla(perusteId: number, id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOsaaminenVersiolla(perusteId, id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminenVersiot(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOsaaminenVersiot(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamiset(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LaajaalainenOsaaminenDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getOsaamiset(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppiaine(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getPerusopetusOppiaine(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppiaineet(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineSuppeaDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getPerusopetusOppiaineet(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppimaarat(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineSuppeaDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getPerusopetusOppimaarat(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuudenOppiaineet(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OppiaineSuppeaDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getVuosiluokkaKokonaisuudenOppiaineet(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuudet(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VuosiluokkaKokonaisuusDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getVuosiluokkaKokonaisuudet(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuus(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VuosiluokkaKokonaisuusDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).getVuosiluokkaKokonaisuus(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaPerusteenTekstiKappale(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).kloonaaPerusteenTekstiKappale(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Array<OpetuksenKohdealueDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKohdealueet(perusteId: number, id: number, body?: Array<OpetuksenKohdealueDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpetuksenKohdealueDto>> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updateKohdealueet(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<OppiaineSuppeaDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineJarjestys(perusteId: number, body?: Array<OppiaineSuppeaDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updateOppiaineJarjestys(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, body?: UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineenVuosiluokkaKokonaisuusDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updateOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaaminen(perusteId: number, id: number, body?: LaajaalainenOsaaminenDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LaajaalainenOsaaminenDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updateOsaaminen(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {UpdateDtoOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusopetusOppiaine(perusteId: number, id: number, body?: UpdateDtoOppiaineDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OppiaineDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updatePerusopetusOppiaine(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Suppea} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViite(perusteId: number, id: number, body?: Suppea, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updateSisaltoViite(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {UpdateDtoVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkaKokonaisuus(perusteId: number, id: number, body?: UpdateDtoVuosiluokkaKokonaisuusDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VuosiluokkaKokonaisuusDto> {
            const localVarAxiosArgs = PerusopetuksenPerusteenSisaltoApiAxiosParamCreator(configuration).updateVuosiluokkaKokonaisuus(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusopetuksenPerusteenSisaltoApi - factory interface
 * @export
 */
export const PerusopetuksenPerusteenSisaltoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {OpetuksenKohdealueDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaineenKohdealue(perusteId: number, id: number, body?: OpetuksenKohdealueDto, options?: any): AxiosPromise<OpetuksenKohdealueDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addOppiaineenKohdealue(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {OppiaineenVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOppiaineenVuosiluokkakokonaisuus(perusteId: number, id: number, body?: OppiaineenVuosiluokkaKokonaisuusDto, options?: any): AxiosPromise<OppiaineenVuosiluokkaKokonaisuusDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addOppiaineenVuosiluokkakokonaisuus(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addOsaaminen(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetuksenSisaltoLapsi(perusteId: number, id: number, body?: Matala, options?: any): AxiosPromise<Matala> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addPerusopetuksenSisaltoLapsi(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {OppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetusOppiaine(perusteId: number, body?: OppiaineDto, options?: any): AxiosPromise<OppiaineDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addPerusopetusOppiaine(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusopetusSisalto(perusteId: number, body?: Matala, options?: any): AxiosPromise<Matala> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addPerusopetusSisalto(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {VuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVuosiluokkaKokonaisuus(perusteId: number, body?: VuosiluokkaKokonaisuusDto, options?: any): AxiosPromise<VuosiluokkaKokonaisuusDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).addVuosiluokkaKokonaisuus(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} kohdealueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaineenKohdealue(perusteId: number, id: number, kohdealueId: number, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).deleteOppiaineenKohdealue(perusteId, id, kohdealueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).deleteOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusOppiaine(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).deletePerusopetusOppiaine(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusOsaaminen(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).deletePerusopetusOsaaminen(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusopetusSisalto(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).deletePerusopetusSisalto(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVuosiluokkaKokonaisuus(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).deleteVuosiluokkaKokonaisuus(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKohdealueet(perusteId: number, id: number, options?: any): AxiosPromise<Array<OpetuksenKohdealueDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getKohdealueet(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} revisio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineRevisiolla(perusteId: number, id: number, revisio: number, options?: any): AxiosPromise<OppiaineDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOppiaineRevisiolla(perusteId, id, revisio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkaKokonaisuudet(perusteId: number, id: number, options?: any): AxiosPromise<Array<OppiaineenVuosiluokkaKokonaisuusDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOppiaineenVuosiluokkaKokonaisuudet(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options?: any): AxiosPromise<OppiaineenVuosiluokkaKokonaisuusDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminen(perusteId: number, id: number, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOsaaminen(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminenVersiolla(perusteId: number, id: number, versioId: number, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOsaaminenVersiolla(perusteId, id, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaaminenVersiot(perusteId: number, id: number, options?: any): AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOsaaminenVersiot(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamiset(perusteId: number, options?: any): AxiosPromise<Array<LaajaalainenOsaaminenDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getOsaamiset(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppiaine(perusteId: number, id: number, options?: any): AxiosPromise<OppiaineDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getPerusopetusOppiaine(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppiaineet(perusteId: number, options?: any): AxiosPromise<Array<OppiaineSuppeaDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getPerusopetusOppiaineet(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusopetusOppimaarat(perusteId: number, id: number, options?: any): AxiosPromise<Array<OppiaineSuppeaDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getPerusopetusOppimaarat(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuudenOppiaineet(perusteId: number, id: number, options?: any): AxiosPromise<Array<OppiaineSuppeaDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getVuosiluokkaKokonaisuudenOppiaineet(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuudet(perusteId: number, options?: any): AxiosPromise<Array<VuosiluokkaKokonaisuusDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getVuosiluokkaKokonaisuudet(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVuosiluokkaKokonaisuus(perusteId: number, id: number, options?: any): AxiosPromise<VuosiluokkaKokonaisuusDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).getVuosiluokkaKokonaisuus(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaPerusteenTekstiKappale(perusteId: number, id: number, options?: any): AxiosPromise<Laaja> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).kloonaaPerusteenTekstiKappale(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Array<OpetuksenKohdealueDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKohdealueet(perusteId: number, id: number, body?: Array<OpetuksenKohdealueDto>, options?: any): AxiosPromise<Array<OpetuksenKohdealueDto>> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updateKohdealueet(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<OppiaineSuppeaDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineJarjestys(perusteId: number, body?: Array<OppiaineSuppeaDto>, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updateOppiaineJarjestys(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} id 
         * @param {UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, body?: UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto, options?: any): AxiosPromise<OppiaineenVuosiluokkaKokonaisuusDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updateOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {LaajaalainenOsaaminenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaaminen(perusteId: number, id: number, body?: LaajaalainenOsaaminenDto, options?: any): AxiosPromise<LaajaalainenOsaaminenDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updateOsaaminen(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {UpdateDtoOppiaineDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusopetusOppiaine(perusteId: number, id: number, body?: UpdateDtoOppiaineDto, options?: any): AxiosPromise<OppiaineDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updatePerusopetusOppiaine(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {Suppea} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViite(perusteId: number, id: number, body?: Suppea, options?: any): AxiosPromise<void> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updateSisaltoViite(perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {UpdateDtoVuosiluokkaKokonaisuusDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVuosiluokkaKokonaisuus(perusteId: number, id: number, body?: UpdateDtoVuosiluokkaKokonaisuusDto, options?: any): AxiosPromise<VuosiluokkaKokonaisuusDto> {
            return PerusopetuksenPerusteenSisaltoApiFp(configuration).updateVuosiluokkaKokonaisuus(perusteId, id, body, options)(axios, basePath);
        },
    };
};

/**
 * PerusopetuksenPerusteenSisaltoApi - object-oriented interface
 * @export
 * @class PerusopetuksenPerusteenSisaltoApi
 * @extends {BaseAPI}
 */
export class PerusopetuksenPerusteenSisaltoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {OpetuksenKohdealueDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addOppiaineenKohdealue(perusteId: number, id: number, body?: OpetuksenKohdealueDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addOppiaineenKohdealue(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {OppiaineenVuosiluokkaKokonaisuusDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addOppiaineenVuosiluokkakokonaisuus(perusteId: number, id: number, body?: OppiaineenVuosiluokkaKokonaisuusDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addOppiaineenVuosiluokkakokonaisuus(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {LaajaalainenOsaaminenDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addOsaaminen(perusteId: number, body?: LaajaalainenOsaaminenDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addOsaaminen(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addPerusopetuksenSisaltoLapsi(perusteId: number, id: number, body?: Matala, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addPerusopetuksenSisaltoLapsi(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {OppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addPerusopetusOppiaine(perusteId: number, body?: OppiaineDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addPerusopetusOppiaine(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addPerusopetusSisalto(perusteId: number, body?: Matala, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addPerusopetusSisalto(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {VuosiluokkaKokonaisuusDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public addVuosiluokkaKokonaisuus(perusteId: number, body?: VuosiluokkaKokonaisuusDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).addVuosiluokkaKokonaisuus(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {number} kohdealueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public deleteOppiaineenKohdealue(perusteId: number, id: number, kohdealueId: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).deleteOppiaineenKohdealue(perusteId, id, kohdealueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public deleteOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).deleteOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public deletePerusopetusOppiaine(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).deletePerusopetusOppiaine(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public deletePerusopetusOsaaminen(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).deletePerusopetusOsaaminen(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public deletePerusopetusSisalto(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).deletePerusopetusSisalto(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public deleteVuosiluokkaKokonaisuus(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).deleteVuosiluokkaKokonaisuus(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getKohdealueet(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getKohdealueet(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {number} revisio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOppiaineRevisiolla(perusteId: number, id: number, revisio: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOppiaineRevisiolla(perusteId, id, revisio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOppiaineenVuosiluokkaKokonaisuudet(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOppiaineenVuosiluokkaKokonaisuudet(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOsaaminen(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOsaaminen(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOsaaminenVersiolla(perusteId: number, id: number, versioId: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOsaaminenVersiolla(perusteId, id, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOsaaminenVersiot(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOsaaminenVersiot(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getOsaamiset(perusteId: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getOsaamiset(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getPerusopetusOppiaine(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getPerusopetusOppiaine(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getPerusopetusOppiaineet(perusteId: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getPerusopetusOppiaineet(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getPerusopetusOppimaarat(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getPerusopetusOppimaarat(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getVuosiluokkaKokonaisuudenOppiaineet(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getVuosiluokkaKokonaisuudenOppiaineet(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getVuosiluokkaKokonaisuudet(perusteId: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getVuosiluokkaKokonaisuudet(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public getVuosiluokkaKokonaisuus(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).getVuosiluokkaKokonaisuus(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public kloonaaPerusteenTekstiKappale(perusteId: number, id: number, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).kloonaaPerusteenTekstiKappale(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {Array<OpetuksenKohdealueDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updateKohdealueet(perusteId: number, id: number, body?: Array<OpetuksenKohdealueDto>, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updateKohdealueet(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Array<OppiaineSuppeaDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updateOppiaineJarjestys(perusteId: number, body?: Array<OppiaineSuppeaDto>, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updateOppiaineJarjestys(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} id 
     * @param {UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updateOppiaineenVuosiluokkakokonaisuus(perusteId: number, oppiaineId: number, id: number, body?: UpdateDtoOppiaineenVuosiluokkaKokonaisuusDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updateOppiaineenVuosiluokkakokonaisuus(perusteId, oppiaineId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {LaajaalainenOsaaminenDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updateOsaaminen(perusteId: number, id: number, body?: LaajaalainenOsaaminenDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updateOsaaminen(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {UpdateDtoOppiaineDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updatePerusopetusOppiaine(perusteId: number, id: number, body?: UpdateDtoOppiaineDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updatePerusopetusOppiaine(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {Suppea} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updateSisaltoViite(perusteId: number, id: number, body?: Suppea, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updateSisaltoViite(perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {UpdateDtoVuosiluokkaKokonaisuusDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetuksenPerusteenSisaltoApi
     */
    public updateVuosiluokkaKokonaisuus(perusteId: number, id: number, body?: UpdateDtoVuosiluokkaKokonaisuusDto, options?: any) {
        return PerusopetuksenPerusteenSisaltoApiFp(this.configuration).updateVuosiluokkaKokonaisuus(perusteId, id, body, options)(this.axios, this.basePath);
    }

}


/**
 * PerusopetusLaajaAlainenOsaaminenLukkoApi - axios parameter creator
 * @export
 */
export const PerusopetusLaajaAlainenOsaaminenLukkoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusLao(perusteId: number, osaaminenId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling checkLockPerusopetusLao.');
            }
            // verify required parameter 'osaaminenId' is not null or undefined
            if (osaaminenId === null || osaaminenId === undefined) {
                throw new RequiredError('osaaminenId','Required parameter osaaminenId was null or undefined when calling checkLockPerusopetusLao.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{osaaminenId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"osaaminenId"}}`, encodeURIComponent(String(osaaminenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusLao(perusteId: number, osaaminenId: number, If_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling lockPerusopetusLao.');
            }
            // verify required parameter 'osaaminenId' is not null or undefined
            if (osaaminenId === null || osaaminenId === undefined) {
                throw new RequiredError('osaaminenId','Required parameter osaaminenId was null or undefined when calling lockPerusopetusLao.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{osaaminenId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"osaaminenId"}}`, encodeURIComponent(String(osaaminenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_Match !== undefined && If_Match !== null) {
                localVarHeaderParameter['If-Match'] = String(If_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusLao(perusteId: number, osaaminenId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling unlockPerusopetusLao.');
            }
            // verify required parameter 'osaaminenId' is not null or undefined
            if (osaaminenId === null || osaaminenId === undefined) {
                throw new RequiredError('osaaminenId','Required parameter osaaminenId was null or undefined when calling unlockPerusopetusLao.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/laajaalaisetosaamiset/{osaaminenId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"osaaminenId"}}`, encodeURIComponent(String(osaaminenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusopetusLaajaAlainenOsaaminenLukkoApi - functional programming interface
 * @export
 */
export const PerusopetusLaajaAlainenOsaaminenLukkoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusLao(perusteId: number, osaaminenId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusLaajaAlainenOsaaminenLukkoApiAxiosParamCreator(configuration).checkLockPerusopetusLao(perusteId, osaaminenId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusLao(perusteId: number, osaaminenId: number, If_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusLaajaAlainenOsaaminenLukkoApiAxiosParamCreator(configuration).lockPerusopetusLao(perusteId, osaaminenId, If_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusLao(perusteId: number, osaaminenId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetusLaajaAlainenOsaaminenLukkoApiAxiosParamCreator(configuration).unlockPerusopetusLao(perusteId, osaaminenId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusopetusLaajaAlainenOsaaminenLukkoApi - factory interface
 * @export
 */
export const PerusopetusLaajaAlainenOsaaminenLukkoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusLao(perusteId: number, osaaminenId: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusLaajaAlainenOsaaminenLukkoApiFp(configuration).checkLockPerusopetusLao(perusteId, osaaminenId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusLao(perusteId: number, osaaminenId: number, If_Match?: string, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusLaajaAlainenOsaaminenLukkoApiFp(configuration).lockPerusopetusLao(perusteId, osaaminenId, If_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} osaaminenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusLao(perusteId: number, osaaminenId: number, options?: any): AxiosPromise<void> {
            return PerusopetusLaajaAlainenOsaaminenLukkoApiFp(configuration).unlockPerusopetusLao(perusteId, osaaminenId, options)(axios, basePath);
        },
    };
};

/**
 * PerusopetusLaajaAlainenOsaaminenLukkoApi - object-oriented interface
 * @export
 * @class PerusopetusLaajaAlainenOsaaminenLukkoApi
 * @extends {BaseAPI}
 */
export class PerusopetusLaajaAlainenOsaaminenLukkoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} osaaminenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusLaajaAlainenOsaaminenLukkoApi
     */
    public checkLockPerusopetusLao(perusteId: number, osaaminenId: number, options?: any) {
        return PerusopetusLaajaAlainenOsaaminenLukkoApiFp(this.configuration).checkLockPerusopetusLao(perusteId, osaaminenId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} osaaminenId 
     * @param {string} [If_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusLaajaAlainenOsaaminenLukkoApi
     */
    public lockPerusopetusLao(perusteId: number, osaaminenId: number, If_Match?: string, options?: any) {
        return PerusopetusLaajaAlainenOsaaminenLukkoApiFp(this.configuration).lockPerusopetusLao(perusteId, osaaminenId, If_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} osaaminenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusLaajaAlainenOsaaminenLukkoApi
     */
    public unlockPerusopetusLao(perusteId: number, osaaminenId: number, options?: any) {
        return PerusopetusLaajaAlainenOsaaminenLukkoApiFp(this.configuration).unlockPerusopetusLao(perusteId, osaaminenId, options)(this.axios, this.basePath);
    }

}


/**
 * PerusopetusOppiaineLukkoApi - axios parameter creator
 * @export
 */
export const PerusopetusOppiaineLukkoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling checkLockPerusopetusOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling checkLockPerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusOppiaine(perusteId: number, oppiaineId: number, If_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling lockPerusopetusOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling lockPerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_Match !== undefined && If_Match !== null) {
                localVarHeaderParameter['If-Match'] = String(If_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling unlockPerusopetusOppiaine.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling unlockPerusopetusOppiaine.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusopetusOppiaineLukkoApi - functional programming interface
 * @export
 */
export const PerusopetusOppiaineLukkoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusOppiaineLukkoApiAxiosParamCreator(configuration).checkLockPerusopetusOppiaine(perusteId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusOppiaine(perusteId: number, oppiaineId: number, If_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusOppiaineLukkoApiAxiosParamCreator(configuration).lockPerusopetusOppiaine(perusteId, oppiaineId, If_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetusOppiaineLukkoApiAxiosParamCreator(configuration).unlockPerusopetusOppiaine(perusteId, oppiaineId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusopetusOppiaineLukkoApi - factory interface
 * @export
 */
export const PerusopetusOppiaineLukkoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusOppiaineLukkoApiFp(configuration).checkLockPerusopetusOppiaine(perusteId, oppiaineId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusOppiaine(perusteId: number, oppiaineId: number, If_Match?: string, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusOppiaineLukkoApiFp(configuration).lockPerusopetusOppiaine(perusteId, oppiaineId, If_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options?: any): AxiosPromise<void> {
            return PerusopetusOppiaineLukkoApiFp(configuration).unlockPerusopetusOppiaine(perusteId, oppiaineId, options)(axios, basePath);
        },
    };
};

/**
 * PerusopetusOppiaineLukkoApi - object-oriented interface
 * @export
 * @class PerusopetusOppiaineLukkoApi
 * @extends {BaseAPI}
 */
export class PerusopetusOppiaineLukkoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusOppiaineLukkoApi
     */
    public checkLockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options?: any) {
        return PerusopetusOppiaineLukkoApiFp(this.configuration).checkLockPerusopetusOppiaine(perusteId, oppiaineId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {string} [If_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusOppiaineLukkoApi
     */
    public lockPerusopetusOppiaine(perusteId: number, oppiaineId: number, If_Match?: string, options?: any) {
        return PerusopetusOppiaineLukkoApiFp(this.configuration).lockPerusopetusOppiaine(perusteId, oppiaineId, If_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusOppiaineLukkoApi
     */
    public unlockPerusopetusOppiaine(perusteId: number, oppiaineId: number, options?: any) {
        return PerusopetusOppiaineLukkoApiFp(this.configuration).unlockPerusopetusOppiaine(perusteId, oppiaineId, options)(this.axios, this.basePath);
    }

}


/**
 * PerusopetusOppiaineVlkLukkoApi - axios parameter creator
 * @export
 */
export const PerusopetusOppiaineVlkLukkoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling checkLockPerusopetusOppiaineVlk.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling checkLockPerusopetusOppiaineVlk.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling checkLockPerusopetusOppiaineVlk.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{kokonaisuusId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, If_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling lockPerusopetusOppiaineVlk.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling lockPerusopetusOppiaineVlk.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling lockPerusopetusOppiaineVlk.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{kokonaisuusId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_Match !== undefined && If_Match !== null) {
                localVarHeaderParameter['If-Match'] = String(If_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling unlockPerusopetusOppiaineVlk.');
            }
            // verify required parameter 'oppiaineId' is not null or undefined
            if (oppiaineId === null || oppiaineId === undefined) {
                throw new RequiredError('oppiaineId','Required parameter oppiaineId was null or undefined when calling unlockPerusopetusOppiaineVlk.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling unlockPerusopetusOppiaineVlk.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/oppiaineet/{oppiaineId}/vuosiluokkakokonaisuudet/{kokonaisuusId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"oppiaineId"}}`, encodeURIComponent(String(oppiaineId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusopetusOppiaineVlkLukkoApi - functional programming interface
 * @export
 */
export const PerusopetusOppiaineVlkLukkoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusOppiaineVlkLukkoApiAxiosParamCreator(configuration).checkLockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, If_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusOppiaineVlkLukkoApiAxiosParamCreator(configuration).lockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, If_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetusOppiaineVlkLukkoApiAxiosParamCreator(configuration).unlockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusopetusOppiaineVlkLukkoApi - factory interface
 * @export
 */
export const PerusopetusOppiaineVlkLukkoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusOppiaineVlkLukkoApiFp(configuration).checkLockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, If_Match?: string, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusOppiaineVlkLukkoApiFp(configuration).lockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, If_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} oppiaineId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options?: any): AxiosPromise<void> {
            return PerusopetusOppiaineVlkLukkoApiFp(configuration).unlockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, options)(axios, basePath);
        },
    };
};

/**
 * PerusopetusOppiaineVlkLukkoApi - object-oriented interface
 * @export
 * @class PerusopetusOppiaineVlkLukkoApi
 * @extends {BaseAPI}
 */
export class PerusopetusOppiaineVlkLukkoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} kokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusOppiaineVlkLukkoApi
     */
    public checkLockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options?: any) {
        return PerusopetusOppiaineVlkLukkoApiFp(this.configuration).checkLockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} kokonaisuusId 
     * @param {string} [If_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusOppiaineVlkLukkoApi
     */
    public lockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, If_Match?: string, options?: any) {
        return PerusopetusOppiaineVlkLukkoApiFp(this.configuration).lockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, If_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} oppiaineId 
     * @param {number} kokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusOppiaineVlkLukkoApi
     */
    public unlockPerusopetusOppiaineVlk(perusteId: number, oppiaineId: number, kokonaisuusId: number, options?: any) {
        return PerusopetusOppiaineVlkLukkoApiFp(this.configuration).unlockPerusopetusOppiaineVlk(perusteId, oppiaineId, kokonaisuusId, options)(this.axios, this.basePath);
    }

}


/**
 * PerusopetusVuosiluokkaKokonaisuusLukkoApi - axios parameter creator
 * @export
 */
export const PerusopetusVuosiluokkaKokonaisuusLukkoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling checkLockPerusopetusVlk.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling checkLockPerusopetusVlk.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{kokonaisuusId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusVlk(perusteId: number, kokonaisuusId: number, If_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling lockPerusopetusVlk.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling lockPerusopetusVlk.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{kokonaisuusId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_Match !== undefined && If_Match !== null) {
                localVarHeaderParameter['If-Match'] = String(If_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling unlockPerusopetusVlk.');
            }
            // verify required parameter 'kokonaisuusId' is not null or undefined
            if (kokonaisuusId === null || kokonaisuusId === undefined) {
                throw new RequiredError('kokonaisuusId','Required parameter kokonaisuusId was null or undefined when calling unlockPerusopetusVlk.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/perusopetus/vuosiluokkakokonaisuudet/{kokonaisuusId}/lukko`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"kokonaisuusId"}}`, encodeURIComponent(String(kokonaisuusId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusopetusVuosiluokkaKokonaisuusLukkoApi - functional programming interface
 * @export
 */
export const PerusopetusVuosiluokkaKokonaisuusLukkoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusVuosiluokkaKokonaisuusLukkoApiAxiosParamCreator(configuration).checkLockPerusopetusVlk(perusteId, kokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusVlk(perusteId: number, kokonaisuusId: number, If_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusopetusVuosiluokkaKokonaisuusLukkoApiAxiosParamCreator(configuration).lockPerusopetusVlk(perusteId, kokonaisuusId, If_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusopetusVuosiluokkaKokonaisuusLukkoApiAxiosParamCreator(configuration).unlockPerusopetusVlk(perusteId, kokonaisuusId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusopetusVuosiluokkaKokonaisuusLukkoApi - factory interface
 * @export
 */
export const PerusopetusVuosiluokkaKokonaisuusLukkoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusVuosiluokkaKokonaisuusLukkoApiFp(configuration).checkLockPerusopetusVlk(perusteId, kokonaisuusId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {string} [If_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusopetusVlk(perusteId: number, kokonaisuusId: number, If_Match?: string, options?: any): AxiosPromise<LukkoDto> {
            return PerusopetusVuosiluokkaKokonaisuusLukkoApiFp(configuration).lockPerusopetusVlk(perusteId, kokonaisuusId, If_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} kokonaisuusId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options?: any): AxiosPromise<void> {
            return PerusopetusVuosiluokkaKokonaisuusLukkoApiFp(configuration).unlockPerusopetusVlk(perusteId, kokonaisuusId, options)(axios, basePath);
        },
    };
};

/**
 * PerusopetusVuosiluokkaKokonaisuusLukkoApi - object-oriented interface
 * @export
 * @class PerusopetusVuosiluokkaKokonaisuusLukkoApi
 * @extends {BaseAPI}
 */
export class PerusopetusVuosiluokkaKokonaisuusLukkoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {number} kokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusVuosiluokkaKokonaisuusLukkoApi
     */
    public checkLockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options?: any) {
        return PerusopetusVuosiluokkaKokonaisuusLukkoApiFp(this.configuration).checkLockPerusopetusVlk(perusteId, kokonaisuusId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} kokonaisuusId 
     * @param {string} [If_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusVuosiluokkaKokonaisuusLukkoApi
     */
    public lockPerusopetusVlk(perusteId: number, kokonaisuusId: number, If_Match?: string, options?: any) {
        return PerusopetusVuosiluokkaKokonaisuusLukkoApiFp(this.configuration).lockPerusopetusVlk(perusteId, kokonaisuusId, If_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} kokonaisuusId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusopetusVuosiluokkaKokonaisuusLukkoApi
     */
    public unlockPerusopetusVlk(perusteId: number, kokonaisuusId: number, options?: any) {
        return PerusopetusVuosiluokkaKokonaisuusLukkoApiFp(this.configuration).unlockPerusopetusVlk(perusteId, kokonaisuusId, options)(this.axios, this.basePath);
    }

}


/**
 * PerusteenosatApi - axios parameter creator
 * @export
 */
export const PerusteenosatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {OsaamistavoiteLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaamistavoite(id: number, osaAlueId: number, body?: OsaamistavoiteLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addOsaamistavoite.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling addOsaamistavoite.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalue/{osaAlueId}/osaamistavoite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkinnonOsaOsaAlue(id: number, body?: OsaAlueLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addTutkinnonOsaOsaAlue.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalue`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling checkLockByTutkinnonOsaViite.');
            }
            const localVarPath = `/api/perusteenosat/tutkinnonosaviite/{viiteId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(JSON.stringify(If_None_Match));
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPerusteenOsaLock(id: number, If_None_Match?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling checkPerusteenOsaLock.');
            }
            const localVarPath = `/api/perusteenosat/{id}/lukko`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(JSON.stringify(If_None_Match));
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusteenOsa(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePerusteenOsa.');
            }
            const localVarPath = `/api/perusteenosat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamistavoitteet(id: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOsaamistavoitteet.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling getOsaamistavoitteet.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalue/{osaAlueId}/osaamistavoitteet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwningProjektit(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOwningProjektit.');
            }
            const localVarPath = `/api/perusteenosat/{id}/projektit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsa(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteenOsa.');
            }
            const localVarPath = `/api/perusteenosat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersio(id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteenOsaVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling getPerusteenOsaVersio.');
            }
            const localVarPath = `/api/perusteenosat/{id}/versio/{versioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersioByViite(id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteenOsaVersioByViite.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling getPerusteenOsaVersioByViite.');
            }
            const localVarPath = `/api/perusteenosat/viite/{id}/versio/{versioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersiot(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteenOsaVersiot.');
            }
            const localVarPath = `/api/perusteenosat/{id}/versiot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaViiteLapsilla(perusteId: number, viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenOsaViiteLapsilla.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getPerusteenOsaViiteLapsilla.');
            }
            const localVarPath = `/api/perusteenosat/peruste/{perusteId}/viite/{viiteId}/`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaViiteVersiot(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteenOsaViiteVersiot.');
            }
            const localVarPath = `/api/perusteenosat/viite/{id}/versiot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nimi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatAllWithName(nimi: string, options: any = {}): RequestArgs {
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling getPerusteenOsatAllWithName.');
            }
            const localVarPath = `/api/perusteenosat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatByViite(viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getPerusteenOsatByViite.');
            }
            const localVarPath = `/api/perusteenosat/viite/{viiteId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatByViiteSisalto(viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getPerusteenOsatByViiteSisalto.');
            }
            const localVarPath = `/api/perusteenosat/viite/{viiteId}/sisalto`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getTutkinnonOsaOsaAlue.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling getTutkinnonOsaOsaAlue.');
            }
            const localVarPath = `/api/perusteenosat/{viiteId}/osaalue/{osaAlueId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlueet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTutkinnonOsaOsaAlueet.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalueet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlueetVersio(id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTutkinnonOsaOsaAlueetVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling getTutkinnonOsaOsaAlueetVersio.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalueet/versio/{versioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling lockByTutkinnonOsaViite.');
            }
            const localVarPath = `/api/perusteenosat/tutkinnonosaviite/{viiteId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(JSON.stringify(If_None_Match));
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockByTutkinnonOsaViitePut(viiteId: number, If_None_Match?: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling lockByTutkinnonOsaViitePut.');
            }
            const localVarPath = `/api/perusteenosat/tutkinnonosaviite/{viiteId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(JSON.stringify(If_None_Match));
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusteenOsa(id: number, If_None_Match?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling lockPerusteenOsa.');
            }
            const localVarPath = `/api/perusteenosat/{id}/lukko`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(JSON.stringify(If_None_Match));
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusteenOsaPut(id: number, If_None_Match?: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling lockPerusteenOsaPut.');
            }
            const localVarPath = `/api/perusteenosat/{id}/lukko`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(JSON.stringify(If_None_Match));
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAlue(id: number, osaAlueId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeOsaAlue.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling removeOsaAlue.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalue/{osaAlueId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {number} osaamistavoiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeOsaamistavoite.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling removeOsaamistavoite.');
            }
            // verify required parameter 'osaamistavoiteId' is not null or undefined
            if (osaamistavoiteId === null || osaamistavoiteId === undefined) {
                throw new RequiredError('osaamistavoiteId','Required parameter osaamistavoiteId was null or undefined when calling removeOsaamistavoite.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalue/{osaAlueId}/osaamistavoite/{osaamistavoiteId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)))
                .replace(`{${"osaamistavoiteId"}}`, encodeURIComponent(String(osaamistavoiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertPerusteenOsaToVersio(id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling revertPerusteenOsaToVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling revertPerusteenOsaToVersio.');
            }
            const localVarPath = `/api/perusteenosat/{id}/palauta/{versioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockByTutkinnonOsaViite(viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling unlockByTutkinnonOsaViite.');
            }
            const localVarPath = `/api/perusteenosat/tutkinnonosaviite/{viiteId}/lukko`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusteenOsa(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unlockPerusteenOsa.');
            }
            const localVarPath = `/api/perusteenosat/{id}/lukko`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {number} osaamistavoiteId 
         * @param {OsaamistavoiteLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, body?: OsaamistavoiteLaajaDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOsaamistavoite.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling updateOsaamistavoite.');
            }
            // verify required parameter 'osaamistavoiteId' is not null or undefined
            if (osaamistavoiteId === null || osaamistavoiteId === undefined) {
                throw new RequiredError('osaamistavoiteId','Required parameter osaamistavoiteId was null or undefined when calling updateOsaamistavoite.');
            }
            const localVarPath = `/api/perusteenosat/{id}/osaalue/{osaAlueId}/osaamistavoite/{osaamistavoiteId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)))
                .replace(`{${"osaamistavoiteId"}}`, encodeURIComponent(String(osaamistavoiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PerusteenOsaUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenOsa(id: number, body?: PerusteenOsaUpdateDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePerusteenOsa.');
            }
            const localVarPath = `/api/perusteenosat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {PerusteenOsaUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenOsaPerusteella(viiteId: number, perusteId: number, id: number, body?: PerusteenOsaUpdateDto, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling updatePerusteenOsaPerusteella.');
            }
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updatePerusteenOsaPerusteella.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePerusteenOsaPerusteella.');
            }
            const localVarPath = `/api/perusteenosat/{id}/viite/{viiteId}/peruste/{perusteId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueKokonaanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, body?: OsaAlueKokonaanDto, options: any = {}): RequestArgs {
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling updateTutkinnonOsaOsaAlue.');
            }
            // verify required parameter 'osaAlueId' is not null or undefined
            if (osaAlueId === null || osaAlueId === undefined) {
                throw new RequiredError('osaAlueId','Required parameter osaAlueId was null or undefined when calling updateTutkinnonOsaOsaAlue.');
            }
            const localVarPath = `/api/perusteenosat/{viiteId}/osaalue/{osaAlueId}`
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)))
                .replace(`{${"osaAlueId"}}`, encodeURIComponent(String(osaAlueId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusteenosatApi - functional programming interface
 * @export
 */
export const PerusteenosatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {OsaamistavoiteLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaamistavoite(id: number, osaAlueId: number, body?: OsaamistavoiteLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamistavoiteLaajaDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).addOsaamistavoite(id, osaAlueId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkinnonOsaOsaAlue(id: number, body?: OsaAlueLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueLaajaDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).addTutkinnonOsaOsaAlue(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).checkLockByTutkinnonOsaViite(viiteId, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPerusteenOsaLock(id: number, If_None_Match?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).checkPerusteenOsaLock(id, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusteenOsa(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).deletePerusteenOsa(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamistavoitteet(id: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaamistavoiteLaajaDto>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getOsaamistavoitteet(id, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwningProjektit(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteprojektinPerusteenosaDto>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getOwningProjektit(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsa(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsa(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersio(id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsaVersio(id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersioByViite(id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteenOsaDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsaVersioByViite(id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersiot(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsaVersiot(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaViiteLapsilla(perusteId: number, viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Puu> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsaViiteLapsilla(perusteId, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaViiteVersiot(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsaViiteVersiot(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} nimi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatAllWithName(nimi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Suppea>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsatAllWithName(nimi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatByViite(viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsatByViite(viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatByViiteSisalto(viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getPerusteenOsatByViiteSisalto(viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueKokonaanDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getTutkinnonOsaOsaAlue(viiteId, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlueet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaAlueKokonaanDto>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getTutkinnonOsaOsaAlueet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlueetVersio(id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OsaAlueKokonaanDto>> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).getTutkinnonOsaOsaAlueetVersio(id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).lockByTutkinnonOsaViite(viiteId, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockByTutkinnonOsaViitePut(viiteId: number, If_None_Match?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).lockByTutkinnonOsaViitePut(viiteId, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusteenOsa(id: number, If_None_Match?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).lockPerusteenOsa(id, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusteenOsaPut(id: number, If_None_Match?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LukkoDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).lockPerusteenOsaPut(id, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAlue(id: number, osaAlueId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).removeOsaAlue(id, osaAlueId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {number} osaamistavoiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).removeOsaamistavoite(id, osaAlueId, osaamistavoiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertPerusteenOsaToVersio(id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).revertPerusteenOsaToVersio(id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockByTutkinnonOsaViite(viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).unlockByTutkinnonOsaViite(viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusteenOsa(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).unlockPerusteenOsa(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {number} osaamistavoiteId 
         * @param {OsaamistavoiteLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, body?: OsaamistavoiteLaajaDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaamistavoiteLaajaDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).updateOsaamistavoite(id, osaAlueId, osaamistavoiteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PerusteenOsaUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenOsa(id: number, body?: PerusteenOsaUpdateDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).updatePerusteenOsa(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {PerusteenOsaUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenOsaPerusteella(viiteId: number, perusteId: number, id: number, body?: PerusteenOsaUpdateDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).updatePerusteenOsaPerusteella(viiteId, perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueKokonaanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, body?: OsaAlueKokonaanDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OsaAlueKokonaanDto> {
            const localVarAxiosArgs = PerusteenosatApiAxiosParamCreator(configuration).updateTutkinnonOsaOsaAlue(viiteId, osaAlueId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusteenosatApi - factory interface
 * @export
 */
export const PerusteenosatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {OsaamistavoiteLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOsaamistavoite(id: number, osaAlueId: number, body?: OsaamistavoiteLaajaDto, options?: any): AxiosPromise<OsaamistavoiteLaajaDto> {
            return PerusteenosatApiFp(configuration).addOsaamistavoite(id, osaAlueId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {OsaAlueLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkinnonOsaOsaAlue(id: number, body?: OsaAlueLaajaDto, options?: any): AxiosPromise<OsaAlueLaajaDto> {
            return PerusteenosatApiFp(configuration).addTutkinnonOsaOsaAlue(id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusteenosatApiFp(configuration).checkLockByTutkinnonOsaViite(viiteId, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPerusteenOsaLock(id: number, If_None_Match?: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusteenosatApiFp(configuration).checkPerusteenOsaLock(id, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerusteenOsa(id: number, options?: any): AxiosPromise<void> {
            return PerusteenosatApiFp(configuration).deletePerusteenOsa(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamistavoitteet(id: number, osaAlueId: number, options?: any): AxiosPromise<Array<OsaamistavoiteLaajaDto>> {
            return PerusteenosatApiFp(configuration).getOsaamistavoitteet(id, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwningProjektit(id: number, options?: any): AxiosPromise<Array<PerusteprojektinPerusteenosaDto>> {
            return PerusteenosatApiFp(configuration).getOwningProjektit(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsa(id: number, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).getPerusteenOsa(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersio(id: number, versioId: number, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).getPerusteenOsaVersio(id, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersioByViite(id: number, versioId: number, options?: any): AxiosPromise<PerusteenOsaDto> {
            return PerusteenosatApiFp(configuration).getPerusteenOsaVersioByViite(id, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaVersiot(id: number, options?: any): AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            return PerusteenosatApiFp(configuration).getPerusteenOsaVersiot(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaViiteLapsilla(perusteId: number, viiteId: number, options?: any): AxiosPromise<Puu> {
            return PerusteenosatApiFp(configuration).getPerusteenOsaViiteLapsilla(perusteId, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsaViiteVersiot(id: number, options?: any): AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            return PerusteenosatApiFp(configuration).getPerusteenOsaViiteVersiot(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} nimi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatAllWithName(nimi: string, options?: any): AxiosPromise<Array<Suppea>> {
            return PerusteenosatApiFp(configuration).getPerusteenOsatAllWithName(nimi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatByViite(viiteId: number, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).getPerusteenOsatByViite(viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenOsatByViiteSisalto(viiteId: number, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).getPerusteenOsatByViiteSisalto(viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, options?: any): AxiosPromise<OsaAlueKokonaanDto> {
            return PerusteenosatApiFp(configuration).getTutkinnonOsaOsaAlue(viiteId, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlueet(id: number, options?: any): AxiosPromise<Array<OsaAlueKokonaanDto>> {
            return PerusteenosatApiFp(configuration).getTutkinnonOsaOsaAlueet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaOsaAlueetVersio(id: number, versioId: number, options?: any): AxiosPromise<Array<OsaAlueKokonaanDto>> {
            return PerusteenosatApiFp(configuration).getTutkinnonOsaOsaAlueetVersio(id, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusteenosatApiFp(configuration).lockByTutkinnonOsaViite(viiteId, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockByTutkinnonOsaViitePut(viiteId: number, If_None_Match?: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusteenosatApiFp(configuration).lockByTutkinnonOsaViitePut(viiteId, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusteenOsa(id: number, If_None_Match?: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusteenosatApiFp(configuration).lockPerusteenOsa(id, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockPerusteenOsaPut(id: number, If_None_Match?: number, options?: any): AxiosPromise<LukkoDto> {
            return PerusteenosatApiFp(configuration).lockPerusteenOsaPut(id, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaAlue(id: number, osaAlueId: number, options?: any): AxiosPromise<void> {
            return PerusteenosatApiFp(configuration).removeOsaAlue(id, osaAlueId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {number} osaamistavoiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, options?: any): AxiosPromise<void> {
            return PerusteenosatApiFp(configuration).removeOsaamistavoite(id, osaAlueId, osaamistavoiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertPerusteenOsaToVersio(id: number, versioId: number, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).revertPerusteenOsaToVersio(id, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockByTutkinnonOsaViite(viiteId: number, options?: any): AxiosPromise<void> {
            return PerusteenosatApiFp(configuration).unlockByTutkinnonOsaViite(viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockPerusteenOsa(id: number, options?: any): AxiosPromise<void> {
            return PerusteenosatApiFp(configuration).unlockPerusteenOsa(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} osaAlueId 
         * @param {number} osaamistavoiteId 
         * @param {OsaamistavoiteLaajaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, body?: OsaamistavoiteLaajaDto, options?: any): AxiosPromise<OsaamistavoiteLaajaDto> {
            return PerusteenosatApiFp(configuration).updateOsaamistavoite(id, osaAlueId, osaamistavoiteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PerusteenOsaUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenOsa(id: number, body?: PerusteenOsaUpdateDto, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).updatePerusteenOsa(id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {PerusteenOsaUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenOsaPerusteella(viiteId: number, perusteId: number, id: number, body?: PerusteenOsaUpdateDto, options?: any): AxiosPromise<Laaja> {
            return PerusteenosatApiFp(configuration).updatePerusteenOsaPerusteella(viiteId, perusteId, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} viiteId 
         * @param {number} osaAlueId 
         * @param {OsaAlueKokonaanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, body?: OsaAlueKokonaanDto, options?: any): AxiosPromise<OsaAlueKokonaanDto> {
            return PerusteenosatApiFp(configuration).updateTutkinnonOsaOsaAlue(viiteId, osaAlueId, body, options)(axios, basePath);
        },
    };
};

/**
 * PerusteenosatApi - object-oriented interface
 * @export
 * @class PerusteenosatApi
 * @extends {BaseAPI}
 */
export class PerusteenosatApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {number} osaAlueId 
     * @param {OsaamistavoiteLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public addOsaamistavoite(id: number, osaAlueId: number, body?: OsaamistavoiteLaajaDto, options?: any) {
        return PerusteenosatApiFp(this.configuration).addOsaamistavoite(id, osaAlueId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {OsaAlueLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public addTutkinnonOsaOsaAlue(id: number, body?: OsaAlueLaajaDto, options?: any) {
        return PerusteenosatApiFp(this.configuration).addTutkinnonOsaOsaAlue(id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public checkLockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).checkLockByTutkinnonOsaViite(viiteId, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public checkPerusteenOsaLock(id: number, If_None_Match?: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).checkPerusteenOsaLock(id, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public deletePerusteenOsa(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).deletePerusteenOsa(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getOsaamistavoitteet(id: number, osaAlueId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getOsaamistavoitteet(id, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getOwningProjektit(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getOwningProjektit(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsa(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsa(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsaVersio(id: number, versioId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsaVersio(id, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsaVersioByViite(id: number, versioId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsaVersioByViite(id, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsaVersiot(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsaVersiot(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsaViiteLapsilla(perusteId: number, viiteId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsaViiteLapsilla(perusteId, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsaViiteVersiot(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsaViiteVersiot(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} nimi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsatAllWithName(nimi: string, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsatAllWithName(nimi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsatByViite(viiteId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsatByViite(viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getPerusteenOsatByViiteSisalto(viiteId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getPerusteenOsatByViiteSisalto(viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getTutkinnonOsaOsaAlue(viiteId, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getTutkinnonOsaOsaAlueet(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getTutkinnonOsaOsaAlueet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public getTutkinnonOsaOsaAlueetVersio(id: number, versioId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).getTutkinnonOsaOsaAlueetVersio(id, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public lockByTutkinnonOsaViite(viiteId: number, If_None_Match?: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).lockByTutkinnonOsaViite(viiteId, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public lockByTutkinnonOsaViitePut(viiteId: number, If_None_Match?: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).lockByTutkinnonOsaViitePut(viiteId, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public lockPerusteenOsa(id: number, If_None_Match?: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).lockPerusteenOsa(id, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public lockPerusteenOsaPut(id: number, If_None_Match?: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).lockPerusteenOsaPut(id, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} osaAlueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public removeOsaAlue(id: number, osaAlueId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).removeOsaAlue(id, osaAlueId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} osaAlueId 
     * @param {number} osaamistavoiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public removeOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).removeOsaamistavoite(id, osaAlueId, osaamistavoiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public revertPerusteenOsaToVersio(id: number, versioId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).revertPerusteenOsaToVersio(id, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public unlockByTutkinnonOsaViite(viiteId: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).unlockByTutkinnonOsaViite(viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public unlockPerusteenOsa(id: number, options?: any) {
        return PerusteenosatApiFp(this.configuration).unlockPerusteenOsa(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} osaAlueId 
     * @param {number} osaamistavoiteId 
     * @param {OsaamistavoiteLaajaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public updateOsaamistavoite(id: number, osaAlueId: number, osaamistavoiteId: number, body?: OsaamistavoiteLaajaDto, options?: any) {
        return PerusteenosatApiFp(this.configuration).updateOsaamistavoite(id, osaAlueId, osaamistavoiteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {PerusteenOsaUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public updatePerusteenOsa(id: number, body?: PerusteenOsaUpdateDto, options?: any) {
        return PerusteenosatApiFp(this.configuration).updatePerusteenOsa(id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {PerusteenOsaUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public updatePerusteenOsaPerusteella(viiteId: number, perusteId: number, id: number, body?: PerusteenOsaUpdateDto, options?: any) {
        return PerusteenosatApiFp(this.configuration).updatePerusteenOsaPerusteella(viiteId, perusteId, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} viiteId 
     * @param {number} osaAlueId 
     * @param {OsaAlueKokonaanDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteenosatApi
     */
    public updateTutkinnonOsaOsaAlue(viiteId: number, osaAlueId: number, body?: OsaAlueKokonaanDto, options?: any) {
        return PerusteenosatApiFp(this.configuration).updateTutkinnonOsaOsaAlue(viiteId, osaAlueId, body, options)(this.axios, this.basePath);
    }

}


/**
 * PerusteetApi - axios parameter creator
 * @export
 */
export const PerusteetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {TutkintonimikeKoodiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkintonimikekoodi(perusteId: number, body?: TutkintonimikeKoodiDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addTutkintonimikekoodi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkintonimikekoodit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {TutkintonimikeKoodiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkintonimikekoodiPut(perusteId: number, body?: TutkintonimikeKoodiDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addTutkintonimikekoodiPut.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkintonimikekoodit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaidenPerusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/oppaiden`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusopetus(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/perusopetus`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteiden sisäinen haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {boolean} [tuleva] hae myös tulevatperusteet
         * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {boolean} [poistunut] hae myös poistuneet perusteet
         * @param {string} [nimi] 
         * @param {Array<string>} [koulutusala] 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [kieli] perusteen kieli
         * @param {Array<string>} [opintoala] opintoalakoodi
         * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
         * @param {string} [koulutuskoodi] 
         * @param {string} [diaarinumero] 
         * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
         * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
         * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
         * @param {boolean} [osaamisalat] hae myös osaamisaloista
         * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
         * @param {string} [perusteTyyppi] Perusteen tyyppi
         * @param {boolean} [julkaistu] julkaistut perusteet
         * @param {Array<string>} [tutkinnonosaKoodit] 
         * @param {Array<string>} [osaamisalaKoodit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteet(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, perusteTyyppi?: string, julkaistu?: boolean, tutkinnonosaKoodit?: Array<string>, osaamisalaKoodit?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (siirtyma !== undefined) {
                localVarQueryParameter['siirtyma'] = siirtyma;
            }

            if (voimassaolo !== undefined) {
                localVarQueryParameter['voimassaolo'] = voimassaolo;
            }

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (koulutusala) {
                localVarQueryParameter['koulutusala'] = koulutusala;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (kieli) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (opintoala) {
                localVarQueryParameter['opintoala'] = opintoala;
            }

            if (suoritustapa !== undefined) {
                localVarQueryParameter['suoritustapa'] = suoritustapa;
            }

            if (koulutuskoodi !== undefined) {
                localVarQueryParameter['koulutuskoodi'] = koulutuskoodi;
            }

            if (diaarinumero !== undefined) {
                localVarQueryParameter['diaarinumero'] = diaarinumero;
            }

            if (muokattu !== undefined) {
                localVarQueryParameter['muokattu'] = muokattu;
            }

            if (tutkintonimikkeet !== undefined) {
                localVarQueryParameter['tutkintonimikkeet'] = tutkintonimikkeet;
            }

            if (tutkinnonosat !== undefined) {
                localVarQueryParameter['tutkinnonosat'] = tutkinnonosat;
            }

            if (osaamisalat !== undefined) {
                localVarQueryParameter['osaamisalat'] = osaamisalat;
            }

            if (koulutusvienti !== undefined) {
                localVarQueryParameter['koulutusvienti'] = koulutusvienti;
            }

            if (perusteTyyppi !== undefined) {
                localVarQueryParameter['perusteTyyppi'] = perusteTyyppi;
            }

            if (julkaistu !== undefined) {
                localVarQueryParameter['julkaistu'] = julkaistu;
            }

            if (tutkinnonosaKoodit) {
                localVarQueryParameter['tutkinnonosaKoodit'] = tutkinnonosaKoodit;
            }

            if (osaamisalaKoodit) {
                localVarQueryParameter['osaamisalaKoodit'] = osaamisalaKoodit;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteetInfo(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteiden sisäinen haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {boolean} [tuleva] hae myös tulevatperusteet
         * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {boolean} [poistunut] hae myös poistuneet perusteet
         * @param {string} [nimi] 
         * @param {Array<string>} [koulutusala] 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [kieli] perusteen kieli
         * @param {Array<string>} [opintoala] opintoalakoodi
         * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
         * @param {string} [koulutuskoodi] 
         * @param {string} [diaarinumero] 
         * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
         * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
         * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
         * @param {boolean} [osaamisalat] hae myös osaamisaloista
         * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
         * @param {Array<string>} [tila] Sallitut tilat
         * @param {string} [perusteTyyppi] Perusteen tyyppi
         * @param {boolean} [julkaistu] julkaistut perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteetInternal(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, tila?: Array<string>, perusteTyyppi?: string, julkaistu?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/internal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (tuleva !== undefined) {
                localVarQueryParameter['tuleva'] = tuleva;
            }

            if (siirtyma !== undefined) {
                localVarQueryParameter['siirtyma'] = siirtyma;
            }

            if (voimassaolo !== undefined) {
                localVarQueryParameter['voimassaolo'] = voimassaolo;
            }

            if (poistunut !== undefined) {
                localVarQueryParameter['poistunut'] = poistunut;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (koulutusala) {
                localVarQueryParameter['koulutusala'] = koulutusala;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }

            if (kieli) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (opintoala) {
                localVarQueryParameter['opintoala'] = opintoala;
            }

            if (suoritustapa !== undefined) {
                localVarQueryParameter['suoritustapa'] = suoritustapa;
            }

            if (koulutuskoodi !== undefined) {
                localVarQueryParameter['koulutuskoodi'] = koulutuskoodi;
            }

            if (diaarinumero !== undefined) {
                localVarQueryParameter['diaarinumero'] = diaarinumero;
            }

            if (muokattu !== undefined) {
                localVarQueryParameter['muokattu'] = muokattu;
            }

            if (tutkintonimikkeet !== undefined) {
                localVarQueryParameter['tutkintonimikkeet'] = tutkintonimikkeet;
            }

            if (tutkinnonosat !== undefined) {
                localVarQueryParameter['tutkinnonosat'] = tutkinnonosat;
            }

            if (osaamisalat !== undefined) {
                localVarQueryParameter['osaamisalat'] = osaamisalat;
            }

            if (koulutusvienti !== undefined) {
                localVarQueryParameter['koulutusvienti'] = koulutusvienti;
            }

            if (tila) {
                localVarQueryParameter['tila'] = tila;
            }

            if (perusteTyyppi !== undefined) {
                localVarQueryParameter['perusteTyyppi'] = perusteTyyppi;
            }

            if (julkaistu !== undefined) {
                localVarQueryParameter['julkaistu'] = julkaistu;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmmattitaitovaatimukset(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAmmattitaitovaatimukset.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkinnonosat/ammattitaitovaatimukset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paikallisen puolen ammatillista laadintaa tukevat perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaOpsit(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/amosaaops`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Amosaa jaetun tutkinnon pohja
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaPohja(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/amosaapohja`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen yksilöintietojen haku diaarinumerolla
         * @param {string} [diaarinumero] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByDiaari(diaarinumero?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/diaari`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (diaarinumero !== undefined) {
                localVarQueryParameter['diaarinumero'] = diaarinumero;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Julkaistut perustekoulutustyypit annetulla kielellä
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutKoulutustyypit(kieli: string, options: any = {}): RequestArgs {
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getJulkaistutKoulutustyypit.');
            }
            const localVarPath = `/api/perusteet/julkaistutkoulutustyypit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Julkaistut perustekoulutustyypit annetulla kielellä
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutKoulutustyyppiLukumaarat(kieli: string, options: any = {}): RequestArgs {
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getJulkaistutKoulutustyyppiLukumaarat.');
            }
            const localVarPath = `/api/perusteet/julkaistutkoulutustyyppimaarat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perusteiden koulutustyyppikohtaiset lukumäärät
         * @param {Array<string>} koulutustyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutLukumaarilla(koulutustyyppi: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'koulutustyyppi' is not null or undefined
            if (koulutustyyppi === null || koulutustyyppi === undefined) {
                throw new RequiredError('koulutustyyppi','Required parameter koulutustyyppi was null or undefined when calling getJulkaistutLukumaarilla.');
            }
            const localVarPath = `/api/perusteet/lukumaara`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteet(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/internal/julkaistut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen tutkinnon osien viitteiden haku julkaistusta datasta
         * @param {number} perusteId 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutTutkinnonOsaViitteet(perusteId: number, useCurrentData?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaistutTutkinnonOsaViitteet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kaikki/tutkinnonosaviitteet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (useCurrentData !== undefined) {
                localVarQueryParameter['useCurrentData'] = useCurrentData;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen tutkinnon osien haku julkaistusta datasta
         * @param {number} perusteId 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutTutkinnonOsat(perusteId: number, useCurrentData?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getJulkaistutTutkinnonOsat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kaikki/tutkinnonosat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (useCurrentData !== undefined) {
                localVarQueryParameter['useCurrentData'] = useCurrentData;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perusteet julkisilla aikatauluillla
         * @param {number} sivu 
         * @param {number} sivukoko 
         * @param {Array<string>} koulutustyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisuAikatauluPerusteet(sivu: number, sivukoko: number, koulutustyyppi: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'sivu' is not null or undefined
            if (sivu === null || sivu === undefined) {
                throw new RequiredError('sivu','Required parameter sivu was null or undefined when calling getJulkaisuAikatauluPerusteet.');
            }
            // verify required parameter 'sivukoko' is not null or undefined
            if (sivukoko === null || sivukoko === undefined) {
                throw new RequiredError('sivukoko','Required parameter sivukoko was null or undefined when calling getJulkaisuAikatauluPerusteet.');
            }
            // verify required parameter 'koulutustyyppi' is not null or undefined
            if (koulutustyyppi === null || koulutustyyppi === undefined) {
                throw new RequiredError('koulutustyyppi','Required parameter koulutustyyppi was null or undefined when calling getJulkaisuAikatauluPerusteet.');
            }
            const localVarPath = `/api/perusteet/aikataululliset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (koulutustyyppi) {
                localVarQueryParameter['koulutustyyppi'] = koulutustyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen kaikkien tietojen haku
         * @param {number} perusteId 
         * @param {number} [rev] 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKokoSisalto(perusteId: number, rev?: number, useCurrentData?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKokoSisalto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kaikki`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rev !== undefined) {
                localVarQueryParameter['rev'] = rev;
            }

            if (useCurrentData !== undefined) {
                localVarQueryParameter['useCurrentData'] = useCurrentData;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKvLiite(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getKvLiite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kvliite`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeta(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getMeta.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/meta`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(perusteId: number, kieli?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getNavigation.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/navigaatio`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} [kieli] 
         * @param {boolean} [esikatselu] 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationPublic(perusteId: number, kieli?: string, esikatselu?: boolean, rev?: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getNavigationPublic.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/navigaatio/public`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (esikatselu !== undefined) {
                localVarQueryParameter['esikatselu'] = esikatselu;
            }

            if (rev !== undefined) {
                localVarQueryParameter['rev'] = rev;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Oppaat joihin kiinnitetty koodiUri
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpasKiinnitettyKoodi(koodiUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodiUri' is not null or undefined
            if (koodiUri === null || koodiUri === undefined) {
                throw new RequiredError('koodiUri','Required parameter koodiUri was null or undefined when calling getOpasKiinnitettyKoodi.');
            }
            const localVarPath = `/api/perusteet/opaskoodikiinnitys/{koodiUri}`
                .replace(`{${"koodiUri"}}`, encodeURIComponent(String(koodiUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen osaamisalojen kuvaukset koulutustarjontaa varten
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamisalat(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getOsaamisalat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/osaamisalakuvaukset`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteKooste(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/kooste`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteprojektin tila
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteProjektiTila(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteProjektiTila.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/projektitila`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen uusin versio
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteVersion(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteVersion.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/version`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenTiedot(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenTiedot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteetWithTekstikappaleKoodi(koodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodi' is not null or undefined
            if (koodi === null || koodi === undefined) {
                throw new RequiredError('koodi','Required parameter koodi was null or undefined when calling getPerusteetWithTekstikappaleKoodi.');
            }
            const localVarPath = `/api/perusteet/tekstikappale`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koodi !== undefined) {
                localVarQueryParameter['koodi'] = koodi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [perustetyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjaperusteet(perustetyyppi?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/internal/pohjat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perustetyyppi !== undefined) {
                localVarQueryParameter['perustetyyppi'] = perustetyyppi;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuoritustapa(perusteId: number, suoritustapakoodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getSuoritustapa.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getSuoritustapa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkintonimikekoodit(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getTutkintonimikekoodit.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkintonimikekoodit`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUusimmatPerusteet(kieli: string, options: any = {}): RequestArgs {
            // verify required parameter 'kieli' is not null or undefined
            if (kieli === null || kieli === undefined) {
                throw new RequiredError('kieli','Required parameter kieli was null or undefined when calling getUusimmatPerusteet.');
            }
            const localVarPath = `/api/perusteet/uusimmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValittavatKielet(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteet/valittavatkielet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushAmmattitaitovaatimuksetToKoodisto(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling pushAmmattitaitovaatimuksetToKoodisto.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkinnonosat/ammattitaitovaatimuskoodisto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} tutkintonimikeKoodiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutkintonimikekoodi(perusteId: number, tutkintonimikeKoodiId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeTutkintonimikekoodi.');
            }
            // verify required parameter 'tutkintonimikeKoodiId' is not null or undefined
            if (tutkintonimikeKoodiId === null || tutkintonimikeKoodiId === undefined) {
                throw new RequiredError('tutkintonimikeKoodiId','Required parameter tutkintonimikeKoodiId was null or undefined when calling removeTutkintonimikekoodi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkintonimikekoodit/{tutkintonimikeKoodiId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"tutkintonimikeKoodiId"}}`, encodeURIComponent(String(tutkintonimikeKoodiId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {KVLiiteJulkinenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKvLiite(perusteId: number, body?: KVLiiteJulkinenDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateKvLiite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/kvliite`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<KoodiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamisalat(perusteId: number, body?: Array<KoodiDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateOsaamisalat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/osaamisalat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {PerusteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePeruste(perusteId: number, body?: PerusteDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updatePeruste.');
            }
            const localVarPath = `/api/perusteet/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<TutkintonimikeKoodiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkintonimikkeet(perusteId: number, body?: Array<TutkintonimikeKoodiDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateTutkintonimikkeet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/tutkintonimikkeet`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusteetApi - functional programming interface
 * @export
 */
export const PerusteetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {TutkintonimikeKoodiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkintonimikekoodi(perusteId: number, body?: TutkintonimikeKoodiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkintonimikeKoodiDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).addTutkintonimikekoodi(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {TutkintonimikeKoodiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkintonimikekoodiPut(perusteId: number, body?: TutkintonimikeKoodiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkintonimikeKoodiDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).addTutkintonimikekoodiPut(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaidenPerusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteKevytDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAllOppaidenPerusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusopetus(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteInfoDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAllPerusopetus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteiden sisäinen haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {boolean} [tuleva] hae myös tulevatperusteet
         * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {boolean} [poistunut] hae myös poistuneet perusteet
         * @param {string} [nimi] 
         * @param {Array<string>} [koulutusala] 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [kieli] perusteen kieli
         * @param {Array<string>} [opintoala] opintoalakoodi
         * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
         * @param {string} [koulutuskoodi] 
         * @param {string} [diaarinumero] 
         * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
         * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
         * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
         * @param {boolean} [osaamisalat] hae myös osaamisaloista
         * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
         * @param {string} [perusteTyyppi] Perusteen tyyppi
         * @param {boolean} [julkaistu] julkaistut perusteet
         * @param {Array<string>} [tutkinnonosaKoodit] 
         * @param {Array<string>} [osaamisalaKoodit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteet(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, perusteTyyppi?: string, julkaistu?: boolean, tutkinnonosaKoodit?: Array<string>, osaamisalaKoodit?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteHakuDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAllPerusteet(sivu, sivukoko, tuleva, siirtyma, voimassaolo, poistunut, nimi, koulutusala, koulutustyyppi, kieli, opintoala, suoritustapa, koulutuskoodi, diaarinumero, muokattu, tutkintonimikkeet, tutkinnonosat, osaamisalat, koulutusvienti, perusteTyyppi, julkaistu, tutkinnonosaKoodit, osaamisalaKoodit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteetInfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteInfoDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAllPerusteetInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteiden sisäinen haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {boolean} [tuleva] hae myös tulevatperusteet
         * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {boolean} [poistunut] hae myös poistuneet perusteet
         * @param {string} [nimi] 
         * @param {Array<string>} [koulutusala] 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [kieli] perusteen kieli
         * @param {Array<string>} [opintoala] opintoalakoodi
         * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
         * @param {string} [koulutuskoodi] 
         * @param {string} [diaarinumero] 
         * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
         * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
         * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
         * @param {boolean} [osaamisalat] hae myös osaamisaloista
         * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
         * @param {Array<string>} [tila] Sallitut tilat
         * @param {string} [perusteTyyppi] Perusteen tyyppi
         * @param {boolean} [julkaistu] julkaistut perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteetInternal(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, tila?: Array<string>, perusteTyyppi?: string, julkaistu?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteHakuInternalDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAllPerusteetInternal(sivu, sivukoko, tuleva, siirtyma, voimassaolo, poistunut, nimi, koulutusala, koulutustyyppi, kieli, opintoala, suoritustapa, koulutuskoodi, diaarinumero, muokattu, tutkintonimikkeet, tutkinnonosat, osaamisalat, koulutusvienti, tila, perusteTyyppi, julkaistu, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmmattitaitovaatimukset(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ammattitaitovaatimus2019Dto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAmmattitaitovaatimukset(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Paikallisen puolen ammatillista laadintaa tukevat perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaOpsit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteHakuDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAmosaaOpsit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Amosaa jaetun tutkinnon pohja
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaPohja(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getAmosaaPohja(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen yksilöintietojen haku diaarinumerolla
         * @param {string} [diaarinumero] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByDiaari(diaarinumero?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteInfoDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getByDiaari(diaarinumero, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Julkaistut perustekoulutustyypit annetulla kielellä
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutKoulutustyypit(kieli: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaistutKoulutustyypit(kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Julkaistut perustekoulutustyypit annetulla kielellä
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutKoulutustyyppiLukumaarat(kieli: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustyyppiLukumaara>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaistutKoulutustyyppiLukumaarat(kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Perusteiden koulutustyyppikohtaiset lukumäärät
         * @param {Array<string>} koulutustyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutLukumaarilla(koulutustyyppi: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoulutustyyppiLukumaara>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaistutLukumaarilla(koulutustyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteKevytDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaistutPerusteet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen tutkinnon osien viitteiden haku julkaistusta datasta
         * @param {number} perusteId 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutTutkinnonOsaViitteet(perusteId: number, useCurrentData?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaViiteSuppeaDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaistutTutkinnonOsaViitteet(perusteId, useCurrentData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen tutkinnon osien haku julkaistusta datasta
         * @param {number} perusteId 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutTutkinnonOsat(perusteId: number, useCurrentData?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaKaikkiDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaistutTutkinnonOsat(perusteId, useCurrentData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Perusteet julkisilla aikatauluillla
         * @param {number} sivu 
         * @param {number} sivukoko 
         * @param {Array<string>} koulutustyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisuAikatauluPerusteet(sivu: number, sivukoko: number, koulutustyyppi: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteBaseDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getJulkaisuAikatauluPerusteet(sivu, sivukoko, koulutustyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen kaikkien tietojen haku
         * @param {number} perusteId 
         * @param {number} [rev] 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKokoSisalto(perusteId: number, rev?: number, useCurrentData?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteKaikkiDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getKokoSisalto(perusteId, rev, useCurrentData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKvLiite(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getKvLiite(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeta(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteInfoDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getMeta(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(perusteId: number, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getNavigation(perusteId, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} [kieli] 
         * @param {boolean} [esikatselu] 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationPublic(perusteId: number, kieli?: string, esikatselu?: boolean, rev?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NavigationNodeDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getNavigationPublic(perusteId, kieli, esikatselu, rev, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Oppaat joihin kiinnitetty koodiUri
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpasKiinnitettyKoodi(koodiUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getOpasKiinnitettyKoodi(koodiUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen osaamisalojen kuvaukset koulutustarjontaa varten
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamisalat(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<TekstiKappaleDto>; }; }> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getOsaamisalat(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteKooste(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteKoosteDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteKooste(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteprojektin tila
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteProjektiTila(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteProjektiTila(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen uusin versio
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteVersion(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteVersionDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteVersion(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenTiedot(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteenTiedot(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteetWithTekstikappaleKoodi(koodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteTekstikappaleillaDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPerusteetWithTekstikappaleKoodi(koodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [perustetyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjaperusteet(perustetyyppi?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteKevytDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getPohjaperusteet(perustetyyppi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuoritustapa(perusteId: number, suoritustapakoodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuoritustapaDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getSuoritustapa(perusteId, suoritustapakoodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkintonimikekoodit(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getTutkintonimikekoodit(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUusimmatPerusteet(kieli: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getUusimmatPerusteet(kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValittavatKielet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).getValittavatKielet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushAmmattitaitovaatimuksetToKoodisto(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KoodiDto>> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).pushAmmattitaitovaatimuksetToKoodisto(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} tutkintonimikeKoodiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutkintonimikekoodi(perusteId: number, tutkintonimikeKoodiId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkintonimikeKoodiDto> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).removeTutkintonimikekoodi(perusteId, tutkintonimikeKoodiId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {KVLiiteJulkinenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKvLiite(perusteId: number, body?: KVLiiteJulkinenDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).updateKvLiite(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<KoodiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamisalat(perusteId: number, body?: Array<KoodiDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).updateOsaamisalat(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {PerusteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePeruste(perusteId: number, body?: PerusteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).updatePeruste(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<TutkintonimikeKoodiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkintonimikkeet(perusteId: number, body?: Array<TutkintonimikeKoodiDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteetApiAxiosParamCreator(configuration).updateTutkintonimikkeet(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusteetApi - factory interface
 * @export
 */
export const PerusteetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {TutkintonimikeKoodiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkintonimikekoodi(perusteId: number, body?: TutkintonimikeKoodiDto, options?: any): AxiosPromise<TutkintonimikeKoodiDto> {
            return PerusteetApiFp(configuration).addTutkintonimikekoodi(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {TutkintonimikeKoodiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkintonimikekoodiPut(perusteId: number, body?: TutkintonimikeKoodiDto, options?: any): AxiosPromise<TutkintonimikeKoodiDto> {
            return PerusteetApiFp(configuration).addTutkintonimikekoodiPut(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOppaidenPerusteet(options?: any): AxiosPromise<Array<PerusteKevytDto>> {
            return PerusteetApiFp(configuration).getAllOppaidenPerusteet(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusopetus(options?: any): AxiosPromise<Array<PerusteInfoDto>> {
            return PerusteetApiFp(configuration).getAllPerusopetus(options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteiden sisäinen haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {boolean} [tuleva] hae myös tulevatperusteet
         * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {boolean} [poistunut] hae myös poistuneet perusteet
         * @param {string} [nimi] 
         * @param {Array<string>} [koulutusala] 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [kieli] perusteen kieli
         * @param {Array<string>} [opintoala] opintoalakoodi
         * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
         * @param {string} [koulutuskoodi] 
         * @param {string} [diaarinumero] 
         * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
         * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
         * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
         * @param {boolean} [osaamisalat] hae myös osaamisaloista
         * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
         * @param {string} [perusteTyyppi] Perusteen tyyppi
         * @param {boolean} [julkaistu] julkaistut perusteet
         * @param {Array<string>} [tutkinnonosaKoodit] 
         * @param {Array<string>} [osaamisalaKoodit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteet(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, perusteTyyppi?: string, julkaistu?: boolean, tutkinnonosaKoodit?: Array<string>, osaamisalaKoodit?: Array<string>, options?: any): AxiosPromise<PagePerusteHakuDto> {
            return PerusteetApiFp(configuration).getAllPerusteet(sivu, sivukoko, tuleva, siirtyma, voimassaolo, poistunut, nimi, koulutusala, koulutustyyppi, kieli, opintoala, suoritustapa, koulutuskoodi, diaarinumero, muokattu, tutkintonimikkeet, tutkinnonosat, osaamisalat, koulutusvienti, perusteTyyppi, julkaistu, tutkinnonosaKoodit, osaamisalaKoodit, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteetInfo(options?: any): AxiosPromise<PagePerusteInfoDto> {
            return PerusteetApiFp(configuration).getAllPerusteetInfo(options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteiden sisäinen haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {boolean} [tuleva] hae myös tulevatperusteet
         * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
         * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
         * @param {boolean} [poistunut] hae myös poistuneet perusteet
         * @param {string} [nimi] 
         * @param {Array<string>} [koulutusala] 
         * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
         * @param {Array<string>} [kieli] perusteen kieli
         * @param {Array<string>} [opintoala] opintoalakoodi
         * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
         * @param {string} [koulutuskoodi] 
         * @param {string} [diaarinumero] 
         * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
         * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
         * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
         * @param {boolean} [osaamisalat] hae myös osaamisaloista
         * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
         * @param {Array<string>} [tila] Sallitut tilat
         * @param {string} [perusteTyyppi] Perusteen tyyppi
         * @param {boolean} [julkaistu] julkaistut perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteetInternal(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, tila?: Array<string>, perusteTyyppi?: string, julkaistu?: boolean, options?: any): AxiosPromise<PagePerusteHakuInternalDto> {
            return PerusteetApiFp(configuration).getAllPerusteetInternal(sivu, sivukoko, tuleva, siirtyma, voimassaolo, poistunut, nimi, koulutusala, koulutustyyppi, kieli, opintoala, suoritustapa, koulutuskoodi, diaarinumero, muokattu, tutkintonimikkeet, tutkinnonosat, osaamisalat, koulutusvienti, tila, perusteTyyppi, julkaistu, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmmattitaitovaatimukset(perusteId: number, options?: any): AxiosPromise<Array<Ammattitaitovaatimus2019Dto>> {
            return PerusteetApiFp(configuration).getAmmattitaitovaatimukset(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Paikallisen puolen ammatillista laadintaa tukevat perusteet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaOpsit(options?: any): AxiosPromise<Array<PerusteHakuDto>> {
            return PerusteetApiFp(configuration).getAmosaaOpsit(options)(axios, basePath);
        },
        /**
         * 
         * @summary Amosaa jaetun tutkinnon pohja
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaPohja(options?: any): AxiosPromise<PerusteKaikkiDto> {
            return PerusteetApiFp(configuration).getAmosaaPohja(options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen yksilöintietojen haku diaarinumerolla
         * @param {string} [diaarinumero] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByDiaari(diaarinumero?: string, options?: any): AxiosPromise<PerusteInfoDto> {
            return PerusteetApiFp(configuration).getByDiaari(diaarinumero, options)(axios, basePath);
        },
        /**
         * 
         * @summary Julkaistut perustekoulutustyypit annetulla kielellä
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutKoulutustyypit(kieli: string, options?: any): AxiosPromise<Array<string>> {
            return PerusteetApiFp(configuration).getJulkaistutKoulutustyypit(kieli, options)(axios, basePath);
        },
        /**
         * 
         * @summary Julkaistut perustekoulutustyypit annetulla kielellä
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutKoulutustyyppiLukumaarat(kieli: string, options?: any): AxiosPromise<Array<KoulutustyyppiLukumaara>> {
            return PerusteetApiFp(configuration).getJulkaistutKoulutustyyppiLukumaarat(kieli, options)(axios, basePath);
        },
        /**
         * 
         * @summary Perusteiden koulutustyyppikohtaiset lukumäärät
         * @param {Array<string>} koulutustyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutLukumaarilla(koulutustyyppi: Array<string>, options?: any): AxiosPromise<Array<KoulutustyyppiLukumaara>> {
            return PerusteetApiFp(configuration).getJulkaistutLukumaarilla(koulutustyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutPerusteet(options?: any): AxiosPromise<Array<PerusteKevytDto>> {
            return PerusteetApiFp(configuration).getJulkaistutPerusteet(options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen tutkinnon osien viitteiden haku julkaistusta datasta
         * @param {number} perusteId 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutTutkinnonOsaViitteet(perusteId: number, useCurrentData?: boolean, options?: any): AxiosPromise<Array<TutkinnonOsaViiteSuppeaDto>> {
            return PerusteetApiFp(configuration).getJulkaistutTutkinnonOsaViitteet(perusteId, useCurrentData, options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen tutkinnon osien haku julkaistusta datasta
         * @param {number} perusteId 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaistutTutkinnonOsat(perusteId: number, useCurrentData?: boolean, options?: any): AxiosPromise<Array<TutkinnonOsaKaikkiDto>> {
            return PerusteetApiFp(configuration).getJulkaistutTutkinnonOsat(perusteId, useCurrentData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Perusteet julkisilla aikatauluillla
         * @param {number} sivu 
         * @param {number} sivukoko 
         * @param {Array<string>} koulutustyyppi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJulkaisuAikatauluPerusteet(sivu: number, sivukoko: number, koulutustyyppi: Array<string>, options?: any): AxiosPromise<PagePerusteBaseDto> {
            return PerusteetApiFp(configuration).getJulkaisuAikatauluPerusteet(sivu, sivukoko, koulutustyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen kaikkien tietojen haku
         * @param {number} perusteId 
         * @param {number} [rev] 
         * @param {boolean} [useCurrentData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKokoSisalto(perusteId: number, rev?: number, useCurrentData?: boolean, options?: any): AxiosPromise<PerusteKaikkiDto> {
            return PerusteetApiFp(configuration).getKokoSisalto(perusteId, rev, useCurrentData, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKvLiite(perusteId: number, options?: any): AxiosPromise<void> {
            return PerusteetApiFp(configuration).getKvLiite(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeta(perusteId: number, options?: any): AxiosPromise<PerusteInfoDto> {
            return PerusteetApiFp(configuration).getMeta(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(perusteId: number, kieli?: string, options?: any): AxiosPromise<NavigationNodeDto> {
            return PerusteetApiFp(configuration).getNavigation(perusteId, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} [kieli] 
         * @param {boolean} [esikatselu] 
         * @param {number} [rev] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigationPublic(perusteId: number, kieli?: string, esikatselu?: boolean, rev?: number, options?: any): AxiosPromise<NavigationNodeDto> {
            return PerusteetApiFp(configuration).getNavigationPublic(perusteId, kieli, esikatselu, rev, options)(axios, basePath);
        },
        /**
         * 
         * @summary Oppaat joihin kiinnitetty koodiUri
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpasKiinnitettyKoodi(koodiUri: string, options?: any): AxiosPromise<Array<PerusteDto>> {
            return PerusteetApiFp(configuration).getOpasKiinnitettyKoodi(koodiUri, options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen osaamisalojen kuvaukset koulutustarjontaa varten
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsaamisalat(perusteId: number, options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<TekstiKappaleDto>; }; }> {
            return PerusteetApiFp(configuration).getOsaamisalat(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteKooste(options?: any): AxiosPromise<Array<PerusteKoosteDto>> {
            return PerusteetApiFp(configuration).getPerusteKooste(options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteprojektin tila
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteProjektiTila(perusteId: number, options?: any): AxiosPromise<string> {
            return PerusteetApiFp(configuration).getPerusteProjektiTila(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen uusin versio
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteVersion(perusteId: number, options?: any): AxiosPromise<PerusteVersionDto> {
            return PerusteetApiFp(configuration).getPerusteVersion(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @summary perusteen tietojen haku
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenTiedot(perusteId: number, options?: any): AxiosPromise<PerusteDto> {
            return PerusteetApiFp(configuration).getPerusteenTiedot(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteetWithTekstikappaleKoodi(koodi: string, options?: any): AxiosPromise<Array<PerusteTekstikappaleillaDto>> {
            return PerusteetApiFp(configuration).getPerusteetWithTekstikappaleKoodi(koodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [perustetyyppi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPohjaperusteet(perustetyyppi?: string, options?: any): AxiosPromise<Array<PerusteKevytDto>> {
            return PerusteetApiFp(configuration).getPohjaperusteet(perustetyyppi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuoritustapa(perusteId: number, suoritustapakoodi: string, options?: any): AxiosPromise<SuoritustapaDto> {
            return PerusteetApiFp(configuration).getSuoritustapa(perusteId, suoritustapakoodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkintonimikekoodit(perusteId: number, options?: any): AxiosPromise<Array<CombinedDtoTutkintonimikeKoodiDtoHashMapStringKoodistoKoodiDto>> {
            return PerusteetApiFp(configuration).getTutkintonimikekoodit(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} kieli 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUusimmatPerusteet(kieli: string, options?: any): AxiosPromise<Array<PerusteDto>> {
            return PerusteetApiFp(configuration).getUusimmatPerusteet(kieli, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValittavatKielet(options?: any): AxiosPromise<Array<string>> {
            return PerusteetApiFp(configuration).getValittavatKielet(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushAmmattitaitovaatimuksetToKoodisto(perusteId: number, options?: any): AxiosPromise<Array<KoodiDto>> {
            return PerusteetApiFp(configuration).pushAmmattitaitovaatimuksetToKoodisto(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} tutkintonimikeKoodiId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutkintonimikekoodi(perusteId: number, tutkintonimikeKoodiId: number, options?: any): AxiosPromise<TutkintonimikeKoodiDto> {
            return PerusteetApiFp(configuration).removeTutkintonimikekoodi(perusteId, tutkintonimikeKoodiId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {KVLiiteJulkinenDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKvLiite(perusteId: number, body?: KVLiiteJulkinenDto, options?: any): AxiosPromise<void> {
            return PerusteetApiFp(configuration).updateKvLiite(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<KoodiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOsaamisalat(perusteId: number, body?: Array<KoodiDto>, options?: any): AxiosPromise<void> {
            return PerusteetApiFp(configuration).updateOsaamisalat(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {PerusteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePeruste(perusteId: number, body?: PerusteDto, options?: any): AxiosPromise<void> {
            return PerusteetApiFp(configuration).updatePeruste(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {Array<TutkintonimikeKoodiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkintonimikkeet(perusteId: number, body?: Array<TutkintonimikeKoodiDto>, options?: any): AxiosPromise<void> {
            return PerusteetApiFp(configuration).updateTutkintonimikkeet(perusteId, body, options)(axios, basePath);
        },
    };
};

/**
 * PerusteetApi - object-oriented interface
 * @export
 * @class PerusteetApi
 * @extends {BaseAPI}
 */
export class PerusteetApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {TutkintonimikeKoodiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public addTutkintonimikekoodi(perusteId: number, body?: TutkintonimikeKoodiDto, options?: any) {
        return PerusteetApiFp(this.configuration).addTutkintonimikekoodi(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {TutkintonimikeKoodiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public addTutkintonimikekoodiPut(perusteId: number, body?: TutkintonimikeKoodiDto, options?: any) {
        return PerusteetApiFp(this.configuration).addTutkintonimikekoodiPut(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAllOppaidenPerusteet(options?: any) {
        return PerusteetApiFp(this.configuration).getAllOppaidenPerusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAllPerusopetus(options?: any) {
        return PerusteetApiFp(this.configuration).getAllPerusopetus(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteiden sisäinen haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {boolean} [tuleva] hae myös tulevatperusteet
     * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
     * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
     * @param {boolean} [poistunut] hae myös poistuneet perusteet
     * @param {string} [nimi] 
     * @param {Array<string>} [koulutusala] 
     * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
     * @param {Array<string>} [kieli] perusteen kieli
     * @param {Array<string>} [opintoala] opintoalakoodi
     * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
     * @param {string} [koulutuskoodi] 
     * @param {string} [diaarinumero] 
     * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
     * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
     * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
     * @param {boolean} [osaamisalat] hae myös osaamisaloista
     * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
     * @param {string} [perusteTyyppi] Perusteen tyyppi
     * @param {boolean} [julkaistu] julkaistut perusteet
     * @param {Array<string>} [tutkinnonosaKoodit] 
     * @param {Array<string>} [osaamisalaKoodit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAllPerusteet(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, perusteTyyppi?: string, julkaistu?: boolean, tutkinnonosaKoodit?: Array<string>, osaamisalaKoodit?: Array<string>, options?: any) {
        return PerusteetApiFp(this.configuration).getAllPerusteet(sivu, sivukoko, tuleva, siirtyma, voimassaolo, poistunut, nimi, koulutusala, koulutustyyppi, kieli, opintoala, suoritustapa, koulutuskoodi, diaarinumero, muokattu, tutkintonimikkeet, tutkinnonosat, osaamisalat, koulutusvienti, perusteTyyppi, julkaistu, tutkinnonosaKoodit, osaamisalaKoodit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAllPerusteetInfo(options?: any) {
        return PerusteetApiFp(this.configuration).getAllPerusteetInfo(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteiden sisäinen haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {boolean} [tuleva] hae myös tulevatperusteet
     * @param {boolean} [siirtyma] hae myös siirtymäajalla olevat perusteet
     * @param {boolean} [voimassaolo] hae myös voimassaolevat perusteet
     * @param {boolean} [poistunut] hae myös poistuneet perusteet
     * @param {string} [nimi] 
     * @param {Array<string>} [koulutusala] 
     * @param {Array<string>} [koulutustyyppi] koulutustyyppi (koodistokoodi)
     * @param {Array<string>} [kieli] perusteen kieli
     * @param {Array<string>} [opintoala] opintoalakoodi
     * @param {string} [suoritustapa] AM-perusteet; naytto tai ops
     * @param {string} [koulutuskoodi] 
     * @param {string} [diaarinumero] 
     * @param {number} [muokattu] Perustetta muokattu jälkeen (aikaleima; millisenkunteja alkaen 1970-01-01 00:00:00 UTC). Huomioi koko perusteen sisällön.
     * @param {boolean} [tutkintonimikkeet] hae myös tutkintonimikkeistä
     * @param {boolean} [tutkinnonosat] hae myös tutkinnon osista
     * @param {boolean} [osaamisalat] hae myös osaamisaloista
     * @param {boolean} [koulutusvienti] Haku ainoastaan koulutusviennistä
     * @param {Array<string>} [tila] Sallitut tilat
     * @param {string} [perusteTyyppi] Perusteen tyyppi
     * @param {boolean} [julkaistu] julkaistut perusteet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAllPerusteetInternal(sivu?: number, sivukoko?: number, tuleva?: boolean, siirtyma?: boolean, voimassaolo?: boolean, poistunut?: boolean, nimi?: string, koulutusala?: Array<string>, koulutustyyppi?: Array<string>, kieli?: Array<string>, opintoala?: Array<string>, suoritustapa?: string, koulutuskoodi?: string, diaarinumero?: string, muokattu?: number, tutkintonimikkeet?: boolean, tutkinnonosat?: boolean, osaamisalat?: boolean, koulutusvienti?: boolean, tila?: Array<string>, perusteTyyppi?: string, julkaistu?: boolean, options?: any) {
        return PerusteetApiFp(this.configuration).getAllPerusteetInternal(sivu, sivukoko, tuleva, siirtyma, voimassaolo, poistunut, nimi, koulutusala, koulutustyyppi, kieli, opintoala, suoritustapa, koulutuskoodi, diaarinumero, muokattu, tutkintonimikkeet, tutkinnonosat, osaamisalat, koulutusvienti, tila, perusteTyyppi, julkaistu, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAmmattitaitovaatimukset(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getAmmattitaitovaatimukset(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Paikallisen puolen ammatillista laadintaa tukevat perusteet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAmosaaOpsit(options?: any) {
        return PerusteetApiFp(this.configuration).getAmosaaOpsit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Amosaa jaetun tutkinnon pohja
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getAmosaaPohja(options?: any) {
        return PerusteetApiFp(this.configuration).getAmosaaPohja(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen yksilöintietojen haku diaarinumerolla
     * @param {string} [diaarinumero] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getByDiaari(diaarinumero?: string, options?: any) {
        return PerusteetApiFp(this.configuration).getByDiaari(diaarinumero, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Julkaistut perustekoulutustyypit annetulla kielellä
     * @param {string} kieli 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaistutKoulutustyypit(kieli: string, options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaistutKoulutustyypit(kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Julkaistut perustekoulutustyypit annetulla kielellä
     * @param {string} kieli 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaistutKoulutustyyppiLukumaarat(kieli: string, options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaistutKoulutustyyppiLukumaarat(kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Perusteiden koulutustyyppikohtaiset lukumäärät
     * @param {Array<string>} koulutustyyppi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaistutLukumaarilla(koulutustyyppi: Array<string>, options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaistutLukumaarilla(koulutustyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaistutPerusteet(options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaistutPerusteet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen tutkinnon osien viitteiden haku julkaistusta datasta
     * @param {number} perusteId 
     * @param {boolean} [useCurrentData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaistutTutkinnonOsaViitteet(perusteId: number, useCurrentData?: boolean, options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaistutTutkinnonOsaViitteet(perusteId, useCurrentData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen tutkinnon osien haku julkaistusta datasta
     * @param {number} perusteId 
     * @param {boolean} [useCurrentData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaistutTutkinnonOsat(perusteId: number, useCurrentData?: boolean, options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaistutTutkinnonOsat(perusteId, useCurrentData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Perusteet julkisilla aikatauluillla
     * @param {number} sivu 
     * @param {number} sivukoko 
     * @param {Array<string>} koulutustyyppi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getJulkaisuAikatauluPerusteet(sivu: number, sivukoko: number, koulutustyyppi: Array<string>, options?: any) {
        return PerusteetApiFp(this.configuration).getJulkaisuAikatauluPerusteet(sivu, sivukoko, koulutustyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen kaikkien tietojen haku
     * @param {number} perusteId 
     * @param {number} [rev] 
     * @param {boolean} [useCurrentData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getKokoSisalto(perusteId: number, rev?: number, useCurrentData?: boolean, options?: any) {
        return PerusteetApiFp(this.configuration).getKokoSisalto(perusteId, rev, useCurrentData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getKvLiite(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getKvLiite(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen tietojen haku
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getMeta(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getMeta(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getNavigation(perusteId: number, kieli?: string, options?: any) {
        return PerusteetApiFp(this.configuration).getNavigation(perusteId, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} [kieli] 
     * @param {boolean} [esikatselu] 
     * @param {number} [rev] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getNavigationPublic(perusteId: number, kieli?: string, esikatselu?: boolean, rev?: number, options?: any) {
        return PerusteetApiFp(this.configuration).getNavigationPublic(perusteId, kieli, esikatselu, rev, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Oppaat joihin kiinnitetty koodiUri
     * @param {string} koodiUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getOpasKiinnitettyKoodi(koodiUri: string, options?: any) {
        return PerusteetApiFp(this.configuration).getOpasKiinnitettyKoodi(koodiUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen osaamisalojen kuvaukset koulutustarjontaa varten
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getOsaamisalat(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getOsaamisalat(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteKooste(options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteKooste(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteprojektin tila
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteProjektiTila(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteProjektiTila(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen uusin versio
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteVersion(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteVersion(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary perusteen tietojen haku
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteenTiedot(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteenTiedot(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPerusteetWithTekstikappaleKoodi(koodi: string, options?: any) {
        return PerusteetApiFp(this.configuration).getPerusteetWithTekstikappaleKoodi(koodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [perustetyyppi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getPohjaperusteet(perustetyyppi?: string, options?: any) {
        return PerusteetApiFp(this.configuration).getPohjaperusteet(perustetyyppi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapakoodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getSuoritustapa(perusteId: number, suoritustapakoodi: string, options?: any) {
        return PerusteetApiFp(this.configuration).getSuoritustapa(perusteId, suoritustapakoodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getTutkintonimikekoodit(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).getTutkintonimikekoodit(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} kieli 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getUusimmatPerusteet(kieli: string, options?: any) {
        return PerusteetApiFp(this.configuration).getUusimmatPerusteet(kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public getValittavatKielet(options?: any) {
        return PerusteetApiFp(this.configuration).getValittavatKielet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public pushAmmattitaitovaatimuksetToKoodisto(perusteId: number, options?: any) {
        return PerusteetApiFp(this.configuration).pushAmmattitaitovaatimuksetToKoodisto(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} tutkintonimikeKoodiId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public removeTutkintonimikekoodi(perusteId: number, tutkintonimikeKoodiId: number, options?: any) {
        return PerusteetApiFp(this.configuration).removeTutkintonimikekoodi(perusteId, tutkintonimikeKoodiId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {KVLiiteJulkinenDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public updateKvLiite(perusteId: number, body?: KVLiiteJulkinenDto, options?: any) {
        return PerusteetApiFp(this.configuration).updateKvLiite(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Array<KoodiDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public updateOsaamisalat(perusteId: number, body?: Array<KoodiDto>, options?: any) {
        return PerusteetApiFp(this.configuration).updateOsaamisalat(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {PerusteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public updatePeruste(perusteId: number, body?: PerusteDto, options?: any) {
        return PerusteetApiFp(this.configuration).updatePeruste(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {Array<TutkintonimikeKoodiDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteetApi
     */
    public updateTutkintonimikkeet(perusteId: number, body?: Array<TutkintonimikeKoodiDto>, options?: any) {
        return PerusteetApiFp(this.configuration).updateTutkintonimikkeet(perusteId, body, options)(this.axios, this.basePath);
    }

}


/**
 * PerusteprojektitApi - axios parameter creator
 * @export
 */
export const PerusteprojektitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PerusteprojektiLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusteprojekti(body?: PerusteprojektiLuontiDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteprojektit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PerusteprojektiLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusteprojektiPohja(body?: PerusteprojektiLuontiDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteprojektit/pohja`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avaaPerusteProjekti(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling avaaPerusteProjekti.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/projekti/avaa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteprojektit(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteprojektit/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteprojektitKevyt(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteprojektit/perusteHaku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJasenetTiedot(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getJasenetTiedot.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/jasenet/tiedot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatJulkaistutPerusteprojektit(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteprojektit/omat/julkaistut`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatPerusteprojektit(options: any = {}): RequestArgs {
            const localVarPath = `/api/perusteprojektit/omat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerustenosienTyoryhmat(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerustenosienTyoryhmat.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/perusteenosientyoryhmat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojekti(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojekti.');
            }
            const localVarPath = `/api/perusteprojektit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Ref} diaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiByDiaarinumero(diaarinumero: Ref, options: any = {}): RequestArgs {
            // verify required parameter 'diaarinumero' is not null or undefined
            if (diaarinumero === null || diaarinumero === undefined) {
                throw new RequiredError('diaarinumero','Required parameter diaarinumero was null or undefined when calling getPerusteprojektiByDiaarinumero.');
            }
            const localVarPath = `/api/perusteprojektit/diaarinumero/uniikki/{diaarinumero}`
                .replace(`{${"diaarinumero"}}`, encodeURIComponent(String(diaarinumero)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiJasenet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektiJasenet.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/jasenet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiOikeudet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektiOikeudet.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/oikeudet`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTilat(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektiTilat.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tilat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nimi 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTyoryhmaByNimi(nimi: string, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling getPerusteprojektiTyoryhmaByNimi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektiTyoryhmaByNimi.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tyoryhma/{nimi}`
                .replace(`{${"nimi"}}`, encodeURIComponent(String(nimi)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTyoryhmat(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektiTyoryhmat.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tyoryhma`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiValidointi(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektiValidointi.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/validoi`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektienOikeudet(ids: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getPerusteprojektienOikeudet.');
            }
            const localVarPath = `/api/perusteprojektit/oikeudet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektinTyoryhma(id: number, pid: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPerusteprojektinTyoryhma.');
            }
            // verify required parameter 'pid' is not null or undefined
            if (pid === null || pid === undefined) {
                throw new RequiredError('pid','Required parameter pid was null or undefined when calling getPerusteprojektinTyoryhma.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/perusteenosat/{pid}/tyoryhmat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pid"}}`, encodeURIComponent(String(pid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} perusteenOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omistaaPerusteprojektiOsan(id: number, perusteenOsaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling omistaaPerusteprojektiOsan.');
            }
            // verify required parameter 'perusteenOsaId' is not null or undefined
            if (perusteenOsaId === null || perusteenOsaId === undefined) {
                throw new RequiredError('perusteenOsaId','Required parameter perusteenOsaId was null or undefined when calling omistaaPerusteprojektiOsan.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/oikeudet/{perusteenOsaId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"perusteenOsaId"}}`, encodeURIComponent(String(perusteenOsaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<TyoryhmaHenkiloDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMultipleTyoryhmaHenkilot(id: number, body?: Array<TyoryhmaHenkiloDto>, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postMultipleTyoryhmaHenkilot.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tyoryhma`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} nimi 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMultipleTyoryhmaHenkilotToTyoryhma(id: number, nimi: string, body?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postMultipleTyoryhmaHenkilotToTyoryhma.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling postMultipleTyoryhmaHenkilotToTyoryhma.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tyoryhma/{nimi}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"nimi"}}`, encodeURIComponent(String(nimi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pid 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPerusteenOsaTyoryhma(id: number, pid: number, body?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postPerusteenOsaTyoryhma.');
            }
            // verify required parameter 'pid' is not null or undefined
            if (pid === null || pid === undefined) {
                throw new RequiredError('pid','Required parameter pid was null or undefined when calling postPerusteenOsaTyoryhma.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/perusteenosat/{pid}/tyoryhmat`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pid"}}`, encodeURIComponent(String(pid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} nimi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTyoryhmat(id: number, nimi: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeTyoryhmat.');
            }
            // verify required parameter 'nimi' is not null or undefined
            if (nimi === null || nimi === undefined) {
                throw new RequiredError('nimi','Required parameter nimi was null or undefined when calling removeTyoryhmat.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tyoryhma/{nimi}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"nimi"}}`, encodeURIComponent(String(nimi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PerusteprojektiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteprojekti(id: number, body?: PerusteprojektiDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePerusteprojekti.');
            }
            const localVarPath = `/api/perusteprojektit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tila 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteprojektiTila(id: number, tila: string, body?: TiedoteDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePerusteprojektiTila.');
            }
            // verify required parameter 'tila' is not null or undefined
            if (tila === null || tila === undefined) {
                throw new RequiredError('tila','Required parameter tila was null or undefined when calling updatePerusteprojektiTila.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/tila/{tila}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tila"}}`, encodeURIComponent(String(tila)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tila 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjektiTilaOnly(id: number, tila: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProjektiTilaOnly.');
            }
            // verify required parameter 'tila' is not null or undefined
            if (tila === null || tila === undefined) {
                throw new RequiredError('tila','Required parameter tila was null or undefined when calling updateProjektiTilaOnly.');
            }
            const localVarPath = `/api/perusteprojektit/{id}/projekti/tila/{tila}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tila"}}`, encodeURIComponent(String(tila)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerusteprojektitApi - functional programming interface
 * @export
 */
export const PerusteprojektitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PerusteprojektiLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusteprojekti(body?: PerusteprojektiLuontiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteprojektiDto> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).addPerusteprojekti(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PerusteprojektiLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusteprojektiPohja(body?: PerusteprojektiLuontiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteprojektiDto> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).addPerusteprojektiPohja(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avaaPerusteProjekti(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).avaaPerusteProjekti(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteprojektit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteprojektiInfoDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getAllPerusteprojektit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteprojektitKevyt(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePerusteprojektiKevytDto> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getAllPerusteprojektitKevyt(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJasenetTiedot(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getJasenetTiedot(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatJulkaistutPerusteprojektit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteprojektiListausDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getOmatJulkaistutPerusteprojektit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatPerusteprojektit(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteprojektiListausDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getOmatPerusteprojektit(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerustenosienTyoryhmat(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerusteenOsaTyoryhmaDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerustenosienTyoryhmat(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojekti(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteprojektiDto> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojekti(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Ref} diaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiByDiaarinumero(diaarinumero: Ref, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiByDiaarinumero(diaarinumero, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiJasenet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KayttajanTietoDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiJasenet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiOikeudet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiOikeudet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTilat(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiTilat(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} nimi 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTyoryhmaByNimi(nimi: string, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiTyoryhmaByNimi(nimi, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTyoryhmat(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiTyoryhmat(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiValidointi(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Validointi>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektiValidointi(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektienOikeudet(ids: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<string>; }; }> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektienOikeudet(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektinTyoryhma(id: number, pid: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).getPerusteprojektinTyoryhma(id, pid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} perusteenOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omistaaPerusteprojektiOsan(id: number, perusteenOsaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OmistajaDto> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).omistaaPerusteprojektiOsan(id, perusteenOsaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<TyoryhmaHenkiloDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMultipleTyoryhmaHenkilot(id: number, body?: Array<TyoryhmaHenkiloDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).postMultipleTyoryhmaHenkilot(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} nimi 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMultipleTyoryhmaHenkilotToTyoryhma(id: number, nimi: string, body?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).postMultipleTyoryhmaHenkilotToTyoryhma(id, nimi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pid 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPerusteenOsaTyoryhma(id: number, pid: number, body?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).postPerusteenOsaTyoryhma(id, pid, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} nimi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTyoryhmat(id: number, nimi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TyoryhmaHenkiloDto> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).removeTyoryhmat(id, nimi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PerusteprojektiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteprojekti(id: number, body?: PerusteprojektiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).updatePerusteprojekti(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tila 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteprojektiTila(id: number, tila: string, body?: TiedoteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).updatePerusteprojektiTila(id, tila, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tila 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjektiTilaOnly(id: number, tila: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PerusteprojektitApiAxiosParamCreator(configuration).updateProjektiTilaOnly(id, tila, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PerusteprojektitApi - factory interface
 * @export
 */
export const PerusteprojektitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {PerusteprojektiLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusteprojekti(body?: PerusteprojektiLuontiDto, options?: any): AxiosPromise<PerusteprojektiDto> {
            return PerusteprojektitApiFp(configuration).addPerusteprojekti(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {PerusteprojektiLuontiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPerusteprojektiPohja(body?: PerusteprojektiLuontiDto, options?: any): AxiosPromise<PerusteprojektiDto> {
            return PerusteprojektitApiFp(configuration).addPerusteprojektiPohja(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avaaPerusteProjekti(id: number, options?: any): AxiosPromise<void> {
            return PerusteprojektitApiFp(configuration).avaaPerusteProjekti(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteprojektit(options?: any): AxiosPromise<Array<PerusteprojektiInfoDto>> {
            return PerusteprojektitApiFp(configuration).getAllPerusteprojektit(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerusteprojektitKevyt(options?: any): AxiosPromise<PagePerusteprojektiKevytDto> {
            return PerusteprojektitApiFp(configuration).getAllPerusteprojektitKevyt(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJasenetTiedot(id: number, options?: any): AxiosPromise<Array<CombinedDtoKayttajanTietoDtoKayttajanProjektitiedotDto>> {
            return PerusteprojektitApiFp(configuration).getJasenetTiedot(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatJulkaistutPerusteprojektit(options?: any): AxiosPromise<Array<PerusteprojektiListausDto>> {
            return PerusteprojektitApiFp(configuration).getOmatJulkaistutPerusteprojektit(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOmatPerusteprojektit(options?: any): AxiosPromise<Array<PerusteprojektiListausDto>> {
            return PerusteprojektitApiFp(configuration).getOmatPerusteprojektit(options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerustenosienTyoryhmat(id: number, options?: any): AxiosPromise<Array<PerusteenOsaTyoryhmaDto>> {
            return PerusteprojektitApiFp(configuration).getPerustenosienTyoryhmat(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojekti(id: number, options?: any): AxiosPromise<PerusteprojektiDto> {
            return PerusteprojektitApiFp(configuration).getPerusteprojekti(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {Ref} diaarinumero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiByDiaarinumero(diaarinumero: Ref, options?: any): AxiosPromise<void> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiByDiaarinumero(diaarinumero, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiJasenet(id: number, options?: any): AxiosPromise<Array<KayttajanTietoDto>> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiJasenet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiOikeudet(id: number, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiOikeudet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTilat(id: number, options?: any): AxiosPromise<Array<string>> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiTilat(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} nimi 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTyoryhmaByNimi(nimi: string, id: number, options?: any): AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiTyoryhmaByNimi(nimi, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiTyoryhmat(id: number, options?: any): AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiTyoryhmat(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektiValidointi(id: number, options?: any): AxiosPromise<Array<Validointi>> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektiValidointi(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<number>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektienOikeudet(ids: Array<number>, options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<string>; }; }> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektienOikeudet(ids, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteprojektinTyoryhma(id: number, pid: number, options?: any): AxiosPromise<Array<string>> {
            return PerusteprojektitApiFp(configuration).getPerusteprojektinTyoryhma(id, pid, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} perusteenOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omistaaPerusteprojektiOsan(id: number, perusteenOsaId: number, options?: any): AxiosPromise<OmistajaDto> {
            return PerusteprojektitApiFp(configuration).omistaaPerusteprojektiOsan(id, perusteenOsaId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<TyoryhmaHenkiloDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMultipleTyoryhmaHenkilot(id: number, body?: Array<TyoryhmaHenkiloDto>, options?: any): AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            return PerusteprojektitApiFp(configuration).postMultipleTyoryhmaHenkilot(id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} nimi 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMultipleTyoryhmaHenkilotToTyoryhma(id: number, nimi: string, body?: Array<string>, options?: any): AxiosPromise<Array<TyoryhmaHenkiloDto>> {
            return PerusteprojektitApiFp(configuration).postMultipleTyoryhmaHenkilotToTyoryhma(id, nimi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} pid 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPerusteenOsaTyoryhma(id: number, pid: number, body?: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return PerusteprojektitApiFp(configuration).postPerusteenOsaTyoryhma(id, pid, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} nimi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTyoryhmat(id: number, nimi: string, options?: any): AxiosPromise<TyoryhmaHenkiloDto> {
            return PerusteprojektitApiFp(configuration).removeTyoryhmat(id, nimi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PerusteprojektiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteprojekti(id: number, body?: PerusteprojektiDto, options?: any): AxiosPromise<void> {
            return PerusteprojektitApiFp(configuration).updatePerusteprojekti(id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tila 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteprojektiTila(id: number, tila: string, body?: TiedoteDto, options?: any): AxiosPromise<void> {
            return PerusteprojektitApiFp(configuration).updatePerusteprojektiTila(id, tila, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tila 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjektiTilaOnly(id: number, tila: string, options?: any): AxiosPromise<void> {
            return PerusteprojektitApiFp(configuration).updateProjektiTilaOnly(id, tila, options)(axios, basePath);
        },
    };
};

/**
 * PerusteprojektitApi - object-oriented interface
 * @export
 * @class PerusteprojektitApi
 * @extends {BaseAPI}
 */
export class PerusteprojektitApi extends BaseAPI {
    /**
     * 
     * @param {PerusteprojektiLuontiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public addPerusteprojekti(body?: PerusteprojektiLuontiDto, options?: any) {
        return PerusteprojektitApiFp(this.configuration).addPerusteprojekti(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {PerusteprojektiLuontiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public addPerusteprojektiPohja(body?: PerusteprojektiLuontiDto, options?: any) {
        return PerusteprojektitApiFp(this.configuration).addPerusteprojektiPohja(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public avaaPerusteProjekti(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).avaaPerusteProjekti(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getAllPerusteprojektit(options?: any) {
        return PerusteprojektitApiFp(this.configuration).getAllPerusteprojektit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getAllPerusteprojektitKevyt(options?: any) {
        return PerusteprojektitApiFp(this.configuration).getAllPerusteprojektitKevyt(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getJasenetTiedot(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getJasenetTiedot(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getOmatJulkaistutPerusteprojektit(options?: any) {
        return PerusteprojektitApiFp(this.configuration).getOmatJulkaistutPerusteprojektit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getOmatPerusteprojektit(options?: any) {
        return PerusteprojektitApiFp(this.configuration).getOmatPerusteprojektit(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerustenosienTyoryhmat(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerustenosienTyoryhmat(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojekti(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojekti(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Ref} diaarinumero 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiByDiaarinumero(diaarinumero: Ref, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiByDiaarinumero(diaarinumero, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiJasenet(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiJasenet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiOikeudet(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiOikeudet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiTilat(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiTilat(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} nimi 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiTyoryhmaByNimi(nimi: string, id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiTyoryhmaByNimi(nimi, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiTyoryhmat(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiTyoryhmat(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektiValidointi(id: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektiValidointi(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektienOikeudet(ids: Array<number>, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektienOikeudet(ids, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} pid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public getPerusteprojektinTyoryhma(id: number, pid: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).getPerusteprojektinTyoryhma(id, pid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} perusteenOsaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public omistaaPerusteprojektiOsan(id: number, perusteenOsaId: number, options?: any) {
        return PerusteprojektitApiFp(this.configuration).omistaaPerusteprojektiOsan(id, perusteenOsaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<TyoryhmaHenkiloDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public postMultipleTyoryhmaHenkilot(id: number, body?: Array<TyoryhmaHenkiloDto>, options?: any) {
        return PerusteprojektitApiFp(this.configuration).postMultipleTyoryhmaHenkilot(id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} nimi 
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public postMultipleTyoryhmaHenkilotToTyoryhma(id: number, nimi: string, body?: Array<string>, options?: any) {
        return PerusteprojektitApiFp(this.configuration).postMultipleTyoryhmaHenkilotToTyoryhma(id, nimi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} pid 
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public postPerusteenOsaTyoryhma(id: number, pid: number, body?: Array<string>, options?: any) {
        return PerusteprojektitApiFp(this.configuration).postPerusteenOsaTyoryhma(id, pid, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} nimi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public removeTyoryhmat(id: number, nimi: string, options?: any) {
        return PerusteprojektitApiFp(this.configuration).removeTyoryhmat(id, nimi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {PerusteprojektiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public updatePerusteprojekti(id: number, body?: PerusteprojektiDto, options?: any) {
        return PerusteprojektitApiFp(this.configuration).updatePerusteprojekti(id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} tila 
     * @param {TiedoteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public updatePerusteprojektiTila(id: number, tila: string, body?: TiedoteDto, options?: any) {
        return PerusteprojektitApiFp(this.configuration).updatePerusteprojektiTila(id, tila, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {string} tila 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerusteprojektitApi
     */
    public updateProjektiTilaOnly(id: number, tila: string, options?: any) {
        return PerusteprojektitApiFp(this.configuration).updateProjektiTilaOnly(id, tila, options)(this.axios, this.basePath);
    }

}


/**
 * PoistettuSisaltoApi - axios parameter creator
 * @export
 */
export const PoistettuSisaltoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoistetutSisallot(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPoistetutSisallot.');
            }
            const localVarPath = `/api/poistettusisalto/peruste/{perusteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} palautettavaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauta(perusteId: number, palautettavaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling palauta.');
            }
            // verify required parameter 'palautettavaId' is not null or undefined
            if (palautettavaId === null || palautettavaId === undefined) {
                throw new RequiredError('palautettavaId','Required parameter palautettavaId was null or undefined when calling palauta.');
            }
            const localVarPath = `/api/poistettusisalto/peruste/{perusteId}/palauta/{palautettavaId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"palautettavaId"}}`, encodeURIComponent(String(palautettavaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoistettuSisaltoApi - functional programming interface
 * @export
 */
export const PoistettuSisaltoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoistetutSisallot(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoistettuSisaltoDto>> {
            const localVarAxiosArgs = PoistettuSisaltoApiAxiosParamCreator(configuration).getPoistetutSisallot(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} palautettavaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauta(perusteId: number, palautettavaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PoistettuSisaltoApiAxiosParamCreator(configuration).palauta(perusteId, palautettavaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PoistettuSisaltoApi - factory interface
 * @export
 */
export const PoistettuSisaltoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoistetutSisallot(perusteId: number, options?: any): AxiosPromise<Array<PoistettuSisaltoDto>> {
            return PoistettuSisaltoApiFp(configuration).getPoistetutSisallot(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} palautettavaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        palauta(perusteId: number, palautettavaId: number, options?: any): AxiosPromise<void> {
            return PoistettuSisaltoApiFp(configuration).palauta(perusteId, palautettavaId, options)(axios, basePath);
        },
    };
};

/**
 * PoistettuSisaltoApi - object-oriented interface
 * @export
 * @class PoistettuSisaltoApi
 * @extends {BaseAPI}
 */
export class PoistettuSisaltoApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoistettuSisaltoApi
     */
    public getPoistetutSisallot(perusteId: number, options?: any) {
        return PoistettuSisaltoApiFp(this.configuration).getPoistetutSisallot(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} palautettavaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoistettuSisaltoApi
     */
    public palauta(perusteId: number, palautettavaId: number, options?: any) {
        return PoistettuSisaltoApiFp(this.configuration).palauta(perusteId, palautettavaId, options)(this.axios, this.basePath);
    }

}


/**
 * SisallotApi - axios parameter creator
 * @export
 */
export const SisallotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} perusteenosaViiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoLapsi(perusteId: number, suoritustapa: string, perusteenosaViiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addSisaltoLapsi.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling addSisaltoLapsi.');
            }
            // verify required parameter 'perusteenosaViiteId' is not null or undefined
            if (perusteenosaViiteId === null || perusteenosaViiteId === undefined) {
                throw new RequiredError('perusteenosaViiteId','Required parameter perusteenosaViiteId was null or undefined when calling addSisaltoLapsi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{perusteenosaViiteId}/lapsi`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"perusteenosaViiteId"}}`, encodeURIComponent(String(perusteenosaViiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUUSI(perusteId: number, suoritustapa: string, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addSisaltoUUSI.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling addSisaltoUUSI.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} parentId 
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUusiLapsi(perusteId: number, suoritustapa: string, parentId: number, childId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addSisaltoUusiLapsi.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling addSisaltoUusiLapsi.');
            }
            // verify required parameter 'parentId' is not null or undefined
            if (parentId === null || parentId === undefined) {
                throw new RequiredError('parentId','Required parameter parentId was null or undefined when calling addSisaltoUusiLapsi.');
            }
            // verify required parameter 'childId' is not null or undefined
            if (childId === null || childId === undefined) {
                throw new RequiredError('childId','Required parameter childId was null or undefined when calling addSisaltoUusiLapsi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{parentId}/lapsi/{childId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} parentId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUusiLapsiViitteella(perusteId: number, suoritustapa: string, parentId: number, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addSisaltoUusiLapsiViitteella.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling addSisaltoUusiLapsiViitteella.');
            }
            // verify required parameter 'parentId' is not null or undefined
            if (parentId === null || parentId === undefined) {
                throw new RequiredError('parentId','Required parameter parentId was null or undefined when calling addSisaltoUusiLapsiViitteella.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{parentId}/lapsi/viitteella`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoViiteUUSI(perusteId: number, suoritustapa: string, body?: Matala, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addSisaltoViiteUUSI.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling addSisaltoViiteUUSI.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {string} [muoto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuoritustapaSisaltoUUSI(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', muoto?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getSuoritustapaSisaltoUUSI.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling getSuoritustapaSisaltoUUSI.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (muoto !== undefined) {
                localVarQueryParameter['muoto'] = muoto;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTekstiKappale(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling kloonaaTekstiKappale.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling kloonaaTekstiKappale.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling kloonaaTekstiKappale.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{id}/muokattavakopio`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViite(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeSisaltoViite.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling removeSisaltoViite.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeSisaltoViite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {Laaja} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteWithPost(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateSisaltoViiteWithPost.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling updateSisaltoViiteWithPost.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSisaltoViiteWithPost.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {Laaja} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteWithPut(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateSisaltoViiteWithPut.');
            }
            // verify required parameter 'suoritustapa' is not null or undefined
            if (suoritustapa === null || suoritustapa === undefined) {
                throw new RequiredError('suoritustapa','Required parameter suoritustapa was null or undefined when calling updateSisaltoViiteWithPut.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSisaltoViiteWithPut.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapa}/sisalto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapa"}}`, encodeURIComponent(String(suoritustapa)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SisallotApi - functional programming interface
 * @export
 */
export const SisallotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} perusteenosaViiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoLapsi(perusteId: number, suoritustapa: string, perusteenosaViiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).addSisaltoLapsi(perusteId, suoritustapa, perusteenosaViiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUUSI(perusteId: number, suoritustapa: string, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).addSisaltoUUSI(perusteId, suoritustapa, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} parentId 
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUusiLapsi(perusteId: number, suoritustapa: string, parentId: number, childId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).addSisaltoUusiLapsi(perusteId, suoritustapa, parentId, childId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} parentId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUusiLapsiViitteella(perusteId: number, suoritustapa: string, parentId: number, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).addSisaltoUusiLapsiViitteella(perusteId, suoritustapa, parentId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoViiteUUSI(perusteId: number, suoritustapa: string, body?: Matala, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Matala> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).addSisaltoViiteUUSI(perusteId, suoritustapa, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {string} [muoto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuoritustapaSisaltoUUSI(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', muoto?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerusteenOsaViiteDtoObject> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).getSuoritustapaSisaltoUUSI(perusteId, suoritustapa, muoto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTekstiKappale(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Laaja> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).kloonaaTekstiKappale(perusteId, suoritustapa, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViite(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).removeSisaltoViite(perusteId, suoritustapa, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {Laaja} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteWithPost(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).updateSisaltoViiteWithPost(perusteId, suoritustapa, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {Laaja} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteWithPut(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SisallotApiAxiosParamCreator(configuration).updateSisaltoViiteWithPut(perusteId, suoritustapa, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SisallotApi - factory interface
 * @export
 */
export const SisallotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} perusteenosaViiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoLapsi(perusteId: number, suoritustapa: string, perusteenosaViiteId: number, options?: any): AxiosPromise<Matala> {
            return SisallotApiFp(configuration).addSisaltoLapsi(perusteId, suoritustapa, perusteenosaViiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUUSI(perusteId: number, suoritustapa: string, body?: Matala, options?: any): AxiosPromise<Matala> {
            return SisallotApiFp(configuration).addSisaltoUUSI(perusteId, suoritustapa, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} parentId 
         * @param {number} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUusiLapsi(perusteId: number, suoritustapa: string, parentId: number, childId: number, options?: any): AxiosPromise<Matala> {
            return SisallotApiFp(configuration).addSisaltoUusiLapsi(perusteId, suoritustapa, parentId, childId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {number} parentId 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoUusiLapsiViitteella(perusteId: number, suoritustapa: string, parentId: number, body?: Matala, options?: any): AxiosPromise<Matala> {
            return SisallotApiFp(configuration).addSisaltoUusiLapsiViitteella(perusteId, suoritustapa, parentId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapa 
         * @param {Matala} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSisaltoViiteUUSI(perusteId: number, suoritustapa: string, body?: Matala, options?: any): AxiosPromise<Matala> {
            return SisallotApiFp(configuration).addSisaltoViiteUUSI(perusteId, suoritustapa, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {string} [muoto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuoritustapaSisaltoUUSI(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', muoto?: string, options?: any): AxiosPromise<PerusteenOsaViiteDtoObject> {
            return SisallotApiFp(configuration).getSuoritustapaSisaltoUUSI(perusteId, suoritustapa, muoto, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTekstiKappale(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options?: any): AxiosPromise<Laaja> {
            return SisallotApiFp(configuration).kloonaaTekstiKappale(perusteId, suoritustapa, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSisaltoViite(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options?: any): AxiosPromise<void> {
            return SisallotApiFp(configuration).removeSisaltoViite(perusteId, suoritustapa, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {Laaja} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteWithPost(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options?: any): AxiosPromise<void> {
            return SisallotApiFp(configuration).updateSisaltoViiteWithPost(perusteId, suoritustapa, id, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
         * @param {number} id 
         * @param {Laaja} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSisaltoViiteWithPut(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options?: any): AxiosPromise<void> {
            return SisallotApiFp(configuration).updateSisaltoViiteWithPut(perusteId, suoritustapa, id, body, options)(axios, basePath);
        },
    };
};

/**
 * SisallotApi - object-oriented interface
 * @export
 * @class SisallotApi
 * @extends {BaseAPI}
 */
export class SisallotApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapa 
     * @param {number} perusteenosaViiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public addSisaltoLapsi(perusteId: number, suoritustapa: string, perusteenosaViiteId: number, options?: any) {
        return SisallotApiFp(this.configuration).addSisaltoLapsi(perusteId, suoritustapa, perusteenosaViiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapa 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public addSisaltoUUSI(perusteId: number, suoritustapa: string, body?: Matala, options?: any) {
        return SisallotApiFp(this.configuration).addSisaltoUUSI(perusteId, suoritustapa, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapa 
     * @param {number} parentId 
     * @param {number} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public addSisaltoUusiLapsi(perusteId: number, suoritustapa: string, parentId: number, childId: number, options?: any) {
        return SisallotApiFp(this.configuration).addSisaltoUusiLapsi(perusteId, suoritustapa, parentId, childId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapa 
     * @param {number} parentId 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public addSisaltoUusiLapsiViitteella(perusteId: number, suoritustapa: string, parentId: number, body?: Matala, options?: any) {
        return SisallotApiFp(this.configuration).addSisaltoUusiLapsiViitteella(perusteId, suoritustapa, parentId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapa 
     * @param {Matala} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public addSisaltoViiteUUSI(perusteId: number, suoritustapa: string, body?: Matala, options?: any) {
        return SisallotApiFp(this.configuration).addSisaltoViiteUUSI(perusteId, suoritustapa, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
     * @param {string} [muoto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public getSuoritustapaSisaltoUUSI(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', muoto?: string, options?: any) {
        return SisallotApiFp(this.configuration).getSuoritustapaSisaltoUUSI(perusteId, suoritustapa, muoto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public kloonaaTekstiKappale(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options?: any) {
        return SisallotApiFp(this.configuration).kloonaaTekstiKappale(perusteId, suoritustapa, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public removeSisaltoViite(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, options?: any) {
        return SisallotApiFp(this.configuration).removeSisaltoViite(perusteId, suoritustapa, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
     * @param {number} id 
     * @param {Laaja} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public updateSisaltoViiteWithPost(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options?: any) {
        return SisallotApiFp(this.configuration).updateSisaltoViiteWithPost(perusteId, suoritustapa, id, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapa 
     * @param {number} id 
     * @param {Laaja} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SisallotApi
     */
    public updateSisaltoViiteWithPut(perusteId: number, suoritustapa: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', id: number, body?: Laaja, options?: any) {
        return SisallotApiFp(this.configuration).updateSisaltoViiteWithPut(perusteId, suoritustapa, id, body, options)(this.axios, this.basePath);
    }

}


/**
 * TermitApi - axios parameter creator
 * @export
 */
export const TermitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {TermiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermi(perusteId: number, body?: TermiDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addTermi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/termisto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermi(perusteId: number, id: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling deleteTermi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTermi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/termisto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTermit(perusteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getAllTermit.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/termisto`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} avain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermi(perusteId: number, avain: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getTermi.');
            }
            // verify required parameter 'avain' is not null or undefined
            if (avain === null || avain === undefined) {
                throw new RequiredError('avain','Required parameter avain was null or undefined when calling getTermi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/termisto/{avain}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"avain"}}`, encodeURIComponent(String(avain)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {TermiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermi(perusteId: number, id: number, body?: TermiDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateTermi.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTermi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/termisto/{id}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermitApi - functional programming interface
 * @export
 */
export const TermitApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {TermiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermi(perusteId: number, body?: TermiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermiDto> {
            const localVarAxiosArgs = TermitApiAxiosParamCreator(configuration).addTermi(perusteId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermi(perusteId: number, id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TermitApiAxiosParamCreator(configuration).deleteTermi(perusteId, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTermit(perusteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TermiDto>> {
            const localVarAxiosArgs = TermitApiAxiosParamCreator(configuration).getAllTermit(perusteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} avain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermi(perusteId: number, avain: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermiDto> {
            const localVarAxiosArgs = TermitApiAxiosParamCreator(configuration).getTermi(perusteId, avain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {TermiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermi(perusteId: number, id: number, body?: TermiDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TermiDto> {
            const localVarAxiosArgs = TermitApiAxiosParamCreator(configuration).updateTermi(perusteId, id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TermitApi - factory interface
 * @export
 */
export const TermitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {TermiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTermi(perusteId: number, body?: TermiDto, options?: any): AxiosPromise<TermiDto> {
            return TermitApiFp(configuration).addTermi(perusteId, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermi(perusteId: number, id: number, options?: any): AxiosPromise<void> {
            return TermitApiFp(configuration).deleteTermi(perusteId, id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTermit(perusteId: number, options?: any): AxiosPromise<Array<TermiDto>> {
            return TermitApiFp(configuration).getAllTermit(perusteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} avain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermi(perusteId: number, avain: string, options?: any): AxiosPromise<TermiDto> {
            return TermitApiFp(configuration).getTermi(perusteId, avain, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {number} id 
         * @param {TermiDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermi(perusteId: number, id: number, body?: TermiDto, options?: any): AxiosPromise<TermiDto> {
            return TermitApiFp(configuration).updateTermi(perusteId, id, body, options)(axios, basePath);
        },
    };
};

/**
 * TermitApi - object-oriented interface
 * @export
 * @class TermitApi
 * @extends {BaseAPI}
 */
export class TermitApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {TermiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermitApi
     */
    public addTermi(perusteId: number, body?: TermiDto, options?: any) {
        return TermitApiFp(this.configuration).addTermi(perusteId, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermitApi
     */
    public deleteTermi(perusteId: number, id: number, options?: any) {
        return TermitApiFp(this.configuration).deleteTermi(perusteId, id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermitApi
     */
    public getAllTermit(perusteId: number, options?: any) {
        return TermitApiFp(this.configuration).getAllTermit(perusteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} avain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermitApi
     */
    public getTermi(perusteId: number, avain: string, options?: any) {
        return TermitApiFp(this.configuration).getTermi(perusteId, avain, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {number} id 
     * @param {TermiDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermitApi
     */
    public updateTermi(perusteId: number, id: number, body?: TermiDto, options?: any) {
        return TermitApiFp(this.configuration).updateTermi(perusteId, id, body, options)(this.axios, this.basePath);
    }

}


/**
 * TiedotteetApi - axios parameter creator
 * @export
 */
export const TiedotteetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTiedote(body?: TiedoteDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/tiedotteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTiedote(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTiedote.');
            }
            const localVarPath = `/api/tiedotteet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {boolean} [koulutustyypiton] koulutustyypiton tiedote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTiedotteetBy(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, koulutustyypiton?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/tiedotteet/haku`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (kieli) {
                localVarQueryParameter['kieli'] = kieli;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (perusteeton !== undefined) {
                localVarQueryParameter['perusteeton'] = perusteeton;
            }

            if (julkinen !== undefined) {
                localVarQueryParameter['julkinen'] = julkinen;
            }

            if (yleinen !== undefined) {
                localVarQueryParameter['yleinen'] = yleinen;
            }

            if (tiedoteJulkaisuPaikka) {
                localVarQueryParameter['tiedoteJulkaisuPaikka'] = tiedoteJulkaisuPaikka;
            }

            if (perusteIds) {
                localVarQueryParameter['perusteIds'] = perusteIds;
            }

            if (koulutusTyyppi) {
                localVarQueryParameter['koulutusTyyppi'] = koulutusTyyppi;
            }

            if (jarjestys !== undefined) {
                localVarQueryParameter['jarjestys'] = jarjestys;
            }

            if (jarjestysNouseva !== undefined) {
                localVarQueryParameter['jarjestysNouseva'] = jarjestysNouseva;
            }

            if (koulutustyypiton !== undefined) {
                localVarQueryParameter['koulutustyypiton'] = koulutustyypiton;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [vainJulkiset] 
         * @param {number} [perusteId] 
         * @param {number} [alkaen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTiedotteet(vainJulkiset?: boolean, perusteId?: number, alkaen?: number, options: any = {}): RequestArgs {
            const localVarPath = `/api/tiedotteet`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (vainJulkiset !== undefined) {
                localVarQueryParameter['vainJulkiset'] = vainJulkiset;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (alkaen !== undefined) {
                localVarQueryParameter['alkaen'] = alkaen;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedote(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTiedote.');
            }
            const localVarPath = `/api/tiedotteet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTiedote(id: number, body?: TiedoteDto, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTiedote.');
            }
            const localVarPath = `/api/tiedotteet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TiedotteetApi - functional programming interface
 * @export
 */
export const TiedotteetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTiedote(body?: TiedoteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiedoteDto> {
            const localVarAxiosArgs = TiedotteetApiAxiosParamCreator(configuration).addTiedote(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTiedote(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TiedotteetApiAxiosParamCreator(configuration).deleteTiedote(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {boolean} [koulutustyypiton] koulutustyypiton tiedote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTiedotteetBy(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, koulutustyypiton?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTiedoteDto> {
            const localVarAxiosArgs = TiedotteetApiAxiosParamCreator(configuration).findTiedotteetBy(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, tiedoteJulkaisuPaikka, perusteIds, koulutusTyyppi, jarjestys, jarjestysNouseva, koulutustyypiton, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [vainJulkiset] 
         * @param {number} [perusteId] 
         * @param {number} [alkaen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTiedotteet(vainJulkiset?: boolean, perusteId?: number, alkaen?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TiedoteDto>> {
            const localVarAxiosArgs = TiedotteetApiAxiosParamCreator(configuration).getAllTiedotteet(vainJulkiset, perusteId, alkaen, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedote(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiedoteDto> {
            const localVarAxiosArgs = TiedotteetApiAxiosParamCreator(configuration).getTiedote(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTiedote(id: number, body?: TiedoteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TiedoteDto> {
            const localVarAxiosArgs = TiedotteetApiAxiosParamCreator(configuration).updateTiedote(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TiedotteetApi - factory interface
 * @export
 */
export const TiedotteetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTiedote(body?: TiedoteDto, options?: any): AxiosPromise<TiedoteDto> {
            return TiedotteetApiFp(configuration).addTiedote(body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTiedote(id: number, options?: any): AxiosPromise<void> {
            return TiedotteetApiFp(configuration).deleteTiedote(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary tiedotteiden haku
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {Array<string>} [kieli] tiedotteen kieli
         * @param {string} [nimi] hae nimellä
         * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
         * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
         * @param {boolean} [julkinen] hae julkiset tiedotteet
         * @param {boolean} [yleinen] hae yleiset tiedotteet
         * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
         * @param {Array<number>} [perusteIds] tiedotteen perusteiden
         * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
         * @param {string} [jarjestys] tiedotteen jarjestys
         * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
         * @param {boolean} [koulutustyypiton] koulutustyypiton tiedote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTiedotteetBy(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, koulutustyypiton?: boolean, options?: any): AxiosPromise<PageTiedoteDto> {
            return TiedotteetApiFp(configuration).findTiedotteetBy(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, tiedoteJulkaisuPaikka, perusteIds, koulutusTyyppi, jarjestys, jarjestysNouseva, koulutustyypiton, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [vainJulkiset] 
         * @param {number} [perusteId] 
         * @param {number} [alkaen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTiedotteet(vainJulkiset?: boolean, perusteId?: number, alkaen?: number, options?: any): AxiosPromise<Array<TiedoteDto>> {
            return TiedotteetApiFp(configuration).getAllTiedotteet(vainJulkiset, perusteId, alkaen, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiedote(id: number, options?: any): AxiosPromise<TiedoteDto> {
            return TiedotteetApiFp(configuration).getTiedote(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {TiedoteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTiedote(id: number, body?: TiedoteDto, options?: any): AxiosPromise<TiedoteDto> {
            return TiedotteetApiFp(configuration).updateTiedote(id, body, options)(axios, basePath);
        },
    };
};

/**
 * TiedotteetApi - object-oriented interface
 * @export
 * @class TiedotteetApi
 * @extends {BaseAPI}
 */
export class TiedotteetApi extends BaseAPI {
    /**
     * 
     * @param {TiedoteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiedotteetApi
     */
    public addTiedote(body?: TiedoteDto, options?: any) {
        return TiedotteetApiFp(this.configuration).addTiedote(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiedotteetApi
     */
    public deleteTiedote(id: number, options?: any) {
        return TiedotteetApiFp(this.configuration).deleteTiedote(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary tiedotteiden haku
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {Array<string>} [kieli] tiedotteen kieli
     * @param {string} [nimi] hae nimellä
     * @param {number} [perusteId] hae perusteeseen liitetyt tiedotteet
     * @param {boolean} [perusteeton] hae perusteettomat tiedotteet
     * @param {boolean} [julkinen] hae julkiset tiedotteet
     * @param {boolean} [yleinen] hae yleiset tiedotteet
     * @param {Array<string>} [tiedoteJulkaisuPaikka] tiedotteen julkaisupaikat
     * @param {Array<number>} [perusteIds] tiedotteen perusteiden
     * @param {Array<string>} [koulutusTyyppi] tiedotteen koulutustyypit
     * @param {string} [jarjestys] tiedotteen jarjestys
     * @param {boolean} [jarjestysNouseva] tiedotteen jarjestyksen suunta
     * @param {boolean} [koulutustyypiton] koulutustyypiton tiedote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiedotteetApi
     */
    public findTiedotteetBy(sivu?: number, sivukoko?: number, kieli?: Array<string>, nimi?: string, perusteId?: number, perusteeton?: boolean, julkinen?: boolean, yleinen?: boolean, tiedoteJulkaisuPaikka?: Array<string>, perusteIds?: Array<number>, koulutusTyyppi?: Array<string>, jarjestys?: string, jarjestysNouseva?: boolean, koulutustyypiton?: boolean, options?: any) {
        return TiedotteetApiFp(this.configuration).findTiedotteetBy(sivu, sivukoko, kieli, nimi, perusteId, perusteeton, julkinen, yleinen, tiedoteJulkaisuPaikka, perusteIds, koulutusTyyppi, jarjestys, jarjestysNouseva, koulutustyypiton, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [vainJulkiset] 
     * @param {number} [perusteId] 
     * @param {number} [alkaen] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiedotteetApi
     */
    public getAllTiedotteet(vainJulkiset?: boolean, perusteId?: number, alkaen?: number, options?: any) {
        return TiedotteetApiFp(this.configuration).getAllTiedotteet(vainJulkiset, perusteId, alkaen, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiedotteetApi
     */
    public getTiedote(id: number, options?: any) {
        return TiedotteetApiFp(this.configuration).getTiedote(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {TiedoteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TiedotteetApi
     */
    public updateTiedote(id: number, body?: TiedoteDto, options?: any) {
        return TiedotteetApiFp(this.configuration).updateTiedote(id, body, options)(this.axios, this.basePath);
    }

}


/**
 * TilastotApi - axios parameter creator
 * @export
 */
export const TilastotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaTilastot(options: any = {}): RequestArgs {
            const localVarPath = `/api/tilastot/amosaa`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYlopsTilastot(options: any = {}): RequestArgs {
            const localVarPath = `/api/tilastot/ylops`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TilastotApi - functional programming interface
 * @export
 */
export const TilastotApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaTilastot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>> {
            const localVarAxiosArgs = TilastotApiAxiosParamCreator(configuration).getAmosaaTilastot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYlopsTilastot(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = TilastotApiAxiosParamCreator(configuration).getYlopsTilastot(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TilastotApi - factory interface
 * @export
 */
export const TilastotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmosaaTilastot(options?: any): AxiosPromise<Array<object>> {
            return TilastotApiFp(configuration).getAmosaaTilastot(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYlopsTilastot(options?: any): AxiosPromise<object> {
            return TilastotApiFp(configuration).getYlopsTilastot(options)(axios, basePath);
        },
    };
};

/**
 * TilastotApi - object-oriented interface
 * @export
 * @class TilastotApi
 * @extends {BaseAPI}
 */
export class TilastotApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TilastotApi
     */
    public getAmosaaTilastot(options?: any) {
        return TilastotApiFp(this.configuration).getAmosaaTilastot(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TilastotApi
     */
    public getYlopsTilastot(options?: any) {
        return TilastotApiFp(this.configuration).getYlopsTilastot(options)(this.axios, this.basePath);
    }

}


/**
 * TutkinnonRakenneApi - axios parameter creator
 * @export
 */
export const TutkinnonRakenneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {TutkinnonOsaViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling addTutkinnonOsa.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling addTutkinnonOsa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {TutkinnonOsaViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling attachTutkinnonOsa.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling attachTutkinnonOsa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {Array<TutkinnonOsaViiteLuontiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<TutkinnonOsaViiteLuontiDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling attachTutkinnonOsat.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling attachTutkinnonOsat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/tuo`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getPerusteenTutkinnonOsat.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getPerusteenTutkinnonOsat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', If_None_Match?: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getRakenne.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getRakenne.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/rakenne`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (If_None_Match !== undefined && If_None_Match !== null) {
                localVarHeaderParameter['If-None-Match'] = String(If_None_Match);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getRakenneVersio.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getRakenneVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling getRakenneVersio.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/rakenne/versio/{versioId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenneVersiot(perusteId: number, suoritustapakoodi: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getRakenneVersiot.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getRakenneVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/rakenne/versiot`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaVersiot(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versio: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getTutkinnonOsaVersiot.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getTutkinnonOsaVersiot.');
            }
            // verify required parameter 'versio' is not null or undefined
            if (versio === null || versio === undefined) {
                throw new RequiredError('versio','Required parameter versio was null or undefined when calling getTutkinnonOsaVersiot.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/versiot/{versio}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"versio"}}`, encodeURIComponent(String(versio)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViite(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', viiteId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getTutkinnonOsaViite.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getTutkinnonOsaViite.');
            }
            // verify required parameter 'viiteId' is not null or undefined
            if (viiteId === null || viiteId === undefined) {
                throw new RequiredError('viiteId','Required parameter viiteId was null or undefined when calling getTutkinnonOsaViite.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/{viiteId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"viiteId"}}`, encodeURIComponent(String(viiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViiteByKoodi(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', koodiUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getTutkinnonOsaViiteByKoodi.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getTutkinnonOsaViiteByKoodi.');
            }
            // verify required parameter 'koodiUri' is not null or undefined
            if (koodiUri === null || koodiUri === undefined) {
                throw new RequiredError('koodiUri','Required parameter koodiUri was null or undefined when calling getTutkinnonOsaViiteByKoodi.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/koodi/{koodiUri}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"koodiUri"}}`, encodeURIComponent(String(koodiUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsienTilat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling getTutkinnonOsienTilat.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling getTutkinnonOsienTilat.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/tilat`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling kloonaaTutkinnonOsa.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling kloonaaTutkinnonOsa.');
            }
            // verify required parameter 'osanId' is not null or undefined
            if (osanId === null || osanId === undefined) {
                throw new RequiredError('osanId','Required parameter osanId was null or undefined when calling kloonaaTutkinnonOsa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/{osanId}/muokattavakopio`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"osanId"}}`, encodeURIComponent(String(osanId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling removeTutkinnonOsa.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling removeTutkinnonOsa.');
            }
            // verify required parameter 'osanId' is not null or undefined
            if (osanId === null || osanId === undefined) {
                throw new RequiredError('osanId','Required parameter osanId was null or undefined when calling removeTutkinnonOsa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/{osanId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"osanId"}}`, encodeURIComponent(String(osanId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling revertRakenneVersio.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling revertRakenneVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling revertRakenneVersio.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/rakenne/palauta/{versioId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {Array<SortableDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPerusteenOsaViitteet(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<SortableDto>, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling sortPerusteenOsaViitteet.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling sortPerusteenOsaViitteet.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/jarjesta`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {UpdateDtoRakenneModuuliDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: UpdateDtoRakenneModuuliDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updatePerusteenRakenne.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling updatePerusteenRakenne.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/rakenne`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {TutkinnonOsaViiteUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, body?: TutkinnonOsaViiteUpdateDto, options: any = {}): RequestArgs {
            // verify required parameter 'perusteId' is not null or undefined
            if (perusteId === null || perusteId === undefined) {
                throw new RequiredError('perusteId','Required parameter perusteId was null or undefined when calling updateTutkinnonOsa.');
            }
            // verify required parameter 'suoritustapakoodi' is not null or undefined
            if (suoritustapakoodi === null || suoritustapakoodi === undefined) {
                throw new RequiredError('suoritustapakoodi','Required parameter suoritustapakoodi was null or undefined when calling updateTutkinnonOsa.');
            }
            // verify required parameter 'osanId' is not null or undefined
            if (osanId === null || osanId === undefined) {
                throw new RequiredError('osanId','Required parameter osanId was null or undefined when calling updateTutkinnonOsa.');
            }
            const localVarPath = `/api/perusteet/{perusteId}/suoritustavat/{suoritustapakoodi}/tutkinnonosat/{osanId}`
                .replace(`{${"perusteId"}}`, encodeURIComponent(String(perusteId)))
                .replace(`{${"suoritustapakoodi"}}`, encodeURIComponent(String(suoritustapakoodi)))
                .replace(`{${"osanId"}}`, encodeURIComponent(String(osanId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutkinnonRakenneApi - functional programming interface
 * @export
 */
export const TutkinnonRakenneApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {TutkinnonOsaViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkinnonOsaViiteDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).addTutkinnonOsa(perusteId, suoritustapakoodi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {TutkinnonOsaViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkinnonOsaViiteDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).attachTutkinnonOsa(perusteId, suoritustapakoodi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {Array<TutkinnonOsaViiteLuontiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<TutkinnonOsaViiteLuontiDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).attachTutkinnonOsat(perusteId, suoritustapakoodi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaViiteDto>> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getPerusteenTutkinnonOsat(perusteId, suoritustapakoodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', If_None_Match?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RakenneModuuliDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getRakenne(perusteId, suoritustapakoodi, If_None_Match, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RakenneModuuliDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getRakenneVersio(perusteId, suoritustapakoodi, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenneVersiot(perusteId: number, suoritustapakoodi: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getRakenneVersiot(perusteId, suoritustapakoodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaVersiot(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versio: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaViiteDto>> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getTutkinnonOsaVersiot(perusteId, suoritustapakoodi, versio, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViite(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', viiteId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getTutkinnonOsaViite(perusteId, suoritustapakoodi, viiteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViiteByKoodi(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', koodiUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getTutkinnonOsaViiteByKoodi(perusteId, suoritustapakoodi, koodiUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsienTilat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaTilaDto>> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).getTutkinnonOsienTilat(perusteId, suoritustapakoodi, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkinnonOsaViiteDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).kloonaaTutkinnonOsa(perusteId, suoritustapakoodi, osanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).removeTutkinnonOsa(perusteId, suoritustapakoodi, osanId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RakenneModuuliDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).revertRakenneVersio(perusteId, suoritustapakoodi, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {Array<SortableDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPerusteenOsaViitteet(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<SortableDto>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SortableDto>> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).sortPerusteenOsaViitteet(perusteId, suoritustapakoodi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {UpdateDtoRakenneModuuliDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: UpdateDtoRakenneModuuliDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RakenneModuuliDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).updatePerusteenRakenne(perusteId, suoritustapakoodi, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {TutkinnonOsaViiteUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, body?: TutkinnonOsaViiteUpdateDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkinnonOsaViiteDto> {
            const localVarAxiosArgs = TutkinnonRakenneApiAxiosParamCreator(configuration).updateTutkinnonOsa(perusteId, suoritustapakoodi, osanId, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TutkinnonRakenneApi - factory interface
 * @export
 */
export const TutkinnonRakenneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {TutkinnonOsaViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options?: any): AxiosPromise<TutkinnonOsaViiteDto> {
            return TutkinnonRakenneApiFp(configuration).addTutkinnonOsa(perusteId, suoritustapakoodi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {TutkinnonOsaViiteDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options?: any): AxiosPromise<TutkinnonOsaViiteDto> {
            return TutkinnonRakenneApiFp(configuration).attachTutkinnonOsa(perusteId, suoritustapakoodi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {Array<TutkinnonOsaViiteLuontiDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<TutkinnonOsaViiteLuontiDto>, options?: any): AxiosPromise<void> {
            return TutkinnonRakenneApiFp(configuration).attachTutkinnonOsat(perusteId, suoritustapakoodi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerusteenTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options?: any): AxiosPromise<Array<TutkinnonOsaViiteDto>> {
            return TutkinnonRakenneApiFp(configuration).getPerusteenTutkinnonOsat(perusteId, suoritustapakoodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {string} [If_None_Match] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', If_None_Match?: string, options?: any): AxiosPromise<RakenneModuuliDto> {
            return TutkinnonRakenneApiFp(configuration).getRakenne(perusteId, suoritustapakoodi, If_None_Match, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options?: any): AxiosPromise<RakenneModuuliDto> {
            return TutkinnonRakenneApiFp(configuration).getRakenneVersio(perusteId, suoritustapakoodi, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {string} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRakenneVersiot(perusteId: number, suoritustapakoodi: string, options?: any): AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            return TutkinnonRakenneApiFp(configuration).getRakenneVersiot(perusteId, suoritustapakoodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaVersiot(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versio: number, options?: any): AxiosPromise<Array<TutkinnonOsaViiteDto>> {
            return TutkinnonRakenneApiFp(configuration).getTutkinnonOsaVersiot(perusteId, suoritustapakoodi, versio, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} viiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViite(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', viiteId: number, options?: any): AxiosPromise<void> {
            return TutkinnonRakenneApiFp(configuration).getTutkinnonOsaViite(perusteId, suoritustapakoodi, viiteId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaViiteByKoodi(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', koodiUri: string, options?: any): AxiosPromise<void> {
            return TutkinnonRakenneApiFp(configuration).getTutkinnonOsaViiteByKoodi(perusteId, suoritustapakoodi, koodiUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsienTilat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options?: any): AxiosPromise<Array<TutkinnonOsaTilaDto>> {
            return TutkinnonRakenneApiFp(configuration).getTutkinnonOsienTilat(perusteId, suoritustapakoodi, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kloonaaTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options?: any): AxiosPromise<TutkinnonOsaViiteDto> {
            return TutkinnonRakenneApiFp(configuration).kloonaaTutkinnonOsa(perusteId, suoritustapakoodi, osanId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options?: any): AxiosPromise<void> {
            return TutkinnonRakenneApiFp(configuration).removeTutkinnonOsa(perusteId, suoritustapakoodi, osanId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options?: any): AxiosPromise<RakenneModuuliDto> {
            return TutkinnonRakenneApiFp(configuration).revertRakenneVersio(perusteId, suoritustapakoodi, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {Array<SortableDto>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sortPerusteenOsaViitteet(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<SortableDto>, options?: any): AxiosPromise<Array<SortableDto>> {
            return TutkinnonRakenneApiFp(configuration).sortPerusteenOsaViitteet(perusteId, suoritustapakoodi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {UpdateDtoRakenneModuuliDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerusteenRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: UpdateDtoRakenneModuuliDto, options?: any): AxiosPromise<RakenneModuuliDto> {
            return TutkinnonRakenneApiFp(configuration).updatePerusteenRakenne(perusteId, suoritustapakoodi, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} perusteId 
         * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
         * @param {number} osanId 
         * @param {TutkinnonOsaViiteUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, body?: TutkinnonOsaViiteUpdateDto, options?: any): AxiosPromise<TutkinnonOsaViiteDto> {
            return TutkinnonRakenneApiFp(configuration).updateTutkinnonOsa(perusteId, suoritustapakoodi, osanId, body, options)(axios, basePath);
        },
    };
};

/**
 * TutkinnonRakenneApi - object-oriented interface
 * @export
 * @class TutkinnonRakenneApi
 * @extends {BaseAPI}
 */
export class TutkinnonRakenneApi extends BaseAPI {
    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {TutkinnonOsaViiteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public addTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).addTutkinnonOsa(perusteId, suoritustapakoodi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {TutkinnonOsaViiteDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public attachTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: TutkinnonOsaViiteDto, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).attachTutkinnonOsa(perusteId, suoritustapakoodi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {Array<TutkinnonOsaViiteLuontiDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public attachTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<TutkinnonOsaViiteLuontiDto>, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).attachTutkinnonOsat(perusteId, suoritustapakoodi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getPerusteenTutkinnonOsat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getPerusteenTutkinnonOsat(perusteId, suoritustapakoodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {string} [If_None_Match] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', If_None_Match?: string, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getRakenne(perusteId, suoritustapakoodi, If_None_Match, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getRakenneVersio(perusteId, suoritustapakoodi, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {string} suoritustapakoodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getRakenneVersiot(perusteId: number, suoritustapakoodi: string, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getRakenneVersiot(perusteId, suoritustapakoodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} versio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getTutkinnonOsaVersiot(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versio: number, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getTutkinnonOsaVersiot(perusteId, suoritustapakoodi, versio, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} viiteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getTutkinnonOsaViite(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', viiteId: number, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getTutkinnonOsaViite(perusteId, suoritustapakoodi, viiteId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {string} koodiUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getTutkinnonOsaViiteByKoodi(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', koodiUri: string, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getTutkinnonOsaViiteByKoodi(perusteId, suoritustapakoodi, koodiUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public getTutkinnonOsienTilat(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).getTutkinnonOsienTilat(perusteId, suoritustapakoodi, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} osanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public kloonaaTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).kloonaaTutkinnonOsa(perusteId, suoritustapakoodi, osanId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} osanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public removeTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).removeTutkinnonOsa(perusteId, suoritustapakoodi, osanId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public revertRakenneVersio(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', versioId: number, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).revertRakenneVersio(perusteId, suoritustapakoodi, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {Array<SortableDto>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public sortPerusteenOsaViitteet(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: Array<SortableDto>, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).sortPerusteenOsaViitteet(perusteId, suoritustapakoodi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {UpdateDtoRakenneModuuliDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public updatePerusteenRakenne(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', body?: UpdateDtoRakenneModuuliDto, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).updatePerusteenRakenne(perusteId, suoritustapakoodi, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} perusteId 
     * @param {'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO'} suoritustapakoodi 
     * @param {number} osanId 
     * @param {TutkinnonOsaViiteUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonRakenneApi
     */
    public updateTutkinnonOsa(perusteId: number, suoritustapakoodi: 'OPS' | 'NAYTTO' | 'REFORMI' | 'PERUSOPETUS' | 'LISAOPETUS' | 'VARHAISKASVATUS' | 'OPAS' | 'ESIOPETUS' | 'AIPE' | 'TPO' | 'LUKIOKOULUTUS' | 'LUKIOKOULUTUS2019' | 'VAPAASIVISTYSTYO', osanId: number, body?: TutkinnonOsaViiteUpdateDto, options?: any) {
        return TutkinnonRakenneApiFp(this.configuration).updateTutkinnonOsa(perusteId, suoritustapakoodi, osanId, body, options)(this.axios, this.basePath);
    }

}


/**
 * TutkinnonosatApi - axios parameter creator
 * @export
 */
export const TutkinnonosatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary hae tutkinnon osiin liittyvät viitteet
         * @param {number} tutkinnonOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsaViitteet(tutkinnonOsaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'tutkinnonOsaId' is not null or undefined
            if (tutkinnonOsaId === null || tutkinnonOsaId === undefined) {
                throw new RequiredError('tutkinnonOsaId','Required parameter tutkinnonOsaId was null or undefined when calling getAllTutkinnonOsaViitteet.');
            }
            const localVarPath = `/api/tutkinnonosat/{tutkinnonOsaId}/viitteet`
                .replace(`{${"tutkinnonOsaId"}}`, encodeURIComponent(String(tutkinnonOsaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary hae tutkinnon osia
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {number} [perusteId] 
         * @param {boolean} [vanhentuneet] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsatBy(sivu?: number, sivukoko?: number, nimi?: string, perusteId?: number, vanhentuneet?: boolean, kieli?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/tutkinnonosat/all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (nimi !== undefined) {
                localVarQueryParameter['nimi'] = nimi;
            }

            if (perusteId !== undefined) {
                localVarQueryParameter['perusteId'] = perusteId;
            }

            if (vanhentuneet !== undefined) {
                localVarQueryParameter['vanhentuneet'] = vanhentuneet;
            }

            if (kieli !== undefined) {
                localVarQueryParameter['kieli'] = kieli;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary hae tutkinnon osia
         * @param {string} koodiUri tutkinnonosakoodi
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsatByKoodiUri(koodiUri: string, sivu?: number, sivukoko?: number, options: any = {}): RequestArgs {
            // verify required parameter 'koodiUri' is not null or undefined
            if (koodiUri === null || koodiUri === undefined) {
                throw new RequiredError('koodiUri','Required parameter koodiUri was null or undefined when calling getAllTutkinnonOsatByKoodiUri.');
            }
            const localVarPath = `/api/tutkinnonosat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sivu !== undefined) {
                localVarQueryParameter['sivu'] = sivu;
            }

            if (sivukoko !== undefined) {
                localVarQueryParameter['sivukoko'] = sivukoko;
            }

            if (koodiUri !== undefined) {
                localVarQueryParameter['koodiUri'] = koodiUri;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutkinnonosatApi - functional programming interface
 * @export
 */
export const TutkinnonosatApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary hae tutkinnon osiin liittyvät viitteet
         * @param {number} tutkinnonOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsaViitteet(tutkinnonOsaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TutkinnonOsaViiteKontekstiDto>> {
            const localVarAxiosArgs = TutkinnonosatApiAxiosParamCreator(configuration).getAllTutkinnonOsaViitteet(tutkinnonOsaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary hae tutkinnon osia
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {number} [perusteId] 
         * @param {boolean} [vanhentuneet] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsatBy(sivu?: number, sivukoko?: number, nimi?: string, perusteId?: number, vanhentuneet?: boolean, kieli?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTutkinnonOsaViiteKontekstiDto> {
            const localVarAxiosArgs = TutkinnonosatApiAxiosParamCreator(configuration).getAllTutkinnonOsatBy(sivu, sivukoko, nimi, perusteId, vanhentuneet, kieli, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary hae tutkinnon osia
         * @param {string} koodiUri tutkinnonosakoodi
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsatByKoodiUri(koodiUri: string, sivu?: number, sivukoko?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTutkinnonOsaDto> {
            const localVarAxiosArgs = TutkinnonosatApiAxiosParamCreator(configuration).getAllTutkinnonOsatByKoodiUri(koodiUri, sivu, sivukoko, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TutkinnonosatApi - factory interface
 * @export
 */
export const TutkinnonosatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary hae tutkinnon osiin liittyvät viitteet
         * @param {number} tutkinnonOsaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsaViitteet(tutkinnonOsaId: number, options?: any): AxiosPromise<Array<TutkinnonOsaViiteKontekstiDto>> {
            return TutkinnonosatApiFp(configuration).getAllTutkinnonOsaViitteet(tutkinnonOsaId, options)(axios, basePath);
        },
        /**
         * 
         * @summary hae tutkinnon osia
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {string} [nimi] 
         * @param {number} [perusteId] 
         * @param {boolean} [vanhentuneet] 
         * @param {string} [kieli] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsatBy(sivu?: number, sivukoko?: number, nimi?: string, perusteId?: number, vanhentuneet?: boolean, kieli?: string, options?: any): AxiosPromise<PageTutkinnonOsaViiteKontekstiDto> {
            return TutkinnonosatApiFp(configuration).getAllTutkinnonOsatBy(sivu, sivukoko, nimi, perusteId, vanhentuneet, kieli, options)(axios, basePath);
        },
        /**
         * 
         * @summary hae tutkinnon osia
         * @param {string} koodiUri tutkinnonosakoodi
         * @param {number} [sivu] 
         * @param {number} [sivukoko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTutkinnonOsatByKoodiUri(koodiUri: string, sivu?: number, sivukoko?: number, options?: any): AxiosPromise<PageTutkinnonOsaDto> {
            return TutkinnonosatApiFp(configuration).getAllTutkinnonOsatByKoodiUri(koodiUri, sivu, sivukoko, options)(axios, basePath);
        },
    };
};

/**
 * TutkinnonosatApi - object-oriented interface
 * @export
 * @class TutkinnonosatApi
 * @extends {BaseAPI}
 */
export class TutkinnonosatApi extends BaseAPI {
    /**
     * 
     * @summary hae tutkinnon osiin liittyvät viitteet
     * @param {number} tutkinnonOsaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatApi
     */
    public getAllTutkinnonOsaViitteet(tutkinnonOsaId: number, options?: any) {
        return TutkinnonosatApiFp(this.configuration).getAllTutkinnonOsaViitteet(tutkinnonOsaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary hae tutkinnon osia
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {string} [nimi] 
     * @param {number} [perusteId] 
     * @param {boolean} [vanhentuneet] 
     * @param {string} [kieli] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatApi
     */
    public getAllTutkinnonOsatBy(sivu?: number, sivukoko?: number, nimi?: string, perusteId?: number, vanhentuneet?: boolean, kieli?: string, options?: any) {
        return TutkinnonosatApiFp(this.configuration).getAllTutkinnonOsatBy(sivu, sivukoko, nimi, perusteId, vanhentuneet, kieli, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary hae tutkinnon osia
     * @param {string} koodiUri tutkinnonosakoodi
     * @param {number} [sivu] 
     * @param {number} [sivukoko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatApi
     */
    public getAllTutkinnonOsatByKoodiUri(koodiUri: string, sivu?: number, sivukoko?: number, options?: any) {
        return TutkinnonosatApiFp(this.configuration).getAllTutkinnonOsatByKoodiUri(koodiUri, sivu, sivukoko, options)(this.axios, this.basePath);
    }

}


/**
 * TutkinnonosatPrivateApi - axios parameter creator
 * @export
 */
export const TutkinnonosatPrivateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaByKoodi(koodiUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodiUri' is not null or undefined
            if (koodiUri === null || koodiUri === undefined) {
                throw new RequiredError('koodiUri','Required parameter koodiUri was null or undefined when calling getTutkinnonOsaByKoodi.');
            }
            const localVarPath = `/api/tutkinnonosat/koodi/uniikki/{koodiUri}`
                .replace(`{${"koodiUri"}}`, encodeURIComponent(String(koodiUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} koodiuri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaByKoodit(koodiuri: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'koodiuri' is not null or undefined
            if (koodiuri === null || koodiuri === undefined) {
                throw new RequiredError('koodiuri','Required parameter koodiuri was null or undefined when calling getTutkinnonOsaByKoodit.');
            }
            const localVarPath = `/api/tutkinnonosat/koodi/uniikit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (koodiuri) {
                localVarQueryParameter['koodiuri'] = koodiuri;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaKaikkiDtoByKoodi(koodiUri: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodiUri' is not null or undefined
            if (koodiUri === null || koodiUri === undefined) {
                throw new RequiredError('koodiUri','Required parameter koodiUri was null or undefined when calling getTutkinnonOsaKaikkiDtoByKoodi.');
            }
            const localVarPath = `/api/tutkinnonosat/kaikki/{koodiUri}`
                .replace(`{${"koodiUri"}}`, encodeURIComponent(String(koodiUri)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViiteVersio(id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getViiteVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling getViiteVersio.');
            }
            const localVarPath = `/api/tutkinnonosat/viite/{id}/versio/{versioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViiteVersiot(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getViiteVersiot.');
            }
            const localVarPath = `/api/tutkinnonosat/viite/{id}/versiot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToVersio(id: number, versioId: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling revertToVersio.');
            }
            // verify required parameter 'versioId' is not null or undefined
            if (versioId === null || versioId === undefined) {
                throw new RequiredError('versioId','Required parameter versioId was null or undefined when calling revertToVersio.');
            }
            const localVarPath = `/api/tutkinnonosat/palauta/viite/{id}/versio/{versioId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"versioId"}}`, encodeURIComponent(String(versioId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutkinnonosatPrivateApi - functional programming interface
 * @export
 */
export const TutkinnonosatPrivateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaByKoodi(koodiUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonosatPrivateApiAxiosParamCreator(configuration).getTutkinnonOsaByKoodi(koodiUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} koodiuri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaByKoodit(koodiuri: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonosatPrivateApiAxiosParamCreator(configuration).getTutkinnonOsaByKoodit(koodiuri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaKaikkiDtoByKoodi(koodiUri: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TutkinnonosatPrivateApiAxiosParamCreator(configuration).getTutkinnonOsaKaikkiDtoByKoodi(koodiUri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViiteVersio(id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkinnonOsaViiteDto> {
            const localVarAxiosArgs = TutkinnonosatPrivateApiAxiosParamCreator(configuration).getViiteVersio(id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViiteVersiot(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            const localVarAxiosArgs = TutkinnonosatPrivateApiAxiosParamCreator(configuration).getViiteVersiot(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToVersio(id: number, versioId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TutkinnonOsaViiteDto> {
            const localVarAxiosArgs = TutkinnonosatPrivateApiAxiosParamCreator(configuration).revertToVersio(id, versioId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TutkinnonosatPrivateApi - factory interface
 * @export
 */
export const TutkinnonosatPrivateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaByKoodi(koodiUri: string, options?: any): AxiosPromise<void> {
            return TutkinnonosatPrivateApiFp(configuration).getTutkinnonOsaByKoodi(koodiUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} koodiuri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaByKoodit(koodiuri: Array<string>, options?: any): AxiosPromise<void> {
            return TutkinnonosatPrivateApiFp(configuration).getTutkinnonOsaByKoodit(koodiuri, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} koodiUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutkinnonOsaKaikkiDtoByKoodi(koodiUri: string, options?: any): AxiosPromise<void> {
            return TutkinnonosatPrivateApiFp(configuration).getTutkinnonOsaKaikkiDtoByKoodi(koodiUri, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViiteVersio(id: number, versioId: number, options?: any): AxiosPromise<TutkinnonOsaViiteDto> {
            return TutkinnonosatPrivateApiFp(configuration).getViiteVersio(id, versioId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViiteVersiot(id: number, options?: any): AxiosPromise<Array<CombinedDtoRevisionHenkiloTietoDto>> {
            return TutkinnonosatPrivateApiFp(configuration).getViiteVersiot(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} versioId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertToVersio(id: number, versioId: number, options?: any): AxiosPromise<TutkinnonOsaViiteDto> {
            return TutkinnonosatPrivateApiFp(configuration).revertToVersio(id, versioId, options)(axios, basePath);
        },
    };
};

/**
 * TutkinnonosatPrivateApi - object-oriented interface
 * @export
 * @class TutkinnonosatPrivateApi
 * @extends {BaseAPI}
 */
export class TutkinnonosatPrivateApi extends BaseAPI {
    /**
     * 
     * @param {string} koodiUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatPrivateApi
     */
    public getTutkinnonOsaByKoodi(koodiUri: string, options?: any) {
        return TutkinnonosatPrivateApiFp(this.configuration).getTutkinnonOsaByKoodi(koodiUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} koodiuri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatPrivateApi
     */
    public getTutkinnonOsaByKoodit(koodiuri: Array<string>, options?: any) {
        return TutkinnonosatPrivateApiFp(this.configuration).getTutkinnonOsaByKoodit(koodiuri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} koodiUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatPrivateApi
     */
    public getTutkinnonOsaKaikkiDtoByKoodi(koodiUri: string, options?: any) {
        return TutkinnonosatPrivateApiFp(this.configuration).getTutkinnonOsaKaikkiDtoByKoodi(koodiUri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatPrivateApi
     */
    public getViiteVersio(id: number, versioId: number, options?: any) {
        return TutkinnonosatPrivateApiFp(this.configuration).getViiteVersio(id, versioId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatPrivateApi
     */
    public getViiteVersiot(id: number, options?: any) {
        return TutkinnonosatPrivateApiFp(this.configuration).getViiteVersiot(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {number} versioId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutkinnonosatPrivateApi
     */
    public revertToVersio(id: number, versioId: number, options?: any) {
        return TutkinnonosatPrivateApiFp(this.configuration).revertToVersio(id, versioId, options)(this.axios, this.basePath);
    }

}


/**
 * UlkopuolisetApi - axios parameter creator
 * @export
 */
export const UlkopuolisetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodisto(koodisto: string, haku?: string, options: any = {}): RequestArgs {
            // verify required parameter 'koodisto' is not null or undefined
            if (koodisto === null || koodisto === undefined) {
                throw new RequiredError('koodisto','Required parameter koodisto was null or undefined when calling getKoodisto.');
            }
            const localVarPath = `/api/ulkopuoliset/{koodisto}`
                .replace(`{${"koodisto"}}`, encodeURIComponent(String(koodisto)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (haku !== undefined) {
                localVarQueryParameter['haku'] = haku;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmat(options: any = {}): RequestArgs {
            const localVarPath = `/api/ulkopuoliset/organisaatioryhmat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmatByOid(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getOrganisaatioRyhmatByOid.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatioryhmat/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioVirkailijat(oid: string, options: any = {}): RequestArgs {
            // verify required parameter 'oid' is not null or undefined
            if (oid === null || oid === undefined) {
                throw new RequiredError('oid','Required parameter oid was null or undefined when calling getOrganisaatioVirkailijat.');
            }
            const localVarPath = `/api/ulkopuoliset/organisaatiovirkailijat/{oid}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UlkopuolisetApi - functional programming interface
 * @export
 */
export const UlkopuolisetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodisto(koodisto: string, haku?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageKoodistoKoodiDto> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getKoodisto(koodisto, haku, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmat(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOrganisaatioRyhmat(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmatByOid(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOrganisaatioRyhmatByOid(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioVirkailijat(oid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = UlkopuolisetApiAxiosParamCreator(configuration).getOrganisaatioVirkailijat(oid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UlkopuolisetApi - factory interface
 * @export
 */
export const UlkopuolisetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} koodisto 
         * @param {string} [haku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKoodisto(koodisto: string, haku?: string, options?: any): AxiosPromise<PageKoodistoKoodiDto> {
            return UlkopuolisetApiFp(configuration).getKoodisto(koodisto, haku, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmat(options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getOrganisaatioRyhmat(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioRyhmatByOid(oid: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getOrganisaatioRyhmatByOid(oid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} oid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisaatioVirkailijat(oid: string, options?: any): AxiosPromise<object> {
            return UlkopuolisetApiFp(configuration).getOrganisaatioVirkailijat(oid, options)(axios, basePath);
        },
    };
};

/**
 * UlkopuolisetApi - object-oriented interface
 * @export
 * @class UlkopuolisetApi
 * @extends {BaseAPI}
 */
export class UlkopuolisetApi extends BaseAPI {
    /**
     * 
     * @param {string} koodisto 
     * @param {string} [haku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getKoodisto(koodisto: string, haku?: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getKoodisto(koodisto, haku, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOrganisaatioRyhmat(options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOrganisaatioRyhmat(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOrganisaatioRyhmatByOid(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOrganisaatioRyhmatByOid(oid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} oid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UlkopuolisetApi
     */
    public getOrganisaatioVirkailijat(oid: string, options?: any) {
        return UlkopuolisetApiFp(this.configuration).getOrganisaatioVirkailijat(oid, options)(this.axios, this.basePath);
    }

}


