import { render, staticRenderFns } from "./RouteOpintokokonaisuus.vue?vue&type=template&id=d8c5e842&scoped=true"
import script from "./RouteOpintokokonaisuus.vue?vue&type=script&lang=ts"
export * from "./RouteOpintokokonaisuus.vue?vue&type=script&lang=ts"
import style0 from "./RouteOpintokokonaisuus.vue?vue&type=style&index=0&id=d8c5e842&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8c5e842",
  null
  
)

export default component.exports