
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpContent from '@shared/components/EpContent/EpContent.vue';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import { Kielet } from '@shared/stores/kieli';
import { ITPalauteProvider } from '@shared/stores/types';
import EpMaterialIcon from '@shared/components/EpMaterialIcon/EpMaterialIcon.vue';

interface Rating {
  value: number,
  selected: boolean,
}

@Component({
  components: {
    EpContent,
    EpButton,
    EpMaterialIcon,
  },
})
export default class EpFeedbackModal extends Vue {
  private hoveredRating = 0;
  private selectedRating = 0;
  private ratings = Array.from({ length: 5 }, (v, k) => ({ value: k + 1, selected: false }));
  private feedbackMessage = '';
  private feedbackSent = false;
  private isSending = false;

  @Prop({ required: true })
  private palauteProvider!: ITPalauteProvider;

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.feedbackSent = false;
      this.feedbackMessage = '';
      this.selectedRating = 0;
      this.ratings = this.ratings.map(rating => ({ ...rating, selected: false }));
    });
  }

  get tutkintorakennepalaute() {
    return this.palauteProvider.tutkintorakennepalaute.value;
  }

  showModal() {
    this.$bvModal.show('feedback-modal');
  }

  onSelectRating(selectedRating: Rating) {
    this.selectedRating = selectedRating.value;
    this.ratings = this.currentRatings.map(rating => ({
      ...rating,
      selected: rating.value === selectedRating.value,
    }),
    );
  }

  onRatingHover(val: number) {
    this.hoveredRating = val;
  }

  onRatingBlur() {
    this.hoveredRating = 0;
  }

  async onFeedbackSubmit() {
    this.isSending = true;
    await this.palauteProvider.sendPalaute({
      stars: this.selectedRating,
      feedback: this.feedbackMessage,
      user_agent: navigator.userAgent,
    });
    this.isSending = false;
    this.feedbackSent = true;
    (this.$refs.feedbackHeader as HTMLElement).focus();
  }

  isActiveRating(rating: Rating) {
    return rating.value <= this.hoveredRating || rating.selected || rating.value < this.selectedRating;
  }

  get currentRatings() {
    return this.ratings;
  }

  get hasSelectedRating() {
    return this.currentRatings.some(rating => rating.selected);
  }

  get sisaltokieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get furtherFeedbackUrl() {
    return `https://www.oph.fi/${this.sisaltokieli}/koulutus-ja-tutkinnot/tutkintorakenne/lomake`;
  }
}
