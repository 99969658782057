import { render, staticRenderFns } from "./RouteAmmatillinenSelaus.vue?vue&type=template&id=6ae7d687&scoped=true"
import script from "./RouteAmmatillinenSelaus.vue?vue&type=script&lang=ts"
export * from "./RouteAmmatillinenSelaus.vue?vue&type=script&lang=ts"
import style0 from "./RouteAmmatillinenSelaus.vue?vue&type=style&index=0&id=6ae7d687&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae7d687",
  null
  
)

export default component.exports