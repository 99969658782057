
import { NavigationNodeDto } from '@shared/tyypit';
import * as _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EpNavigationPostFix extends Vue {
  @Prop()
  node!: NavigationNodeDto;

  get postfixLabel(): string {
    return _.toString(this.node.meta?.postfix_label);
  }

  get postfixTooltip(): string {
    return _.toString(this.node.meta?.postfix_tooltip);
  }
}
