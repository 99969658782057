
import { Component, Prop, Vue } from 'vue-property-decorator';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpContent from '@shared/components/EpContent/EpContent.vue';
import EpAlert from '@shared/components/EpAlert/EpAlert.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';

@Component({
  components: {
    EpCollapse,
    EpContent,
    EpAlert,
    EpContentViewer,
  },
} as any)
export default class EpPerusteContent extends Vue {
  @Prop({ required: false })
  private perusteObject!: any;

  @Prop({ required: false })
  private pohjaObject!: any;

  @Prop({ required: false })
  private object!: any;

  @Prop({ default: false })
  private isEditing!: boolean;

  @Prop({ default: 'otsikko' })
  private otsikko!: string;

  @Prop({ default: 'teksti' })
  private teksti!: string;

  @Prop({ default: false })
  private perusteTekstiAvattu!: boolean;

  @Prop({ default: true })
  private naytaSisaltoTyhjana!: boolean;

  @Prop({ required: false })
  private kuvat!: any[];

  @Prop({ required: false })
  private termit!: any[];

  get hasContent() {
    return this.object != null && this.object[this.teksti] != null;
  }
}
