
import { Component, Vue } from 'vue-property-decorator';
import EpLinkki from '@shared/components/EpLinkki/EpLinkki.vue';
import ophLogo from '@assets/img/banners/opintopolku/logo.svg';
import opintopolkuLogo from '@assets/img/banners/opintopolku/oph_logo2.png';

@Component({
  name: 'EpFooter',
  components: {
    EpLinkki,
  },
})
export default class EpFooter extends Vue {
  get ophLogo() {
    return ophLogo;
  }

  get opintopolkuLogo() {
    return opintopolkuLogo;
  }

  get linkit() {
    return {
      oph: {
        fi: 'https://www.oph.fi/fi',
        sv: 'https://www.oph.fi/sv',
        en: 'https://www.oph.fi/en',
      },
      virkailija: {
        fi: 'http://virkailija.opintopolku.fi',
        sv: 'http://virkailija.opintopolku.fi',
      },
      palaute: {
        fi: 'mailto:eperusteet@opintopolku.fi',
        sv: 'mailto:eperusteet@opintopolku.fi',
      },
      saavutettavuusseloste: {
        fi: 'https://opintopolku.fi/konfo/fi/sivu/eperusteet-saavutettavuusseloste',
        sv: 'https://opintopolku.fi/konfo/sv/sivu/tillganglighetsutlatande-for-egrunder',
      },
    };
  }
}
